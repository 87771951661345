const ImageInstructionStep = ({ image, textBelow, children }) => {
  const textDiv = (
    <div style={{
      fontWeight: 'bold',
      marginBottom: '4px'
    }}>
      {children}
    </div>
  );

  const imgStyle = {
    maxWidth: '100%',
    height: 'auto',
    border: '1px solid',
    marginBottom: '4px'
  };

  if (!textBelow) {
    imgStyle.marginBottom = '32px';
  }

  return (
    <>
      {!textBelow && textDiv}

      <img src={image} alt="" style={imgStyle} />

      {textBelow && textDiv}
    </>
  );
};

export default ImageInstructionStep;
