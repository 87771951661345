import { useTranslation } from '../common/i18n';

import { APIForm } from '../api';
import { useUsernameChangeForm, logout } from '.';

const UsernameChangeForm: React.FC = () => (
  <APIForm
    method="POST"
    path="/users/me/username-change"
    form={useUsernameChangeForm()}
    submitText={useTranslation('Change username')}
    onSuccess={(): void => { setTimeout(logout, 3000); }}
    successMessage={useTranslation('Username changed successfully! You will now be logged out.')}
  />
);

export default UsernameChangeForm;
