import { Button } from 'react-bootstrap';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

const OpenButton = ({ as, to, target, rel, style, size, noMargin, disabled, verticalAlign, ...props }) => (
  <span {...props}>
    <Button
      as={as}
      to={to}
      target={target}
      rel={rel}
      variant="link"
      disabled={disabled}
      style={{
        verticalAlign: (verticalAlign || 'baseline'),
        padding: 0,
        border: 0,
        marginLeft: (noMargin ? 0 : '4px'),
        ...style
      }}
    >
      <BoxArrowUpRight size={size} />
    </Button>
  </span>
);

export default OpenButton;
