import { T } from '../common/i18n';

import type { PagedTitle } from '../api/models';

const AllocationHistoryJSON: React.FC<{
  pagedTitle: PagedTitle
}> = ({ pagedTitle }) => (
  <>
    <strong>
      <T>Allocation History</T>
    </strong>

    <pre>
      {JSON.stringify(pagedTitle?.allocationHistory, null, 2)}
    </pre>
  </>
);

export default AllocationHistoryJSON;
