import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

import type { RFIDReader, RFIDTag } from '../../api/models';

interface RFIDState {
  reader: RFIDReader | null;
  lastTagRead: RFIDTag | null;
  currentTagsRead: RFIDTag[];
}

const initialState: RFIDState = {
  reader: null,
  lastTagRead: null,
  currentTagsRead: []
};

const reducers = {
  setReader: (state: RFIDState, action: PayloadAction<RFIDReader>): void => {
    state.reader = action.payload;
  },
  clearReader: (state: RFIDState): void => {
    state.reader = null;
  }
};

const rfidSlice = createSlice({
  name: 'rfid',
  initialState,
  reducers
});

export const selectReader = (state: RootState): RFIDReader | null =>
  state.rfid.reader;

export const {
  setReader,
  clearReader
} = rfidSlice.actions;

export default rfidSlice.reducer;
