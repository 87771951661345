import { T } from '../common/i18n';

import type { PagedTitle } from '../api/models';

const PagedTitleJSON: React.FC<{
  pagedTitle: PagedTitle
}> = ({ pagedTitle }) => (
  <>
    <strong>
      <T>Paged Title</T>
    </strong>

    <pre>
      {JSON.stringify(
        Object.fromEntries(Object.entries(pagedTitle).filter(([key]) => (
          ![
            'TitlePagingReports',
            'allocationHistory',
            'PagedTitleEvents'
          ].includes(key)
        ))),
        null,
        2
      )}
    </pre>
  </>
);

export default PagedTitleJSON;
