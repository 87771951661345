import { useState } from 'react';

import { Buttons } from '../../common/layout/buttons';
import { useT } from '../../common/i18n';

import {
  MassUpdateCommonForm as CommonForm,
  MassUpdateBibForm as BibForm,
  MassUpdateItemForm as ItemForm
} from '.';

import type { IButton } from '../../common/layout/buttons';
import type { RecordType, Operation } from '.';

const useButtons = (
  validateDisabled: boolean,
  executeDisabled: boolean
): IButton[] => {
  const t = useT();

  return [
    {
      id: 'validate-button',
      variant: 'primary',
      content: t('Validate'),
      disabled: validateDisabled
    },
    {
      id: 'execute-button',
      variant: 'secondary',
      content: t('Execute'),
      disabled: executeDisabled
    }
  ];
};

const MassUpdateCreate: React.FC<{
  defaultRecordType: RecordType,
  defaultQueryJSON?: string
}> = ({
  defaultRecordType,
  defaultQueryJSON
}) => {
  const [operation, setOperation] = useState<Operation>({
    recordType: defaultRecordType,
    queryJSON: defaultQueryJSON ?? null
  });

  return (
    <>
      <CommonForm
        operation={operation}
        setOperation={setOperation}
        defaultRecordType={defaultRecordType}
      />

      {(operation?.recordType ?? defaultRecordType) === 'b'
        ? <BibForm setOperation={setOperation} />
        : <ItemForm setOperation={setOperation} />}

      <Buttons buttons={useButtons(!operation?.queryJSON, true)} />
    </>
  );
};

export default MassUpdateCreate;
