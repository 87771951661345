import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import { Form, Row, Col } from 'react-bootstrap';

const MassreaderSelector = ({ reader, setReader, children }) => {
  const { t } = useTranslation();

  const [massreaders, loading, error] = useAPI('GET', '/rfid/massreaders');

  if (error) return t('Could not fetch massreaders');

  return (
    <Form>
      <Form.Group as={Row} style={{ marginBottom: 0 }}>
        <Form.Label xs="auto" column="sm">
          {t('Reader')}
        </Form.Label>

        <Col xs="auto" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Form.Control
            as="select"
            size="sm"
            value={reader || 'none'}
            onChange={event => setReader(event.target.value === 'none' ? null : event.target.value)}
          >
            <option value="none">
              {loading ? t('Loading...') : t('Select reader...')}
            </option>

            {!loading && !error && massreaders.map(({ name }) => (
              <option key={name}>
                {name}
              </option>
            ))}
          </Form.Control>
        </Col>

        {children && (
          <Col xs="auto" style={{ paddingLeft: 0 }}>
            {children}
          </Col>
        )}
      </Form.Group>
    </Form>
  );
};

export default MassreaderSelector;
