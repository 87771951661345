import sierraGlobals from '../sierraGlobals';

export const formatLocationCode = (login, locationCode) => {
  if (locationCode === undefined || locationCode === null) {
    if (login === undefined || login === null) {
      return null;
    } else {
      return login;
    }
  }

  const arr = [];

  if (locationCode.agency) arr.push(locationCode.agency);
  if (locationCode.name) arr.push(locationCode.name);
  if (locationCode.description) arr.push(locationCode.description);

  return `${login} (${arr.join(' ')})`;
};

export const formatLocationInfo = locationInfo => {
  if (locationInfo === undefined || locationInfo === null) {
    return null;
  }

  return `${locationInfo.code} (${locationInfo.name})`;
};

export const formatCity = cityCode => {
  if (!sierraGlobals.cities[cityCode]) {
    return null;
  } else {
    return `${cityCode} (${sierraGlobals.cities[cityCode]})`;
  }
};
