import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Button } from 'react-bootstrap';

import LocationSelector from '../forms/LocationSelector';

const RFIDReaderForm = ({ reader, onSubmit }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(reader ? reader.defaultLocation : null);

  const onFormSubmit = async event => {
    event.preventDefault();

    const name = event.target.name.value;
    const overrideLocation = event.target.overrideLocation.checked;

    if (!name || typeof name !== 'string' || name.length === 0) {
      return setError(t('Reader name is empty!'));
    }

    if (typeof defaultLocation === 'string' && defaultLocation.length !== 3) {
      return setError(t('Invalid default location!'));
    }

    if (overrideLocation && defaultLocation === null) {
      return setError(t('No overriding location given!'));
    }

    try {
      await onSubmit({
        name,
        description: event.target.description.value || null,
        massreader: event.target.massreader.checked,
        defaultLocation,
        overrideLocation
      });
    } catch (err) {
      if (err.response && err.response.data === 'Reader name already in use') {
        return setError(t('Reader name is already in use!'));
      }

      // eslint-disable-next-line no-console
      console.log('Could not save reader:', err);

      setError('Could not save reader');
    }
  };

  return (
    <Form onSubmit={onFormSubmit}>
      <Form.Group>
        <Form.Label>
          {t('Name')}
        </Form.Label>

        <Form.Control
          name="name"
          size="sm"
          defaultValue={reader ? reader.name : ''}
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {t('Description')}
        </Form.Label>

        <Form.Control
          as="textarea"
          name="description"
          size="sm"
          defaultValue={reader ? reader.description : ''}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {t('Default Location')}
        </Form.Label>

        <LocationSelector
          defaultValue={defaultLocation}
          onChange={value => setDefaultLocation(value || null)}
          showEmptyOption
        />
      </Form.Group>

      <Form.Group>
        <Form.Check
          name="overrideLocation"
          label={t('Override Location')}
          defaultChecked={reader && reader.overrideLocation}
        />

        <Form.Check
          name="massreader"
          label={t('Massreader')}
          defaultChecked={reader && reader.massreader}
        />
      </Form.Group>

      <Button type="submit" size="sm">
        {t('Submit')}
      </Button>

      <div style={{ marginTop: '6px', color: 'var(--danger)' }}>
        {error}
      </div>
    </Form>
  );
};

export default RFIDReaderForm;
