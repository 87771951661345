import { createSlice, PayloadAction, createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { RootState } from '../store';
import api from '../../api';

import type { Broadcast } from '../../api/models';

interface BroadcastsState {
  active: Broadcast[];
  dismissed: number[];
}

const initialState: BroadcastsState = {
  active: [],
  dismissed: JSON.parse(localStorage.getItem('dismissedBroadcasts') ?? '[]')
};

export const fetchAll = createAsyncThunk(
  'broadcasts/fetchAllStatus',
  async () => await api.get<Broadcast[]>('/broadcasts')
);

const reducers = {
  dismiss: (state: BroadcastsState, action: PayloadAction<number>): void => {}
};

const extraReducers = (builder: ActionReducerMapBuilder<BroadcastsState>): void => {
  builder.addCase(fetchAll.fulfilled, (state: BroadcastsState, action: PayloadAction<Broadcast[]>) => {
    state.active = action.payload;
  });
};

const broadcastsSlice = createSlice({
  name: 'broadcasts',
  initialState,
  reducers,
  extraReducers
});

export const selectActive = (state: RootState): Broadcast[] =>
  state.broadcasts.active;

export const {
  dismiss
} = broadcastsSlice.actions;

export default broadcastsSlice.reducer;
