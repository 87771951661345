import Form from 'react-bootstrap/Form';

import { Info } from '../../common/layout';

import type { FormItem } from '.';

interface Props {
  formItem: FormItem;
}

const InnerFormItemLabel: React.FC<Props> = ({ formItem }) => (
  <Form.Label>
    {formItem.label}

    <Info info={formItem.info} />
  </Form.Label>
);

const FormItemLabel: React.FC<Props> = ({ formItem }) =>
  formItem.label
    ? <InnerFormItemLabel formItem={formItem} />
    : null;

export default FormItemLabel;
