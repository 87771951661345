import { Space } from '../common/layout';
import { T } from '../common/i18n';

import buildNumber from '../build.json';

const Version: React.FC = () => (
  <>
    LIRP
    <Space />
    {process.env.REACT_APP_VERSION}
    <Space />
    (<T>build</T> #{buildNumber})
  </>
);

export default Version;
