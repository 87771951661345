import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useAPI from '../../hooks/useAPI';
import expandRow from '../../services/expandRow';
import { hasRole } from '../../services/auth';
import useQueryParams from '../../hooks/useQueryParams';

import BootstrapTable from '@ounai/react-bootstrap-table2';
import { Button, Form, Row, Col } from 'react-bootstrap';

import ItemView from '../items/ItemView';
import Loading from '../api/Loading';
import ItemDescription from '../items/ItemDescription';

import { CoverImage } from '../../../bibs';

const MissingHoldsTableFiltersForm = ({ tresholdMinutes, setTresholdMinutes, onUpdate }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const onChange = event => {
    setTresholdMinutes(Number(event.target.value));

    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set('tresholdMinutes', event.target.value);

    history.replace({
      search: searchParams.toString()
    });
  };

  return (
    <Form onSubmit={event => event.preventDefault()}>
      <Form.Group as={Row}>
        <Form.Label xs="auto" column="sm">
          {t('Treshold')}
        </Form.Label>

        <Col xs="auto" style={{ paddingLeft: 0, paddingRight: '6px' }}>
          <Form.Control
            as="select"
            name="tresholdMinutes"
            size="sm"
            defaultValue={tresholdMinutes}
            onChange={onChange}
          >
            <option value="0">{t('None')}</option>
            <option value="5">5 {t('minutes')}</option>
            <option value="60">1 {t('hour')}</option>
            <option value="240">4 {t('hours')}</option>
            <option value="720">12 {t('hours')}</option>
            <option value="1440">24 {t('hours')}</option>
            <option value="2880">48 {t('hours')}</option>
          </Form.Control>
        </Col>

        <Col xs="auto" style={{ paddingLeft: 0 }}>
          <Button size="sm" onClick={onUpdate}>
            {t('Update')}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

const MissingHoldsInstructions = ({ locationName, tresholdMinutes }) => {
  const { t } = useTranslation();

  let timeString;

  if (tresholdMinutes % 60 === 0 && tresholdMinutes >= 60) {
    timeString = (tresholdMinutes / 60) + ' ' + (tresholdMinutes === 60 ? t('hour') : t('hours'));
  } else {
    timeString = tresholdMinutes + ' ' + (tresholdMinutes === 1 ? t('minute') : t('minutes'));
  }

  const tresholdDiv = (
    <div>
      {t('Items that have been scanned less than')} {timeString} {t('ago will not be shown.')}
    </div>
  );

  return (
    <>
      <div>
        {t('This list contains items that are waiting for pickup, but have not yet been processed with the Mikro-Väylä machine.')}
      </div>

      {(tresholdMinutes !== 0) && tresholdDiv}
    </>
  );
};

const MissingHoldsBootstrapTable = ({ location, tresholdMinutes, updateCount }) => {
  const { t } = useTranslation();

  const [missingHolds, loading, error] = useAPI('GET', '/holds/missing', {
    params: {
      tresholdMinutes,
      location
    }
  }, [tresholdMinutes, location, updateCount]);

  const columns = [
    {
      dataField: 'coverImage',
      text: t('Cover'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <CoverImage isbn={row.Bib.marcISBN} materialType={row.Bib.fixedFieldMaterialType} />
      ),
      headerStyle: () => ({ width: '100px' })
    },
    {
      dataField: 'itemDescription',
      text: t('Item'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <ItemDescription item={row} noEditButton />
      )
    }
  ];

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch missing holds');
  }

  if (missingHolds === null) {
    return t('Feature unavailable');
  }

  if (missingHolds.length === 0) {
    return <strong>{t('No missing holds within treshold.')}</strong>;
  }

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <strong>
          {missingHolds.length} {t('items on list')}
        </strong>
      </div>

      <BootstrapTable
        keyField="sierraItemRecordId"
        columns={columns}
        data={missingHolds}
        bordered={false}
        bootstrap4

        expandRow={
          hasRole('VIEW-ITEMS')
            ? expandRow(row => <ItemView itemId={row.sierraItemRecordId} />)
            : undefined
        }
      />
    </>
  );
};

const MissingHoldsTable = ({ location, locationName, defaultTreshold }) => {
  if (!defaultTreshold) defaultTreshold = 5;

  const queryTresholdMinutes = Number(useQueryParams().get('tresholdMinutes'));

  const [tresholdMinutes, setTresholdMinutes] = useState(
    (queryTresholdMinutes && !isNaN(queryTresholdMinutes))
      ? queryTresholdMinutes
      : defaultTreshold
  );

  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdateCount(updateCount => updateCount + 1);
    }, 10 * 60 * 1000); // 10 minutes

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <MissingHoldsTableFiltersForm
          tresholdMinutes={tresholdMinutes}
          setTresholdMinutes={setTresholdMinutes}
          onUpdate={() => setUpdateCount(updateCount + 1)}
        />
      </div>

      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <MissingHoldsInstructions
          locationName={locationName || location}
          tresholdMinutes={tresholdMinutes}
        />
      </div>

      <MissingHoldsBootstrapTable
        location={location}
        tresholdMinutes={tresholdMinutes}
        updateCount={updateCount}
      />
    </>
  );
};

export default MissingHoldsTable;
