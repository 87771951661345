import { useT } from '../../common/i18n';

import { useSelector } from '../../state';
import { selectEmail } from '../../state/slices/currentUser';
import { useCommons } from '../../layout/forms';

import type { FormItem } from '../../layout/forms';

const useEmailChangeForm = (): FormItem[] => {
  const t = useT();
  const commons = useCommons();

  return [
    {
      type: 'text',
      name: 'email',
      label: t('New email address'),
      defaultValue: useSelector(selectEmail),
      required: true,
      validation: [
        {
          type: 'has-changed',
          message: t('No change in email address')
        },
        {
          type: 'email',
          message: t('Selected email address is invalid')
        }
      ]
    },
    commons.passwordRequired
  ];
};

export default useEmailChangeForm;
