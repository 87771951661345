import { useTranslation } from 'react-i18next';

import expandRow from '../../services/expandRow';
import { hasRole } from '../../services/auth';
import { formatDateTimeRelative } from '../../services/date';
import { formatLocationInfo } from '../../services/location';
import useQueryParamState from '../../hooks/useQueryParamState';

import { Form } from 'react-bootstrap';

import APITable from '../api/APITable';
import ItemView from '../items/ItemView';

const Filters = ({ own, setOwn, status, setStatus, location, setLocation }) => {
  const { t } = useTranslation();

  return (
    <>
      <Form style={{ marginBottom: '6px' }} inline>
        <Form.Label>
          {t('Status')}
        </Form.Label>

        <Form.Control
          as="select"
          size="sm"
          onChange={event => setStatus(event.target.value)}
          defaultValue={status}
          style={{ marginLeft: '6px' }}
        >
          <option value="all">
            {t('All')}
          </option>

          <option value="active">
            {t('Active')}
          </option>

          <option value="satisfied">
            {t('Satisfied')}
          </option>

          <option value="cancelled">
            {t('Cancelled')}
          </option>
        </Form.Control>
      </Form>

      <Form style={{ marginBottom: '6px' }} inline>
        <Form.Label>
          {t('Location')}
        </Form.Label>

        <Form.Control
          size="sm"
          onChange={event => setLocation(event.target.value)}
          defaultValue={location}
          style={{ marginLeft: '6px' }}
          placeholder={t('all')}
        />
      </Form>

      <span onClick={() => setOwn(o => !o)}>
        <Form.Check
          name="own"
          checked={own}
          label={t('Show only my tasks')}
          readOnly
        />
      </span>
    </>
  );
};

const CollectionTaskTable = ({ enableQueryParams }) => {
  const { t } = useTranslation();

  const [own, setOwn] = useQueryParamState('own', false);
  const [status, setStatus] = useQueryParamState('status', 'all');
  const [location, setLocation] = useQueryParamState('location', '');

  const columns = [
    {
      dataField: 'id',
      text: t('Allocation ID'),
      sort: true,
      hide: !hasRole('DEVELOPER')
    },
    /*
    {
      dataField: 'sierraItemRecordId',
      text: t('Sierra ID'),
      sort: true
    },
    */
    {
      dataField: 'Item.barcode',
      text: t('Barcode')
    },
    {
      dataField: 'Item.callNumber',
      text: t('Call Number')
    },
    {
      dataField: 'Item.Bib.marcAuthor',
      text: t('Author')
    },
    {
      dataField: 'Item.Bib.marcTitle',
      text: t('Title')
    },
    {
      dataField: 'locationSource',
      text: t('Location'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) =>
        (cell === row?.Item?.location?.code)
          ? formatLocationInfo(row?.Item?.location)
          : cell
    },
    {
      dataField: 'allocationType',
      text: t('Task'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) =>
        (cell === 3)
          ? t('Item Deletion')
          : (
            <>
              <div>
                {t('Other')}
              </div>

              <div>
                {row?.Comments && row.Comments[0]?.comment}
              </div>
            </>
            )
    },
    {
      dataField: 'status',
      isDummyField: true,
      text: t('Status'),
      formatter: (cell, row, rowIndex, formatExtraData) =>
        (row.satisfiedAt !== null)
          ? `${t('Satisfied at')} ${formatDateTimeRelative(row.satisfiedAt, t, true)}`
          : (row.active ? t('Active') : t('Cancelled'))
    },
    {
      dataField: 'createdAt',
      text: t('Created At'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) =>
        formatDateTimeRelative(cell, t) ?? '-'
    }
  ];

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Filters
          own={own}
          setOwn={setOwn}
          status={status}
          setStatus={setStatus}
          location={location}
          setLocation={setLocation}
        />
      </div>

      <APITable
        resource="items/collection-tasks"
        keyField="id"
        columns={columns}
        enableQueryParams={enableQueryParams}
        defaultSortField="id"
        defaultSortOrder="desc"
        query={{ own, status, location }}

        expandRow={
          hasRole('VIEW-ITEMS')
            ? expandRow(row => <ItemView itemId={row.sierraItemRecordId} />)
            : undefined
        }
      />
    </>
  );
};

export default CollectionTaskTable;
