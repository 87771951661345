export const cities = {
  e: 'ESPOO',
  h: 'HELSINKI',
  k: 'KAUNIAINEN',
  v: 'VANTAA',
  n: 'PIILOTETTU',
  d: 'POISTETTU',
  i: 'INV POISTO'
};

export type CityCode = keyof typeof cities;
export type CityName = typeof cities[CityCode];
