import { useState, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import { Chart } from 'react-charts';
import { Form, Button } from 'react-bootstrap';

import Loading from '../api/Loading';

const ItemScanChartsFiltersForm = ({
  locationFilter, setLocationFilter, loginFilter, setLoginFilter, dateString, setDateString
}) => {
  const { t } = useTranslation();

  const onSubmit = event => {
    event.preventDefault();

    setLocationFilter(event.target.locationFilter.value);
    setLoginFilter(event.target.loginFilter.value);

    if (event.target.date.value !== '') {
      setDateString(event.target.date.value);
    }
  };

  return (
    <Form onSubmit={onSubmit} inline>
      <Form.Group>
        <Form.Control
          type="date"
          name="date"
          size="sm"
          defaultValue={dateString}
        />
      </Form.Group>

      <Form.Group>
        <Form.Control
          name="locationFilter"
          size="sm"
          defaultValue={locationFilter}
          placeholder={t('Filter by location...')}
          style={{ marginLeft: '6px' }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Control
          name="loginFilter"
          size="sm"
          defaultValue={loginFilter}
          placeholder={t('Filter by login...')}
          style={{ marginLeft: '6px' }}
        />
      </Form.Group>

      <Button variant="primary" size="sm" type="submit" style={{ marginLeft: '6px' }}>
        {t('Update')}
      </Button>
    </Form>
  );
};

const ItemScanActivityChart = ({
  label, height, tresholdDays, locationFilter, loginFilter, minDate, maxDate, groupBy
}) => {
  const { t } = useTranslation();

  const [activity, loading, error] = useAPI('GET', '/itemscans/activity', {
    params: {
      tresholdDays,
      locationFilter,
      loginFilter,
      minDate,
      maxDate,
      groupBy
    }
  }, [tresholdDays, locationFilter, loginFilter, minDate, maxDate, groupBy]);

  const data = [{
    label,
    data: (activity ? activity.map(i => [i.time, i.count]) : [])
  }];

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch item scan activity');
  }

  return (
    <div style={{ width: '100%', height: `${height}px` }}>
      <Chart
        data={data}
        tooltip

        series={{
          type: 'bar'
        }}

        axes={[
          { primary: true, type: 'time', position: 'bottom' },
          { type: 'linear', position: 'left' }
        ]}
      />
    </div>
  );
};

const ItemScanCharts = ({ pageHeight }) => {
  const { t } = useTranslation();

  const filterDivRef = useRef(null);
  const weekTitleRef = useRef(null);
  const dayTitleRef = useRef(null);

  const [chartHeight, setChartHeight] = useState(null);
  const [locationFilter, setLocationFilter] = useState(localStorage.getItem('location'));
  const [loginFilter, setLoginFilter] = useState('');
  const [dateString, setDateString] = useState(new Date().toISOString().split('T')[0]);

  const startDateWeek = new Date(dateString);
  const endDateWeek = new Date(dateString);

  let weekDay = startDateWeek.getDay();
  if (weekDay === 0) weekDay = 7; // Make Sunday last day of week

  startDateWeek.setDate(startDateWeek.getDate() - weekDay + 1);
  endDateWeek.setDate(endDateWeek.getDate() + (8 - weekDay));

  if (endDateWeek.getTime() > new Date().getTime()) endDateWeek.setTime(new Date().getTime());

  const startDateDay = new Date(dateString);
  startDateDay.setHours(0, 0, 0, 0);

  const endDateDay = new Date(dateString);
  endDateDay.setHours(24, 0, 0, 0);

  useLayoutEffect(() => {
    if (!filterDivRef.current || !weekTitleRef.current || !dayTitleRef.current) return;

    const elementsHeight = (
      filterDivRef.current.clientHeight +
      weekTitleRef.current.clientHeight +
      dayTitleRef.current.clientHeight +
      50 // Extra padding for vertical scroll bar
    );

    setChartHeight(Math.floor((pageHeight - elementsHeight) / 2));
  }, [filterDivRef, weekTitleRef, dayTitleRef, pageHeight]);

  return (
    <>
      <div ref={filterDivRef}>
        <ItemScanChartsFiltersForm
          locationFilter={locationFilter}
          setLocationFilter={setLocationFilter}
          loginFilter={loginFilter}
          setLoginFilter={setLoginFilter}
          dateString={dateString}
          setDateString={setDateString}
        />
      </div>

      <h3 ref={weekTitleRef}>
        {t('Week')}
      </h3>

      {
        chartHeight &&
        <ItemScanActivityChart
          label={t('Item scans per hour')}
          height={chartHeight}
          tresholdDays={7}
          locationFilter={locationFilter}
          loginFilter={loginFilter}
          minDate={startDateWeek.getTime()}
          maxDate={endDateWeek.getTime()}
          groupBy="hours"
        />
      }

      <h3 ref={dayTitleRef}>
        {t('Day')}
      </h3>

      {
        chartHeight &&
        <ItemScanActivityChart
          label={t('Item scans per minute')}
          height={chartHeight}
          tresholdDays={1}
          locationFilter={locationFilter}
          loginFilter={loginFilter}
          minDate={startDateDay.getTime()}
          maxDate={endDateDay.getTime()}
          groupBy="minutes"
        />
      }
    </>
  );
};

export default ItemScanCharts;
