import { useColor } from '../common/layout';
import { T } from '../common/i18n';
import { formatDateTime, oneHourAgo } from '../common/dates';

import { APIWrap, useGet } from '../api';

import type { LastDatabaseUpdateTime as ILastDatabaseUpdateTime } from '../api/models';

const useStyle = (lastUpdateTime?: number): React.CSSProperties => {
  const danger = useColor('danger');

  return (typeof lastUpdateTime !== 'number' || new Date(lastUpdateTime) < oneHourAgo())
    ? danger
    : {};
};

const LastDatabaseUpdateTime: React.FC = () => {
  const { data: lastUpdate, loading, error } = useGet<ILastDatabaseUpdateTime>('/developer/last-db-update');

  return (
    <APIWrap loading={loading} error={error} style={useStyle(lastUpdate?.time)}>
      <strong>
        <T>Last DB update</T> {lastUpdate?.time && formatDateTime(lastUpdate.time)}
      </strong>
    </APIWrap>
  );
};

export default LastDatabaseUpdateTime;
