import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import BootstrapTable from '@ounai/react-bootstrap-table2';

import Authorized from '../auth/Authorized';
import Loading from '../api/Loading';
import AddRFIDReaderButton from './AddRFIDReaderButton';
import RFIDReaderOptionsColumn from './RFIDReaderOptionsColumn';

const RFIDReadersManager = () => {
  const { t } = useTranslation();

  const [readers, loading, error, /* headers */, update] = useAPI('GET', '/rfid/readers');

  const columns = [
    {
      dataField: 'id',
      text: t('ID'),
      sort: true
    },
    {
      dataField: 'name',
      text: t('Name'),
      sort: true
    },
    {
      dataField: 'description',
      text: t('Description'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => cell || '-'
    },
    {
      dataField: 'massreader',
      text: t('Massreader'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => cell ? t('yes') : t('no')
    },
    {
      dataField: 'defaultLocation',
      text: t('Default Location'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => cell || '-'
    },
    {
      dataField: 'overrideLocation',
      text: t('Override Location'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => cell ? t('yes') : t('no')
    },
    {
      dataField: 'options',
      text: '',
      isDummyField: true,
      headerStyle: () => ({ width: '74px' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <RFIDReaderOptionsColumn reader={row} update={update} />
      )
    }
  ];

  return (
    <>
      <Authorized role="DEVELOPER">
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <AddRFIDReaderButton update={update} />
        </div>
      </Authorized>

      <BootstrapTable
        keyField="id"
        columns={columns}
        data={readers ?? []}

        classes="w-auto api-table mw-100"
        wrapperClasses="table-responsive"

        noDataIndication={() => {
          if (loading) return <Loading />;
          else if (error) return t('Could not fetch RFID readers');
          else return t('No data to show');
        }}

        bootstrap4
      />
    </>
  );
};

export default RFIDReadersManager;
