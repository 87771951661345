import { hasRole } from '.';

import type { Role } from '.';

const Authorized: React.FC<{
  role: Role | Role[],
  roleNot?: Role | Role[]
}> = ({ role, roleNot, children }) => {
  if (roleNot && hasRole(roleNot)) {
    return null;
  }

  if (hasRole(role) || hasRole('DEVELOPER')) {
    return <>{children}</>;
  }

  return null;
};

export default Authorized;
