import { useRef } from 'react';
import ReactToPrint from 'react-to-print';

import { T } from '../i18n';
import { Icon, Space } from '../layout';
import { Button } from '../layout/buttons';

const DefaultButton: React.VFC = ({ ...props }) => (
  <Button variant="primary" {...props}>
    <Icon icon="printer" />

    <Space />

    <T>Print receipt</T>
  </Button>
);

const style: React.CSSProperties = {
  margin: '10px',
  border: '1px solid black',
  width: 'fit-content'
};

const ReceiptButton: React.FC<{
  button?: React.ReactElement
}> = ({ button, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <ReactToPrint
        trigger={(): React.ReactElement => (button ?? <DefaultButton />)}
        content={(): React.ReactInstance | null => ref.current}
        copyStyles={false}
      />

      <div style={{ display: 'none' }}>
        <div ref={ref} style={style}>
          {children}
        </div>
      </div>
    </>
  );
};

export default ReceiptButton;
