import { useString } from '../layout';

interface Spannable {
  span?: boolean;
}

type Props = Record<string, any> & Spannable;

const getPropString = (props: Props): string =>
  Object.values(props).find(p => typeof p !== 'boolean')
    ? JSON.stringify(props, null, 1)
    : Object.keys(props).join(', ');

const style: React.CSSProperties = {
  padding: '4px',
  border: '1px solid black'
};

const Container: React.FC<Spannable> = ({ span, children }) =>
  span
    ? <span style={style}>{children}</span>
    : <div style={style}>{children}</div>;

const TODO: React.FC<Props> = ({ span, children, ...props }) => (
  <Container span={span}>
    TODO

    {useString` (${Object.keys(props).length > 0 && getPropString(props)})`}

    {children}
  </Container>
);

export default TODO;
