import { Heading, Stack } from '../common/layout';
import { T } from '../common/i18n';

import {
  LastDatabaseUpdateTime,
  Uptime,
  DatabaseFileSystemStatus
} from '.';

const Header: React.FC = () => (
  <Heading level={3}>
    <T>Developer Statistics</T>
  </Heading>
);

const DeveloperStatistics: React.FC = () => (
  <Stack>
    <Header />

    <LastDatabaseUpdateTime />

    <Uptime />

    <DatabaseFileSystemStatus />
  </Stack>
);

export default DeveloperStatistics;
