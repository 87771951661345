import config from '../config';
import { approximateWidth, approximateHeight } from './bib';
import { getSignumText, hasProblem } from './item';

export const renderShelf = ({ ctx, previous, current, next, canvasWidth, canvasHeight, genreRefs, highlighter, highlightItemsSet }) => {
  ctx.clearRect(0, 0, canvasWidth, canvasHeight);
  ctx.beginPath();

  const books = [];

  // Space around the selected book
  const selectedBookSpacing = 1.5;

  // Space around main class changes (or subclass changes in fiction)
  const classChangeSpacing = 5;

  const spineMarginTop = 4;
  const topTextMargin = 4;

  const parseAuthor = bib => {
    if (!bib.marcAuthor) return null;

    return bib.marcAuthor.replace(', ', ',').split(' ')[0].replace(',', ', ').replace(/,$/, '');
  };

  let lastClass = null;
  let classChangeSpacers = 0;

  const addBook = (item, highlight = false) => {
    if (
      lastClass !== null &&
      (
        (item.classification !== lastClass && item.fiction) ||
        (item.classification.split('.')[0] !== lastClass.split('.')[0] && !item.fiction)
      )
    ) {
      books.push('classChangeSpacer');

      classChangeSpacers++;
    }

    const topGenres = [
      'ISOTEKSTISET', 'STORSTIL', 'LARGE PRINT',
      'SELKOKIRJAT', 'LL-BÖCKER', 'EASY READERS',

      'OPI SUOMEA', 'BESTSELLER'
    ];

    const bottomGenres = {
      JÄNNITYS: genreRefs.detective,
      SPÄNNING: genreRefs.detective,
      THRILLER: genreRefs.detective,

      VIIHDE: genreRefs.heart,
      UNDERHÄLLNING: genreRefs.heart,
      ROMANCE: genreRefs.heart,

      FANTASIA: genreRefs.dragon,
      FANTASY: genreRefs.dragon,

      KAUHU: genreRefs.skull,
      SKRÄCK: genreRefs.skull,
      HORROR: genreRefs.skull,

      JOULU: genreRefs.christmastree,
      JULEN: genreRefs.christmastree,
      CHRISTMAS: genreRefs.christmastree,

      SCIFI: genreRefs.alien,
      VITSIT: genreRefs.smiley,
      SOTA: genreRefs.tank,

      HELPPOLUKUISET: genreRefs.rooster,
      'BÖRJA LÄSA': genreRefs.rooster,
      'EASY READERS': genreRefs.rooster,

      SUURAAKKOSET: genreRefs.suur,
      TAVUTETUT: genreRefs.tavu,

      HEVOSET: genreRefs.horse,
      HÄSTAR: genreRefs.horse,

      SUOMI: genreRefs.fin,
      TV: genreRefs.circle,
      KOMEDIA: genreRefs.smiley,
      TOIMINTA: genreRefs.detective,

      JOJO: genreRefs.jojo
    };

    let topText = null;
    let bottomImage = null;
    let section = null;

    if (topGenres.indexOf(item.genre) !== -1) topText = item.genre;
    if (Object.keys(bottomGenres).includes(item.genre)) bottomImage = bottomGenres[item.genre].current;

    if (
      item.Bib.fixedFieldLang !== null &&
      item.Bib.fixedFieldLang.trim() !== '' &&
      ![
        'fin', 'swe', 'eng',
        'mul', // Multiple languages
        'zxx' // No linguistic content
      ].includes(item.Bib.fixedFieldLang.toLowerCase())
    ) {
      topText = item.Bib.fixedFieldLang.toUpperCase();
    }

    const sectionLocation = item.fixedFieldLocation.slice(3, 5);

    if (sectionLocation === 'll' || sectionLocation === 'lo') section = 'LAS';
    else if (sectionLocation === 'nl') section = 'NUO';
    else if (sectionLocation === 'ml') section = 'MUS';

    let lastSeen = null;
    if (item.LastItemScan) {
      lastSeen = 0;

      if (item.LastItemScan.ItemScan) {
        lastSeen = new Date(item.LastItemScan.ItemScan.scanDate).getTime();
      }

      if (item.LastItemScan.RfidItemScan) {
        lastSeen = Math.max(lastSeen, new Date(item.LastItemScan.RfidItemScan.scanDate).getTime());
      }
    }

    books.push({
      itemId: item.sierraItemRecordId,
      author: parseAuthor(item.Bib),
      title: item.Bib.marcTitle,
      width: approximateWidth(item.Bib),
      height: approximateHeight(item.Bib),
      signum: getSignumText(item),
      problem: hasProblem(item),
      topText,
      bottomImage,
      section,
      highlight,
      spineTextStart: 0,
      lastSeen
    });

    lastClass = item.classification;
  };

  // Add all books to the array in order
  for (const item of previous) addBook(item);
  if (current !== null) addBook(current, true);
  for (const item of next) addBook(item);

  // Total width and max height of the shelf
  // These will be matched to the canvas width and height
  let totalWidth = books.reduce((acc, cur) => (typeof cur === 'string' ? acc : acc + cur.width), 0);
  const maxHeight = books.reduce((acc, cur) => (typeof cur === 'string' ? acc : Math.max(acc, cur.height)), 0);

  const relativeSelectedBookSpacing = Math.floor(canvasWidth / totalWidth * selectedBookSpacing);
  totalWidth += selectedBookSpacing * ((previous.length === 0 || next.length === 0) ? 1 : 2) + 1;

  const relativeClassChangeSpacing = Math.floor(canvasWidth / totalWidth * classChangeSpacing);
  totalWidth += classChangeSpacing * (classChangeSpacers + 1);

  ctx.fillStyle = '#000000';

  let x = 0;

  const baseFont = 'Arial';
  const signumFont = 'Arial';

  for (const book of books) {
    if (typeof book === 'string') {
      if (book === 'classChangeSpacer') x += relativeClassChangeSpacing;

      continue;
    }

    if (book.highlight) x += relativeSelectedBookSpacing;

    const highlighted = book.highlight || (highlightItemsSet && highlightItemsSet.has(book.itemId));

    let bookColor = (
      book.problem
        ? config.shelfViewColors.problem
        : highlighted ? config.shelfViewColors.highlighted : config.shelfViewColors.normal
    );

    // Last seen highlighter
    if (!highlighted && highlighter === 'lastSeen') {
      const redGradientRGB = [
        [255, 0, 0], // from (closer to maxDate)
        [255, 255, 255] // to (closer to current time)
      ];

      const blueGradientRGB = [
        [64, 64, 255], // from (closer to week ago)
        [64, 64, 255] // to (closer to current time)
      ];

      const getHex = component => (
        component.toString(16).length === 1
          ? '0' + component.toString(16)
          : component.toString(16)
      );

      if (!book.lastSeen) {
        bookColor = [
          '#',
          getHex(redGradientRGB[0][0]),
          getHex(redGradientRGB[0][1]),
          getHex(redGradientRGB[0][2])
        ].join('');
      } else {
        const weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 7);

        const lastSeenDate = new Date(book.lastSeen);

        if (lastSeenDate >= weekAgo) {
          // Last seen within a week
          const maxDate = 1000 * 60 * 60 * 24 * 7;
          const weight = Math.min(maxDate, (new Date().getTime() - lastSeenDate.getTime())) / maxDate;

          const RGB = [
            Math.round(blueGradientRGB[0][0] * weight + blueGradientRGB[1][0] * (1 - weight)),
            Math.round(blueGradientRGB[0][1] * weight + blueGradientRGB[1][1] * (1 - weight)),
            Math.round(blueGradientRGB[0][2] * weight + blueGradientRGB[1][2] * (1 - weight))
          ];

          bookColor = '#' + getHex(RGB[0]) + getHex(RGB[1]) + getHex(RGB[2]);
        } else {
          // Last seen more than a week ago
          const maxDate = 1000 * 60 * 60 * 24 * 365;
          const weight = Math.min(maxDate, (new Date().getTime() - lastSeenDate.getTime())) / maxDate;

          const RGB = [
            Math.round(redGradientRGB[0][0] * weight + redGradientRGB[1][0] * (1 - weight)),
            Math.round(redGradientRGB[0][1] * weight + redGradientRGB[1][1] * (1 - weight)),
            Math.round(redGradientRGB[0][2] * weight + redGradientRGB[1][2] * (1 - weight))
          ];

          bookColor = '#' + getHex(RGB[0]) + getHex(RGB[1]) + getHex(RGB[2]);
        }
      }
    }

    ctx.font = `14px ${baseFont}`;
    ctx.fillStyle = '#000000';

    const mWidth = () => ctx.measureText('M').width;

    const relativeWidth = Math.round(((book.width / totalWidth) * canvasWidth) / 2) * 2;
    const relativeHeight = Math.round(((book.height / maxHeight) * canvasHeight) / 2) * 2 - 2;

    ctx.fillStyle = bookColor;
    ctx.fillRect(x, canvasHeight - relativeHeight, relativeWidth, relativeHeight);

    const bestsellerTextColor = '#ffffff';
    const bestsellerBackgroundColor = '#ff6319';

    if (book.topText) {
      if (book.topText === 'BESTSELLER') ctx.font = `bold ${Math.min(18, relativeWidth - 2)}px ${signumFont}`;
      else ctx.font = `${Math.min(18, relativeWidth - 2)}px ${signumFont}`;

      const topTextWidth = ctx.measureText(book.topText).width;

      if (topTextWidth >= relativeWidth) book.topTextSideways = book.topText;
      else {
        if (book.topText === 'BESTSELLER') ctx.fillStyle = bestsellerBackgroundColor;
        else ctx.fillStyle = '#ffffff';

        ctx.fillRect(
          Math.max(x, x + relativeWidth / 2 - topTextWidth / 2 - topTextMargin),
          canvasHeight - relativeHeight + spineMarginTop,
          Math.min(relativeWidth, topTextWidth + topTextMargin * 2),
          mWidth() + topTextMargin * 2
        );

        if (book.topText === 'BESTSELLER') {
          ctx.fillStyle = '#000000';
          ctx.rect(
            Math.max(x, x + relativeWidth / 2 - topTextWidth / 2 - topTextMargin),
            canvasHeight - relativeHeight + spineMarginTop,
            Math.min(relativeWidth, topTextWidth + topTextMargin * 2),
            mWidth() + topTextMargin * 2
          );
          ctx.stroke();
        }

        if (book.topText === 'BESTSELLER') ctx.fillStyle = bestsellerTextColor;
        else ctx.fillStyle = '#000000';

        ctx.fillText(
          book.topText,
          x + relativeWidth / 2 - topTextWidth / 2,
          canvasHeight - relativeHeight + mWidth() + spineMarginTop + topTextMargin
        );

        book.spineTextStart = mWidth() + spineMarginTop + topTextMargin * 2;
      }
    }

    ctx.save();

    ctx.translate(x + relativeWidth, canvasHeight - relativeHeight);
    ctx.rotate(Math.PI / 2);

    if (book.topTextSideways) {
      if (book.topText === 'BESTSELLER') ctx.font = `bold ${Math.min(18, relativeWidth - 2)}px ${signumFont}`;
      else ctx.font = `${Math.min(18, relativeWidth - 2)}px ${signumFont}`;

      const topTextWidth = ctx.measureText(book.topText).width;

      if (book.topText === 'BESTSELLER') ctx.fillStyle = bestsellerBackgroundColor;
      else ctx.fillStyle = '#ffffff';

      ctx.fillRect(
        spineMarginTop,
        Math.max(0, relativeWidth / 2 - mWidth() / 2 - topTextMargin),
        topTextWidth + topTextMargin * 2,
        Math.min(relativeWidth, mWidth() + topTextMargin * 2)
      );

      if (book.topText === 'BESTSELLER') {
        ctx.fillStyle = '#000000';
        ctx.lineWidth = 2;

        ctx.rect(
          spineMarginTop,
          Math.max(0, relativeWidth / 2 - mWidth() / 2 - topTextMargin),
          topTextWidth + topTextMargin * 2,
          Math.min(relativeWidth, mWidth() + topTextMargin * 2)
        );

        ctx.stroke();
        ctx.lineWidth = 1;
      }

      if (book.topText === 'BESTSELLER') ctx.fillStyle = bestsellerTextColor;
      else ctx.fillStyle = '#000000';

      ctx.fillText(
        book.topText,
        spineMarginTop + topTextMargin,
        relativeWidth / 2 + mWidth() / 2
      );

      book.spineTextStart = topTextWidth + spineMarginTop + topTextMargin * 2;
    }

    ctx.fillStyle = '#000000';

    if (book.author && relativeWidth > mWidth() * 2 + 2) {
      ctx.font = `bold 14px ${baseFont}`;
      ctx.fillText(book.author, spineMarginTop + book.spineTextStart, relativeWidth / 2 - 2);

      ctx.font = `14px ${baseFont}`;
      ctx.fillText(book.title, spineMarginTop + book.spineTextStart, relativeWidth / 2 + mWidth());
    } else {
      ctx.font = `bold ${Math.min(14, relativeWidth - 2)}px ${baseFont}`;
      ctx.fillText(book.title, spineMarginTop + book.spineTextStart, relativeWidth / 2 + mWidth() / 2);
    }

    ctx.font = `${Math.min(18, relativeWidth - 2)}px ${signumFont}`;

    const signum = {
      text: book.signum,
      width: ctx.measureText(book.signum).width,
      margin: 4, // White space around the characters
      spacing: 4 // Distance from bottom of the book to signum
    };

    ctx.fillStyle = bookColor;
    ctx.fillRect(
      relativeHeight - signum.width - signum.margin * 2 - signum.spacing,
      0,
      signum.width + signum.margin * 2 + signum.spacing,
      relativeWidth
    );

    const signumStickerWidth = Math.min(relativeWidth + signum.margin / 2, mWidth() + signum.margin * 2);

    ctx.fillStyle = '#ffffff';
    ctx.fillRect(
      relativeHeight - signum.width - signum.margin * 2 - signum.spacing,
      relativeWidth / 2 - mWidth() / 2 - signum.margin,
      signum.width + signum.margin * 2,
      signumStickerWidth
    );

    ctx.fillStyle = '#000000';

    ctx.fillText(
      signum.text,
      relativeHeight - signum.width - signum.margin - signum.spacing,
      relativeWidth / 2 + mWidth() / 2
    );

    ctx.restore();

    let y = canvasHeight - signum.width - signum.margin - signum.spacing;

    if (book.bottomImage) {
      const image = book.bottomImage;

      const imageRatio = image.height / image.width;
      const imageMargin = 2;

      const imagePos = {
        x: x + relativeWidth / 2 - signumStickerWidth / 2 + imageMargin,
        y: y - signumStickerWidth * imageRatio + imageMargin - 1,
        w: signumStickerWidth - imageMargin * 2,
        h: signumStickerWidth * imageRatio - imageMargin * 2
      };

      ctx.fillStyle = bookColor;
      ctx.fillRect(
        x,
        imagePos.y - imageMargin,
        relativeWidth,
        Math.ceil(signumStickerWidth * imageRatio)
      );

      ctx.fillStyle = '#ffffff';
      ctx.fillRect(
        imagePos.x - imageMargin,
        imagePos.y - imageMargin,
        signumStickerWidth,
        Math.ceil(signumStickerWidth * imageRatio) + 1
      );

      ctx.fillStyle = '#000000';
      ctx.drawImage(image, imagePos.x, imagePos.y, imagePos.w, imagePos.h);

      y -= signumStickerWidth * imageRatio + imageMargin + 1;
    } else y -= signum.margin;

    if (book.section) {
      ctx.fillStyle = bookColor;
      ctx.fillRect(
        x,
        y - mWidth(),
        relativeWidth,
        Math.round((mWidth() / 2 + signum.margin * 2) / 2) * 2
      );

      ctx.fillStyle = '#ffffff';
      ctx.fillRect(
        x + relativeWidth / 2 - signumStickerWidth / 2,
        y - mWidth() / 2 - signum.margin,
        signumStickerWidth,
        Math.round((mWidth() / 2 + signum.margin * 2) / 2) * 2
      );

      let fontSize = 18;
      ctx.font = `${fontSize}px ${signumFont}`;

      while (ctx.measureText(book.section).width > signumStickerWidth - 2) {
        ctx.font = `${fontSize--}px ${signumFont}`;
      }

      const sectionWidth = ctx.measureText(book.section).width;

      ctx.fillStyle = '#000000';
      ctx.fillText(book.section, x + relativeWidth / 2 - sectionWidth / 2, y);
    }

    ctx.fillStyle = '#000000';
    ctx.rect(x, canvasHeight - relativeHeight, relativeWidth, relativeHeight);
    ctx.stroke();

    if (book.highlight) x += relativeSelectedBookSpacing;

    x += relativeWidth;
  }
};
