import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import ItemView from './ItemView';
import Loading from '../api/Loading';

const RandomItemView = () => {
  const { t } = useTranslation();

  const [item, loading, error] = useAPI('GET', '/items/random');

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch random item');
  }

  return <ItemView itemId={item.sierraItemRecordId} />;
};

export default RandomItemView;
