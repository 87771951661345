import { T } from '../common/i18n';
import { Modal, ErrorCondition } from '../common/layout';
import { Button } from '../common/layout/buttons';

import { usePostRequest } from '.';

import type { Params } from '.';

const Buttons: React.FC<{
  post: () => void,
  posting: boolean,
  onHide: () => void
}> = ({ post, posting, onHide }) => (
  <>
    <Button variant="secondary" disabled={posting} onClick={onHide}>
      <T>Cancel</T>
    </Button>

    <Button variant="primary" disabled={posting} onClick={post}>
      <T>Confirm</T>
    </Button>
  </>
);

const APIPostModal: React.FC<{
  show: boolean,
  onHide: () => void,
  path: string,
  title?: string,
  showCloseButton?: boolean,
  data?: any,
  params?: Params
}> = ({ show, onHide, path, title, showCloseButton, data, params, children }) => {
  const { post, posting, error } = usePostRequest(path, onHide, data, params);

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title}
      closeButton={showCloseButton}

      footer={
        <Buttons
          post={post}
          posting={posting}
          onHide={onHide}
        />
      }
    >
      {children}

      <ErrorCondition error={error}>
        <T>Action failed</T>:
      </ErrorCondition>
    </Modal>
  );
};

export default APIPostModal;
