import TopTooltip from '../layout/TopTooltip';

const TooltippedLocation = ({ item }) => {
  if (item.location && item.location.name) {
    return (
      <TopTooltip content={item.location.name}>
        <span>
          {item.location.code}
        </span>
      </TopTooltip>
    );
  } else {
    return item.fixedFieldLocation ?? null;
  }
};

export default TooltippedLocation;
