import { useT } from '../../common/i18n';
import { Loading } from '../../common/layout';

interface Props {
  loading: boolean;
  error: Error | null;
}

const useNoDataIndication = ({ loading, error }: Props): React.ReactNode => {
  const t = useT();

  if (loading) {
    return <Loading />;
  } else if (error) {
    return `${t('Could not fetch data')}: ${error.message}`;
  } else {
    return t('No data to show');
  }
};

export default useNoDataIndication;
