import { useTranslation } from 'react-i18next';

import expandRow from '../../services/expandRow';
import { hasRole } from '../../services/auth';

import BootstrapTable from '@ounai/react-bootstrap-table2';

import ItemView from './ItemView';
import ItemDescription from './ItemDescription';

import { CoverImage } from '../../../bibs';

const QuickItemBrowser = ({ items, update, onEdit, showMissingButton }) => {
  const { t } = useTranslation();

  const columns = [
    {
      dataField: 'coverImage',
      text: t('Cover'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => row.Bib
        ? (
          <CoverImage
            isbn={row.Bib.marcISBN}
            materialType={row.Bib.fixedFieldMaterialType}
          />
          )
        : t('Invalid bib'),
      headerStyle: () => ({ width: '100px' })
    },
    {
      dataField: 'itemDescription',
      text: t('Item'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <ItemDescription
          item={row}
          update={update}
          onEdit={onEdit}
          showMissingButton={showMissingButton}
        />
      )
    }
  ];

  return (
    <BootstrapTable
      keyField="sierraItemRecordId"
      columns={columns}
      data={items}
      bordered={false}
      bootstrap4

      expandRow={
        hasRole('VIEW-ITEMS')
          ? expandRow(row => <ItemView itemId={row.sierraItemRecordId} alwaysTabbed />)
          : undefined
      }
    />
  );
};

export default QuickItemBrowser;
