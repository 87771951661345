import api from './api';
import { validateAccount } from './account';

export const createSharedAccount = async (
  username,
  password,
  city,
  location
) => {
  validateAccount(username, password, city, location);

  await api.post('/accounts/shared', {
    username,
    password,
    city,
    location
  });
};

export const updateSharedAccount = async (
  id,
  username,
  password,
  city,
  location
) => {
  validateAccount(username, password, city, location);

  await api.put(`/accounts/shared/${id}`, {
    username,
    password,
    city,
    location
  });
};

export const deleteSharedAccount = async id => {
  if (typeof id !== 'number' || isNaN(id)) {
    throw new Error(`Invalid shared account id ${id}`);
  }

  return await api.delete(`/accounts/shared/${id}`);
};
