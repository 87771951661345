export const orderStatuses = {
  o: 'Tilattu',
  a: 'Maksettu',
  q: 'Maksettu osaksi',
  z: 'Peruttu',
  1: 'Odottaa',
  2: 'Hylätty',
  c: 'Lehti tilattu',
  d: 'Lehti maksettu',
  e: 'Lehti maks osaksi',
  f: 'SERIAL NO ENC',
  g: 'SERIAL LIENED'
};

export type OrderStatusCode = keyof typeof orderStatuses;
export type OrderStatusName = typeof orderStatuses[OrderStatusCode];
