import BibTitleAndAuthor from '../bibs/BibTitleAndAuthor';

const DeletedItemTitleAndAuthor = ({ deletedItem }) => {
  const fakeBib = {
    marcTitle: deletedItem.marcTitle,
    marcAuthor: deletedItem.marcAuthor
  };

  if (!deletedItem.marcTitle && !deletedItem.marcAuthor) return null;
  else return <BibTitleAndAuthor bib={fakeBib} />;
};

export default DeletedItemTitleAndAuthor;
