import { useTranslation } from 'react-i18next';

const SeriesInfo = ({ bib }) => {
  const { t } = useTranslation();

  if (!bib.marcSeriesInfo) return null;

  return (
  <>
    <strong>{t('Series')}:</strong>

    {' '}

    {bib.marcSeriesInfo.replace(/;$/, '').trim()}
  </>
  );
};

export default SeriesInfo;
