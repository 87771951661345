import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import sierraGlobals from '../../sierraGlobals';

import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';

const BindingsSelector = ({ defaultValue, onChange, defaultAllSelected, sm }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(defaultValue ?? []);

  const onDropdownChange = selected => {
    setSelected(selected);

    if (typeof onChange === 'function') {
      if (selected.length === sierraGlobals.bindings.length) {
        onChange([]);
      } else {
        onChange(selected);
      }
    }
  };

  return (
    <div className={sm ? 'dropdown-sm-container' : ''}>
      <DropdownMultiselect
        name="bindings"
        options={sierraGlobals.bindings}
        selected={defaultValue}
        placeholder={defaultAllSelected ? `${t('All')} (${t('default')})` : t('Nothing selected')}
        selectDeselectLabel={t('Select all')}
        handleOnChange={onDropdownChange}

        placeholderMultipleChecked={
          selected.length === sierraGlobals.bindings.length
            ? t('All')
            : selected.join(', ')
        }
      />
    </div>
  );
};

export default BindingsSelector;
