import { useTranslation } from 'react-i18next';

import { Spinner } from 'react-bootstrap';

const Loading = ({ text }) => {
  const { t } = useTranslation();

  return (
    <>
      <Spinner size="sm" animation="border" /> {text || t('Loading...')}
    </>
  );
};

export default Loading;
