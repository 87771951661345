import { useState } from 'react';

import { validateFormItem } from '../layout/forms';
import { submitForm } from '.';

import type { FormItem, FormData, OnSubmit } from '../layout/forms';
import type { Method } from '.';

interface ReturnType {
  onSubmit: OnSubmit;
  submitting: boolean;
  submitted: boolean;
  error: Error | null;
}

const useFormOnSubmit = (
  method: Method,
  path: string,
  form: FormItem[],
  onSuccess?: ((data: FormData) => void) | null
): ReturnType => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const onSubmit: OnSubmit = async (data: FormData) => {
    // Validation
    for (const formItem of form) {
      const result = await validateFormItem(formItem, data);

      if (typeof result === 'string') {
        setError(new Error(result));

        return;
      }
    }

    // Submit
    setSubmitting(true);
    setError(null);

    try {
      await submitForm(method, path, form, data);

      setSubmitted(true);

      if (onSuccess) onSuccess(data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Form submit failed:', err);

      if (err instanceof Error) {
        setError(err);
      } else {
        throw new Error(`Invalid error object caught: ${err}`);
      }
    }

    setSubmitting(false);
  };

  return { onSubmit, submitting, submitted, error };
};

export default useFormOnSubmit;
