import { useRef } from 'react';
import Form from 'react-bootstrap/Form';

import { Info } from '../../../common/layout';

import { getElementValue } from '..';

import type { FormItem } from '..';
import type { FormElementProps } from '.';

interface CheckableProps {
  type: 'checkbox' | 'radio';
}

type Props = CheckableProps & FormElementProps;

const Label: React.FC<{
  formItem: FormItem,
  checkboxRef: React.RefObject<HTMLInputElement>
}> = ({ formItem, checkboxRef }) => (
  <span onClick={(): void => { checkboxRef.current?.click(); }}>
    {formItem.label}

    <Info info={formItem.info} />
  </span>
);

const Checkable: React.FC<Props> = ({ type, formItem, onChange }) => {
  const ref = useRef<HTMLInputElement | null>(null);

  if (formItem.type !== 'checkbox' && formItem.type !== 'radio') {
    throw new Error(`Invalid checkable form item type ${formItem.type}`);
  }

  return (
    <Form.Check
      ref={ref}
      type={type}
      name={formItem.name}
      defaultChecked={formItem.defaultValue ?? undefined}
      required={formItem.required}
      onChange={(event): void => onChange &&
        onChange(getElementValue(event.target))}

      label={
        formItem.label
          ? <Label formItem={formItem} checkboxRef={ref} />
          : null
      }
    />
  );
};

export default Checkable;
