import paginationFactory from '@ounai/react-bootstrap-table2-paginator';

const usePagination = ({ page, pageSize, data, total, loading }) =>
  paginationFactory({
    page,
    sizePerPage: pageSize,
    totalSize: total,
    showTotal: !loading,
    hideSizePerPage: false
  });

export default usePagination;
