import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';

import { Dropdown, ButtonGroup, Button, Form, Modal } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

import OnClickModal from '../layout/OnClickModal';
import MarkItemAsMissingDialog from '../items/MarkItemAsMissingDialog';
import ItemDeletionInstructions from './ItemDeletionInstructions';

const DeletionModal = ({ allocation, show, onHide, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Item Deletion Instructions')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ItemDeletionInstructions />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t('Cancel')}
        </Button>

        <Button variant="primary" onClick={onConfirm}>
          {t('Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CollectionTaskModal = ({ allocation, show, onHide, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Collection Task Instructions')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ReactMarkdown>
          {allocation?.Comments && allocation.Comments[0]?.comment}
        </ReactMarkdown>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t('Cancel')}
        </Button>

        <Button variant="primary" onClick={onConfirm}>
          {t('Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ItemDropdown = ({ allocation, updateTable }) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(allocation.satisfiedAt !== null);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [markAsMissingComplete, setMarkAsMissingComplete] = useState(false);
  const [showCollectionTaskModal, setShowCollectionTaskModal] = useState(false);

  const markAsFoundOnChange = async event => {
    setChecked(checked => !checked);

    await api.post(`/item-searches/${allocation.id}/satisfied`, {
      satisfied: (allocation.satisfiedAt === null)
    });

    updateTable();
  };

  const deleteItem = async () => {
    if (allocation.satisfiedAt !== null) {
      throw new Error('Item has already been deleted!');
    }

    await api.post(`/item-searches/${allocation.id}/satisfied`, {
      satisfied: true
    });

    setChecked(true);
    setShowDeletionModal(false);
    updateTable();
  };

  return (
    <Dropdown as={ButtonGroup}>
      <DeletionModal
        allocation={allocation}
        show={showDeletionModal}
        onHide={() => setShowDeletionModal(false)}
        onConfirm={deleteItem}
      />

      <CollectionTaskModal
        allocation={allocation}
        show={showCollectionTaskModal}
        onHide={() => setShowCollectionTaskModal(false)}
        onConfirm={() => {
          markAsFoundOnChange();
          setShowCollectionTaskModal(false);
        }}
      />

      <Button variant="outline-dark">
        <Form.Check
          type="checkbox"
          className="checkbox-30px"

          checked={checked}

          onChange={(allocation.allocationType === 3)
            ? () => setShowDeletionModal(true)
            : (allocation.allocationType === 4)
              // wtf
                ? () => setShowCollectionTaskModal(true)
                : markAsFoundOnChange}

          disabled={
            /* once item deletion / collecion task is complete, it can not be undone */
            [3, 4, 5, 6].includes(allocation.allocationType) && allocation.satisfiedAt !== null
          }
        />
      </Button>

      <Dropdown.Toggle variant="outline-dark" split />

      <Dropdown.Menu>
        <OnClickModal
          forceClose={
            markAsMissingComplete ||
            (allocation.allocationType === 3 && allocation.satisfiedAt !== null)
          }

          button={
            <Dropdown.Item disabled={allocation.allocationType === 3 && allocation.satisfiedAt !== null}>
              {t('Mark as missing')}
            </Dropdown.Item>
          }
        >
          <MarkItemAsMissingDialog
            item={{
              ...allocation.Item,
              sierraItemRecordId: allocation.sierraItemRecordId
            }}
            onCancel={() => setMarkAsMissingComplete(true)}
            onSuccess={() => {
              setMarkAsMissingComplete(true);
              updateTable();
            }}
          />
        </OnClickModal>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ItemDropdown;
