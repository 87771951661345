import type { SortOrder } from '.';

interface HookProps {
  setPage: React.Dispatch<number>;
  setPageSize: React.Dispatch<number>;
  setSortField: React.Dispatch<string>;
  setSortOrder: React.Dispatch<SortOrder>;
}

// Implemented by react-bootstrap-table2
interface CallbackProps {
  page: number;
  sizePerPage: number;
  sortField: string;
  sortOrder: SortOrder;
}

const useOnTableChange = ({ setPage, setPageSize, setSortField, setSortOrder }: HookProps) =>
  (type: unknown, { page, sizePerPage, sortField, sortOrder }: CallbackProps): void => {
    if (typeof page === 'number') {
      setPage(page);
    }

    if (typeof sizePerPage === 'number') {
      setPageSize(sizePerPage);
    }

    if (typeof sortField === 'string') {
      setSortField(sortField);
    }

    if (['asc', 'desc'].includes(sortOrder)) {
      setSortOrder(sortOrder);
    }
  };

export default useOnTableChange;
