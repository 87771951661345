import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';
import useQueryParamState from '../../hooks/useQueryParamState';
import useMassreader from '../../hooks/useMassreader';
import { formatDateTimeRelative } from '../../services/date';
import { markAsMissing } from '../../services/item';
import { getProblems } from '../../services/inventory';
import { getUserLocation } from '../../services/user';

import { Button, Modal, Row, Col } from 'react-bootstrap';

import ItemShelfViewCanvas from '../items/shelfView/ItemShelfViewCanvas';
import Loading from '../api/Loading';
import BibTitleAndAuthor from '../bibs/BibTitleAndAuthor';
import ClickSelect from '../api/ClickSelect';
import OnClickModal from '../layout/OnClickModal';
import MassreaderSelector from '../api/MassreaderSelector';
import ItemView from '../items/ItemView';
import ItemSelector from '../forms/ItemSelector';
import Signum from '../items/Signum';

import { CoverImage } from '../../../bibs';

const ItemNotInShelfModal = ({ item, nextIndex }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const button = (
    <Button size="sm" variant="warning">
      {t('Not in Shelf')}
    </Button>
  );

  const neverSeen = !item.LastItemScan;

  let notSeenString = null;
  let adviceMarkAsMissing = false;

  if (!neverSeen) {
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    if (new Date(item.LastItemScan.lastScanDate).getTime() < threeMonthsAgo.getTime()) {
      notSeenString = t('This item has not been seen in over three months.');
    }

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    if (new Date(item.LastItemScan.lastScanDate).getTime() < sixMonthsAgo.getTime()) {
      notSeenString = t('This item has not been seen in over six months.');
      adviceMarkAsMissing = true;
    }

    const yearAgo = new Date();
    yearAgo.setFullYear(yearAgo.getFullYear() - 1);

    if (new Date(item.LastItemScan.lastScanDate).getTime() < yearAgo.getTime()) {
      notSeenString = t('This item has not been seen in over a year.');
      adviceMarkAsMissing = true;
    }
  }

  if (!neverSeen && !notSeenString) {
    return <span onClick={nextIndex}>{button}</span>;
  }

  return (
    <OnClickModal
      button={button}
      forceClose={forceClose}
      onOpen={() => setForceClose(false)}
    >
      <div style={{ marginBottom: '10px' }}>
        <strong>
          {neverSeen ? t('This item has never been seen.') : notSeenString}
        </strong>

        {adviceMarkAsMissing && (
          <div>
            {t('It should be marked as missing.')}
          </div>
        )}
      </div>

      <Button
        variant="danger"
        size="sm"
        style={{ marginRight: '6px' }}
        onClick={() => {
          markAsMissing(item.sierraItemRecordId);
          nextIndex();
          setForceClose(true);
        }}
      >
        {t('Mark as missing')}
      </Button>

      <Button
        variant="warning"
        size="sm"
        style={{ marginRight: '6px' }}
        onClick={() => {
          nextIndex();
          setForceClose(true);
        }}
      >
        {t('Skip without marking as missing')}
      </Button>

      <Button
        variant="secondary"
        size="sm"
        onClick={() => setForceClose(true)}
      >
        {t('Cancel')}
      </Button>
    </OnClickModal>
  );
};

const ItemViewModal = ({ itemId }) => {
  const { t } = useTranslation();

  const button = (
    <Button size="sm" variant="primary">
      {t('View Item')}
    </Button>
  );

  return (
    <OnClickModal
      title={t('View Item')}
      button={button}
      closeButton
    >
      <ItemView
        itemId={itemId}
        alwaysTabbed
        hideInventoryButton
      />
    </OnClickModal>
  );
};

const ReaderDoesNotFindItemModal = ({ nextIndex }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const button = (
    <Button size="sm" variant="secondary">
      {t('Reader does not find this item')}
    </Button>
  );

  return (
    <OnClickModal
      button={button}
      forceClose={forceClose}
      onOpen={() => setForceClose(false)}
    >
      <div style={{ marginBottom: '10px' }}>
        <strong>
          {t('Verify that your reader is in reading mode.')}
        </strong>
      </div>

      <div style={{ marginBottom: '10px' }}>
        (TODO kuva tähän)
      </div>

      <div style={{ marginBottom: '10px' }}>
        {t('If the reading mode is enabled and your reader still does not find the item, the item\'s RFID tag may be broken.')} {t('Collect the item.')}
      </div>

      <Button
        variant="primary"
        size="sm"
        style={{ marginRight: '6px' }}
        onClick={() => {
          nextIndex();
          setForceClose(true);
        }}
      >
        {t('Item Collected')}
      </Button>

      <Button
        variant="secondary"
        size="sm"
        onClick={() => setForceClose(true)}
      >
        {t('Cancel')}
      </Button>
    </OnClickModal>
  );
};

const ProblemItemModal = ({ tag, onComplete }) => {
  const { t } = useTranslation();

  if (!tag) {
    return null;
  }

  if (!tag.item) {
    return (
      <>
        <ClickSelect pre>
          {tag.barcode}
        </ClickSelect>

        <div style={{ marginBottom: '10px' }}>
          {t('Item record not found')}.
        </div>

        <Button variant="secondary" size="sm" onClick={onComplete}>
          {t('Close')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Row style={{ marginBottom: '10px' }}>
        <Col xs="auto" style={{ paddingRight: 0 }}>
          {tag.item.Bib && (
            <CoverImage
              isbn={tag.item.Bib.marcISBN}
              materialType={tag.item.Bib.fixedFieldMaterialType}
              noLink
            />
          )}
        </Col>

        <Col>
          <div>
            <Signum itemId={tag.item.sierraItemRecordId} height="2rem" />
          </div>

          <div style={{ marginTop: '2px', marginBottom: '6px' }}>
            <BibTitleAndAuthor bib={tag.item.Bib} />
          </div>

          <ClickSelect pre>
            {tag.barcode}
          </ClickSelect>
        </Col>
      </Row>

      <hr />

      <strong>
        {t('Item has the following problems')}:
      </strong>

      <div style={{ marginBottom: '10px' }}>
        {tag.problems.map((problem, i) => (
          <li style={{ marginLeft: '12px' }} key={i}>
            {problem}
          </li>
        ))}
      </div>

      <Button variant="secondary" size="sm" onClick={onComplete}>
        {t('Close')}
      </Button>
    </>
  );
};

const InventoryShelf = ({ firstItemId, setFirstItemId, itemsAfter, reader, height }) => {
  const { t } = useTranslation();

  const minItemBatchSize = 20;

  const current = useRef([]);
  const next = useRef([]);
  const currentFirstItemId = useRef(firstItemId);
  const readItemIds = useRef(new Set());

  const [queue, setQueue] = useState([]);
  const [index, setIndex] = useState(null);
  const [problemItems, setProblemItems] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const currentItem = useMemo(() => (
    (index !== null && queue.length > index) ? queue[index] : null
  ), [index, queue]);

  const fetchShelfData = useCallback(async () => {
    if (isEmpty) return;

    if (current.current.length === 0) {
      const currentShelfData = await api.get('/items/shelf', {
        params: {
          sierraItemRecordId: currentFirstItemId.current,
          itemsBefore: 0,
          itemsAfter: Math.max(minItemBatchSize, itemsAfter) * 2
        }
      });

      current.current = [currentShelfData.current, ...currentShelfData.next];

      const nextShelfData = await api.get('/items/shelf', {
        params: {
          sierraItemRecordId: current.current[current.current.length - 1].sierraItemRecordId,
          itemsBefore: 0,
          itemsAfter: Math.max(minItemBatchSize, itemsAfter) * 2
        }
      });

      if (nextShelfData.next && nextShelfData.next.length > 0) {
        next.current = nextShelfData.next;
        currentFirstItemId.current = next.current[next.current.length - 1].sierraItemRecordId;

        setIndex(0);
        setQueue([...current.current, ...next.current]);

        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    } else {
      const nextShelfData = await api.get('/items/shelf', {
        params: {
          sierraItemRecordId: currentFirstItemId.current,
          itemsBefore: 0,
          itemsAfter: Math.max(minItemBatchSize, itemsAfter) * 2
        }
      });

      next.current = nextShelfData.next;
      currentFirstItemId.current = next.current[next.current.length - 1].sierraItemRecordId;

      setQueue([...current.current, ...next.current]);
    }
  }, [itemsAfter, isEmpty]);

  const nextIndex = useCallback(() => {
    if (index === null) return;

    if (index === current.current.length) {
      current.current = next.current;
      next.current = [];

      setIndex(1);
      setQueue([...current.current]);

      fetchShelfData();
    } else if (queue.length - itemsAfter - 1 > index) {
      setIndex(i => i + 1);
    }
  }, [fetchShelfData, index, itemsAfter, queue.length]);

  const checkNextId = useCallback(() => {
    if (index === null || !currentItem) return;

    const tresholdDate = new Date();
    tresholdDate.setDate(tresholdDate.getDate() - 7); // a week

    if (
      readItemIds.current.has(currentItem.sierraItemRecordId) || (
        currentItem.LastItemScan &&
        new Date(currentItem.LastItemScan.lastScanDate).getTime() > tresholdDate.getTime()
      )
    ) {
      nextIndex();
    } else if (currentItem) {
      setFirstItemId(currentItem.sierraItemRecordId);
    }
  }, [index, nextIndex, currentItem, setFirstItemId]);

  useEffect(checkNextId, [checkNextId, index]);

  const onTagRead = useCallback(async tag => {
    if (!tag.item || !readItemIds.current.has(tag.item.sierraItemRecordId)) {
      const userLocation = getUserLocation();

      const rules = {
        city: ['e', 'v'],
        location: userLocation,
        itemStatus: ['-', 'o']
      };

      const enabledRules = [
        'barcode',
        'tagValid',
        'itemStatus',
        'city',
        'location',
        'itemHold',
        'checkedOut'
      ];

      const problems = await getProblems(tag, t, rules, enabledRules);

      if (problems.length > 0) {
        setProblemItems(m => {
          if (m.find(_tag => _tag.tagId === tag.tagId)) return m;
          else return [...m, { ...tag, problems }];
        });
      }

      if (tag.item) {
        for (let i = index; i < queue.length; i++) { // search all items on queue
          if (queue.length > i && queue[i].sierraItemRecordId === tag.item.sierraItemRecordId) {
            readItemIds.current.add(tag.item.sierraItemRecordId);

            break;
          }
        }

        checkNextId();
      }
    }
  }, [checkNextId, index, queue, t]);

  useMassreader(reader, onTagRead);

  useEffect(() => fetchShelfData(), [fetchShelfData]);

  if (isEmpty) {
    return <strong>{t('Shelf is empty.')}</strong>;
  }

  if (queue.length === 0 || index === null) {
    return <Loading />;
  }

  return (
    <>
      <Modal show={problemItems.length > 0}>
        <Modal.Body>
          <ProblemItemModal
            tag={problemItems[0]}
            onComplete={() => setProblemItems(p => [...p.slice(1)])}
          />
        </Modal.Body>
      </Modal>

      {currentItem && (
        <>
          <div style={{ marginBottom: '6px' }}>
            <div>
              {t('Next Item')}: <BibTitleAndAuthor bib={currentItem.Bib} />
            </div>

            <div style={{ marginTop: '4px' }}>
              {t('Last Scan Date')}: <strong>{
                currentItem.LastItemScan
                  ? formatDateTimeRelative(currentItem.LastItemScan.lastScanDate, t, true)
                  : t('never')
              }</strong>
            </div>

            <ClickSelect style={{ marginTop: '4px' }} pre>
              {currentItem.barcode}
            </ClickSelect>
          </div>

          <span style={{ marginRight: '6px' }}>
            <ItemViewModal itemId={currentItem.sierraItemRecordId} />
          </span>

          <span style={{ marginRight: '6px' }}>
            <ItemNotInShelfModal item={currentItem} nextIndex={nextIndex} />
          </span>

          <ReaderDoesNotFindItemModal nextIndex={nextIndex} />
        </>
      )}

      <hr />

      <ItemShelfViewCanvas
        shelfData={{
          previous: [],
          current: null,
          next: queue.slice(index, index + itemsAfter + 1)
        }}
        highlighter="lastSeen"
        highlightItemsSet={readItemIds.current}
        height={height}
      />
    </>
  );
};

const ShelfInventoryPage = ({ containerHeight }) => {
  const { t } = useTranslation();

  const [reader, setReader] = useQueryParamState('reader', 'none');
  const [firstItemId, setFirstItemId] = useQueryParamState('itemId', null, 'number');

  const [itemsAfter, setItemsAfter] = useState(null);
  const [shelfHeight, setShelfHeight] = useState(null);

  const topRef = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current || !topRef.current) return;

    setItemsAfter(Math.floor(ref.current.offsetWidth / 60));
    setShelfHeight(Math.min(ref.current.offsetWidth, containerHeight - topRef.current.offsetHeight - 160));
  }, [ref, topRef, containerHeight]);

  return (
    <>
      <div ref={topRef}>
        <h3>{t('Shelf Inventory')}</h3>

        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <MassreaderSelector
            reader={reader}
            setReader={setReader}
          />
        </div>
      </div>

      <div ref={ref} style={{ width: '100%' }}>
        {
          firstItemId === null
            ? <ItemSelector onSubmit={itemId => setFirstItemId(itemId)} />
            : (itemsAfter !== null && shelfHeight !== null) && (
              <InventoryShelf
                firstItemId={firstItemId}
                setFirstItemId={setFirstItemId}
                itemsAfter={itemsAfter}
                reader={reader}
                height={shelfHeight}
              />
              )
        }
      </div>
    </>
  );
};

export default ShelfInventoryPage;
