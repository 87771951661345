import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../../hooks/useAPI';

import { Marker, Popup, LayersControl, LayerGroup } from 'react-leaflet';

const LibrariesLayer = ({ setLibraries, setSelectedLibrary }) => {
  const { t } = useTranslation();

  const [locations] = useAPI('GET', '/locations');

  const libraryMarkers = [];

  useEffect(() => {
    if (setLibraries && locations !== null) {
      const libraries = {};

      for (const library of locations) {
        if (library.latitude === null || library.longitude === null || library.code.length !== 3) continue;
        libraries[library.code] = library;
      }

      setLibraries(libraries);
    }
  }, [locations, setLibraries]);

  if (locations !== null) {
    for (const location of locations) {
      if (location.latitude === null || location.longitude === null || location.code.length !== 3) continue;

      libraryMarkers.push(
        <Marker
          key={location.code}
          position={[location.latitude, location.longitude]}
        >
          <Popup
            onOpen={() => setSelectedLibrary(location.code)}
            onClose={() => setSelectedLibrary(null)}
          >
            {location.code} ({location.name})
          </Popup>
        </Marker>
      );
    }
  }

  return (
    <LayersControl.Overlay checked name={t('Libraries')}>
      <LayerGroup>
        {libraryMarkers}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

export default LibrariesLayer;
