import {
  validateUrl,
  validatePassword,
  validateEmail,
  validateUsername,
  validateJSON
} from '.';

import type { FormItem, FormData } from '..';
import type { FormItemValidation } from '.';

const doValidate = (validation: FormItemValidation, formItem: FormItem, data: FormData): boolean => {
  const value = data[formItem.name];

  switch (validation.type) {
    case 'has-changed':
      return (value !== formItem.defaultValue);
    case 'same-as':
      if (data[validation.otherName] === undefined) {
        throw new Error(`Other form element ${validation.otherName} does not exist`);
      }

      return (value === data[validation.otherName]);
    case 'different-from':
      if (data[validation.otherName] === undefined) {
        throw new Error(`Other form element ${validation.otherName} does not exist`);
      }

      return (value !== data[validation.otherName]);
    case 'url':
      return validateUrl(value);
    case 'password':
      return validatePassword(value);
    case 'email':
      return validateEmail(value);
    case 'username':
      return validateUsername(value);
    case 'json':
      return validateJSON(value);
  }
};

const validateFormItem = (formItem: FormItem, data: FormData): string | void => {
  if (formItem.validation) {
    if (Array.isArray(formItem.validation)) {
      for (const validation of formItem.validation) {
        if (!doValidate(validation, formItem, data)) {
          return validation.message;
        }
      }
    } else {
      if (!doValidate(formItem.validation, formItem, data)) {
        return formItem.validation.message;
      }
    }
  }
};

export default validateFormItem;
