import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatItemStatus } from '../../services/item';
import expandRow from '../../services/expandRow';
import { hasRole } from '../../services/auth';
import { formatDateRelative } from '../../services/date';

import BootstrapTable from '@ounai/react-bootstrap-table2';

import ItemView from './ItemView';
import BibView from '../bibs/BibView';
import Signum from './Signum';
import ClickSelect from '../api/ClickSelect';
import BibTitleAndAuthor from '../bibs/BibTitleAndAuthor';
import InventoryTableItemTools from './InventoryTableItemTools';
import InventoryTableEnabledColumnsForm from './InventoryTableEnabledColumnsForm';
import DeletedItemTitleAndAuthor from './DeletedItemTitleAndAuthor';

import { CoverImage } from '../../../bibs';

const InventoryTable = ({
  items,
  rules,
  enabledRules,
  notifys,
  setNotifys,
  removeTag,
  showSignum,
  recentlySeen,
  radarRssi,
  setRadarItem,
  rssiMax,
  radarLastSeen,
  children
}) => {
  const { t } = useTranslation();

  const [enabledColumns, setEnabledColumns] = useState([
    'cover',
    'barcode',
    'item.callNumber',
    'item.genre',
    'work',
    'info',
    'tools'
  ]);

  const emptyFormatter = (cell, row, rowIndex, formatExtraData) => cell || '-';

  const columnStyle = (cell, row, rowIndex, colIndex) => {
    if (recentlySeen[row.tagId]) {
      const opacity = 1 - Math.min(1, (new Date().getTime() - recentlySeen[row.tagId].getTime()) / 500);

      return { backgroundColor: `rgba(129, 199, 132, ${opacity})` };
    }
  };

  const columns = [
    {
      dataField: 'cover',
      text: t('Cover'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <CoverImage
          isbn={
            (row.item && row.item.Bib && row.item.Bib.marcISBN) ||
            (row.deletedItem && row.deletedItem.marcISBN) ||
            (row.deletedBib && row.deletedBib.marcISBN)
          }

          materialType={
            (row.item && row.item.Bib && row.item.Bib.fixedFieldMaterialType) ||
            (row.deletedItem && row.deletedItem.fixedFieldMaterialType) ||
            (row.deletedBib && row.deletedBib.fixedFieldMaterialType)
          }
        />
      ),
      headerStyle: () => ({ width: '104px' }),
      style: columnStyle
    },
    {
      dataField: 'tagId',
      text: t('Tag ID'),
      sort: true,
      style: columnStyle
    },
    {
      dataField: 'barcode',
      text: t('Barcode'),
      sort: true,
      headerStyle: () => ({ width: '150px' }),
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell
          ? <ClickSelect pre>{cell}</ClickSelect>
          : '-'
      )
    },
    {
      dataField: 'gs1',
      text: t('Product Code'),
      sort: true,
      headerStyle: () => ({ width: '150px' }),
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell
          ? <ClickSelect pre>{cell}</ClickSelect>
          : '-'
      )
    },
    {
      dataField: 'item.callNumber',
      text: t('Call Number'),
      sort: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        !cell
          ? (
              (row.deletedItem && row.deletedItem.callNumber)
                ? row.deletedItem.callNumber
                : '-'
            )
          : (
              formatExtraData.showSignum
                ? (row.item ? <Signum itemId={row.item.sierraItemRecordId} height="2rem" /> : null)
                : cell
            )
      ),
      formatExtraData: { showSignum }
    },
    {
      dataField: 'item.genre',
      text: t('Genre'),
      sort: true,
      style: columnStyle,
      formatter: emptyFormatter
    },
    {
      dataField: 'work',
      text: t('Work'),
      isDummyField: true,
      sort: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        (row.item && row.item.Bib)
          ? <BibTitleAndAuthor bib={row.item.Bib} />
          : row.deletedItem
            ? <DeletedItemTitleAndAuthor deletedItem={row.deletedItem} />
            : row.deletedBib
              ? <BibTitleAndAuthor bib={row.deletedBib} />
              : '-'
      )
    },
    {
      dataField: 'item.Bib.marcTitle',
      text: t('Title'),
      sort: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell || (
          (row.deletedItem && row.deletedItem.marcTitle)
            ? row.deletedItem.marcTitle
            : (row.deletedBib && row.deletedBib.marcTitle)
                ? row.deletedBib.marcTitle
                : '-'
        )
      )
    },
    {
      dataField: 'item.Bib.marcAuthor',
      text: t('Author'),
      sort: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell || (
          (row.deletedItem && row.deletedItem.marcAuthor)
            ? row.deletedItem.marcAuthor
            : (row.deletedBib && row.deletedBib.marcAuthor)
                ? row.deletedBib.marcAuthor
                : '-'
        )
      )
    },
    {
      dataField: 'item.Bib.fixedFieldLang',
      text: t('Language'),
      sort: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell || (
          (row.deletedBib && row.deletedBib.fixedFieldLang)
            ? row.deletedBib.fixedFieldLang
            : '-'
        )
      )
    },
    {
      dataField: 'item.fixedFieldItemStatus',
      text: t('Item Status'),
      sort: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        row.item
          ? formatItemStatus(row.item, t)
          : '-'
      )
    },
    {
      dataField: 'item.fixedFieldCity',
      text: t('City'),
      sort: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell || (
          (row.deletedItem && row.deletedItem.fixedFieldCity)
            ? row.deletedItem.fixedFieldCity
            : '-'
        )
      )
    },
    {
      dataField: 'item.fixedFieldLocation',
      text: t('Location'),
      sort: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell || (
          (row.deletedItem && row.deletedItem.fixedFieldLocation)
            ? row.deletedItem.fixedFieldLocation
            : '-'
        )
      )
    },
    {
      dataField: 'item.fixedFieldLastCheckoutDate',
      text: t('Last Checked Out'),
      sort: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => formatDateRelative(cell, t) || '-'
    },
    {
      dataField: 'item.sierraCreatedAt',
      text: t('Item Created At'),
      sort: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => formatDateRelative(cell, t) || '-'
    },
    {
      dataField: 'info',
      isDummyField: true,
      text: t('Info'),
      headerStyle: () => ({ minWidth: '300px' }),
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        row.problems.map((problem, i) => (
          <li style={{ marginLeft: '12px' }} key={i}>
            {problem}
          </li>
        ))
      )
    },
    {
      dataField: 'tools',
      isDummyField: true,
      text: t('Tools'),
      headerStyle: () => ({ width: '156px' }),
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <InventoryTableItemTools
          item={row}
          notifys={formatExtraData.notifys}
          setNotifys={setNotifys}
          removeTag={removeTag}
          radarRssi={formatExtraData.radarRssi}
          setRadarItem={setRadarItem}
          rssiMax={formatExtraData.rssiMax}
          radarLastSeen={formatExtraData.radarLastSeen}
        />
      ),
      formatExtraData: { notifys, radarRssi, rssiMax, radarLastSeen }
    }
  ].map(column => ({
    ...column,
    hidden: !enabledColumns.includes(column.dataField)
  }));

  return (
    <>
      <InventoryTableEnabledColumnsForm
        columns={columns}
        enabledColumns={enabledColumns}
        setEnabledColumns={setEnabledColumns}
      />

      {children}

      <BootstrapTable
        keyField="tagId"
        data={items}
        columns={columns}
        classes="w-auto api-table mw-100"
        wrapperClasses="table-responsive"
        bootstrap4

        expandRow={
          hasRole('VIEW-ITEMS')
            ? expandRow(row => (
              row.item
                ? <ItemView itemId={row.item.sierraItemRecordId} />
                : row.deletedBib
                  ? <BibView bibId={row.deletedBib.sierraBibRecordId} />
                  : t('No item record found')
            ))
            : undefined
        }
      />
    </>
  );
};

export default InventoryTable;
