import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';
import { isBarcodeValid } from '../../services/item';

import { Button, Row, Col, Form } from 'react-bootstrap';
import { UpcScan } from 'react-bootstrap-icons';

import BarcodeScannerModal from '../nav/BarcodeScannerModal';
import Loading from '../api/Loading';

const ItemSelector = ({ onSubmit }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmitBarcode = async barcode => {
    if (loading || barcode === '') return;

    setLoading(true);

    if (typeof onSubmit === 'function') {
      if (!isBarcodeValid(barcode)) {
        setError(`${t('Invalid barcode')} "${barcode}"`);
      } else {
        const itemId = await api.get(`/items/barcode/${barcode}/id`);

        if (itemId === null) setError(t('No item found for barcode') + ` "${barcode}"`);
        else onSubmit(itemId);
      }
    } else {
      throw new Error('No onSubmit callback given!');
    }

    setLoading(false);
  };

  const onFormSubmit = event => {
    event.preventDefault();

    onSubmitBarcode(event.target.barcode.value);
  };

  const scanButton = (
    <Button
      variant="link"
      size="sm"
      style={{
        padding: 0,
        border: 0,
        verticalAlign: 'baseline'
      }}
    >
      <UpcScan size={24} />
    </Button>
  );

  return (
    <>
      <div style={{ marginBottom: '6px' }}>
        <strong>
          {t('Search First Item')}
        </strong>
      </div>

      <Form onSubmit={onFormSubmit}>
        <Form.Group as={Row}>
          <Form.Label xs="auto" column="sm" style={{ paddingRight: 0 }}>
            {t('Barcode')}
          </Form.Label>

          <Col xs="auto" style={{ paddingRight: 0 }}>
            {!loading && (
              <BarcodeScannerModal
                onScan={onSubmitBarcode}
                scanButton={scanButton}
              />
            )}
          </Col>

          <Col xs="auto" style={{ paddingLeft: '4px', paddingRight: 0 }}>
            <Form.Control
              name="barcode"
              size="sm"
            />
          </Col>

          <Col xs="auto" style={{ paddingLeft: '4px' }}>
            <Button
              variant="primary"
              type="submit"
              size="sm"
              disabled={loading}
              style={{ marginRight: '4px' }}
            >
              {t('Submit')}
            </Button>

            {loading && <Loading />}
          </Col>
        </Form.Group>

        <span style={{ color: 'var(--danger)' }}>
          {error}
        </span>
      </Form>
    </>
  );
};

export default ItemSelector;
