import { PagedTitleJSON, EventsJSON, TitlePagingReportsJSON, AllocationHistoryJSON } from '.';

import type { PagedTitle } from '../api/models';

const PagedTitleView: React.FC<{
  pagedTitle: PagedTitle
}> = ({ pagedTitle }) => (
  <>
    <PagedTitleJSON pagedTitle={pagedTitle} />

    <hr />

    <EventsJSON pagedTitle={pagedTitle} />

    <hr />

    <TitlePagingReportsJSON pagedTitle={pagedTitle} />

    <hr />

    <AllocationHistoryJSON pagedTitle={pagedTitle} />
  </>
);

export default PagedTitleView;
