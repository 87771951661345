import { useQueryParams } from '.';

const useQueryParam = (name: string): string | null => {
  const queryParam = useQueryParams().get(name);

  return (typeof queryParam === 'string' && queryParam.length > 0)
    ? queryParam
    : null;
};

export default useQueryParam;
