import axios from 'axios';

import config from './config';

const isApiUrl = url =>
  url.startsWith(config.api.url) || url.startsWith(config.api.urlV2);

// Automatically add authorization bearer token to axios requests
axios.interceptors.request.use(
  axiosConfig => {
    if (isApiUrl(axiosConfig.url)) {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken !== null) {
        axiosConfig.headers.authorization = `Bearer ${accessToken}`;
      }
    }

    return axiosConfig;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401 && error?.response?.data === 'Invalid token') {
      error.message = 'Login expired';

      window.localStorage.clear();
      window.location.reload();
    } else if (error?.response?.data) {
      error.message = error.response.data;
    }

    return Promise.reject(error);
  }
);
