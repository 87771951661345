import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';

import { Button } from 'react-bootstrap';
import { Trash, PencilSquare } from 'react-bootstrap-icons';

import Authorized from '../auth/Authorized';
import OnClickModal from '../layout/OnClickModal';
import RFIDReaderForm from './RFIDReaderForm';

const RFIDReaderOptionsColumn = ({ reader, update }) => {
  const { t } = useTranslation();

  const [forceCloseEditor, setForceCloseEditor] = useState(false);
  const [forceCloseConfirm, setForceCloseConfirm] = useState(false);

  const editButton = (
    <Button variant="link" size="sm" style={{ padding: 0, border: 0 }}>
      <PencilSquare size={20} style={{ color: 'var(--primary)' }} />
    </Button>
  );

  const deleteButton = (
    <Authorized role="DEVELOPER">
      <Button variant="link" size="sm" style={{ padding: 0, border: 0 }}>
        <Trash size={20} style={{ color: 'var(--danger)' }} />
      </Button>
    </Authorized>
  );

  const onConfirmDelete = async () => {
    await api.delete(`/rfid/readers/${reader.id}`);

    update();

    setForceCloseConfirm(true);
  };

  const onSubmitEdit = async data => {
    await api.put(`/rfid/readers/${reader.id}`, data);

    update();

    setForceCloseEditor(true);
  };

  return (
    <>
      <OnClickModal
        title={t('Edit reader')}
        button={editButton}
        forceClose={forceCloseEditor}
        onOpen={() => setForceCloseEditor(false)}
        closeButton
      >
        <RFIDReaderForm
          reader={reader}
          onSubmit={onSubmitEdit}
        />
      </OnClickModal>

      <span style={{ marginRight: '8px' }} />

      <OnClickModal
        button={deleteButton}
        forceClose={forceCloseConfirm}
        onOpen={() => setForceCloseConfirm(false)}
      >
        <div style={{ marginBottom: '10px' }}>
          <strong>
            {t('Are you sure you want to delete reader')} {reader.name}?
          </strong>
        </div>

        <Button variant="primary" size="sm" style={{ marginRight: '4px' }} onClick={onConfirmDelete}>
          {t('Delete')}
        </Button>

        <Button variant="secondary" size="sm" onClick={() => setForceCloseConfirm(true)}>
          {t('Cancel')}
        </Button>
      </OnClickModal>
    </>
  );
};

export default RFIDReaderOptionsColumn;
