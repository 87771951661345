import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Table } from '../../common/layout';

import { useGet } from '..';

import {
  useNoDataIndication,
  useColumns,
  useSetSearchParams,
  usePagination,
  useDefaultSorting,
  useOnTableChange,
  useTableQueryParams
} from '.';

// eslint-disable-next-line max-lines-per-function
const APITable = ({
  resource, keyField, columns, expandRow, enableQueryParams,
  pageSize: propPageSize,
  sortField: propSortField,
  sortOrder: propSortOrder,
  noPagination
}) => {
  const { queryPage, queryPageSize, querySortField, querySortOrder } = useTableQueryParams(enableQueryParams);
  const setSearchParams = useSetSearchParams();

  const [page, setPage] = useState(queryPage ?? 1);
  const [pageSize, setPageSize] = useState(queryPageSize ?? propPageSize ?? 25);
  const [sortField, setSortField] = useState(querySortField ?? propSortField ?? keyField);
  const [sortOrder, setSortOrder] = useState(querySortOrder ?? propSortOrder ?? 'asc');

  useEffect(() => {
    if (enableQueryParams) {
      setSearchParams({ page, pageSize, sortField, sortOrder });
    }
  }, [setSearchParams, page, pageSize, sortField, sortOrder, enableQueryParams]);

  const params = { page, pageSize, sortField, sortOrder };
  const { data, total, loading, error, update } = useGet(resource, params);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => update(), [pageSize, page, sortField, sortOrder]);

  if (error && !(error instanceof Error)) {
    // eslint-disable-next-line no-console
    console.log('Invalid APITable error:', error);

    throw new Error('Invalid error encountered!');
  }

  const pagination = usePagination({ page, pageSize, data, total, loading });

  return (
    <Table
      keyField={keyField}
      columns={useColumns(columns)}
      data={(loading || error) ? [] : data}
      expandRow={expandRow}
      rowStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
      classes="w-auto api-table mw-100"
      wrapperClasses="table-responsive"
      noDataIndication={useNoDataIndication({ loading, error })}
      defaultSorted={useDefaultSorting({ sortField, sortOrder })}
      onTableChange={useOnTableChange({ setPage, setPageSize, setSortField, setSortOrder })}
      pagination={noPagination ? undefined : pagination}
      striped
      remote
      bootstrap4
    />
  );
};

APITable.propTypes = {
  // Required
  resource: PropTypes.string.isRequired,
  keyField: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  enableQueryParams: PropTypes.bool.isRequired,

  // Optional
  expandRow: PropTypes.object,
  pageSize: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  noPagination: PropTypes.bool
};

export default APITable;
