import { useMargins } from '.';

const StackItem: React.FC<{ first: boolean }> = ({ children, first }) => (
  <div style={useMargins(0, 0, (first ? 0 : 10), 0)}>
    {children}
  </div>
);

const InnerStack: React.FC<{ children: React.ReactNode[] }> = ({ children }) => (
  <>
    {children
      .filter(child => (child !== null && child !== undefined && typeof child !== 'boolean'))
      .map((child, i) => (
        <StackItem key={i} first={i === 0}>
          {child}
        </StackItem>
      ))}
  </>
);

const Stack: React.FC<{ children: React.ReactNode | React.ReactNode[] }> = ({ children }) =>
  Array.isArray(children)
    ? <InnerStack>{children}</InnerStack>
    : <>{children}</>;

export default Stack;
