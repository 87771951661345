import { useTranslation } from '../../common/i18n';

import type { Link } from '../../api/models';
import type { Dialog } from '../../layout/dialogs';

const useDeleteLinkDialog = (link: Link): Dialog => {
  if (!link.id) {
    throw new Error(`Invalid link id ${link.id}`);
  }

  return {
    type: 'api-delete',
    id: `delete-link-${link.id}`,
    path: '/links',
    recordId: link.id,
    content: `${useTranslation('Delete link')} "${link.name}"?`
  };
};

export default useDeleteLinkDialog;
