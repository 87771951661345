import { useTranslation } from 'react-i18next';

import { Button, Card, Row, Col } from 'react-bootstrap';

import BibTitleAndAuthor from './BibTitleAndAuthor';

import { CoverImage } from '../../../bibs';

const BookCard = ({ bib }) => (
  <Card style={{ width: '10rem', marginBottom: '2rem' }}>
    <Card.Body>
      <center style={{ marginBottom: '10px' }}>
        <CoverImage isbn={bib.marcISBN} materialType={bib.fixedFieldMaterialType} />
      </center>

      <div>
        <BibTitleAndAuthor bib={bib} />
      </div>
    </Card.Body>
  </Card>
);

const BookShelfView = ({ bibs }) => {
  const { t } = useTranslation();

  const cards = bibs.map(bib => (
    <BookCard bib={bib} key={bib.sierraBibRecordId} />
  ));

  return (
    <>
      <Row xs={1} sm={2} lg={4}>
        {cards.map((card, i) => (
          <Col key={i}>
            {card}
          </Col>
        ))}
      </Row>

      <center>
        <Button size="sm" variant="outline-dark">
          {t('Load More...')}
        </Button>
      </center>
    </>
  );
};

export default BookShelfView;
