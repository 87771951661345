export const numberField = (field, label) => ({
  field,
  label,
  type: 'NUMBER',
  operators: [
    'EQUAL',
    'NOT_EQUAL',
    'LARGER_EQUAL',
    'LARGER',
    'SMALLER_EQUAL',
    'SMALLER',
    'BETWEEN',
    'NOT_BETWEEN'
  ]
});

export const textField = (field, label) => ({
  field,
  label,
  type: 'TEXT',
  operators: [
    'LIKE',
    'NOT_LIKE',
    'EQUAL',
    'NOT_EQUAL',
    'REGEX',
    'NOT_REGEX'
  ]
});

export const listField = (field, label, list) => ({
  field,
  label,
  type: 'LIST',
  operators: [
    'EQUAL',
    'NOT_EQUAL'
  ],
  value: list
});

export const booleanField = (field, label) => ({
  field,
  label,
  type: 'BOOLEAN'
});

export const dateField = (field, label) => ({
  field,
  label,
  type: 'DATE',
  operators: [
    'EQUAL',
    'NOT_EQUAL',
    'LARGER_EQUAL',
    'SMALLER_EQUAL',
    'BETWEEN',
    'NOT_BETWEEN'
  ]
});
