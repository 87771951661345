const TabWrapper = ({ center, children }) => {
  const style = {
    margin: '10px'
  };

  if (center) {
    style.marginLeft = style.marginRight = 'auto';
    style.width = 'max-content';
  }

  return (
    <div style={style}>
      {children}
    </div>
  );
};

export default TabWrapper;
