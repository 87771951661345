const useStyle = (): React.CSSProperties => ({
  paddingLeft: '1em',
  marginBottom: 0
});

const UnorderedList: React.FC<{
  style?: React.CSSProperties
}> = ({ style, children }) => (
  <ul style={{ ...useStyle(), ...style }}>
    {children}
  </ul>
);

export default UnorderedList;
