import { IconButton } from '.';

import type { Props as ButtonProps } from './Button';

export const Close: React.FC<ButtonProps> = ({ ...props }) =>
  <IconButton icon="x" color="dark" {...props} />;

export const Delete: React.FC<ButtonProps> = ({ ...props }) =>
  <IconButton icon="trash" color="danger" {...props} />;

export const Edit: React.FC<ButtonProps> = ({ ...props }) =>
  <IconButton icon="pencil-square" {...props} />;
