import { useTranslation } from 'react-i18next';

import useMarkAsMissingProblem from '../../hooks/useMarkAsMissingProblem';
import { markAsMissing } from '../../services/item';

import { Button } from 'react-bootstrap';

import BibTitleAndAuthor from '../bibs/BibTitleAndAuthor';
import Loading from '../api/Loading';

const MarkItemAsMissingDialogContent = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        {t('Mark this item as missing in Sierra?')}
      </div>

      <Button variant="primary" size="sm" style={{ marginRight: '4px' }} onClick={onSuccess}>
        {t('Mark as missing')}
      </Button>

      {onCancel && (
        <Button variant="secondary" size="sm" onClick={onCancel}>
          {t('Cancel')}
        </Button>
      )}
    </>
  );
};

const MarkItemAsMissingDialogProblem = ({ problem, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: '20px' }}>
        <strong>{t('This item cannot be marked as missing')}:</strong> {problem}
      </div>

      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <strong>{t('Use Sierra to clear the item and mark it as missing.')}</strong>
      </div>

      <Button variant="secondary" size="sm" onClick={onClose}>
        {t('Close')}
      </Button>
    </>
  );
};

const MarkItemAsMissingDialog = ({ item, onSuccess, onCancel }) => {
  const [markAsMissingProblem, loading] = useMarkAsMissingProblem(item);

  if (!onSuccess) throw new Error('MarkItemAsMissingDialog not given prop onSuccess');

  const markAsMissingOnClick = async () => {
    await markAsMissing(item.sierraItemRecordId);

    onSuccess();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <h4>
        <BibTitleAndAuthor bib={item.Bib} />
      </h4>

      {
        markAsMissingProblem
          ? <MarkItemAsMissingDialogProblem problem={markAsMissingProblem} onClose={onCancel} />
          : <MarkItemAsMissingDialogContent onSuccess={markAsMissingOnClick} onCancel={onCancel} />
      }
    </>
  );
};

export default MarkItemAsMissingDialog;
