import { Authorized } from '../users';

import { devModeEnabled } from '.';

const Debug: React.FC = ({ children }) => {
  if (!devModeEnabled) {
    return null;
  }

  return (
    <Authorized role="DEVELOPER">
      {children}
    </Authorized>
  );
};

export default Debug;
