import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatCity } from '../../services/location';
import expandRow from '../../services/expandRow';
import useQueryParamState from '../../hooks/useQueryParamState';
import { hasRole } from '../../services/auth';

import { Button, Form } from 'react-bootstrap';

import APITable from '../api/APITable';
import ItemView from '../items/ItemView';

const MostTraveledItemsTableFiltersForm = ({
  tresholdDays, setTresholdDays, locationFilter, setLocationFilter
}) => {
  const { t } = useTranslation();

  const onSubmit = event => {
    event.preventDefault();

    setLocationFilter(event.target.locationFilter.value);

    setTresholdDays(
      event.target.tresholdDays.value === 'all'
        ? 'all'
        : Number(event.target.tresholdDays.value)
    );
  };

  return (
    <Form onSubmit={onSubmit} inline>
      <Form.Group>
        <Form.Control
          name="locationFilter"
          size="sm"
          defaultValue={locationFilter}
          placeholder={t('Filter by scan location...')}
          style={{ width: '200px' }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Control
          name="tresholdDays"
          as="select"
          size="sm"
          defaultValue={tresholdDays}
          placeholder={t('Treshold days')}
          style={{ marginLeft: '6px' }}
        >
          <option value="1">1 {t('Day')}</option>
          <option value="7">7 {t('Days')}</option>
          <option value="14">14 {t('Days')}</option>
          <option value="30">30 {t('Days')}</option>
          <option value="60">60 {t('Days')}</option>
          <option value="90">90 {t('Days')}</option>
          <option value="180">180 {t('Days')}</option>
          <option value="360">360 {t('Days')}</option>
          <option value="all">{t('All')}</option>
        </Form.Control>
      </Form.Group>

      <Button variant="primary" size="sm" type="submit" style={{ marginLeft: '6px' }}>
        {t('Update')}
      </Button>
    </Form>
  );
};

const MostTraveledItemsTable = ({ enableQueryParams }) => {
  const { t } = useTranslation();

  const [tresholdDays, setTresholdDays] = useQueryParamState('tresholdDays', 30);
  const [locationFilter, setLocationFilter] = useQueryParamState('locationFilter', '');

  const columns = [
    {
      dataField: 'count',
      text: t('Scan Count'),
      formatter: (cell, row, rowIndex, formatExtraData) =>
        <strong>{cell}</strong>
    },
    {
      dataField: 'sierraItemRecordId',
      text: t('Sierra ID')
    },
    {
      dataField: 'scannedItem.barcode',
      text: t('Barcode')
    },
    {
      dataField: 'itemCity',
      text: t('City'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) =>
        formatCity(row.scannedItem.fixedFieldCity)
    },
    {
      dataField: 'scannedItem.callNumber',
      text: t('Call Number')
    },
    {
      dataField: 'scannedItem.Bib.marcAuthor',
      text: t('Author')
    },
    {
      dataField: 'scannedItem.Bib.marcTitle',
      text: t('Title')
    }
  ];

  const query = useMemo(() => {
    const q = { locationFilter };

    if (tresholdDays !== 'all') q.tresholdDays = tresholdDays;

    return q;
  }, [locationFilter, tresholdDays]);

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <MostTraveledItemsTableFiltersForm
          tresholdDays={tresholdDays}
          setTresholdDays={setTresholdDays}
          locationFilter={locationFilter}
          setLocationFilter={setLocationFilter}
        />
      </div>

      <APITable
        resource="items/most-traveled"
        keyField="sierraItemRecordId"
        columns={columns}
        query={query}
        enableQueryParams={enableQueryParams}
        defaultSortField="count"
        simple

        expandRow={
          hasRole('VIEW-ITEMS')
            ? expandRow(row => <ItemView itemId={row.sierraItemRecordId} />)
            : undefined
        }
      />
    </>
  );
};

export default MostTraveledItemsTable;
