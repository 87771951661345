import { OppositeSidedContent } from '.';
import { CloseButton } from './buttons';

const Title: React.FC<{ title: string }> = ({ title }) => (
  <span style={{ verticalAlign: 'middle' }}>
    {title}
  </span>
);

const TitleWithCloseButton: React.FC<{
  title: string,
  onClickClose: () => void
}> = ({
  title,
  onClickClose
}) => (
  <OppositeSidedContent
    left={<Title title={title} />}
    right={<CloseButton onClick={onClickClose} />}
  />
);

export default TitleWithCloseButton;
