import { T } from '../i18n';
import { Space, useColor } from '.';

interface Props {
  error: Error;
  style?: React.CSSProperties;
}

const ActionFailed: React.FC = () => (
  <>
    <T>Action failed</T>:
  </>
);

const ErrorMessage: React.FC<Props> = ({ error, style, children }) => (
  <div style={{ ...useColor('danger'), ...style }}>
    <strong>
      {children ?? <ActionFailed />}
    </strong>

    <Space />

    {error.message === 'Login expired'
      ? <T>Login expired</T>
      : error.message}
  </div>
);

export default ErrorMessage;
