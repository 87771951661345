import { Icon } from '../layout';

interface Props {
  to?: string | null
  style?: React.CSSProperties;
}

const ExternalLink: React.FC<Props> = ({ to, style, children }) => (
  <a href={to ?? undefined} style={style} target="_blank" rel="noreferrer">
    {children}

    <Icon
      icon="box-arrow-up-right"
      size={12}
      leftMargin={4}
    />
  </a>
);

export default ExternalLink;
