import { forwardRef } from 'react';
import BootstrapTooltip from 'react-bootstrap/Tooltip';

export interface Props {
  id: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<Props> = forwardRef<HTMLDivElement, Props>(({
  id,
  children,
  ...props
}, ref) => (
  <BootstrapTooltip id={id} ref={ref} {...props}>
    {children}
  </BootstrapTooltip>
));

export default Tooltip;
