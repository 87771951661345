import { useTranslation } from '../../common/i18n';

import type { Shipment } from '../../api/models';
import type { Dialog } from '../../layout/dialogs';

const useShipmentManuallyCompletedDialog = (shipment: Shipment): Dialog => {
  if (typeof shipment.id !== 'number') {
    throw new Error(`Invalid shipment id ${shipment.id}`);
  }

  return {
    type: 'api-post',
    id: `shipment-manually-completed-${shipment.id}`,
    path: `/shipments/${shipment.id}/manually-completed`,
    content: useTranslation('Mark shipment as manually completed?')
  };
};

export default useShipmentManuallyCompletedDialog;
