import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../../hooks/useAPI';
import api from '../../../services/api';

import { Button } from 'react-bootstrap';

import BibListTable from './BibListTable';
import CreateBibListButton from './CreateBibListButton';
import OnClickModal from '../../layout/OnClickModal';
import QuickBibSearch from '../QuickBibSearch';
import FindBibByBarcode from '../FindBibByBarcode';
import Separator from '../../api/Separator';
import Loading from '../../api/Loading';
import BibListBibsTable from './BibListBibsTable';

const AddBibsButton = ({ listId, update }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const onSubmit = async ({ sierraBibRecordId }) => {
    await api.post(`/bib-lists/${listId}/bibs`, {
      sierraBibRecordId
    });

    update();
  };

  const button = (
    <Button size="sm">
      {t('Add Bibs')}
    </Button>
  );

  return (
    <OnClickModal
      title={t('Add Bibs')}
      button={button}
      forceClose={forceClose}
      onOpen={() => setForceClose(false)}
      closeButton
    >
      <FindBibByBarcode onSubmit={onSubmit} />

      <Separator style={{ marginTop: '10px', marginBottom: '6px' }}>
        <strong style={{ marginTop: '-4px' }}>
          {t('or')}
        </strong>
      </Separator>

      <QuickBibSearch onSubmit={onSubmit} />
    </OnClickModal>
  );
};

const ListChildren = ({ listId }) => {
  const { t } = useTranslation();

  const [children, loading, error, /* headers */, update] = useAPI('GET', `/bib-lists/${listId}/children`);

  if (children === null && loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch bib lists');
  }

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <span style={{ marginRight: '6px' }}>
          <CreateBibListButton
            title={t('Create Sublist')}
            parentListId={listId}
            update={update}
          />
        </span>

        <AddBibsButton
          listId={listId}
          update={update}
        />
      </div>

      <div>
        <strong>
          {children.bibLists.length} {t('sublists')}, {children.bibs.length} {t('bibs')}
        </strong>
      </div>

      {children.bibLists.length > 0 && (
        <div>
          <BibListTable
            bibLists={children.bibLists}
            update={update}
          />
        </div>
      )}

      {children.bibs.length > 0 && (
        <div>
          <BibListBibsTable bibs={children.bibs} />
        </div>
      )}
    </>
  );
};

export default ListChildren;
