import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isBarcodeValid } from '../../../services/item';

import { Form } from 'react-bootstrap';

import ReceivedItemsTable from '../ReceivedItemsTable';

const ReceptionShipmentAddItemsForm = ({ shipment, addReceivedItem }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);

  const onSubmit = event => {
    event.preventDefault();

    const str = event.target.addItems.value;

    if (str.indexOf('.') === -1 || str.indexOf(',') === -1) {
      if (!isBarcodeValid(str)) return setError(t('Invalid barcode'));

      addReceivedItem(shipment, str, null);
    } else {
      // Barcode and product code
      const barcode = str.split(',')[0];
      const productCode = str.split(',')[1].split('.')[0];

      if (!isBarcodeValid(barcode)) return setError(t('Invalid barcode'));

      addReceivedItem(shipment, barcode, productCode);
    }

    event.target.addItems.value = '';
  };

  return (
    <Form onSubmit={onSubmit} inline>
      <Form.Label>
        {t('Add items manually')}
      </Form.Label>

      <Form.Control
        name="addItems"
        size="sm"
        placeholder={t('Barcode')}
        style={{ width: '200px', marginLeft: '6px' }}
      />

      <span style={{ color: 'red', marginLeft: '6px' }}>
        {error}
      </span>
    </Form>
  );
};

const ReceptionShipmentView = ({ shipment, addReceivedItem, onItemRemoved, receivedCounts }) => {
  const { t } = useTranslation();

  const addItemsForm = (
    <ReceptionShipmentAddItemsForm
      shipment={shipment}
      addReceivedItem={addReceivedItem}
    />
  );

  const receivedItemsTable = (
    <ReceivedItemsTable
      shipment={shipment}
      onItemRemoved={onItemRemoved}
      noConfigureButton
      noPrintLabelsButton
    />
  );

  return (
    <div style={{ margin: 'auto', width: 'max-content' }}>
      <h3 style={{ marginBottom: '10px' }}>
        {t('Received Items')}
      </h3>

      {(receivedCounts[shipment.id] < shipment.copiesOnShipment) && addItemsForm}

      <div style={{ marginTop: '4px' }}>
        {
          shipment.receivedItems.length === 0
            ? <strong>{t('No items received for this shipment.')}</strong>
            : receivedItemsTable
        }
      </div>
    </div>
  );
};

export default ReceptionShipmentView;
