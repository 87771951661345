import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import useQueryParams from '../../hooks/useQueryParams';
import { submitRfidScan } from '../../services/item';

import ItemShelfView from '../items/shelfView/ItemShelfView';

const ItemShelfViewPage = () => {
  const params = useParams();
  const queryParams = useQueryParams();

  if (params.barcode && queryParams.get('rfid') === 'true') {
    submitRfidScan(params.barcode);
  }

  const [itemsPerSide, setItemsPerSide] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setItemsPerSide(Math.floor(ref.current.offsetWidth / 85));
  }, [ref]);

  if (!itemsPerSide) {
    return <div ref={ref} style={{ width: '100%' }} />;
  }

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <ItemShelfView
        itemId={params.id}
        barcode={params.barcode}
        itemsPerSide={itemsPerSide}
      />
    </div>
  );
};

export default ItemShelfViewPage;
