import { useState } from 'react';

import { numberField, textField, listField, booleanField, dateField } from '../../services/queryBuilder';
import sierraGlobals from '../../sierraGlobals';

import QueryBuilder from '../api/QueryBuilder';

import { useT } from '../../../common/i18n';
import { MassUpdateCreateFromQueryButton } from '../../../sierra/mass-update';

const useFields = () => {
  const t = useT();

  const bindings = sierraGlobals.bindings.map(binding => ({
    value: binding,
    label: binding
  }));

  const mapObject = obj => Object.entries(obj).map(([key, value]) => ({
    value: key,
    label: `${key} ${value}`
  }));

  const BCode3s = Object.entries(sierraGlobals.BCode3s).map(([key, value]) => ({
    value: key,
    label: `${key} ${value[1] ?? value[0]}`
  }));

  const fields = [
    numberField('', `=== ${t('ITEM FIELDS')} ===`),
    numberField('sierra_item_record_id', 'Sierra Item ID'),
    textField('barcode', t('Barcode')),
    textField('call_number', t('Call Number')),
    booleanField('checked_out', t('Checked Out')),
    listField('fixed_field_item_status', t('Item Status'), mapObject(sierraGlobals.itemStatuses)),
    numberField('fixed_field_item_type', t('Item Type ID')),
    booleanField('$itemType.reservable$', t('Reservable')),
    textField('$itemType.description$', t('Item Type')),
    listField('fixed_field_city', t('City'), mapObject(sierraGlobals.cities)),
    numberField('fixed_field_price', t('Price')),
    textField('fixed_field_location', t('Location')),
    numberField('fixed_field_total_checkouts', t('Total Checkouts')),
    numberField('fixed_field_total_renewals', t('Total Renewals')),
    numberField('fixed_field_circulation_this_year', t('Circulation This Year')),
    numberField('fixed_field_circulation_last_year', t('Circulation Last Year')),
    dateField('fixed_field_current_loan_out_date', t('Checkout Date')),
    dateField('fixed_field_current_loan_due_date', t('Due Date')),
    dateField('fixed_field_last_checkout_date', t('Last Checkout Date')),
    numberField('fixed_field_last_checkout_location_id', t('Last Checkout Location ID')),
    dateField('fixed_field_last_checkin_date', t('Last Checkin Date')),
    numberField('fixed_field_last_checkin_location_id', t('Last Checkin Location ID')),
    numberField('fixed_field_agency', t('Agency ID')),
    textField('transit_from', t('Transit From')),
    textField('transit_to', t('Transit To')),
    booleanField('transit_for_hold', t('Transit For Hold')),
    numberField('hold_count', t('Item Hold Count')),
    textField('genre', t('Genre')),
    textField('classification', t('Classification')),
    booleanField('fiction', t('Is Fiction')),
    dateField('sierra_created_at', t('Sierra Created At')),
    dateField('sierra_updated_at', t('Sierra Updated At')),
    dateField('created_at', t('Created At')),
    dateField('updated_at', t('Updated At')),

    numberField('', ''),
    numberField('', `=== ${t('BIB FIELDS')} ===`),

    numberField('sierra_bib_record_id', t('Sierra Bib ID')),
    textField('$Bib.marc_control_number$', t('Control Number')),
    textField('$Bib.marc_isbn$', t('ISBN')),
    textField('$Bib.marc_issn$', t('ISSN')),
    textField('$Bib.marc_ean13$', t('EAN13')),
    textField('$Bib.marc_title$', t('Title')),
    textField('$Bib.marc_author$', t('Author')),
    textField('$Bib.marc_series_info$', t('Series Info')),
    textField('$Bib.marc_physical_description$', t('Physical Description')),
    listField('$Bib.marc_binding_info$', t('Binding'), bindings),
    textField('$Bib.marc091$', t('MARC 091')),
    numberField('$Bib.marc_published_year$', t('Year Published')),
    textField('$Bib.marc_espoo_class$', t('Espoo Class')),
    textField('$Bib.marc_helsinki_class$', t('Helsinki Class')),
    textField('$Bib.marc_kauniainen_class$', t('Kauniainen Class')),
    textField('$Bib.marc_vantaa_class$', t('Vantaa Class')),
    textField('$Bib.fixed_field_lang$', t('Language Code')),
    listField('$Bib.fixed_field_bcode3$', t('Sierra BCode3'), BCode3s),
    listField('$Bib.fixed_field_material_type$', t('Material Type'), mapObject(sierraGlobals.materialTypes)),
    textField('$Bib.fixed_field_bib_level$', t('Bib Level')),
    textField('$Bib.fixed_field_country$', t('Country')),
    numberField('$Bib.hold_count$', t('Bib Hold Count')),
    dateField('$Bib.sierra_created_at$', t('Sierra Created At')),
    dateField('$Bib.sierra_updated_at$', t('Sierra Updated At')),
    dateField('$Bib.created_at$', t('Created At')),
    dateField('$Bib.updated_at$', t('Updated At'))
  ];

  return fields;
};

const ItemQueryBuilder = ({ query: initialQuery }) => {
  const [query, setQuery] = useState(null);

  return (
    <>
      <QueryBuilder
        resource="items"
        fields={useFields()}
        query={initialQuery}
        onChange={setQuery}
      />

      <MassUpdateCreateFromQueryButton
        recordType="i"
        query={query}
      />
    </>
  );
};

export default ItemQueryBuilder;
