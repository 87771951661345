import type { ItemAllocation } from '../api/models';

const ItemAllocationView: React.FC<{
  itemAllocation: ItemAllocation
}> = ({ itemAllocation }) => (
  <pre>
    {JSON.stringify(itemAllocation, null, 2)}
  </pre>
);

export default ItemAllocationView;
