import { useTranslation } from 'react-i18next';

import { formatPrice } from '../../services/shipment';
import { formatDateRelative } from '../../services/date';
import { useDispatch } from '../../../state';
import { useShipmentManuallyCompletedDialog } from '../../../shipments';
import { open as openDialog } from '../../../state/slices/dialogs';

import { Button } from 'react-bootstrap';
import { CheckSquareFill } from 'react-bootstrap-icons';

import DetailsTable from '../api/DetailsTable';
import Authorized from '../auth/Authorized';

const useDetails = shipment => {
  const { t } = useTranslation();

  const details = [
    {
      label: t('Shipment Number'),
      value: shipment.shipmentId
    },
    {
      label: t('Sierra Order ID'),
      value: shipment.sierraOrderRecordId
    },
    {
      label: t('Product Code'),
      value: shipment.productCode
    },
    {
      label: t('Copies'),
      value: `${shipment.copiesOnShipment} / ${shipment.copiesOrdered}`
    },
    {
      label: t('Price'),
      value: formatPrice(shipment.price)
    },
    {
      label: t('Taxless Price'),
      value: formatPrice(shipment.priceTaxless)
    },
    {
      label: t('Total Price'),
      value: formatPrice(shipment.priceTotal)
    },
    {
      label: t('Tax %'),
      value: shipment.taxPercent
    },
    {
      label: t('Author'),
      value: shipment.author
    },
    {
      label: t('Title'),
      value: shipment.title
    },
    {
      label: t('Language'),
      value: shipment.language
    },
    {
      label: t('Vendor'),
      value: shipment.vendor
    },
    {
      label: t('BTJ catalogue plan'),
      value: shipment.btjCataloguePlan
    },
    {
      label: t('Comment'),
      value: shipment.comment
    },
    {
      label: t('Account'),
      value: shipment.account
    },
    {
      label: t('Bestseller Count'),
      value: shipment.bestsellerCount
    },
    {
      label: t('Reception Date'),
      value: formatDateRelative(shipment.receptionDate, t)
    }
  ];

  return details;
};

const ShipmentDetails = ({ shipment }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const dialog = useShipmentManuallyCompletedDialog(shipment);

  return (
    <>
      <h3>{t('Shipment Details')}</h3>

      <Authorized role="ADMIN">
        <Button
          size="sm"
          style={{ marginRight: '4px', marginBottom: '4px' }}
          onClick={() => dispatch(openDialog(dialog))}
        >
          <CheckSquareFill style={{
            verticalAlign: 'middle',
            marginTop: '-2px'
          }} />

          &nbsp;{t('Manually Completed')}
        </Button>
      </Authorized>

      <DetailsTable details={useDetails(shipment)} />
    </>
  );
};

export default ShipmentDetails;
