import { useTranslation } from '../../common/i18n';

import { useLinkForm } from '..';

import type { Dialog } from '../../layout/dialogs';

const useCreateLinkDialog = (groupId: number): Dialog => ({
  type: 'api-form-post',
  id: `create-link-${groupId}`,
  title: useTranslation('Create New Link'),
  path: `/links/groups/${groupId}/links`,
  form: useLinkForm()
});

export default useCreateLinkDialog;
