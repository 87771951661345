import Form from 'react-bootstrap/Form';

import { FormItemLabel, getElementValue } from '..';

import type { FormElementProps } from '.';

const TextArray: React.FC<FormElementProps> = ({ formItem, onChange }) => {
  if (formItem.type !== 'text-array') {
    throw new Error(`Invalid text array form item type ${formItem.type}`);
  }

  return (
    <Form.Group>
      <FormItemLabel formItem={formItem} />

      <Form.Control
        type="text"
        size="sm"
        name={`${formItem.name}[]`}
        defaultValue={Array.isArray(formItem.defaultValue) ? formItem.defaultValue.join(',') : ''}
        required={formItem.required}
        placeholder={formItem.placeholder}
        onChange={(event): void => onChange &&
          onChange(getElementValue(event.target as unknown as HTMLInputElement, true))}
      />
    </Form.Group>
  );
};

export default TextArray;
