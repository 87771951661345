import { useT } from '../common/i18n';

import { APITable, useTableFormatters } from '../api';

import type { APITableColumn } from '../api';

const useColumns = (): APITableColumn[] => {
  const t = useT();
  const { location3 } = useTableFormatters();

  return [
    {
      field: 'location',
      label: t('Location'),
      formatter: location3,
      disableSorting: true
    },
    {
      field: 'count',
      label: t('Allocation Count'),
      disableSorting: true
    }
  ];
};

const ItemAllocationDistributionTable: React.FC<{
  enableQueryParams: boolean
}> = ({ enableQueryParams }) => (
  <APITable
    resource="item-allocations/distribution"
    keyField="location"
    columns={useColumns()}
    enableQueryParams={enableQueryParams}
    noPagination
  />
);

export default ItemAllocationDistributionTable;
