import { Link } from 'react-router-dom';
import BootstrapButton from 'react-bootstrap/Button';

import { useProps } from './Button';

import type { Props as ButtonProps } from './Button';

const LinkButton: React.FC<ButtonProps & { to: string }> = ({ to, ...props }) => (
  <BootstrapButton
    as={Link}
    to={to}
    {...useProps(props)}
  />
);

export default LinkButton;
