import BootstrapOverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { Tooltip } from '.';

import type { Props as TooltipProps } from './Tooltip';
import type { Placement } from '.';

const useOverlay = (props: TooltipProps): JSX.Element => <Tooltip {...props} />;

const HoverTooltip: React.FC<{
  id: string,
  content: React.ReactNode,
  placement?: Placement
}> = ({
  id,
  content,
  placement,
  children,
  ...props
}) => (
  <BootstrapOverlayTrigger
    placement={placement ?? 'top'}
    overlay={useOverlay({ id, children: content })}
    {...props}
  >
    <span>
      {children}
    </span>
  </BootstrapOverlayTrigger>
);

export default HoverTooltip;
