import BootstrapDropdown from 'react-bootstrap/Dropdown';

import { Icon } from '../../common/layout';

import { useDialog } from '../../layout/dialogs';

import type { Color } from '../../common/layout';
import type { IconName } from '../../common/layout/Icon';
import type { Dialog } from '../../layout/dialogs';

interface Item {
  text?: string;
  color?: Color;
  disabled?: boolean;
  dialog?: Dialog;
}

type IconItem = Item & { icon: IconName; };

export type DropdownButtonItem =
  | { type: 'divider' }
  | { type: 'icon' } & IconItem
  | { type: 'edit' } & Item
  | { type: 'delete' } & Item;

const InnerButtonContent: React.FC<{
  button: IconItem
}> = ({ button }) => (
  <>
    <Icon
      icon={button.icon}
      color={button.color}
      style={{ marginRight: '6px' }}
    />

    {button.text}
  </>
);

const NoDialogInnerButton: React.FC<{ button: IconItem }> = ({ button }) => (
  <BootstrapDropdown.Item disabled={button.disabled}>
    <InnerButtonContent button={button} />
  </BootstrapDropdown.Item>
);

const DialogInnerButton: React.FC<{
  button: IconItem,
  dialog: Dialog
}> = ({ button, dialog }) => (
  <BootstrapDropdown.Item disabled={button.disabled} onClick={useDialog(dialog)}>
    <InnerButtonContent button={button} />
  </BootstrapDropdown.Item>
);

const InnerButton: React.FC<{ button: IconItem }> = ({ button }) => (
  button.dialog
    ? <DialogInnerButton button={button} dialog={button.dialog} />
    : <NoDialogInnerButton button={button} />
);

const editButton: DropdownButtonItem = {
  type: 'icon',
  icon: 'pencil-square',
  color: 'edit'
};

const deleteButton: DropdownButtonItem = {
  type: 'icon',
  icon: 'trash',
  color: 'delete'
};

const ButtonsDropdownButton: React.FC<{ button: DropdownButtonItem, }> = ({ button }) => {
  switch (button.type) {
    case 'divider':
      return <BootstrapDropdown.Divider />;
    case 'icon':
      return <InnerButton button={button} />;
    case 'edit':
      return <InnerButton button={{ ...button, ...editButton }} />;
    case 'delete':
      return <InnerButton button={{ ...button, ...deleteButton }} />;
  }
};

export default ButtonsDropdownButton;
