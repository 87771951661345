import CreateBibListButton from './CreateBibListButton';
import BibListTable from './BibListTable';

const MyBibLists = ({ myLists, update }) => (
  <>
    <CreateBibListButton update={update} />

    <div style={{ marginTop: '10px' }}>
      <BibListTable
        bibLists={myLists}
        update={update}
      />
    </div>
  </>
);

export default MyBibLists;
