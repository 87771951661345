import { getPreference } from '../../services/preferences';

import ClickSelect from './ClickSelect';

const SierraID = ({ code, id }) => {
  if (code === undefined || code === null || id === undefined || id === null) {
    return null;
  }

  const format = getPreference('sierraIdFormat', 'full');

  const checkDigit = 'A'; // TODO

  return (
    <ClickSelect>
      <code>
        {format === 'full' ? `${code}${id}${checkDigit}` : id}
      </code>
    </ClickSelect>
  );
};

export default SierraID;
