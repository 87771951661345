import { FormContent, validateJSON } from '../../layout/forms';
import { useMassUpdateCommonForm } from '.';

import type { FormDataValue } from '../../layout/forms';
import type { RecordType, Operation } from '.';

const useOnChange = (operation: Operation, setOperation: React.Dispatch<Operation>) => {
  return (name: string, value: FormDataValue): void => {
    if (typeof value !== 'string' && value !== null) {
      throw new Error(`Invalid ${name} value ${value}`);
    } else if (name === 'record-type') {
      if (value !== 'b' && value !== 'i') {
        throw new Error(`Invalid record type value ${value}`);
      }

      setOperation({
        ...operation,
        recordType: value
      });
    } else if (name === 'query-json') {
      setOperation({
        ...operation,
        queryJSON: validateJSON(value) ? value : null
      });
    } else {
      throw new Error(`Invalid mass update form element name ${name}`);
    }
  };
};

const MassUpdateCommonForm: React.FC<{
  operation: Operation,
  setOperation: React.Dispatch<Operation>,
  defaultRecordType: RecordType
}> = ({
  operation,
  setOperation,
  defaultRecordType
}) => (
  <FormContent
    form={useMassUpdateCommonForm({
      recordType: operation.recordType,
      queryJSON: operation.queryJSON ?? null
    })}

    onChange={useOnChange(operation, setOperation)}
  />
);

export default MassUpdateCommonForm;
