import { useTranslation } from 'react-i18next';

import { Button, Form } from 'react-bootstrap';

const BibListForm = ({ onSubmit, submitText }) => {
  const { t } = useTranslation();

  if (!onSubmit) throw new Error('onSubmit not defined!');

  const onFormSubmit = event => {
    event.preventDefault();

    const getColor = color => color === '' ? null : color.replace('#', '');

    onSubmit({
      name: event.target.name.value,
      description: event.target.description.value,
      isPublic: event.target.isPublic.checked,
      imageUrl: event.target.imageUrl.value,
      primaryColor: getColor(event.target.primaryColor.value),
      secundaryColor: getColor(event.target.secundaryColor.value),
      orderString: event.target.orderString.value
    });
  };

  return (
    <Form onSubmit={onFormSubmit}>
      <Form.Group>
        <Form.Label>
          {t('Name')}
        </Form.Label>

        <Form.Control
          type="text"
          name="name"
          autoFocus
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {t('Description')}
        </Form.Label>

        <Form.Control
          as="textarea"
          name="description"
        />
      </Form.Group>

      <Form.Group>
        <Form.Check
          label={t('Public')}
          name="isPublic"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {t('Image URL')}
        </Form.Label>

        <Form.Control
          type="text"
          name="imageUrl"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {t('Primary Color')}
        </Form.Label>

        <Form.Control
          type="text"
          name="primaryColor"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {t('Secondary Color')}
        </Form.Label>

        <Form.Control
          type="text"
          name="secundaryColor"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {t('Order String')}
        </Form.Label>

        <Form.Control
          type="text"
          name="orderString"
        />
      </Form.Group>

      <Button type="submit" size="sm">
        {submitText || t('Submit')}
      </Button>
    </Form>
  );
};

export default BibListForm;
