export const formatDate = date => {
  if (!date) return null;

  if (typeof date === 'string' || typeof date === 'number') date = new Date(date);

  return date.toISOString().split('T')[0];
};

export const formatTime = (date, locale = 'fi') => {
  if (!date) return null;

  if (typeof date === 'string' || typeof date === 'number') date = new Date(date);

  return date.toLocaleTimeString(locale);
};

export const formatDateTime = (date, locale = 'fi') => {
  if (!date) return null;

  if (typeof date === 'string' || typeof date === 'number') date = new Date(date);

  return date.toLocaleString(locale);
};

export const formatDateTimeRelative = (date, t, lowerCase = false, locale = 'fi') => {
  if (!t || typeof t !== 'function') {
    throw new Error('Invalid t!');
  }

  if (!date) {
    return null;
  }

  if (!(date instanceof Date)) date = new Date(date);

  const compareDate = new Date();
  compareDate.setHours(0, 0, 0, 0);

  if (date.getTime() >= compareDate.getTime()) {
    return (lowerCase ? t('today') : t('Today')) + ' ' + formatTime(date, locale);
  }

  compareDate.setDate(compareDate.getDate() - 1);

  if (date.getTime() >= compareDate.getTime()) {
    return (lowerCase ? t('yesterday') : t('Yesterday')) + ' ' + formatTime(date, locale);
  }

  return formatDateTime(date, locale);
};

export const formatDateRelative = (date, t, lowerCase = false, locale = 'fi') => {
  if (!t || typeof t !== 'function') {
    throw new Error('Invalid t!');
  }

  if (!date) {
    return null;
  }

  if (!(date instanceof Date)) date = new Date(date);

  const compareDate = new Date();
  compareDate.setHours(0, 0, 0, 0);

  if (date.getTime() >= compareDate.getTime()) {
    return (lowerCase ? t('today') : t('Today'));
  }

  compareDate.setDate(compareDate.getDate() - 1);

  if (date.getTime() >= compareDate.getTime()) {
    return (lowerCase ? t('yesterday') : t('Yesterday'));
  }

  return formatDate(date);
};

export const getYearsAgo = (amount = 1) => {
  const date = new Date();

  date.setFullYear(date.getFullYear() - amount);

  return date;
};
