import { useState } from 'react';

import { deleteRecord as apiDeleteRecord } from '.';

import type { Params } from '.';

interface ReturnType {
  deleteRecord: () => void;
  deleting: boolean;
  error: Error | null;
}

const useDeleteRecord = (
  path: string,
  recordId: number,
  onSuccess?: () => void,
  params?: Params
): ReturnType => {
  const [deleting, setDeleting] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const deleteRecord = async (): Promise<void> => {
    setDeleting(true);

    try {
      await apiDeleteRecord(path, recordId, params);

      if (onSuccess) onSuccess();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Could not DELETE ${path}/${recordId}:`, err);

      if (err instanceof Error) {
        setError(err);
      } else {
        throw new Error(`Invalid error object caught: ${err}`);
      }
    }

    setDeleting(false);
  };

  return { deleteRecord, deleting, error };
};

export default useDeleteRecord;
