import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';

import { Button, Form } from 'react-bootstrap';

import BroadcastAlert from '../layout/BroadcastAlert';

const CreateBroadcastForm = ({ onCreate }) => {
  const { t } = useTranslation();

  const [content, setContent] = useState('');
  const [variant, setVariant] = useState('primary');

  const onSubmit = async event => {
    event.preventDefault();

    await api.post('/broadcasts', {
      variant: event.target.variant.value,
      content: event.target.content.value
    });

    if (onCreate) onCreate();
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>
          {t('Variant')}
        </Form.Label>

        <Form.Control
          as="select"
          name="variant"
          defaultValue={variant}
          onChange={event => setVariant(event.target.value)}
          required
        >
          <option value="primary">{t('Primary')}</option>
          <option value="secondary">{t('Secondary')}</option>
          <option value="success">{t('Success')}</option>
          <option value="danger">{t('Danger')}</option>
          <option value="warning">{t('Warning')}</option>
          <option value="info">{t('Info')}</option>
          <option value="light">{t('Light')}</option>
          <option value="dark">{t('Dark')}</option>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {t('Content')} ({t('markdown supported')})
        </Form.Label>

        <Form.Control
          as="textarea"
          name="content"
          onChange={event => setContent(event.target.value)}
          required
        />
      </Form.Group>

      <Button
        type="submit"
        size="sm"
      >
        {t('Submit')}
      </Button>

      {content.length > 0 && (
        <>
          <hr />

          <strong>{t('Preview')}:</strong>

          <BroadcastAlert
            variant={variant}
            content={content}
            notDismissible
          />
        </>
      )}
    </Form>
  );
};

export default CreateBroadcastForm;
