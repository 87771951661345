import { useDispatch } from '../../state';
import { create as createToast } from '../../state/slices/toasts';

import type { Toast } from '.';

const useToast = (toast: Toast): (() => void) => {
  const dispatch = useDispatch();

  return (): void => {
    dispatch(createToast(toast));
  };
};

export default useToast;
