import { useTranslation } from 'react-i18next';

import { numberField, textField, listField, dateField, booleanField } from '../../services/queryBuilder';
import sierraGlobals from '../../sierraGlobals';

import QueryBuilder from '../api/QueryBuilder';

const useFields = () => {
  const { t } = useTranslation();

  const bindings = sierraGlobals.bindings.map(binding => ({
    value: binding,
    label: binding
  }));

  const mapObject = obj => Object.entries(obj).map(([key, value]) => ({
    value: key,
    label: `${key} ${value}`
  }));

  const BCode3s = Object.entries(sierraGlobals.BCode3s).map(([key, value]) => ({
    value: key,
    label: `${key} ${value[1] ?? value[0]}`
  }));

  const fields = [
    numberField('', `=== ${t('ORDER FIELDS')} ===`),
    numberField('sierra_order_record_id', t('Sierra Order ID')),
    numberField('price', t('Price')),
    numberField('code1', t('Sierra Code1')),
    numberField('fund', t('Fund')),
    textField('status', t('Status')),
    dateField('order_date', t('Order Date')),
    numberField('copies', t('Copies')),
    textField('lang', t('Language')),
    textField('country', t('Country')),
    booleanField('suppressed', t('Suppressed')),

    numberField('', ''),
    numberField('', `=== ${t('BIB FIELDS')} ===`),

    numberField('sierra_bib_record_id', t('Sierra Bib ID')),
    textField('$Bib.marc_control_number$', t('Control Number')),
    textField('$Bib.marc_isbn$', t('ISBN')),
    textField('$Bib.marc_issn$', t('ISSN')),
    textField('$Bib.marc_ean13$', t('EAN13')),
    textField('$Bib.marc_title$', t('Title')),
    textField('$Bib.marc_author$', t('Author')),
    textField('$Bib.marc_series_info$', t('Series Info')),
    textField('$Bib.marc_physical_description$', t('Physical Description')),
    listField('$Bib.marc_binding_info$', t('Binding'), bindings),
    textField('$Bib.marc091$', t('MARC Field 091')),
    numberField('$Bib.marc_published_year$', t('Year Published')),
    textField('$Bib.marc_espoo_class$', t('Espoo Class')),
    textField('$Bib.marc_helsinki_class$', t('Helsinki Class')),
    textField('$Bib.marc_kauniainen_class$', t('Kauniainen Class')),
    textField('$Bib.marc_vantaa_class$', t('Vantaa Class')),
    textField('$Bib.fixed_field_lang$', t('Language Code')),
    listField('$Bib.fixed_field_bcode3$', t('Sierra BCode3'), BCode3s),
    listField('$Bib.fixed_field_material_type$', t('Material Type'), mapObject(sierraGlobals.materialTypes)),
    textField('$Bib.fixed_field_bib_level$', t('Bib Level')),
    textField('$Bib.fixed_field_country$', t('Country')),
    numberField('$Bib.hold_count$', t('Bib Hold Count')),
    dateField('$Bib.sierra_created_at$', t('Sierra Created At')),
    dateField('$Bib.sierra_updated_at$', t('Sierra Updated At')),
    dateField('$Bib.created_at$', t('Created At')),
    dateField('$Bib.updated_at$', t('Updated At'))
  ];

  return fields;
};

const OrderQueryBuilder = ({ query }) => (
  <QueryBuilder
    resource="orders"
    fields={useFields()}
    query={query}
  />
);

export default OrderQueryBuilder;
