import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import OrderView from './OrderView';
import Loading from '../api/Loading';

const RandomOrderView = () => {
  const { t } = useTranslation();

  const [order, loading, error] = useAPI('GET', '/orders/random');

  if (loading) return <Loading />;
  else if (error) return t('Could not fetch random order');
  else return <OrderView orderId={order.sierraOrderRecordId} />;
};

export default RandomOrderView;
