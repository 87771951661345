import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import { Button } from 'react-bootstrap';

import ItemScanBrowserElement from './ItemScanBrowserElement';
import Loading from '../api/Loading';

const RfidItemScanBrowser = ({ location, itemScanId, itemsPerSide }) => {
  const { t } = useTranslation();

  const defaultItemsPerSide = 5;

  if (!itemsPerSide) itemsPerSide = defaultItemsPerSide;

  if (!itemScanId) throw new Error('RfidItemScanBrowser not given itemScanId');

  const [previousCount, setPreviousCount] = useState(itemsPerSide);
  const [nextCount, setNextCount] = useState(itemsPerSide);
  const [currentItemScanId, setCurrentItemScanId] = useState(itemScanId);

  const [itemScans, loading, error] = useAPI('GET', `/rfid/scans/${currentItemScanId}`, {
    params: {
      previous: previousCount,
      next: nextCount,
      location
    }
  }, [previousCount, nextCount, location, currentItemScanId]);

  const moveBackward = () => {
    setCurrentItemScanId(
      itemScans.previous.length !== 0
        ? itemScans.previous[0].id
        : itemScans.current.id
    );

    setPreviousCount(itemsPerSide * 2);
    setNextCount(0);
  };

  const moveForward = () => {
    setCurrentItemScanId(
      itemScans.next.length !== 0
        ? itemScans.next[itemScans.next.length - 1].id
        : itemScans.current.id
    );

    setPreviousCount(0);
    setNextCount(itemsPerSide * 2);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch RFID item scans');
  }

  return (
    <>
      <center>
        <Button variant="outline-dark" size="sm" onClick={moveBackward}>
          {t('Load previous')}
        </Button>
      </center>

      {itemScans.previous.map(itemScan => (
        <ItemScanBrowserElement key={itemScan.id} itemScan={itemScan} />
      ))}

      <ItemScanBrowserElement itemScan={itemScans.current} highlight />

      {itemScans.next.map(itemScan => (
        <ItemScanBrowserElement key={itemScan.id} itemScan={itemScan} />
      ))}

      <center>
        <Button variant="outline-dark" size="sm" onClick={moveForward}>
          {t('Load next')}
        </Button>
      </center>
    </>
  );
};

export default RfidItemScanBrowser;
