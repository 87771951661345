import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BackToQueryButton = ({ resource, query }) => {
  const { t } = useTranslation();

  return (
    <Button
      as={Link}
      to={`/${resource}/query?query=${query}`}
      size="sm"
      style={{ marginBottom: '4px' }}
    >
      {t('Back to Query')}
    </Button>
  );
};

export default BackToQueryButton;
