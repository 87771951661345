import { Heading, useMargins } from '../../common/layout';
import { T } from '../../common/i18n';
import { useQueryParam } from '../../common/navigation';

import { MassUpdateView, MassUpdateCreate } from '../../sierra/mass-update';

import type { RecordType } from '../../sierra/mass-update';

const MassUpdatePage: React.FC = () => (
  <>
    <MassUpdateView />

    <hr style={useMargins(0, 0, 20, 20)} />

    <Heading level={4}>
      <T>New operation</T>
    </Heading>

    <MassUpdateCreate
      defaultRecordType={useQueryParam('recordType') as RecordType ?? 'b'}
      defaultQueryJSON={useQueryParam('query') ?? undefined}
    />
  </>
);

export default MassUpdatePage;
