import api from './api';
import { hasRole } from './auth';

let preferences = {};

const saveToApi = () => {
  if (!hasRole('SHARED')) {
    api.put('/preferences', preferences);
  }
};

export const getPreference = (key, defaultValue = null) => (
  preferences[key] ?? defaultValue
);

export const getPreferences = () => preferences;

export const setPreference = (key, value) => {
  preferences[key] = value;

  saveToApi();
};

export const setPreferences = newPreferences => {
  preferences = newPreferences;

  saveToApi();
};
