import { useParams } from 'react-router-dom';

import { Container } from 'react-bootstrap';

import OrderView from '../orders/OrderView';

// /orders/view/:id
const OrderViewPage = () => (
  <Container>
    <OrderView orderId={useParams().id} />
  </Container>
);

export default OrderViewPage;
