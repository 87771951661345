import { ErrorMessage, Loading } from '../common/layout';

const APIWrap: React.FC<{
  loading: boolean,
  error: Error | null,
  style?: React.CSSProperties
}> = ({
  loading,
  error,
  style,
  children
}) => {
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (style) {
    return <span style={style}>{children}</span>;
  }

  return <>{children}</>;
};

export default APIWrap;
