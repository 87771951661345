import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { isSearchTermAllowed } from '../../services/search';

import { Form } from 'react-bootstrap';

const SearchBar = ({ style }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const searchBarRef = useRef(null);

  const onSubmit = event => {
    event.preventDefault();

    const searchTerm = searchBarRef.current.value;

    if (isSearchTermAllowed(searchTerm)) {
      searchBarRef.current.value = ''; // Clear search bar

      history.push(`/search?query=${searchTerm.replace(/&/g, '')}`);
    }
  };

  return (
    <Form style={style} onSubmit={onSubmit} inline>
      <Form.Control
        ref={searchBarRef}
        type="text"
        placeholder={t('Search')}
        autoFocus
      />
    </Form>
  );
};

export default SearchBar;
