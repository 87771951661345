import useNoDataIndication from './useNoDataIndication';
import useColumns from './useColumns';
import useSetSearchParams from './useSetSearchParams';
import usePagination from './usePagination';
import useDefaultSorting from './useDefaultSorting';
import useOnTableChange from './useOnTableChange';
import useTableQueryParams from './useTableQueryParams';
import useTableFormatters from './useTableFormatters';

import APITable from './Table';

export {
  useNoDataIndication,
  useColumns,
  useSetSearchParams,
  usePagination,
  useDefaultSorting,
  useOnTableChange,
  useTableQueryParams,
  useTableFormatters,

  APITable
};

export type APITableFormatter = (cell?: any, row?: any[], index?: number, state?: any) => any;
export type APITableFormatterHook = () => APITableFormatter;

export interface APITableColumn {
  field: string;
  label?: string;
  formatter?: APITableFormatter;
  disableSorting?: boolean;
  isDummyField?: boolean;
}

export type SortOrder = 'asc' | 'desc';
