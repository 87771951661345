import { useOnSubmit } from '.';

import type { OnSubmit } from '.';

const FormContainer: React.FC<{
  onSubmit?: OnSubmit
}> = ({ onSubmit, children }) => (
  <form onSubmit={useOnSubmit(onSubmit)}>
    {children}
  </form>
);

export default FormContainer;
