import { useTranslation } from 'react-i18next';

import sierraGlobals from '../../sierraGlobals';
import { getUserLocation, setUserLocation } from '../../services/user';
import { hasRole } from '../../services/auth';
import useLocationInfos from '../../hooks/useLocationInfos';

import { Button, Nav } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

import OnClickModal from '../layout/OnClickModal';

const LibraryListItem = ({ locationInfos, index, code, name }) => (
  <>
    {(index !== 0 && locationInfos[index].code[0] !== locationInfos[index - 1].code[0]) && <hr />}

    <li key={code}>
      <Button
        variant="link"
        onClick={() => setUserLocation(code)}
        style={{ paddingLeft: 0, marginTop: '-8px' }}
      >
        {code} {name}
      </Button>
    </li>
  </>
);

const LibrarySelect = () => {
  const { t } = useTranslation();

  const locationCodes = (
    hasRole('DEVELOPER')
      ? sierraGlobals.locations
      : sierraGlobals.ownLocations
  );

  const locationInfos = useLocationInfos(locationCodes);

  const button = (
    <Nav.Link style={{ fontSize: '18px' }}>
      <strong>{getUserLocation()}</strong>
      &nbsp;
      <PencilSquare style={{ marginTop: '-2px' }} />
    </Nav.Link>
  );

  return (
    <OnClickModal
      button={button}
      title={t('Select Library')}
      closeButton
    >
      <ul>
        {locationInfos.map(({ name, code }, index) => (
          <LibraryListItem
            key={code}
            locationInfos={locationInfos}
            index={index}
            code={code}
            name={name}
          />
        ))}
      </ul>
    </OnClickModal>
  );
};

export default LibrarySelect;
