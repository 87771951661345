import getURL from './getURL';
import getResourceForPath from './getResourceForPath';
import fetchData from './fetchData';
import submitForm from './submitForm';
import deleteRecord from './deleteRecord';

import useGet from './useGet';
import useFormOnSubmit from './useFormOnSubmit';
import useDeleteRecord from './useDeleteRecord';
import usePostRequest from './usePostRequest';

import APIWrap from './Wrap';
import APIForm from './Form';
import APIPostModal from './PostModal';
import APIDeleteModal from './DeleteModal';
import APIFormModal from './FormModal';
import APIStatus from './Status';

import * as apiRequest from './request';

export * from './tables';

export {
  getURL,
  getResourceForPath,
  fetchData,
  submitForm,
  deleteRecord,

  useGet,
  useFormOnSubmit,
  useDeleteRecord,
  usePostRequest,

  APIWrap,
  APIForm,
  APIPostModal,
  APIDeleteModal,
  APIFormModal,
  APIStatus
};

export default apiRequest;

export type Method =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'OPTIONS'
  | 'PATCH';

export type Params = Record<string, string>;
