import { useTranslation } from 'react-i18next';

import { formatItemStatus } from '../../services/item';

import DeletionOrderedItemStatus from './DeletionOrderedItemStatus';

export const InnerItemStatus = ({ item, children }) => {
  const { t } = useTranslation();

  return (
    <>
      {formatItemStatus(item, t)}

      {children}
    </>
  );
};

const ItemStatus = ({ item, children }) =>
  item.itemAllocations?.some(
    // Allocation type 3: delete item
    ({ allocationType, satisfiedAt, active }) => ((allocationType === 3 || allocationType === 4) && satisfiedAt === null && active)
  )
    ? <DeletionOrderedItemStatus item={item} allocationType={item.itemAllocations?.find(({ allocationType, satisfiedAt, active }) => ((allocationType === 3 || allocationType === 4) && satisfiedAt === null && active)).allocationType}>{children}</DeletionOrderedItemStatus>
    : <InnerItemStatus item={item}>{children}</InnerItemStatus>;

export default ItemStatus;
