import { useTranslation } from 'react-i18next';

import { getUserLocationName } from '../../services/user';

import TopTooltip from '../layout/TopTooltip';

export const OnShelfHereCount = ({ itemsOnShelfHere }) => {
  const { t } = useTranslation();

  return (
    <TopTooltip content={t('Amount of items on shelf here.')}>
      <span>
        {t('On Shelf')}&nbsp;({getUserLocationName()}):&nbsp;<strong>{itemsOnShelfHere ?? t('unknown')}</strong>
      </span>
    </TopTooltip>
  );
};

export const ReservableCount = ({ itemsReservable }) => {
  const { t } = useTranslation();

  return (
    <TopTooltip content={t('Amount of items on shelf anywhere, excluding bestsellers.')}>
      <span>
        {t('Reservable')}:&nbsp;<strong>{itemsReservable ?? t('unknown')}</strong>
      </span>
    </TopTooltip>
  );
};

export const CirculatingCount = ({ itemsInCirculation }) => {
  const { t } = useTranslation();

  return (
    <TopTooltip content={t('Amount of items not marked as missing or deleted.')}>
      <span>
        {t('Circulating')}:&nbsp;<strong>{itemsInCirculation ?? t('unknown')}</strong>
      </span>
    </TopTooltip>
  );
};
