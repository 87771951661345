import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';
import useAPI from '../../hooks/useAPI';
import { getCreateItemStatus } from '../../services/shipment';
import { hasRole } from '../../services/auth';

import { Button, ButtonGroup, Dropdown, Form, Modal } from 'react-bootstrap';
import { Trash, Gear, Printer, Eye, Check } from 'react-bootstrap-icons';
import BootstrapTable from '@ounai/react-bootstrap-table2';

import OnClickModal from '../layout/OnClickModal';
import ReceivedItemsConfiguration from './ReceivedItemsConfiguration';
import TopTooltip from '../layout/TopTooltip';
import ItemDetails from '../items/details/ItemDetails';
import Loading from '../api/Loading';
import Price from '../api/Price';

const ReceivedItemsConfigurationModal = ({ createItemStatus, shipment, update }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const configureItemsButtonDisabled = (createItemStatus.problems.length > 0 || createItemStatus.allItemsCreated);

  const configureItemsButton = (
    <TopTooltip
      content={t('Shipment is not ready for item creation')}
      disabled={!configureItemsButtonDisabled}
    >
      <Button
        variant="success"
        disabled={configureItemsButtonDisabled}
      >
        <Gear style={{ verticalAlign: 'sub' }} />

        <span style={{ marginLeft: '6px' }}>
          {t('Configure Items')} ({shipment.receivedItems.length})
        </span>
      </Button>
    </TopTooltip>
  );

  return (
    <OnClickModal
      title={`${t('Configure Items')} (${t('shipment')} ${shipment.shipmentId})`}
      button={configureItemsButton}
      forceClose={forceClose}
      onOpen={() => setForceClose(false)}
      closeButton
    >
      <ReceivedItemsConfiguration
        shipment={shipment}
        onSave={() => {
          update();
          setForceClose(true);
        }}
      />
    </OnClickModal>
  );
};

const CreatedItemView = ({ itemId }) => {
  const { t } = useTranslation();

  const [item, loading, error] = useAPI('GET', `/items/${itemId}`);

  if (loading) return <Loading />;
  else if (error) return t('Could not fetch item details');
  else return <ItemDetails item={item} hideTitle hideViewItemButton />;
};

const ViewCreatedItemButton = ({ itemId }) => {
  const { t } = useTranslation();

  const button = (
    <Button
      variant="link"
      size="sm"
      style={{
        padding: 0,
        border: 0,
        verticalAlign: 'text-bottom'
      }}
    >
      {t('view')}
    </Button>
  );

  return (
    <OnClickModal
      title={t('Created Item')}
      button={button}
      closeButton
    >
      <CreatedItemView itemId={itemId} />
    </OnClickModal>
  );
};

const ReceivedItemsTools = ({ shipment, selected, setSelected, onItemRemoved, update, noConfigureButton, noPrintLabelsButton }) => {
  const { t } = useTranslation();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [createdDeleteCount, setCreatedDeleteCount] = useState(0);

  const selectedCount = Object.keys(selected).length;

  const removeById = async id => {
    await api.delete(`/reception/shipments/${shipment.id}/received/${id}`);

    setSelected(s => {
      const newSelected = { ...s };

      delete newSelected[id];

      return newSelected;
    });

    if (onItemRemoved) onItemRemoved(shipment, id);
  };

  const removeSelectedItems = () => {
    for (const id in selected) {
      removeById(id);
    }

    setShowDeleteConfirmation(false);
  };

  const onClickDelete = () => {
    let created = 0;

    for (const id in selected) {
      for (const receivedItem of shipment.receivedItems) {
        if (receivedItem.id === Number(id) && receivedItem.sierraItemRecordId !== null) {
          created++;
        }
      }
    }

    if (created > 0) {
      setShowDeleteConfirmation(true);
      setCreatedDeleteCount(created);
    } else {
      removeSelectedItems();
    }
  };

  const onClickPrintLabels = async id => {
    // TODO
    await api.post(`/shipments/${shipment.id}/labels`);
    await update();
  };

  const onClickCheckAsPrinted = async id => {
    // TODO
    await api.post(`/shipments/${shipment.id}/mark-labels-printed`);
    await update();
  };

  const createItemStatus = getCreateItemStatus(shipment, t);

  return (
    <>
      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
      >
        <Modal.Body>
          <div>
            <strong>
              {t('Are you sure you want to delete')} {
                Object.keys(selected).length === 1
                  ? t('this item')
                  : `${Object.keys(selected).length} ${t('items')}`
              }?
            </strong>
          </div>

          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            {t('This would delete')} {createdDeleteCount} {
              createdDeleteCount === 1
                ? t('item that has already been created in Sierra')
                : t('items that have already been created in Sierra')
            }.
          </div>

          <Button
            variant="danger"
            size="sm"
            onClick={removeSelectedItems}
            disabled={!hasRole('PROCESS-SHIPMENTS')}
          >
            {t('Delete')}
          </Button>

          <Button
            variant="secondary"
            size="sm"
            style={{ marginLeft: '6px' }}
            onClick={() => setShowDeleteConfirmation(false)}
          >
            {t('Cancel')}
          </Button>
        </Modal.Body>
      </Modal>

      <TopTooltip
        content={t('Select at least one item to delete')}
        disabled={selectedCount > 0}
      >
        <Button
          variant="danger"
          style={{ marginLeft: '4px', marginRight: '12px' }}
          disabled={selectedCount === 0}
          onClick={onClickDelete}
        >
          <Trash style={{ verticalAlign: 'sub' }} />

          <span style={{ marginLeft: '6px' }}>
            {t('Delete')} {selectedCount > 0 && selectedCount}
          </span>
        </Button>
      </TopTooltip>

      {(hasRole('PROCESS-SHIPMENTS') && !createItemStatus.allItemsCreated && !noConfigureButton) && (
        <ReceivedItemsConfigurationModal
          createItemStatus={createItemStatus}
          shipment={shipment}
          update={update}
        />
      )}

      {(hasRole('PROCESS-SHIPMENTS') && !noPrintLabelsButton) && (
        <TopTooltip
          content={t('Items must be created before labels can be printed')}
          disabled={createItemStatus.allItemsCreated}
        >
          <Dropdown as={ButtonGroup}>
            <Button
              variant="info"
              style={{ marginLeft: '4px' }}
              onClick={onClickPrintLabels}
              disabled={!createItemStatus.allItemsCreated}
            >
              <Printer style={{ verticalAlign: 'sub' }} />

              <span style={{ marginLeft: '6px' }}>
                {t('Print Spine Labels')}
              </span>
            </Button>

            <Dropdown.Toggle
              variant="info"
              disabled={!createItemStatus.allItemsCreated}
              split
            />

            <Dropdown.Menu>
              <Dropdown.Item onClick={onClickPrintLabels}>
                <Printer style={{ verticalAlign: 'sub' }} /> {t('Print Spine Labels')}
              </Dropdown.Item>

              <Dropdown.Item disabled="true">
                <Eye style={{ verticalAlign: 'sub' }} /> {t('Preview')}
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item onClick={onClickCheckAsPrinted}>
                <Check style={{ verticalAlign: 'sub' }} /> {t('Mark as printed')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </TopTooltip>
      )}
    </>
  );
};

const ReceivedItemsTable = ({ shipment, onItemRemoved, update, noConfigureButton, noPrintLabelsButton }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState({});

  const columns = [
    {
      dataField: 'checkbox',
      text: '',
      isDummyField: true,
      headerFormatter: (column, colIndex, components) => (
        <Form.Check checked={Object.keys(selected).length > 0} onChange={event => {
          if (!event.target.checked) setSelected({});
          else {
            const newSelected = {};

            for (const item of shipment.receivedItems) {
              newSelected[item.id] = true;
            }

            setSelected(newSelected);
          }
        }} />
      ),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <Form.Check checked={formatExtraData.selected[row.id] === true} onChange={event => {
          if (event.target.checked) {
            setSelected(s => ({ ...s, [row.id]: true }));
          } else {
            setSelected(s => {
              const newSelected = { ...s };

              delete newSelected[row.id];

              return newSelected;
            });
          }
        }} />
      ),
      formatExtraData: { selected }
    },
    {
      dataField: 'barcode',
      text: t('Barcode'),
      sort: true
    },
    {
      dataField: 'productCode',
      text: t('Product Code'),
      sort: true
    },
    {
      dataField: 'sierraItemRecordId',
      text: t('Sierra Item ID'),
      formatter: (cell, row, rowIndex, formatExtraData) => (cell === null ? '-' : cell),
      sort: true
    },
    {
      dataField: 'genre',
      text: t('Genre'),
      formatter: (cell, row, rowIndex, formatExtraData) => (cell === null ? '-' : cell),
      sort: true
    },
    {
      dataField: 'owningLocation',
      text: t('Owning Location'),
      formatter: (cell, row, rowIndex, formatExtraData) => (cell === null ? '-' : cell),
      sort: true
    },
    {
      dataField: 'itemType',
      text: t('Item Type'),
      formatter: (cell, row, rowIndex, formatExtraData) => (cell === null ? '-' : cell),
      sort: true
    },
    {
      dataField: 'helmetCategoryGroup',
      text: t('Category Group'),
      formatter: (cell, row, rowIndex, formatExtraData) => (cell === null ? '-' : cell),
      sort: true
    },
    {
      dataField: 'city',
      text: t('City'),
      formatter: (cell, row, rowIndex, formatExtraData) => (cell === null ? '-' : cell),
      sort: true
    },
    {
      dataField: 'price',
      text: t('Price'),
      formatter: (cell, row, rowIndex, formatExtraData) => (cell === null ? '-' : <Price value={cell} />),
      sort: true
    },
    {
      dataField: 'viewCreatedItemButton',
      text: '',
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        row.sierraItemRecordId === null
          ? ''
          : <ViewCreatedItemButton itemId={row.sierraItemRecordId} />
      )
    }
  ];

  if (!shipment || !shipment.receivedItems) {
    return null;
  }

  return (
    <>
      <strong>
        {shipment.receivedItems.length} {t('items on list')}
      </strong>

      <BootstrapTable
        keyField="barcode"
        data={shipment.receivedItems}
        columns={columns}
        classes="w-auto table-sm table-borderless"
        striped
        bootstrap4
      />

      <ReceivedItemsTools
        shipment={shipment}
        selected={selected}
        setSelected={setSelected}
        onItemRemoved={onItemRemoved}
        update={update}
        noConfigureButton={noConfigureButton}
        noPrintLabelsButton={noPrintLabelsButton}
      />
    </>
  );
};

export default ReceivedItemsTable;
