import { useTranslation } from 'react-i18next';

import { getRoleLabel } from '../../services/account';
import { hasRole } from '../../services/auth';
import { formatDateTimeRelative } from '../../services/date';

import BootstrapTable from '@ounai/react-bootstrap-table2';

import Loading from '../api/Loading';
import AccountOptionsColumn from './AccountOptionsColumn';

const AccountsTable = ({ loading, accounts, update }) => {
  const { t } = useTranslation();

  const showId = hasRole('DEVELOPER');

  // TODO
  const columns = [
    {
      dataField: 'id',
      text: t('ID'),
      hidden: !showId,
      headerStyle: () => ({ width: '90px' }),
      sort: true
    },
    {
      dataField: 'username',
      text: t('Username'),
      sort: true
    },
    {
      dataField: 'email',
      text: t('Email Address'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => <a href={`mailto:${cell}`}>{cell}</a>
    },
    {
      dataField: 'roleString',
      text: t('Roles'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        row.Roles.map(role => getRoleLabel(role.name, t)).join(', ')
      )
    },
    {
      dataField: 'city',
      text: t('City'),
      sort: true
    },
    {
      dataField: 'location',
      text: t('Location'),
      sort: true
    },
    {
      dataField: 'createdAt',
      text: t('Created at'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => formatDateTimeRelative(cell, t)
    },
    {
      dataField: 'lastLogin',
      text: t('Last login'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell === null
          ? t('Never')
          : formatDateTimeRelative(cell, t)
      )
    },
    {
      dataField: 'options',
      text: '',
      isDummyField: true,
      headerStyle: () => ({ width: '74px' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <AccountOptionsColumn account={row} update={update} />
      )
    }
  ];

  if (loading) return <Loading />;

  return (
    <>
      <strong>
        {accounts.length} {t('accounts on list')}
      </strong>

      <BootstrapTable
        keyField="username"
        data={accounts}
        columns={columns}

        classes="w-auto api-table mw-100"
        wrapperClasses="table-responsive"

        bootstrap4
      />
    </>
  );
};

export default AccountsTable;
