import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

type ClientId = string | null;

interface ClientDataState {
  id: ClientId;
}

const initialState: ClientDataState = {
  id: null
};

const reducers = {
  setClientId: (state: ClientDataState, action: PayloadAction<ClientId>): void => {
    state.id = action.payload;
  },
  clearClientId: (state: ClientDataState): void => {
    state.id = null;
  }
};

const clientDataSlice = createSlice({
  name: 'clientData',
  initialState,
  reducers
});

export const selectClientId = (state: RootState): ClientId => state.clientData.id;

export const {
  setClientId,
  clearClientId
} = clientDataSlice.actions;

export default clientDataSlice.reducer;
