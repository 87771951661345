import { useTranslation } from '../../common/i18n';

import type { FormItem } from '../../layout/forms';

const useOrderPriorityFormItem = (defaultValue?: number): FormItem => ({
  type: 'number',
  name: 'orderPriority',
  label: useTranslation('Order Priority'),
  defaultValue: (defaultValue ?? 100),
  required: true
});

export default useOrderPriorityFormItem;
