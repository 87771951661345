import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserLocation } from '../../services/user';
import useAPI from '../../hooks/useAPI';

import { Button, Form, Row, Col } from 'react-bootstrap';
import { Funnel, XSquare } from 'react-bootstrap-icons';

import Loading from '../api/Loading';
import OnClickModal from '../layout/OnClickModal';
import MaterialTypeSelector from '../forms/MaterialTypeSelector';
import MultiLibrarySelector from '../forms/MultiLibrarySelector';
import BindingsSelector from '../forms/BindingsSelector';
import ItemStatusesSelector from '../forms/ItemStatusesSelector';
import LanguagesSelector from '../forms/LanguagesSelector';

const QuickFilters = ({ filters, setFilters, onlyOnShelfHereChecked, setOnlyOnShelfHereChecked }) => {
  const { t } = useTranslation();

  const toggleBinding = useCallback(binding => {
    setFilters(f => {
      if (f.bibBindings.includes(binding)) {
        return { ...f, bibBindings: f.bibBindings.filter(b => b !== binding) };
      } else {
        return { ...f, bibBindings: [...f.bibBindings, binding] };
      }
    });
  }, [setFilters]);

  const toggleLanguage = useCallback(language => {
    setFilters(f => {
      if (f.bibLanguages.includes(language)) {
        return { ...f, bibLanguages: f.bibLanguages.filter(b => b !== language) };
      } else {
        return { ...f, bibLanguages: [...f.bibLanguages, language] };
      }
    });
  }, [setFilters]);

  const toggleOnlyOnShelfHere = useCallback(() => {
    setOnlyOnShelfHereChecked(checked => {
      checked = !checked;

      setFilters(f => {
        if (checked) {
          return { ...f, itemLocations: [getUserLocation()], itemStatuses: ['-'], itemCheckedOut: false };
        } else {
          return { ...f, itemLocations: [], itemStatuses: [], itemCheckedOut: null };
        }
      });

      return checked;
    });
  }, [setFilters, setOnlyOnShelfHereChecked]);

  const toggleBooksOnly = useCallback(() => {
    setFilters(f => {
      if (f.bibMaterialTypes.length === 1 && f.bibMaterialTypes[0] === '1') {
        return { ...f, bibMaterialTypes: [] };
      } else {
        return { ...f, bibMaterialTypes: ['1'] };
      }
    });
  }, [setFilters]);

  const toggleFiction = useCallback(isFiction => {
    setFilters(f => {
      if (f.itemFiction === isFiction) return { ...f, itemFiction: null };
      else return { ...f, itemFiction: isFiction };
    });
  }, [setFilters]);

  return (
    <>
      <span onClick={toggleOnlyOnShelfHere}>
        <Form.Check
          label={t('Only show items on shelf in current location')}
          checked={onlyOnShelfHereChecked}
          readOnly
        />
      </span>

      <Row>
        <Col xs="auto">
          <span onClick={toggleBooksOnly}>
            <Form.Check
              label={t('Only show books')}
              checked={filters.bibMaterialTypes.length === 1 && filters.bibMaterialTypes[0] === '1'}
              readOnly
            />
          </span>
        </Col>

        <Col xs="auto" style={{ paddingRight: 0 }}>
          <span onClick={() => toggleBinding('hardcover')}>
            <Form.Check
              label={t('hardcover')}
              checked={filters.bibBindings.includes('hardcover')}
              readOnly
            />
          </span>
        </Col>

        <Col xs="auto">
          <span onClick={() => toggleBinding('softcover')}>
            <Form.Check
              label={t('softcover')}
              checked={filters.bibBindings.includes('softcover')}
              readOnly
            />
          </span>
        </Col>
      </Row>

      <Row>
        <Col xs="auto" style={{ paddingRight: 0 }}>
          <span onClick={() => toggleLanguage('fin')}>
            <Form.Check
              label={t('Finnish')}
              checked={filters.bibLanguages.includes('fin')}
              readOnly
            />
          </span>
        </Col>

        <Col xs="auto" style={{ paddingRight: 0 }}>
          <span onClick={() => toggleLanguage('swe')}>
            <Form.Check
              label={t('Swedish')}
              checked={filters.bibLanguages.includes('swe')}
              readOnly
            />
          </span>
        </Col>

        <Col xs="auto">
          <span onClick={() => toggleLanguage('eng')}>
            <Form.Check
              label={t('English')}
              checked={filters.bibLanguages.includes('eng')}
              readOnly
            />
          </span>
        </Col>
      </Row>

      <Row>
        <Col xs="auto" style={{ paddingRight: 0 }}>
          <span onClick={() => toggleFiction(true)}>
            <Form.Check
              label={t('fiction')}
              checked={filters.itemFiction === true}
              readOnly
            />
          </span>
        </Col>

        <Col xs="auto" style={{ paddingRight: 0 }}>
          <span onClick={() => toggleFiction(false)}>
            <Form.Check
              label={t('nonfiction')}
              checked={filters.itemFiction === false}
              readOnly
            />
          </span>
        </Col>
      </Row>
    </>
  );
};

const FilterButton = ({ filters, id, type, disabled }) => {
  const { t } = useTranslation();

  const activeFiltersCount = (
    +!!filters.bibBindings.length +
    +!!filters.bibLanguages.length +
    +!!filters.bibMaterialTypes.length +
    +!!filters.bibIncludeAcquisitions +
    +!!filters.itemLocations.length +
    +!!filters.itemStatuses.length +
    +!!filters.bibHideHiddenByBCode3
  );

  const filterButtonContent = (
    <>
      <Funnel
        size={18}
        style={{ marginTop: '-2px' }}
      />

      &nbsp;{t('Filter')}

      {activeFiltersCount > 0 && <>&nbsp;({activeFiltersCount})</>}
    </>
  );

  return (
    <Button id={id} type={type} disabled={disabled}>
      {disabled ? <Loading /> : filterButtonContent}
    </Button>
  );
};

const QueryLanguagesSelector = ({ query, filters, bibLanguages, setBibLanguages }) => {
  const { t } = useTranslation();

  const [languages, loading, error] = useAPI('GET', '/bibs/languages', {
    params: {
      query,
      filters
    }
  });

  if (loading) return <Loading />;

  if (error) return t('Could not fetch list of languages');

  return (
    <LanguagesSelector
      languages={languages}
      defaultValue={bibLanguages}
      onChange={setBibLanguages}
      defaultAllSelected
      sm
    />
  );
};

const FullFiltersForm = ({ query, filters, onSubmit, clearFilters }) => {
  const { t } = useTranslation();

  const [bibIncludeAcquisitions, setBibIncludeAcquisitions] = useState(filters.bibIncludeAcquisitions);
  const [bibHideHiddenByBCode3, setBibHideHiddenByBCode3] = useState(filters.bibHideHiddenByBCode3);
  const [bibBindings, setBibBindings] = useState(filters.bibBindings);
  const [bibLanguages, setBibLanguages] = useState(filters.bibLanguages);
  const [bibMaterialTypes, setBibMaterialTypes] = useState(filters.bibMaterialTypes);

  const [itemLocations, setItemLocations] = useState(filters.itemLocations);
  const [itemStatuses, setItemStatuses] = useState(filters.itemStatuses);
  const [itemCheckedOut, setItemCheckedOut] = useState(filters.itemCheckedOut);

  const currentFilters = useMemo(() => ({
    bibIncludeAcquisitions,
    bibHideHiddenByBCode3,
    bibBindings,
    bibLanguages,
    bibMaterialTypes,
    itemLocations,
    itemStatuses,
    itemCheckedOut
  }), [
    bibIncludeAcquisitions,
    bibHideHiddenByBCode3,
    bibBindings,
    bibLanguages,
    bibMaterialTypes,
    itemLocations,
    itemStatuses,
    itemCheckedOut
  ]);

  const formOnSubmit = event => {
    event.preventDefault();

    if (
      event.nativeEvent &&
      event.nativeEvent.submitter &&
      event.nativeEvent.submitter.id !== 'searchFiltersSubmit'
    ) {
      return;
    }

    if (typeof onSubmit === 'function') {
      onSubmit(currentFilters);
    }
  };

  return (
    <Form onSubmit={formOnSubmit}>
      <div style={{ marginBottom: '10px' }}>
        <Form.Label>
          {t('Material Type')}
        </Form.Label>

        <MaterialTypeSelector
          defaultValue={bibMaterialTypes}
          onChange={setBibMaterialTypes}
          defaultAllSelected
          sm
        />
      </div>

      <div style={{ marginBottom: '10px' }}>
        <Form.Label>
          {t('Language')}
        </Form.Label>

        <div>
          <QueryLanguagesSelector
            query={query}
            filters={filters}
            bibLanguages={bibLanguages}
            setBibLanguages={setBibLanguages}
          />
        </div>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <Form.Label>
          {t('Binding')}
        </Form.Label>

        <BindingsSelector
          defaultValue={bibBindings}
          onChange={setBibBindings}
          defaultAllSelected
          sm
        />
      </div>

      <div style={{ marginBottom: '10px' }}>
        <Form.Label>
          {t('Location')}
        </Form.Label>

        <div>
          <MultiLibrarySelector
            defaultValue={itemLocations}
            onChange={setItemLocations}
            defaultAllSelected
            includeUserLocation
            includeAll
          />
        </div>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <Form.Label>
          {t('Item Status')}
        </Form.Label>

        <ItemStatusesSelector
          defaultValue={itemStatuses}

          onChange={newItemStatuses => {
            setItemStatuses(newItemStatuses);
            setItemCheckedOut(!newItemStatuses.includes('-'));
          }}

          defaultAllSelected
          sm
        />
      </div>

      <div style={{ marginBottom: '10px' }}>
        <span onClick={() => setBibIncludeAcquisitions(b => !b)}>
          <Form.Check
            label={t('Show Acquisitions')}
            checked={bibIncludeAcquisitions}
            readOnly
          />
        </span>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <span onClick={() => setBibHideHiddenByBCode3(b => !b)}>
          <Form.Check
            label={t('Hide hidden items (BCode3)')}
            checked={bibHideHiddenByBCode3}
            readOnly
          />
        </span>
      </div>

      <Row style={{ marginTop: '20px' }}>
        <Col xs="auto">
          <FilterButton
            filters={currentFilters}
            id="searchFiltersSubmit"
            type="submit"
          />
        </Col>

        <Col xs="auto" className="ml-auto">
          <Button
            variant="danger"
            onClick={clearFilters}
          >
            <XSquare
              size={18}
              style={{ marginTop: '-2px' }}
            />

            &nbsp;{t('Clear Filters')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const FullFilters = ({ query, filters, setFilters, onSubmit, clearFilters, buttonDisabled }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const onFormSubmit = newFilters => {
    setFilters(newFilters);
    setForceClose(true);

    if (typeof onSubmit === 'function') onSubmit(newFilters);
  };

  return (
    <OnClickModal
      button={<FilterButton filters={filters} disabled={buttonDisabled} />}
      title={t('Filter')}
      forceClose={buttonDisabled || forceClose}
      onOpen={() => setForceClose(false)}
      closeButton
    >
      <FullFiltersForm
        query={query}
        filters={filters}
        setFilters={setFilters}
        onSubmit={onFormSubmit}
        clearFilters={() => {
          clearFilters();
          setForceClose(true);
        }}
      />
    </OnClickModal>
  );
};

const BibSearchFilters = ({
  query,
  filters,
  setFilters,
  emptyFilters,
  enableQuickFilters,
  enableFullFilters,
  onlyOnShelfHereChecked,
  setOnlyOnShelfHereChecked
}) => {
  const [/* updateFiltersTimeout */, setUpdateFiltersTimeout] = useState(null);
  const [newFilters, setNewFilters] = useState(filters);
  const [nextFilters, setNextFilters] = useState(filters);
  const [fullFiltersButtonDisabled, setFullFiltersButtonDisabled] = useState(false);

  useEffect(() => {
    if (JSON.stringify(nextFilters) !== JSON.stringify(newFilters)) {
      setNextFilters(newFilters);
      setFullFiltersButtonDisabled(true);

      setUpdateFiltersTimeout(u => {
        if (u !== null) clearTimeout(u);

        return setTimeout(() => {
          setFilters(newFilters);
          setUpdateFiltersTimeout(null);
          setFullFiltersButtonDisabled(false);
        }, 1000);
      });
    }
  }, [newFilters, nextFilters, setFilters]);

  const onFullFiltersSubmit = newFullFilters => {
    setNewFilters(newFullFilters);

    setOnlyOnShelfHereChecked(
      newFullFilters.itemLocations.length === 1 &&
      newFullFilters.itemLocations[0] === getUserLocation() &&
      newFullFilters.itemStatuses.length === 1 &&
      newFullFilters.itemStatuses[0] === '-' &&
      newFullFilters.itemCheckedOut === false
    );
  };

  const clearFilters = () => onFullFiltersSubmit({ ...emptyFilters });

  return (
    <Row style={{ marginBottom: '6px' }}>
      {enableQuickFilters && (
        <Col xs="auto">
          <QuickFilters
            filters={newFilters}
            setFilters={setNewFilters}
            onlyOnShelfHereChecked={onlyOnShelfHereChecked}
            setOnlyOnShelfHereChecked={setOnlyOnShelfHereChecked}
            setFullFiltersButtonDisabled={setFullFiltersButtonDisabled}
          />
        </Col>
      )}

      {enableFullFilters && (
        <Col xs="auto" className="ml-auto mt-auto">
          <FullFilters
            query={query}
            filters={filters}
            setFilters={setFilters}
            onSubmit={onFullFiltersSubmit}
            clearFilters={clearFilters}
            buttonDisabled={fullFiltersButtonDisabled}
          />
        </Col>
      )}
    </Row>
  );
};

export default BibSearchFilters;
