import { T, useT } from '../common/i18n';
import { Space } from '../common/layout';

import { APIWrap, useGet } from '../api';

import type { DatabaseFileSystemStatus as IDatabaseFileSystemStatus } from '../api/models';

const DatabaseFileSystemStatus: React.FC = () => {
  const t = useT();
  const { data, loading, error } = useGet<IDatabaseFileSystemStatus>('/developer/db-file-system-status');

  const freeGB = (data && typeof data.freeMB === 'number')
    ? Math.floor(data.freeMB / 100) / 10
    : t('unknown');

  const freePercent = (data && typeof data.freeMB === 'number' && typeof data.usedMB === 'number')
    ? Math.floor(data.freeMB / (data.freeMB + data.usedMB) * 1000) / 10
    : t('unknown');

  return (
    <APIWrap loading={loading} error={error}>
      <T>Database file system</T>:

      <Space />

      <strong>
        {freeGB} GB
      </strong>

      <Space />

      ({freePercent} %)

      <Space />

      <T>free space</T>.
    </APIWrap>
  );
};

export default DatabaseFileSystemStatus;
