import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useItemsInCirculation from '../../hooks/useItemsInCirculation';
import api from '../../services/api';
import { getUserCity } from '../../services/user';

import { Dropdown, DropdownButton, ButtonGroup, Form, Button, Spinner } from 'react-bootstrap';
import { Trash, Magic } from 'react-bootstrap-icons';

import Loading from '../api/Loading';
import CountBadge from '../api/CountBadge';
import OnClickModal from '../layout/OnClickModal';
import ItemsInCirculation from './ItemsInCirculation';

const OrderDeletionsModal = ({ bib, count, onConfirm }) => {
  const { t } = useTranslation();

  const [deleteCount, setDeleteCount] = useState(0);
  const [deleteComment, setDeleteComment] = useState('');
  const [forceClose, setForceClose] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const itemsInCirculation = useItemsInCirculation(bib.sierraBibRecordId, true);

  if (itemsInCirculation === null) {
    return <Loading />;
  }

  const city = getUserCity();

  const deletableItemsCount = itemsInCirculation.filter(item => (
    city === item.fixedFieldLocation[0] &&
    item.itemAllocations.filter(({ active, satisfiedAt }) => (
      active === true && satisfiedAt === null
    )).length === 0
  )).length;

  const allocatedItemsCount = itemsInCirculation.filter(item => (
    city === item.fixedFieldLocation[0] &&
    item.itemAllocations.filter(({ active, satisfiedAt }) => (
      active === true && satisfiedAt === null
    )).length > 0
  )).length;

  const allocatedDeletionCount = itemsInCirculation.filter(item => (
    city === item.fixedFieldLocation[0] &&
    item.itemAllocations.filter(({ allocationType, active, satisfiedAt }) => (
      allocationType === 3 &&
      active === true &&
      satisfiedAt === null
    )).length > 0
  )).length;

  const orderDeletionsButton = (
    <Dropdown.Item disabled={deletableItemsCount === 0}>
      <Trash style={{ marginRight: '6px' }} />

      {t('Order Item Deletions')}

      <CountBadge count={count} />
    </Dropdown.Item>
  );

  return (
    <OnClickModal
      button={orderDeletionsButton}
      title={t('Order Item Deletions')}
      forceClose={deletableItemsCount === 0 || forceClose}
      onOpen={() => setForceClose(false)}
      footer={
        <>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setForceClose(true)}
            disabled={submitting}
          >
            {t('Cancel')}
          </Button>

          <Button
            variant="primary"
            size="sm"
            disabled={submitting || deleteCount === 0 || deleteCount > deletableItemsCount}
            onClick={async () => {
              setError(null);
              setSubmitting(true);

              try {
                await onConfirm(deleteCount, deleteComment);

                setForceClose(true);
              } catch (err) {
                setError(err);
              }

              setSubmitting(false);
            }}
          >
            {submitting && (
              <Spinner size="sm" animation="border" style={{ marginRight: '4px' }} />
            )}

            {t('Order Deletions')}
          </Button>

          {error && (
            <div style={{ color: 'var(--danger)' }}>
              {t('Action failed')}: {error?.message}
            </div>
          )}
        </>
      }
      closeButton
    >
      <strong style={{ marginBottom: '4px' }}>
        {t('In Circulation')}
      </strong>

      <ItemsInCirculation items={itemsInCirculation} />

      <hr />

      <Form.Group>
        <Form.Label>
          <strong>{t('Deletion Count')}</strong> ({t('max')} {deletableItemsCount})

          <div>
            {allocatedItemsCount > 0 && (
              <>
                <strong>{allocatedItemsCount}</strong>
                {' '}
                {(allocatedItemsCount === 1) ? t('item currently allocated') : t('items currently allocated')}
                {(allocatedDeletionCount > 0) ? `, ${t('of which')} ` : '.'}
              </>
            )}

            {(allocatedDeletionCount > 0) && (
              <>
                <strong>{allocatedDeletionCount}</strong>
                {' '}
                {(allocatedDeletionCount === 1) ? t('is a deletion order') : t('are deletion orders')}.
              </>
            )}
          </div>
        </Form.Label>

        <Form.Control
          size="sm"
          width="100%"
          type="number"
          min={0}
          max={deletableItemsCount}
          value={deleteCount}
          onChange={e => setDeleteCount(Number(e.target.value))}
          disabled={submitting}
        />

        <Form.Label>

          <strong>{t('Deletion comment')}</strong>

        </Form.Label>

        <Form.Control
          size="sm"
          as="textarea"
          rows={6}
          value={deleteComment}
          onChange={event => setDeleteComment(event.target.value)}
        />

      </Form.Group>
    </OnClickModal>
  );
};

const BibCollectionTaskDropdownButton = ({ bib, update }) => {
  const { t } = useTranslation();

  const [deletionTaskCount, setDeletionTaskCount] = useState(
    bib?.Items?.reduce((acc, item) => (
      acc + item.itemAllocations.filter(({ allocationType, active, satisfiedAt }) => (
        allocationType === 3 &&
        active === true &&
        satisfiedAt === null
      )).length
    ), 0) ?? 0
  );

  const orderDeletions = async (deleteCount, deleteComment) => {
    await api.post(`/bibs/${bib.sierraBibRecordId}/order-deletions`, {
      deleteCount,
      deleteComment
    });

    setDeletionTaskCount(c => c + deleteCount);

    if (typeof update === 'function') update();
  };

  return (
    <DropdownButton
      as={ButtonGroup}
      size="sm"
      style={{ marginRight: '4px', marginBottom: '4px' }}
      title={
        <>
          <Magic style={{
            verticalAlign: 'middle',
            marginTop: '-4px'
          }} />

          &nbsp;

          {t('Collection Tasks')}

          <CountBadge count={deletionTaskCount} />
        </>
      }
    >
      <OrderDeletionsModal
        bib={bib}
        count={deletionTaskCount}
        onConfirm={orderDeletions}
      />
    </DropdownButton>
  );
};

export default BibCollectionTaskDropdownButton;
