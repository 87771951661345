import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import { formatDateTimeRelative } from '../../services/date';

import { Button } from 'react-bootstrap';

const HoldShelfStatisticsShowDetailsButton = ({ children }) => {
  const { t } = useTranslation();

  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <Button
        variant="link"
        size="sm"
        onClick={() => setShowDetails(s => !s)}
        style={{
          marginLeft: '4px',
          padding: 0,
          verticalAlign: 'baseline',
          fontSize: '12px'
        }}
      >
        {showDetails ? t('Hide details') : t('Show details')}
      </Button>

      {showDetails && children}
    </>
  );
};

const HoldShelfStatisticsDetails = ({ statistics }) => {
  const { t } = useTranslation();

  const totalItems = (
    statistics.clearNewItemsTotal +
    statistics.clearItemsCarriedOver
  );

  const totalDone = (
    statistics.clearItemsScanned +
    statistics.clearItemsMarkedAsMissing +
    statistics.clearItemsCheckedOut
  );

  return (
    <ul>
      <li>{t('Total items today')}: {totalItems}</li>

      <ul>
        <li>{t('New items')}: {statistics.clearNewItemsTotal}</li>
        <li>{t('Items carried over from previous days')}: {statistics.clearItemsCarriedOver}</li>
      </ul>

      <li>{t('Cleared items')}: {totalDone}</li>

      <ul>
        <li>{t('Scanned')}: {statistics.clearItemsScanned}</li>
        <li>{t('Marked as missing')}: {statistics.clearItemsMarkedAsMissing}</li>
        <li>{t('Checked out')}: {statistics.clearItemsCheckedOut}</li>
      </ul>

      <li>{t('Total amount of items in hold shelf')}: {statistics.totalHoldShelfItems}</li>
    </ul>
  );
};

const HoldShelfStatistics = ({ location }) => {
  const { t } = useTranslation();

  const [
    statistics,
    /* loading */,
    error,
    /* headers */,
    updateStatistics
  ] = useAPI('GET', `/holdshelf/statistics/today/${location}`);

  useEffect(() => {
    const interval = setInterval(updateStatistics, 10 * 1000);

    return () => clearInterval(interval);
  }, [updateStatistics]);

  if (error) {
    return t('Could not fetch hold shelf statistics');
  }

  if (statistics === null) {
    return null;
  }

  return (
    <>
      {
        statistics.clearEmailReceivedAt === null
          ? <span style={{ color: 'var(--danger)' }}>{t('No email received today.')}</span>
          : `${t('Email received')} ${formatDateTimeRelative(statistics.clearEmailReceivedAt, t, true)}.`
      }

      <HoldShelfStatisticsShowDetailsButton>
        <HoldShelfStatisticsDetails statistics={statistics} />
      </HoldShelfStatisticsShowDetailsButton>
    </>
  );
};

export default HoldShelfStatistics;
