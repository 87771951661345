import { Button } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

const EditButton = ({ style, size, noMargin, disabled, verticalAlign, ...props }) => (
  <span {...props}>
    <Button
      variant="link"
      disabled={disabled}
      style={{
        verticalAlign: (verticalAlign || 'baseline'),
        padding: 0,
        border: 0,
        marginLeft: (noMargin ? 0 : '4px'),
        ...style
      }}
    >
      <PencilSquare size={size} />
    </Button>
  </span>
);

export default EditButton;
