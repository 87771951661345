import { useT } from '../../../common/i18n';

import type { FormItem } from '../../../layout/forms';
import type { RecordType } from '..';

interface MassUpdateCommonProperties {
  recordType: RecordType;
  queryJSON: string | null;
}

const useMassUpdateCommonForm = ({ recordType, queryJSON }: MassUpdateCommonProperties): FormItem[] => {
  const t = useT();

  return [
    {
      type: 'select',
      name: 'record-type',
      label: t('Record Type'),
      defaultValue: recordType,
      options: [
        {
          label: t('Bib'),
          value: 'b'
        },
        {
          label: t('Item'),
          value: 'i'
        }
      ]
    },
    {
      type: 'textarea',
      name: 'query-json',
      label: t('Query JSON'),
      placeholder: '{ ... }',
      defaultValue: queryJSON
    }
  ];
};

export default useMassUpdateCommonForm;
