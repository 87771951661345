import { useTranslation } from 'react-i18next';

import { hasRole } from '../../../services/auth';

import { Tabs, Tab } from 'react-bootstrap';

import TabWrapper from '../../layout/TabWrapper';
import ReceptionShipmentView from './ReceptionShipmentView';
import ShipmentView from '../ShipmentView';

const ReceptionTabView = ({ tab, setTab, shipment, addReceivedItem, onItemRemoved, receivedCounts }) => {
  const { t } = useTranslation();

  return (
    <Tabs activeKey={tab} onSelect={key => setTab(key)}>
      <Tab eventKey="receptionShipmentView" title={t('Received Items')}>
        <TabWrapper>
          <ReceptionShipmentView
            shipment={shipment}
            addReceivedItem={addReceivedItem}
            onItemRemoved={onItemRemoved}
            receivedCounts={receivedCounts}
          />
        </TabWrapper>
      </Tab>

      {hasRole('PROCESS-SHIPMENTS') && (
        <Tab eventKey="shipmentView" title={t('Shipment View')}>
          <TabWrapper>
            <ShipmentView shipmentId={shipment.id} />
          </TabWrapper>
        </Tab>
      )}
    </Tabs>
  );
};

export default ReceptionTabView;
