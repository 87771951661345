import BootstrapToast from 'react-bootstrap/Toast';

import { useSelector, useDispatch } from '../../state';
import {
  selectAll as selectAllToasts,
  close as closeToast
} from '../../state/slices/toasts';

import type { Toast } from '.';

const divStyle: React.CSSProperties = {
  position: 'absolute',
  top: '20px',
  right: '20px'
};

const toastStyle: React.CSSProperties = {
  minWidth: '200px'
};

const getDelay = (toast: Toast): number | undefined =>
  (typeof toast.autoCloseSeconds === 'number' && toast.autoCloseSeconds > 0)
    ? (toast.autoCloseSeconds * 1000)
    : undefined;

const useOnClose = (id: string): (() => void) => {
  const dispatch = useDispatch();

  return (): void => {
    dispatch(closeToast(id));
  };
};

const ToastItem: React.FC<{ toast: Toast }> = ({ toast }) => (
  <BootstrapToast onClose={useOnClose(toast.id)} delay={getDelay(toast)} style={toastStyle}>
    <BootstrapToast.Header closeButton={toast.showCloseButton}>
      <strong className="mr-auto">
        {toast.title}
      </strong>
    </BootstrapToast.Header>

    <BootstrapToast.Body>
      {toast.content}
    </BootstrapToast.Body>
  </BootstrapToast>
);

const InnerToastView: React.FC<{ toasts: Toast[] }> = ({ toasts }) => (
  <div style={divStyle}>
    {toasts.map(toast => (
      <ToastItem key={toast.id} toast={toast} />
    ))}
  </div>
);

const ToastView: React.FC = () => {
  const toasts = useSelector(selectAllToasts);

  return (toasts.length > 0)
    ? <InnerToastView toasts={toasts} />
    : null;
};

export default ToastView;
