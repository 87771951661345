import { useTranslation } from 'react-i18next';

import { getPreference, setPreference } from '../../services/preferences';

import { Form, Row, Col } from 'react-bootstrap';

const Preferences = () => {
  const { t } = useTranslation();

  return (
    <Form>
      <Form.Group as={Row}>
        <Form.Label xs="auto" column="sm" style={{ paddingRight: 0 }}>
          {t('Sierra ID Format')}
        </Form.Label>

        <Col xs="auto">
          <Form.Control
            size="sm"
            as="select"
            defaultValue={getPreference('sierraIdFormat', 'full')}
            onChange={event => setPreference('sierraIdFormat', event.target.value)}
          >
            <option value="numberOnly">
              {t('Number Only')} ({t('e.g.')} 1234567)
            </option>

            <option value="full">
              {t('Full')} ({t('e.g.')} b1234567X)
            </option>
          </Form.Control>
        </Col>
      </Form.Group>

      <div style={{ marginTop: '10px' }}>
        {t('Changes may only take effect after page has been reloaded.')}
      </div>
    </Form>
  );
};

export default Preferences;
