import {
  Checkbox,
  Text,
  TextArray,
  Number,
  Textarea,
  Radio,
  Select,
  Multiselect,
  Password
} from './elements';

import type { FormItem, FormDataValue, OnChangeElement, OnChangeForm } from '.';

interface InnerProps {
  formItem: FormItem;
  onChange?: OnChangeElement;
}

const ItemContent: React.FC<{
  formItem: FormItem,
  onChange?: OnChangeForm
}> = ({ onChange, ...props }) => {
  const p: InnerProps = { ...props };

  p.onChange = (value: FormDataValue): void =>
    onChange && onChange(props.formItem.name, value);

  switch (props.formItem.type) {
    case 'checkbox':
      return <Checkbox {...p} />;
    case 'text':
      return <Text {...p} />;
    case 'text-array':
      return <TextArray {...p} />;
    case 'number':
      return <Number {...p} />;
    case 'textarea':
      return <Textarea {...p} />;
    case 'radio':
      return <Radio {...p} />;
    case 'select':
      return <Select {...p} />;
    case 'multiselect':
      return <Multiselect {...p} />;
    case 'password':
      return <Password {...p} />;
  }
};

const FormContent: React.FC<{
  form: FormItem[],
  onChange?: OnChangeForm
}> = ({ form, onChange }) => (
  <>
    {form.map(formItem => (
      <ItemContent
        key={formItem.name}
        formItem={formItem}
        onChange={onChange}
      />
    ))}
  </>
);

export default FormContent;
