import { useTranslation } from 'react-i18next';

import expandRow from '../../../services/expandRow';
import config from '../../../config';

import BootstrapTable from '@ounai/react-bootstrap-table2';
import { Button } from 'react-bootstrap';
import { PencilSquare, Trash, PeopleFill } from 'react-bootstrap-icons';

import ListChildren from './ListChildren';

const BibListToolsColumn = ({ bibList, update }) => (
  <>
    <Button variant="link" size="sm" style={{ padding: 0, border: 0 }}>
      <PencilSquare size={20} style={{ marginRight: '4px' }} />
    </Button>

    <Button variant="link" size="sm" style={{ padding: 0, border: 0 }}>
      <Trash size={20} style={{ color: 'crimson', marginRight: '4px' }} />
    </Button>

    <Button variant="link" size="sm" style={{ padding: 0, border: 0 }}>
      <PeopleFill size={20} />
    </Button>
  </>
);

const BibListTable = ({ bibLists, update }) => {
  const { t } = useTranslation();

  const columns = [
    {
      dataField: 'imageUrl',
      text: t('Image'),
      formatter: (cell, row, rowIndex, formatExtraData) => row.cell ? <img src={cell} alt="" /> : '-'
    },
    {
      dataField: 'name',
      text: t('Name')
    },
    {
      dataField: 'description',
      text: t('Description')
    },
    {
      dataField: 'public',
      text: t('Public'),
      formatter: (cell, row, rowIndex, formatExtraData) => (cell ? t('yes') : t('no'))
    },
    {
      dataField: 'listToken',
      text: t('URL'),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const url = `${config.bibListPublicUrl}${cell}`;

        return <a href={url} target="_blank" rel="noreferrer">{url}</a>;
      }
    },
    {
      dataField: 'Account.username',
      text: t('Owner')
    },
    {
      dataField: 'colors',
      text: t('Colors'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        `${row.primaryColor || t('default')}, ${row.secondaryColor || t('default')}`
      )
    },
    {
      dataField: 'orderString',
      text: t('Order String'),
      formatter: (cell, row, rowIndex, formatExtraData) => (cell || '-')
    },
    {
      dataField: 'tools',
      text: '',
      isDummyField: true,
      hidden: !update,
      headerStyle: () => ({ width: '100px' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <BibListToolsColumn bibList={row} update={update} />
      )
    }
  ];

  return (
    <BootstrapTable
      keyField="id"
      columns={columns}
      data={bibLists}
      classes="w-auto api-table mw-100"
      wrapperClasses="table-responsive"
      expandRow={expandRow(row => <ListChildren listId={row.id} />, null, false)}
      bootstrap4
    />
  );
};

export default BibListTable;
