import { useState } from 'react';

import BootstrapOverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { Popover } from '.';

import type { Props as PopoverProps } from './Popover';
import type { HeadingLevelString } from './Heading';
import type { Placement } from '.';

const useOverlay = (props: PopoverProps): JSX.Element => <Popover {...props} />;

type Props = {
  id: string;
  titleHeading?: HeadingLevelString;
  title?: string;
  button: JSX.Element,
  placement?: Placement;
  closeButton?: boolean;
  children?: React.ReactNode;
};

const ClickPopover: React.FC<Props> = ({
  id,
  placement,
  button,
  titleHeading,
  title,
  children,
  closeButton,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const onClickClose = closeButton
    ? (): void => setShow(false)
    : null;

  return (
    <BootstrapOverlayTrigger
      trigger="click"
      placement={placement ?? 'bottom'}
      overlay={useOverlay({ id, titleHeading, title, onClickClose, children })}
      onToggle={setShow}
      show={show}
      {...props}
    >
      {button}
    </BootstrapOverlayTrigger>
  );
};

export default ClickPopover;
