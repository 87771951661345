import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Authorized } from '../../../../users';

import api from '../../../services/api';

import { Form, Button, Dropdown, DropdownButton, ButtonGroup, Spinner } from 'react-bootstrap';
import { Trash, Magic, ListTask, ClipboardCheck, Tsunami, HouseDoor } from 'react-bootstrap-icons';

import OnClickModal from '../../layout/OnClickModal';
import CountBadge from '../../api/CountBadge';

const CollectionTaskDropdownButton = ({ item, update }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);
  const [text, setText] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const deleteItem = async () => {
    await api.post(`/items/${item.sierraItemRecordId}/item-deletion`);

    if (typeof update === 'function') update();
  };

  const orderItemDeletion = async () => {
    await api.post(`/items/${item.sierraItemRecordId}/order-deletion`);

    if (typeof update === 'function') update();
  };

  const cancelItemDeletion = async () => {
    await api.post(`/items/${item.sierraItemRecordId}/cancel-deletion`);

    if (typeof update === 'function') update();
  };

  const createOtherTask = async () => {
    await api.post(`/items/${item.sierraItemRecordId}/order-collection-task`, {
      text
    });

    if (typeof update === 'function') update();
  };

  const cancelOtherTask = async () => {
    await api.post(`/items/${item.sierraItemRecordId}/cancel-collection-task`);
    if (typeof update === 'function') update();
  };

  const manuallyTakeInventory = async () => {
    await api.post(`/items/${item.sierraItemRecordId}/manually-take-inventory`);

    if (typeof update === 'function') update();
  };

  const createHomingOrder = async () => {
    await api.post(`/items/${item.sierraItemRecordId}/create-homing-order`);

    if (typeof update === 'function') update();
  };

  const totalTaskCount = useMemo(
    () => item.itemAllocations?.filter(({ allocationType, active, satisfiedAt }) => (
      ([3, 4].includes(allocationType) && satisfiedAt === null && active)
    )).length,
    [item.itemAllocations]
  );

  const deletionTaskCount = useMemo(
    () => item.itemAllocations?.filter(({ allocationType, active, satisfiedAt }) => (
      (allocationType === 3 && satisfiedAt === null && active)
    )).length,
    [item.itemAllocations]
  );

  const otherTaskCount = useMemo(
    () => item.itemAllocations?.filter(({ allocationType, active, satisfiedAt }) => (
      (allocationType === 4 && satisfiedAt === null && active)
    )).length,
    [item.itemAllocations]
  );

  const cancelOtherTaskButton = (
    <Dropdown.Item
      onClick={cancelOtherTask}>
      <ListTask style={{ marginRight: '6px' }} />

      {t('Cancel Other Collection Task')}

      <CountBadge count={otherTaskCount} />
    </Dropdown.Item>
  );

  const orderOtherTaskButton = (
    <Dropdown.Item>
      <ListTask style={{ marginRight: '6px' }} />

      {t('Order Other Collection Task')}

      <CountBadge count={otherTaskCount} />
    </Dropdown.Item>
  );

  const onClickModalDelaer = (

    <OnClickModal
      button={orderOtherTaskButton}
      title={t('Order Collection Task')}
      onOpen={() => setForceClose(false)}
      forceClose={forceClose}
      footer={
        <>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setForceClose(true)}
            disabled={submitting}
          >
            {t('Cancel')}
          </Button>

          <Button
            variant="primary"
            size="sm"
            disabled={submitting || typeof text !== 'string' || text.length === 0}
            onClick={async () => {
              setError(null);
              setSubmitting(true);

              try {
                await createOtherTask(text);

                setText('');
                setForceClose(true);
              } catch (err) {
                setError(err);
              }

              setSubmitting(false);
            }}
          >
            {submitting && (
              <Spinner size="sm" animation="border" style={{ marginRight: '4px' }} />
            )}

            {t('Create Task')}
          </Button>

          {error && (
            <div style={{ color: 'var(--danger)' }}>
              {t('Action failed')}: {error?.message}
            </div>
          )}
        </>
      }
      closeButton
    >
      <Form.Label>
        {t('Task description')} ({t('markdown supported')})
      </Form.Label>

      <Form.Control
        as="textarea"
        rows={6}
        defaultValue={text}
        onChange={event => setText(event.target.value)}
      />
    </OnClickModal>
  );

  const otherTaskButton = (
    (otherTaskCount === 0) ? onClickModalDelaer : cancelOtherTaskButton
  );

  return (
    <DropdownButton
      as={ButtonGroup}
      size="sm"
      style={{ marginRight: '4px', marginBottom: '4px' }}
      title={
        <>
          <Magic style={{
            verticalAlign: 'middle',
            marginTop: '-4px'
          }} />

          &nbsp;

          {t('Collection Tasks')}

          <CountBadge count={totalTaskCount} />
        </>
      }
    >

      <Dropdown.Item
        onClick={deleteItem}

        disabled={
          /* already deleted */
          // item.fixedFieldItemStatus === 'p' && item.fixedFieldCity === 'd'
          true
        }
      >
        <Trash style={{ marginRight: '6px' }} />
        {t('Delete Item')}
        <CountBadge count={deletionTaskCount} />
      </Dropdown.Item>
      <Dropdown.Item
        onClick={deletionTaskCount === 0
          ? orderItemDeletion
          : cancelItemDeletion}

        disabled={
          /* already deleted */
          (item.fixedFieldItemStatus === 'p' && item.fixedFieldCity === 'd') || (item.owningLocation && item.owningLocation[0] !== 'e') || (item.fixedFieldCity && item.fixedFieldCity[0] !== 'e')
        }
      >
        <Trash style={{ marginRight: '6px' }} />
        {deletionTaskCount === 0
          ? t('Order Item Deletion')
          : t('Cancel Item Deletion Task')}

        <CountBadge count={deletionTaskCount} />
      </Dropdown.Item>

      <Dropdown.Divider />

      {otherTaskButton}

      <Dropdown.Item
        onClick={manuallyTakeInventory}>

        <ClipboardCheck style={{
          verticalAlign: 'middle',
          marginRight: '6px'
        }} />

        {t('Manually take inventory')}

      </Dropdown.Item>

      <Authorized role="DEVELOPER">

        <Dropdown.Divider />

        <Dropdown.Item disabled>
          <Tsunami style={{
            verticalAlign: 'middle',
            marginRight: '6px'
          }} />

          {t('Float to destination')}
        </Dropdown.Item>

        <Dropdown.Item disabled>
          <Tsunami style={{
            verticalAlign: 'middle',
            marginRight: '6px'
          }} />

          {t('Create floating order')}
        </Dropdown.Item>

        <Dropdown.Divider />

        <Dropdown.Item disabled >
          <HouseDoor style={{
            verticalAlign: 'middle',
            marginRight: '6px'
          }} />

          {t('Home item')}
        </Dropdown.Item>

        <Dropdown.Item
          onClick={createHomingOrder}>
          <HouseDoor style={{
            verticalAlign: 'middle',
            marginRight: '6px'
          }} />

          {t('Create homing order')}
        </Dropdown.Item>

      </Authorized>

    </DropdownButton>
  );
};

export default CollectionTaskDropdownButton;
