import { useT, T } from '../common/i18n';
import { Space } from '../common/layout';
import { TODO } from '../common/placeholders';

import { useGet, APIWrap } from '../api';
import { SierraID } from '../sierra';

// TODO
import DynamicView from '../legacy/components/api/DynamicView';

import type { Bib } from '../api/models';

const BibDetails = TODO;
const BibItemTable = TODO;
const ItemDetails = TODO;

const BibDynamicView: React.FC<{
  bib: Bib
}> = ({ bib }) => {
  const t = useT();

  return (
    <DynamicView defaultTab="bib-details" alwaysTabbed={false}>
      <span title={t('Bib Details')} key="bib-details">
        <BibDetails />
      </span>

      <span title={t('Items')} key="bib-item-table">
        <BibItemTable />
      </span>

      <span title={t('Item')} key="item-details">
        <ItemDetails />
      </span>
    </DynamicView>
  );
};

const BibNotFound: React.FC<{
  bibId: number
}> = ({ bibId }) => (
  <>
    <T>Bib not found for Sierra ID</T>
    <Space />
    <SierraID resource="bib" id={bibId} />
  </>
);

const BibView: React.FC<{
  bibId: number
}> = ({ bibId }) => {
  const { data: bib, loading, error } = useGet<Bib>(`/bibs/${bibId}`);

  return (
    <APIWrap loading={loading} error={error}>
      {bib
        ? <BibDynamicView bib={bib} />
        : <BibNotFound bibId={bibId} />}
    </APIWrap>
  );
};

export default BibView;
