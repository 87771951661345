import { useTranslation } from 'react-i18next';

import { hasRole } from '../../services/auth';
import { isItemId, isBibId, isOrderId, isItemBarcode, isPatronBarcode } from '../../services/search';
import { parseItemId } from '../../services/item';
import { parseBibId } from '../../services/bib';
import { parseOrderId } from '../../services/order';

import { Redirect } from 'react-router-dom';

const Search = ({ searchTerm }) => {
  const { t } = useTranslation();

  searchTerm = searchTerm.trim();

  if (hasRole('HANDLE-HOLDS') && isPatronBarcode(searchTerm)) {
    return <Redirect to={`/holdshelf/patron/${searchTerm}`} />;
  } else if (hasRole('VIEW-ITEMS') && isItemBarcode(searchTerm)) {
    return <Redirect to={`/items/view/${searchTerm}`} />;
  } else if (hasRole('VIEW-ITEMS') && isItemId(searchTerm)) {
    return <Redirect to={`/items/view/id/${parseItemId(searchTerm)}`} />;
  } else if (hasRole('VIEW-BIBS') && isBibId(searchTerm)) {
    return <Redirect to={`/bibs/view/${parseBibId(searchTerm)}`} />;
  } else if (hasRole('VIEW-ORDERS') && isOrderId(searchTerm)) {
    return <Redirect to={`/orders/view/${parseOrderId(searchTerm)}`} />;
  } else if (hasRole('VIEW-BIBS')) {
    return <Redirect to={`/bibs/search?query=${searchTerm}`} />;
  } else {
    return `${t('No results for search')} "${searchTerm}"`;
  }
};

export default Search;
