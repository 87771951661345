import type { Role } from '.';

const hasRole = (role: Role | Role[]): boolean => {
  try {
    const rolesString: string | null = localStorage.getItem('roles');

    if (!rolesString) {
      return true;
    }

    const roles: Role[] = JSON.parse(rolesString);

    if (roles && roles.includes('DEVELOPER') && role !== 'SHARED') {
      return true;
    } else if (typeof role === 'string') {
      return (role && roles && roles.includes(role));
    } else {
      // Role is array of roles
      for (const r of role) {
        if (hasRole(r)) return true;
      }

      return false;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('hasRole caught:', err);

    return false;
  }
};

export default hasRole;
