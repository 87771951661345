import { useTranslation } from '../common/i18n';

import { APIForm } from '../api';
import { usePasswordChangeForm } from '.';

const PasswordChangeForm: React.FC = () => (
  <APIForm
    method="POST"
    path="/users/me/password-change"
    form={usePasswordChangeForm()}
    submitText={useTranslation('Change password')}
    successMessage={useTranslation('Password changed successfully!')}
  />
);

export default PasswordChangeForm;
