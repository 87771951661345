import type { FormDataValue } from '..';

const validateJSON = (json: FormDataValue): boolean => {
  if (typeof json !== 'string') {
    return false;
  }

  try {
    const parsed = JSON.parse(json);

    return (parsed && typeof parsed === 'object');
  } catch (err) {
    return false;
  }
};

export default validateJSON;
