import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getAccountErrorMessage } from '../../services/account';

import AccountForm from './AccountForm';

const AccountEditor = ({ account, onSubmit, showPassword, noRoles, noEmail }) => {
  const { t } = useTranslation();

  if (!onSubmit) throw new Error('No onSubmit defined!');

  const [formError, setFormError] = useState(null);

  const formOnSubmit = async ({ username, password, city, location, email, roles }) => {
    try {
      await onSubmit({ username, password, city, location, email, roles });
    } catch (err) {
      setFormError(getAccountErrorMessage(err, t));
    }
  };

  return (
    <AccountForm
      account={account}
      error={formError}
      submitText={t('Save')}
      onSubmit={formOnSubmit}
      showPassword={showPassword}
      noRoles={noRoles}
      noEmail={noEmail}
    />
  );
};

export default AccountEditor;
