import { useState, useRef, useCallback } from 'react';

// TODO
import config from '../legacy/config';

import audioCassetteImage from '../resources/materialimages/audio-cassette.png';
import bdImage from '../resources/materialimages/bd.png';
import boardGameImage from '../resources/materialimages/board-game.png';
import bookImage from '../resources/materialimages/book.png';
import cdRomImage from '../resources/materialimages/cd-rom.png';
import cdImage from '../resources/materialimages/cd.png';
import dvdRomImage from '../resources/materialimages/dvd-rom.png';
import dvdImage from '../resources/materialimages/dvd.png';
import lpImage from '../resources/materialimages/lp.png';
import magazineImage from '../resources/materialimages/magazine.png';
import mapImage from '../resources/materialimages/map.png';
import noneImage from '../resources/materialimages/none.png';
import sheetMusicImage from '../resources/materialimages/sheet-music.png';
import videoGameImage from '../resources/materialimages/video-game.png';

import type { MaterialTypeCode } from '../sierra';

interface Props {
  isbn?: string | null;
  materialType?: MaterialTypeCode;
}

const getMaterialTypeImage = (materialType: MaterialTypeCode): string => ({
  1: bookImage,
  2: mapImage,
  3: cdImage,
  4: audioCassetteImage,
  6: lpImage,
  7: sheetMusicImage,
  9: magazineImage,
  d: cdRomImage,
  e: dvdRomImage,
  g: dvdImage,
  h: bdImage,
  r: boardGameImage,
  s: videoGameImage,

  a: noneImage, // image
  b: noneImage, // slide
  c: noneImage, // working picture
  f: noneImage, // video
  j: noneImage, // microfilm
  k: noneImage, // manuscript
  n: noneImage, // braille
  q: noneImage, // physical item
  t: noneImage, // e-audio
  v: noneImage, // e-video
  x: noneImage, // e-item
  y: noneImage, // e-magazine
  z: noneImage // e-book
}[materialType]);

const style: React.CSSProperties = {
  width: '80px',
  height: 'auto'
};

const CoverImage: React.FC<Props> = ({ isbn, materialType }) => {
  const [hidden, setHidden] = useState<boolean>(true);
  const [overrideImage, setOverrideImage] = useState<string | null>(null);
  const ref = useRef<HTMLImageElement>(null);

  const setMaterialTypeImage = useCallback((): void => {
    if (overrideImage === null && materialType) {
      setOverrideImage(getMaterialTypeImage(materialType));

      setHidden(false);
    }
  }, [materialType, overrideImage]);

  // TODO: undefined check will be unnecessary after legacy code is gone
  if (isbn === null || isbn === undefined) {
    setMaterialTypeImage();
  } else if (![10, 13].includes(isbn.length) || !/^[0-9]+[0-9Xx]$/.test(isbn)) {
    // eslint-disable-next-line no-console
    console.log('Invalid ISBN encountered:', isbn);

    setMaterialTypeImage();
  }

  return (
    <img
      ref={ref}
      src={overrideImage ?? `${config.bibCoverImageUrl}/${isbn}?format=image&size=max200`}
      alt=""
      style={style}
      hidden={hidden}
      onError={setMaterialTypeImage}

      onLoad={(): void => {
        if (ref?.current?.width && ref?.current?.height) {
          const { width, height } = ref.current;

          // Placeholder 'no image' is always 285x285
          if (width === 285 && height === 285) setMaterialTypeImage();
        }

        setHidden(false);
      }}
    />
  );
};

export default CoverImage;
