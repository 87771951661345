import { useTranslation } from 'react-i18next';

import config from '../../config';
import useItemsInCirculation from '../../hooks/useItemsInCirculation';
import { formatBibLevel, formatMaterialType, formatLanguage, formatCountry } from '../../services/bib';
import { formatDateTimeRelative } from '../../services/date';
import { getOrderQueryForBibId } from '../../services/order';
import sierraGlobals from '../../sierraGlobals';

import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

import DetailsTable from '../api/DetailsTable';
import BibMARC091 from './BibMARC091';
import Authorized from '../auth/Authorized';
import SubjectHeadings from './SubjectHeadings';
import ExpandableList from '../layout/ExpandableList';
import BCode3 from './BCode3';
import SierraID from '../api/SierraID';
import ClickSelect from '../api/ClickSelect';
import BibJSON from './BibJSON';
import BibCollectionTaskDropdownButton from './BibCollectionTaskDropdownButton';
import Loading from '../api/Loading';

import { CoverImage } from '../../../bibs';
import ItemsInCirculation from './ItemsInCirculation';

const BibDetailsButtons = ({ bib, hideViewBibButton, update }) => {
  const { t } = useTranslation();

  const bibId = bib.sierraBibRecordId;

  const viewBibButton = (
    <Authorized role="VIEW-BIBS">
      <Button
        as={Link}
        size="sm"
        to={`/bibs/view/${bibId}`}
        style={{ marginRight: '4px', marginBottom: '4px' }}
      >
        <BoxArrowUpRight style={{
          verticalAlign: 'middle',
          marginTop: '-4px'
        }} /> {t('View Bib')}
      </Button>
    </Authorized>
  );

  const queryOrdersButton = (
    <Authorized role="VIEW-ORDERS">
      <Button
        as={Link}
        size="sm"
        to={`/orders?query=${JSON.stringify(getOrderQueryForBibId(bibId))}`}
        style={{ marginRight: '4px', marginBottom: '4px' }}
      >
        <BoxArrowUpRight style={{
          verticalAlign: 'middle',
          marginTop: '-4px'
        }} />

        &nbsp;{t('Query Orders')}
      </Button>
    </Authorized>
  );

  const encoreLinkButton = (
    <Button
      size="sm"
      variant="info"
      href={`${config.encoreUrl}${bibId}`}
      target="_blank"
      style={{ marginRight: '4px', marginBottom: '4px' }}
    >
      <BoxArrowUpRight style={{
        verticalAlign: 'middle',
        marginTop: '-4px'
      }} /> helmet.finna.fi
    </Button>
  );

  return (
    <>
      {hideViewBibButton || viewBibButton}
      {queryOrdersButton}

      <Authorized role="CREATE-COLLECTION-TASKS">
        <BibCollectionTaskDropdownButton bib={bib} update={update} />
      </Authorized>

      {encoreLinkButton}

      <Authorized role="DEVELOPER">
        <BibJSON bib={bib} />
      </Authorized>
    </>
  );
};

const BibDetails = ({ bib, hideCoverImage, hideViewBibButton, update }) => {
  const { t } = useTranslation();

  const coverImage = (
    hideCoverImage ||
    <CoverImage isbn={bib.marcISBN} materialType={bib.fixedFieldMaterialType} />
  );

  const itemsInCirculation = useItemsInCirculation(bib.sierraBibRecordId, true);

  const details = [
    {
      label: t('Cover'),
      value: coverImage
    },
    {
      label: t('Sierra ID'),
      value: <SierraID code="b" id={bib.sierraBibRecordId} />
    },
    {
      label: t('In Circulation'),
      value: (itemsInCirculation === null)
        ? <Loading />
        : (itemsInCirculation.length === 0)
            ? 0
            : <ItemsInCirculation items={itemsInCirculation} />
    },
    {
      label: t('Bib Hold Count'),
      value: bib.holdCount
    },
    {
      label: t('Title'),
      value: bib.marcTitle && (
        <ExpandableList primary={bib.marcTitle} all={
          bib.bibTitles
            ? bib.bibTitles.map(({ title }) => title)
            : []
        } />
      )
    },
    {
      label: t('Author'),
      value: bib.marcAuthor && (
        <ExpandableList primary={bib.marcAuthor} all={
          bib.bibAuthors
            ? bib.bibAuthors.map(({ author }) => author)
            : []
        } />
      )
    },
    {
      label: t('Control No.'),
      value: bib.marcControlNumber && (
        <ClickSelect>{bib.marcControlNumber}</ClickSelect>
      )
    },
    {
      label: t('ISBN'),
      value: bib.marcISBN && (
        <ExpandableList primary={bib.marcISBN} all={
          bib.bibStandardNumbers
            ? bib.bibStandardNumbers.filter(({ type }) => type.startsWith('isbn')).map(({ content }) => content)
            : []
        } clickSelect />
      )
    },
    {
      label: t('ISSN'),
      value: bib.marcISSN && (
        <ExpandableList primary={bib.marcISSN} all={
          bib.bibStandardNumbers
            ? bib.bibStandardNumbers.filter(({ type }) => type === 'issn').map(({ content }) => content)
            : []
        } clickSelect />
      )
    },
    {
      label: t('EAN13'),
      value: bib.marcEAN13 && (
        <ExpandableList primary={bib.marcEAN13} all={
          bib.bibStandardNumbers
            ? bib.bibStandardNumbers.filter(({ type }) => type === 'ean13').map(({ content }) => content)
            : []
        } clickSelect />
      )
    },
    {
      label: t('Material Type'),
      value: formatMaterialType(bib)
    },
    {
      label: t('Year Published'),
      value: bib.marcPublishedYear
    },
    {
      label: t('Series Info'),
      value: bib.marcSeriesInfo
    },
    {
      label: t('Series Info'),
      value: bib.marcSeriesInfo2
    },
    {
      label: t('Physical Desc.'),
      value: bib.marcPhysicalDescription
    },
    {
      label: t('Binding'),
      value: bib.marcBindingInfo
    },
    {
      label: t('Subject Headings'),
      value: (
        (!bib.subjectHeadings || bib.subjectHeadings.length === 0)
          ? null
          : <SubjectHeadings subjectHeadings={bib.subjectHeadings} sourceBib={bib} />
      )
    },
    {
      label: t('Age Rating'),
      value: bib.rdaAgeRating
    },
    {
      label: t('Classifications'),
      value: (
        <>
          <div>
            {t('Espoo')}: <strong>{bib.marcEspooClass || '-'}</strong>
          </div>

          <div>
            {t('Helsinki')}: <strong>{bib.marcHelsinkiClass || '-'}</strong>
          </div>

          <div>
            {t('Kauniainen')}: <strong>{bib.marcKauniainenClass || '-'}</strong>
          </div>

          <div>
            {t('Vantaa')}: <strong>{bib.marcVantaaClass || '-'}</strong>
          </div>
        </>
      )
    },
    {
      label: t('Language'),
      value: formatLanguage(bib)
    },
    {
      label: t('Country'),
      value: formatCountry(bib)
    },
    {
      label: t('Cat. Date'),
      value: bib.fixedFieldCatalogueDate
    },
    {
      label: t('Bib Level'),
      value: formatBibLevel(bib)
    },
    {
      label: t('Statistics Group'),
      value: <BibMARC091 bib={bib} />
    },
    {
      label: t('Sierra BCODE3'),
      value: (
        sierraGlobals.BCode3s[bib.fixedFieldBCode3]
          ? <BCode3 code={bib.fixedFieldBCode3} />
          : bib.fixedFieldBCode3
      )
    },
    {
      label: t('Suppressed'),
      value: (bib.suppressed !== null && (bib.suppressed ? t('yes') : t('no')))
    },
    {
      label: t('Bib Created At'),
      value: formatDateTimeRelative(bib.sierraCreatedAt, t)
    },
    {
      label: t('Bib Updated At'),
      value: formatDateTimeRelative(bib.sierraUpdatedAt, t)
    }
  ];

  return (
    <>
      <h3>{t('Bib Details')}</h3>

      <BibDetailsButtons
        bib={bib}
        hideViewBibButton={hideViewBibButton}
        update={update}
      />

      <DetailsTable details={details} />
    </>
  );
};

export default BibDetails;
