import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TopTooltip = ({ content, disabled, children }) => {
  if (disabled) {
    return children || null;
  }

  const tooltip = (
    <Tooltip>
      {content}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      overlay={tooltip}
    >
      {children}
    </OverlayTrigger>
  );
};

export default TopTooltip;
