const margin = 4;

const style: React.CSSProperties = {
  marginLeft: `${margin}px`,
  marginRight: `${margin}px`
};

const Middot: React.FC = () => (
  <span style={style}>&middot;</span>
);

export default Middot;
