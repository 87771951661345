import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BrowserMultiFormatReader } from '@zxing/library';

const BarcodeScanner = ({ onScan }) => {
  const { t } = useTranslation();

  const [noScannerFound, setNoScannerFound] = useState(false);

  useEffect(() => {
    const barcodeReader = new BrowserMultiFormatReader();

    barcodeReader.listVideoInputDevices().then(videoInputDevices => {
      if (videoInputDevices.length === 0) {
        setNoScannerFound(true);
      } else {
        const lastDeviceId = videoInputDevices[videoInputDevices.length - 1].deviceId;

        barcodeReader
          .decodeOnceFromVideoDevice(lastDeviceId, 'webcam-video')
          .then(onScan)
          .catch(err => {
            // eslint-disable-next-line no-console
            if (err.name !== 'NotFoundException') console.error(err);
          });
      }
    });

    return () => barcodeReader.reset();
  }, [onScan]);

  return noScannerFound
    ? t('No camera device found')
    : <video id="webcam-video" style={{ width: '100%', height: 'auto' }} />;
};

export default BarcodeScanner;
