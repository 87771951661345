import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

import type { Role } from '../../users';
import type { Location3, CityCode } from '../../sierra';

interface CurrentUserState {
  id: number | null;
  username: string | null;
  email: string | null;
  roles: Role[];
  city: CityCode | null;
  location: Location3 | null;
  locationName: string | null;
}

const initialState: CurrentUserState = {
  id: (localStorage.getItem('id') === null ? null : Number(localStorage.getItem('id'))),
  username: localStorage.getItem('username'),
  email: localStorage.getItem('email'),
  roles: JSON.parse(localStorage.getItem('roles') ?? '[]'),
  city: localStorage.getItem('city') as CityCode,
  location: localStorage.getItem('location'),
  locationName: localStorage.getItem('locationName')
};

const reducers = {
  setId: (state: CurrentUserState, action: PayloadAction<number | string>): void => {
    state.id = (typeof action.payload === 'number')
      ? action.payload
      : Number(action.payload);
  },
  setUsername: (state: CurrentUserState, action: PayloadAction<string>): void => {
    state.username = action.payload;
  },
  setEmail: (state: CurrentUserState, action: PayloadAction<string>): void => {
    state.email = action.payload;
  },
  setRoles: (state: CurrentUserState, action: PayloadAction<Role[]>): void => {
    state.roles = action.payload;
  },
  setCity: (state: CurrentUserState, action: PayloadAction<CityCode>): void => {
    state.city = action.payload;
  },
  setLocation: (state: CurrentUserState, action: PayloadAction<Location3>): void => {
    state.location = action.payload;
  },
  setLocationName: (state: CurrentUserState, action: PayloadAction<string>): void => {
    state.locationName = action.payload;
  }
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers
});

export const selectId = (state: RootState): number | null =>
  state.currentUser.id;

export const selectUsername = (state: RootState): string | null =>
  state.currentUser.username;

export const selectEmail = (state: RootState): string | null =>
  state.currentUser.email;

export const selectRoles = (state: RootState): Role[] =>
  state.currentUser.roles;

export const selectCity = (state: RootState): CityCode | null =>
  state.currentUser.city;

export const selectLocation = (state: RootState): Location3 | null =>
  state.currentUser.location;

export const selectLocationName = (state: RootState): string | null =>
  state.currentUser.locationName;

export const {
  setId,
  setUsername,
  setEmail,
  setRoles,
  setCity,
  setLocation,
  setLocationName
} = currentUserSlice.actions;

export default currentUserSlice.reducer;
