import { HoverTooltip, Icon } from '.';

import type { Placement } from '.';

interface Props {
  id: string;
  placement?: Placement;
}

const infoIconStyle: React.CSSProperties = {
  marginLeft: '6px',
  marginTop: 0
};

const InnerTooltip: React.FC<Props> = ({ id, placement, children }) => (
  <HoverTooltip placement={placement} id={id} content={children}>
    <Icon
      icon="info-circle"
      size={16}
      style={infoIconStyle}
    />
  </HoverTooltip>
);

const InfoHoverTooltip: React.FC<Props> = ({ children, ...props }) =>
  children
    ? <InnerTooltip {...props}>{children}</InnerTooltip>
    : null;

export default InfoHoverTooltip;
