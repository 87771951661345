import { formatItemType, isBarcodeValid } from './item';
import { formatCity } from './location';
import api from './api';
import { getUserLocation } from './user';
import { formatDate } from './date';

import FloatingModal from '../components/items/FloatingModal';
import InvalidLocationFix from '../components/items/InvalidLocationFix';
import InvalidItemStatusFix from '../components/items/InvalidItemStatusFix';

const checks = {};

checks.isil = (tag, t, rules) => (
  rules.isil.includes(tag.isil) || t('Invalid ISIL') + ` (${tag.isil || 'null'})`
);

checks.dsfid = (tag, t, rules) => (
  rules.dsfid.includes(tag.dsfid) || t('Invalid DSFID') + ` (${tag.dsfid || 'null'})`
);

checks.afi = (tag, t, rules) => (
  rules.afi.includes(tag.afi) || t('Invalid AFI') + ` (${tag.afi || 'null'})`
);

checks.barcode = (tag, t) => (
  isBarcodeValid(tag.barcode) || t('Invalid barcode')
);

checks.tagValid = (tag, t) => (
  tag.validISO28560 || t('Invalid tag data')
);

checks.itemStatus = (tag, t, rules) => {
  if (!tag.item) return null;

  return (
    (
      rules.itemStatus.type === 'not'
        ? rules.itemStatus.values.includes(tag.item.fixedFieldItemStatus)
        : !rules.itemStatus.values.includes(tag.item.fixedFieldItemStatus)
    ) || <InvalidItemStatusFix item={tag.item} />
  );
};

checks.city = (tag, t, rules) => {
  if (!tag.item) return null;

  return (
    (
      rules.city.type === 'not'
        ? rules.city.values.includes(tag.item.fixedFieldCity)
        : !rules.city.values.includes(tag.item.fixedFieldCity)
    ) || t('Invalid city') + ` ${formatCity(tag.item.fixedFieldCity)}`
  );
};

checks.location = (tag, t, rules) => {
  if (!tag.item) return null;

  return (
    (
      rules.location.type === 'not'
        ? !!rules.location.values.find(l => tag.item.fixedFieldLocation.startsWith(l))
        : !rules.location.values.find(l => tag.item.fixedFieldLocation.startsWith(l))
    ) || <InvalidLocationFix item={tag.item} />
  );
};

checks.callNumber = (tag, t, rules) => {
  if (!tag.item) return null;

  return (
    (
      rules.callNumber.type === 'not'
        ? !!rules.callNumber.values.find(l => tag.item.callNumber.startsWith(l))
        : !rules.callNumber.values.find(l => tag.item.callNumber.startsWith(l))
    ) || t('Invalid call number') + ` (${tag.item.callNumber})`
  );
};

checks.genre = (tag, t, rules) => {
  if (!tag.item) return null;

  return (
    (
      rules.genre.type === 'not'
        ? rules.genre.values.includes(tag.item.genre)
        : !rules.genre.values.includes(tag.item.genre)
    ) || t('Invalid genre') + ` (${tag.item.genre})`
  );
};

checks.language = (tag, t, rules) => {
  if (!tag.item || !tag.item.Bib) return null;

  return (
    (
      rules.language.type === 'not'
        ? rules.language.values.includes(tag.item.Bib.fixedFieldLang)
        : !rules.language.values.includes(tag.item.Bib.fixedFieldLang)
    ) || t('Invalid language') + ` (${tag.item.Bib.fixedFieldLang})`
  );
};

checks.itemType = (tag, t, rules) => {
  if (!tag.item) return null;

  return (
    (
      rules.itemType.type === 'not'
        ? rules.itemType.values.includes(tag.item.fixedFieldItemType)
        : !rules.itemType.values.includes(tag.item.fixedFieldItemType)
    ) || t('Invalid item type') + ` ${formatItemType(tag.item)}`
  );
};

checks.bibHold = (tag, t) => (
  (!tag.item || !tag.item.Bib)
    ? null
    : (tag.item.Bib.holdCount === 0 || t('Bib has a hold'))
);

checks.itemHold = (tag, t) => (
  !tag.item
    ? null
    : (tag.item.holdCount === 0 || t('Item has a hold'))
);

checks.noItemHold = (tag, t) => (
  !tag.item
    ? null
    : (tag.item.holdCount > 0 || t('Item has no hold'))
);

checks.checkedOut = (tag, t) => (
  !tag.item
    ? null
    : (!tag.item.checkedOut || t('Item is checked out'))
);

// Defined in InventoryPage
checks.notScannedAfter = () => true;

checks.circulation = (tag, t, rules) => {
  if (!tag.item) return null;

  const check = (count, { operator, value }) => {
    if (typeof count !== 'number') throw new Error(`Invalid count ${count}!`);

    if (operator === '>') return count > value;
    else if (operator === '>=') return count >= value;
    else if (operator === '<') return count < value;
    else if (operator === '<=') return count <= value;
    else throw new Error(`Invalid operator ${operator}`);
  };

  const thisYearPasses = check(tag.item.fixedFieldCirculationThisYear, rules.circulation.thisYear);
  const lastYearPasses = check(tag.item.fixedFieldCirculationLastYear, rules.circulation.lastYear);

  const message = [
    t('Circulation'),
    `${t('this year')}:`,
    `${tag.item.fixedFieldCirculationThisYear},`,
    `${t('last year')}:`,
    tag.item.fixedFieldCirculationLastYear
  ].join(' ');

  if (rules.circulation.group === 'AND') return (thisYearPasses && lastYearPasses) && message;
  else if (rules.circulation.group === 'OR') return (thisYearPasses || lastYearPasses) && message;
  else throw new Error(`Invalid circulation operator group ${rules.circulation.group}`);
};

checks.floatable = async (tag, t, rules, items) => {
  if (!tag.item) return null;
  if (rules.floatable.targetLocations.length === 0) return true;

  // s Kausijulkaisu
  if (tag.item.Bib && tag.item.Bib.fixedFieldBibLevel === 's') return true;

  const ownLocation = getUserLocation();

  if (!tag.item.fixedFieldLocation.startsWith(ownLocation)) return true;

  const queryLocations = [ownLocation, ...rules.floatable.targetLocations];
  const counts = await api.get(`/bibs/${tag.item.sierraBibRecordId}/items/in-circulation/${queryLocations}`);
  const floatableLocations = [];
  const itemsOnList = items.filter(otherTag => (
    otherTag.item && otherTag.item.sierraBibRecordId === tag.item.sierraBibRecordId
  )).length;

  if (counts[ownLocation] - itemsOnList < rules.floatable.minCopiesInOwnLocation) return true;

  // TODO take itemsOnList into account here too
  for (const targetLocation of rules.floatable.targetLocations) {
    if (counts[targetLocation] <= rules.floatable.maxCopiesInTargetLocation) {
      floatableLocations.push(targetLocation + tag.item.fixedFieldLocation.slice(3, 5));
    }
  }

  if (floatableLocations.length === 0) return true;

  return (
    <>
      <span style={{ marginRight: '6px' }}>
        {t('Floatable')}: {floatableLocations.join(', ')}
      </span>

      <FloatingModal
        item={tag.item}
        floatableLocations={floatableLocations}
        counts={counts}
      />
    </>
  );
};

checks.zeroes = (tag, t, rules) => {
  if (!tag.item) return null;

  const lastCheckoutDate = new Date(tag.item.fixedFieldLastCheckoutDate);
  const createdDate = new Date(tag.item.sierraCreatedAt);

  if (
    lastCheckoutDate.getTime() < rules.zeroesDate.getTime() &&
    createdDate.getTime() < rules.zeroesDate.getTime()
  ) {
    return (
      <>
        {t('Last checkout on')} <strong>{lastCheckoutDate.getTime() === 0 ? t('never') : formatDate(lastCheckoutDate)}</strong>, {t('item created on')} <strong>{formatDate(createdDate)}</strong>
      </>
    );
  }

  return true;
};

export default checks;
