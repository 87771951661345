import sierraGlobals from '../sierraGlobals';
import config from '../config';
import { formatDate } from './date';
import api from './api';

let lastBarcodeScanned = null;

export const submitRfidScan = barcode => {
  if (barcode.includes(',')) barcode = barcode.split(',')[0];

  if (lastBarcodeScanned !== barcode) {
    api.post('/rfid/scan', { barcode });
  }

  lastBarcodeScanned = barcode;
};

export const formatTransitInfo = (item, t) => {
  if (item.fixedFieldItemStatus !== 't') return null;
  else if (!item.transitFrom && !item.transitTo) return null;
  else {
    const transitForHold = (item.transitForHold ? ` (${t('hold')})` : '');

    return `${item.transitFrom} -> ${item.transitTo}${transitForHold}`;
  }
};

export const formatItemStatus = (item, t, omitStatusCode = false) => {
  const itemStatusCode = item.fixedFieldItemStatus;

  if (!sierraGlobals.itemStatuses[itemStatusCode]) return null;

  const itemStatusDisplay = (
    item.checkedOut
      ? `${t('DUE')} ${formatDate(item.fixedFieldCurrentLoanDueDate)}`
      : sierraGlobals.itemStatuses[itemStatusCode]
  );

  return (omitStatusCode ? itemStatusDisplay : `${itemStatusCode} (${itemStatusDisplay})`);
};

export const formatItemType = item => {
  if (!item.itemType) return item.fixedFieldItemType ?? null;
  else return `${item.itemType.id} (${item.itemType.description})`;
};

export const formatAgency = item => {
  if (!item.agency) return null;
  else return `${item.agency.code} (${item.agency.display})`;
};

export const formatPrice = item => {
  if (item.fixedFieldPrice === undefined || item.fixedFieldPrice === null) return null;

  if (typeof item.fixedFieldPrice !== 'number') {
    // eslint-disable-next-line no-console
    console.error('Non-numeric price encountered:', item);

    return item.fixedFieldPrice;
  }

  return (item.fixedFieldPrice / 100).toFixed(2);
};

export const getSignumText = item => {
  if (config.toddlerGenres.indexOf(item.genre) !== -1) return '';

  let letters = null;
  let classification = item.classification;

  let callNumberWithoutGenre = item.callNumber;

  if (item.callNumber) {
    for (const genre in config.genres) {
      callNumberWithoutGenre = callNumberWithoutGenre.replace(genre, '');
    }

    for (const part of callNumberWithoutGenre.split(' ')) {
      if (part.length === 3 && !/[0-9.]/.test(part)) {
        letters = part;
      }
    }
  }

  if (!letters) {
    if (item.Bib.marcAuthor) letters = item.Bib.marcAuthor.slice(0, 3).toUpperCase();
    else if (item.Bib.marcTitle) {
      const titleSkip = item.Bib.marcTitleSkip || 0;

      letters = item.Bib.marcTitle.slice(titleSkip, titleSkip + 3).toUpperCase();
    }

    if (letters && typeof letters === 'string') {
      letters = letters.replace(/,/g, '');
    }
  }

  if (classification === '1.4') classification = '';

  return `${classification} ${letters}`.trim();
};

// TODO rename
export const hasProblem = item => {
  const nonProblemStatuses = [
    '-', // On shelf
    'o' // Cannot be checked out
  ];

  return (!nonProblemStatuses.includes(item.fixedFieldItemStatus) || item.checkedOut);
};

export const parseItemId = sierraItemRecordId => (
  Number(sierraItemRecordId.replace(/[Aa]$/, '').replace(/^\.?i?/, ''))
);

export const updateItemRecord = async (sierraItemRecordId, itemPatch) => (
  await api.put(`/items/${sierraItemRecordId}`, itemPatch)
);

export const setItemStatus = async (sierraItemRecordId, newStatus) => (
  await api.put(`/items/${sierraItemRecordId}/status/${newStatus}`)
);

export const setCallNumber = async (sierraItemRecordId, newCallNumber) => (
  await updateItemRecord(sierraItemRecordId, {
    callNumbers: [newCallNumber]
  })
);

export const setLocation = async (sierraItemRecordId, newLocation) => {
  if (newLocation.length !== 5) throw new Error(`Invalid location ${newLocation}`);

  return await updateItemRecord(sierraItemRecordId, {
    location: newLocation
  });
};

export const setBarcode = async (sierraItemRecordId, newBarcode) => (
  await updateItemRecord(sierraItemRecordId, {
    barcodes: [newBarcode]
  })
);

export const setCity = async (sierraItemRecordId, newCity) => {
  if (newCity.length !== 1) throw new Error(`Invalid city ${newCity}!`);

  return await updateItemRecord(sierraItemRecordId, {
    itemCode2: newCity
  });
};

export const markAsMissing = async sierraItemRecordId => (
  await setItemStatus(sierraItemRecordId, config.missingStatus)
);

export const getMarkAsMissingProblem = async (item, t) => {
  const bibItems = await api.get(`/bibs/${item.sierraBibRecordId}/items`, {
    params: {
      status: ['-', '!', 't'],
      count: true
    }
  });

  const problems = [];

  if (bibItems.count <= 1) problems.push(t('last item remaining for this bib'));
  if (item.holdCount >= 1) problems.push(t('there is a hold on the item'));

  if (problems.length > 0) return problems.join(', ');
  else return null;
};

export const getQueryForBarcode = barcode => [{
  type: 'GROUP',
  value: 'AND',
  isNegated: false,
  children: [{
    field: 'barcode',
    value: barcode,
    operator: 'EQUAL'
  }]
}];

export const isBarcodeValid = barcode => (
  /^3[0-9]{13}(,[0-9]*\.)?$/.test(barcode) ||
  /^9\.*[0-9]{12}[0-9xX]+(,[0-9]*\.)?$/.test(barcode) ||
  /^\.\.+[0-9]{10}[0-9xX]+(,[0-9]*\.)?$/.test(barcode)
);

export const printLabel = async itemId => (
  await api.post(`/items/${itemId}/label`)
);
