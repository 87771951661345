export const parseMARC = marc => {
  const { leader, fields } = marc;
  const arr = [`LEADER ${leader}`];

  fields.sort((a, b) => (
    Number(Object.keys(a)[0]) - Number(Object.keys(b)[0])
  ));

  for (const fieldObj of marc.fields) {
    const key = Object.keys(fieldObj)[0];
    const field = fieldObj[key];

    if (typeof field === 'string') {
      arr.push(`${key}${' '.repeat(4)}${field}`);
    } else {
      const subfields = [];

      for (const subfieldObj of field.subfields) {
        const subfieldKey = Object.keys(subfieldObj)[0];
        const subfieldContent = subfieldObj[subfieldKey];

        if (subfieldKey === 'a') subfields.push(subfieldContent);
        else subfields.push(`|${subfieldKey}${subfieldContent}`);
      }

      arr.push(`${key} ${field.ind1}${field.ind2} ${subfields.join('')}`);
    }
  }

  return arr.join('\n');
};
