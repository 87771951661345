import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

import type { Toast } from '../../layout/toasts';

interface ToastsState {
  toasts: Toast[];
}

const initialState: ToastsState = {
  toasts: []
};

const reducers = {
  create: (state: ToastsState, action: PayloadAction<Toast>): void => {
    state.toasts.push(action.payload);
  },
  close: (state: ToastsState, action: PayloadAction<string>): void => {
    state.toasts = state.toasts.filter(toast => (toast.id !== action.payload));
  }
};

const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers
});

export const selectAll = (state: RootState): Toast[] =>
  state.toasts.toasts;

export const {
  create,
  close
} = toastsSlice.actions;

export default toastsSlice.reducer;
