import { ClickSelect } from '../common/io';

type SierraResource =
  | 'bib'
  | 'item'
  | 'order';

const getResourceCode = (resource: SierraResource): string =>
  resource[0];

// TODO use user preferred format
// TODO variable check digit
const SierraID: React.FC<{
  resource: SierraResource,
  id: number
}> = ({ resource, id }) => (
  <ClickSelect>
    <code>
      {getResourceCode(resource)}{id}A
    </code>
  </ClickSelect>
);

export default SierraID;
