import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BoxArrowUpRight, SignpostSplit, ClockHistory } from 'react-bootstrap-icons';

import OnClickModal from '../../layout/OnClickModal';
import BottomTooltip from '../../layout/BottomTooltip';
import ItemShelfView from '../shelfView/ItemShelfView';
import ItemScans from '../ItemScans';
import ItemJSON from './ItemJSON';
import Authorized from '../../auth/Authorized';
import ItemCollectionTaskDropdownButton from './ItemCollectionTaskDropdownButton';

const ItemDetailsButtons = ({ item, hideItemScansButton, hideViewItemButton, hideInventoryButton, hideFloatItemButton, update }) => {
  const { t } = useTranslation();

  const itemId = item.sierraItemRecordId;
  const shelfViewSymbol = '目';

  const shelfViewButton = (
    <Button
      size="sm"
      style={{ marginRight: '4px', marginBottom: '4px' }}
    >
      <span>
        {shelfViewSymbol}
      </span>

      &nbsp;

      {t('Shelf View')}
    </Button>
  );

  const shelfViewModalTitle = (
    <>
      {t('Shelf View')}

      <BottomTooltip content={t('Open full screen shelf view')}>
        <Button
          as={Link}
          to={`/items/shelf/id/${itemId}`}
          size="sm"
          variant="link"
        >
          <BoxArrowUpRight size={18} style={{ marginTop: '-4px' }} />
        </Button>
      </BottomTooltip>
    </>
  );

  const shelfViewModal = (
    <OnClickModal
      title={shelfViewModalTitle}
      button={shelfViewButton}
      closeButton
    >
      <ItemShelfView itemId={itemId} />
    </OnClickModal>
  );

  const scanHistoryButton = (
    <Button
      size="sm"
      style={{ marginRight: '4px', marginBottom: '4px' }}
    >
      <ClockHistory style={{
        verticalAlign: 'middle',
        marginTop: '-2px'
      }} />

      &nbsp;

      {t('Item Scans')}
    </Button>
  );

  const scanHistoryModal = (
    <OnClickModal
      title={t('Item Scans')}
      button={scanHistoryButton}
      closeButton
    >
      <ItemScans itemId={itemId} hideTitle />
    </OnClickModal>
  );

  const viewItemButton = (
    <Button
      as={Link}
      size="sm"
      to={`/items/view/id/${itemId}`}
      style={{ marginRight: '4px', marginBottom: '4px' }}
    >
      <BoxArrowUpRight style={{
        verticalAlign: 'middle',
        marginTop: '-4px'
      }} />

      &nbsp;

      {t('View Item')}
    </Button>
  );

  // TODO
  const inventoryButton = (
    <Authorized role="DEVELOPER">
      <Button
        as={Link}
        size="sm"
        to={`/items/shelf-inventory?itemId=${itemId}`}
        style={{ marginRight: '4px', marginBottom: '4px' }}
      >
        <BoxArrowUpRight style={{
          verticalAlign: 'middle',
          marginTop: '-4px'
        }} />

        &nbsp;

        {t('Start Shelf Inventory')}
      </Button>
    </Authorized>
  );

  const floatItemButton = (
    <Authorized role="DEVELOPER">
      <Button
        size="sm"
        style={{ marginRight: '4px', marginBottom: '4px' }}
        disabled
      >
        <SignpostSplit style={{
          verticalAlign: 'middle',
          marginTop: '-4px'
        }} />

        &nbsp;

        {t('Float')}
      </Button>
    </Authorized>
  );

  return (
    <>
      {shelfViewModal}
      {hideItemScansButton || scanHistoryModal}
      {hideViewItemButton || viewItemButton}

      <Authorized role="CREATE-COLLECTION-TASKS">
        <ItemCollectionTaskDropdownButton item={item} update={update} />
      </Authorized>

      {/* TODO */ true || hideFloatItemButton || floatItemButton}
      {/* TODO */ true || hideInventoryButton || inventoryButton}

      <Authorized role="DEVELOPER">
        <ItemJSON item={item} />
      </Authorized>
    </>
  );
};

export default ItemDetailsButtons;
