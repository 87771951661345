import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';

import { Button } from 'react-bootstrap';

import OnClickModal from '../layout/OnClickModal';
import RFIDReaderForm from './RFIDReaderForm';

const AddButton = () => {
  const { t } = useTranslation();

  return (
    <Button size="sm">
      {t('Add reader')}
    </Button>
  );
};

const AddRFIDReaderButton = ({ update }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const onSubmit = async data => {
    await api.post('/rfid/readers', data);

    update();

    setForceClose(true);
  };

  return (
    <OnClickModal
      title={t('Add reader')}
      button={<AddButton />}
      forceClose={forceClose}
      onOpen={() => setForceClose(false)}
      closeButton
    >
      <RFIDReaderForm onSubmit={onSubmit} />
    </OnClickModal>
  );
};

export default AddRFIDReaderButton;
