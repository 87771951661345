import MassUpdateView from './View';
import MassUpdateCreate from './Create';
import MassUpdateOperationsList from './OperationsList';
import MassUpdateCommonForm from './CommonForm';
import MassUpdateBibForm from './BibForm';
import MassUpdateItemForm from './ItemForm';
import MassUpdateCreateFromQueryButton from './CreateFromQueryButton';

export * from './forms';

export {
  MassUpdateView,
  MassUpdateCreate,
  MassUpdateOperationsList,
  MassUpdateCommonForm,
  MassUpdateBibForm,
  MassUpdateItemForm,
  MassUpdateCreateFromQueryButton
};

export type RecordType = 'b' | 'i';

export interface CommonOperation {
  queryJSON: string | null;
}

export interface BibOperation {
  // TODO
}

export interface ItemOperation {
  // TODO
}

export type Operation =
  | { recordType: 'b' } & CommonOperation & BibOperation
  | { recordType: 'i' } & CommonOperation & ItemOperation;
