import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatItemStatus, setItemStatus, setCity } from '../../services/item';
import { incrementStatistic } from '../../services/inventory';

import { Button, Spinner } from 'react-bootstrap';

const InvalidItemStatusFix = ({ item }) => {
  const { t } = useTranslation();

  const [fixing, setFixing] = useState(false);
  const [fixed, setFixed] = useState(false);

  const fixItemStatus = async () => {
    setFixing(true);

    if (item.fixedFieldItemStatus === 'p' && item.fixedFieldCity === 'd') {
      await setCity(item.sierraItemRecordId, item.fixedFieldLocation[0]);
      incrementStatistic('undeletedItems');
    }

    if (item.fixedFieldItemStatus === 'm') {
      incrementStatistic('missingItemsFound');
    }

    await setItemStatus(item.sierraItemRecordId, '-');
    incrementStatistic('statusChanges');

    setFixing(false);
    setFixed(true);
  };

  const fixString = fixed
    ? `${t('item status set to')} ${t('HYLLYSSÄ')}`
    : `${t('set item status to')} ${t('HYLLYSSÄ')}`;

  const unDeleteString = (fixed ? t('Item undeleted') : t('Undelete item'));

  return (
    <>
      <span style={{ marginRight: '6px' }}>
        {t('Invalid item status')} {formatItemStatus(item, t)}

        {item.fixedFieldCity === 'd' ? ` ${t('and city')} d ${t('POISTETTU')}` : ''}
      </span>

      {(item.fixedFieldItemStatus !== '-') && (
        <div>
          {fixing && (
            <Spinner size="sm" animation="border" style={{ marginRight: '4px' }} />
          )}

          <Button
            size="sm"
            variant="link"
            disabled={fixed}
            onClick={fixItemStatus}
            style={{ padding: 0, border: 0, verticalAlign: 'baseline' }}
          >
            [{(item.fixedFieldItemStatus === 'p' && item.fixedFieldCity === 'd') ? unDeleteString : fixString}]
          </Button>
        </div>
      )}
    </>
  );
};

export default InvalidItemStatusFix;
