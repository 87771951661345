import { getMargin } from './useMargin';

import type { MarginValue } from './useMargin';

const useMargins = (
  leftMargin?: MarginValue,
  rightMargin?: MarginValue,
  topMargin?: MarginValue,
  bottomMargin?: MarginValue
): React.CSSProperties => ({
  marginLeft: getMargin(leftMargin),
  marginRight: getMargin(rightMargin),
  marginTop: getMargin(topMargin),
  marginBottom: getMargin(bottomMargin)
});

export default useMargins;
