import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import useQueryParamState from '../../hooks/useQueryParamState';

// import { hasRole } from '../../services/auth';

import ItemSearchTable from '../item-searches/ItemSearchTable';
import Loading from '../api/Loading';

const ItemSearchPage = ({ location, locationName }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [enabledSections, setEnabledSections] = useQueryParamState(
    'enabledSections',
    ['a']
  );

  const [enabledMaterialType, setEnabledMaterialType] = useQueryParamState(
    'enabledMaterialType',
    'all'
  );

  const [enabledAllocationTypes, setEnabledAllocationTypes] = useQueryParamState(
    'enabledAllocationTypes', ['1', '2']);

  // const [enabledAllocationTypes, setEnabledAllocationTypes] = useQueryParamState(
  //   'enabledAllocationTypes',
  //   hasRole('CREATE-COLLECTION-TASKS')
  //     ? ['all']
  //     : ['1', '2'] // only bib & item holds
  // );

  const [sections, loadingSections, /* error */, /* headers */, updateSections] =
    useAPI('GET', `/item-searches/${location}/sections`);

  const [materialTypes, loadingMaterialTypes, /* error */, /* headers */, updateMaterialTypes] =
    useAPI('GET', `/item-searches/${location}/material-types`, {
      params: {
        enabledSections
      }
    }, [enabledSections]);

  const [allocationTypes, loadingAllocationTypes, /* error */, /* headers */, updateAllocationTypes] =
    useAPI('GET', `/item-searches/${location}/allocation-types`, {
      params: {
        enabledSections,
        enabledMaterialType
      }
    }, [enabledSections, materialTypes]);

  const [allocations, loadingAllocations, /* error */, /* headers */, updateAllocations] = useAPI('GET', `/item-searches/${location}`, {
    params: {
      enabledSections,
      enabledMaterialType,
      enabledAllocationTypes
    }
  }, [enabledSections, enabledMaterialType, enabledAllocationTypes]);

  const update = useCallback(() => {
    updateSections();
    updateMaterialTypes();
    updateAllocationTypes();
    updateAllocations();
  }, [updateSections, updateMaterialTypes, updateAllocationTypes, updateAllocations]);

  useEffect(() => {
    // update every 10 minutes
    const interval = setInterval(update, 60 * 1000 * 10);

    return () => clearInterval(interval);
  }, [update]);

  useEffect(() => setLoading(true), [enabledSections, enabledMaterialType, enabledAllocationTypes]);

  if (loading && !loadingAllocations && !loadingMaterialTypes) {
    setLoading(false);
  }

  if (sections === null) {
    return (loadingSections ? <Loading /> : t('Could not fetch sections'));
  }

  if (materialTypes === null) {
    return (loadingMaterialTypes ? <Loading /> : t('Could not fetch material types'));
  }

  if (allocationTypes === null) {
    return (loadingAllocationTypes ? <Loading /> : t('Could not fetch allocation types'));
  }

  if (allocations === null) {
    return (loadingAllocations ? <Loading /> : t('Could not fetch item searches'));
  }

  return (
    <ItemSearchTable
      allocations={allocations}
      update={update}
      location={location}
      loading={loading}
      enablePagination

      sections={sections}
      enabledSections={enabledSections}
      setEnabledSections={setEnabledSections}

      materialTypes={materialTypes}
      enabledMaterialType={enabledMaterialType}
      setEnabledMaterialType={setEnabledMaterialType}

      allocationTypes={allocationTypes}
      enabledAllocationTypes={enabledAllocationTypes}
      setEnabledAllocationTypes={setEnabledAllocationTypes}
    />
  );
};

export default ItemSearchPage;
