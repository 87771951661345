import config from '../legacy/config';

const ensureSlash = (str: string): string =>
  str.startsWith('/')
    ? str
    : `/${str}`;

const getURL = (path: string): string =>
  config.api.urlV2 + ensureSlash(path);

export default getURL;
