import { Button } from '.';
import { Icon } from '..';

import type { Props as IconProps } from '../Icon';
import type { Props as ButtonProps } from './Button';

export type IconButtonProps = IconProps & ButtonProps;

const IconButton: React.FC<IconButtonProps> = ({ icon, color, style, ...props }) => (
  <Button variant="link" style={style} {...props}>
    <Icon icon={icon} color={color} />
  </Button>
);

export default IconButton;
