import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';
import config from '../../config';

import { Button, Form, Spinner } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';

const BestsellerCountForm = ({ shipment, bestsellers, bestsellerCount, setBestsellerCount }) => {
  const { t } = useTranslation();

  const onChange = event => {
    const value = Number(event.target.value);

    if (
      value >= 0 &&
      value >= bestsellers.length &&
      value <= shipment.copiesOnShipment
    ) {
      setBestsellerCount(value);
    }
  };

  return (
    <Form onSubmit={event => event.preventDefault()} inline>
      <Form.Label>
        {t('Bestseller Count')}
      </Form.Label>

      <Form.Control
        type="number"
        size="sm"
        value={bestsellerCount}
        onChange={onChange}
        style={{ marginLeft: '6px' }}
      />
    </Form>
  );
};

const BestsellersForm = ({ addBestseller }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);

  const onSubmit = event => {
    event.preventDefault();

    setError(addBestseller(event.target.barcode.value) || null);

    event.target.barcode.value = '';
  };

  return (
    <Form onSubmit={onSubmit} inline>
      <Form.Label>
        {t('Add Bestseller')}
      </Form.Label>

      <Form.Control
        name="barcode"
        size="sm"
        placeholder={t('Barcode')}
        style={{ marginLeft: '6px' }}
      />

      <Button
        type="submit"
        variant="primary"
        size="sm"
        style={{ marginLeft: '4px', marginRight: '6px' }}
      >
        {t('Add')}
      </Button>

      <span style={{ color: 'red' }}>
        {error}
      </span>
    </Form>
  );
};

const GenreForm = ({ genre, setGenre }) => {
  const { t } = useTranslation();

  const onChange = value => {
    if (value[0]) {
      if (typeof value[0] === 'object') {
        setGenre(value[0].label.toUpperCase());
      } else {
        setGenre(value[0].toUpperCase());
      }
    }
  };

  const onInputChange = value => setGenre(value.toUpperCase());

  return (
    <Form onSubmit={event => event.preventDefault()} inline>
      <Form.Label>
        {t('Genre')}
      </Form.Label>

      <Typeahead
        id="genre-typeahead"
        size="sm"
        placeholder={t('Genre')}
        style={{ marginLeft: '6px' }}
        selected={[genre || '']}
        options={config.genresAlphabetical}
        onChange={onChange}
        onInputChange={onInputChange}
        minLength={1}
        newSelectionPrefix=""
        allowNew
        flip
      />
    </Form>
  );
};

const ReceivedItemsConfiguration = ({ shipment, onSave }) => {
  const { t } = useTranslation();

  const getBestsellers = useCallback(() => (
    shipment.receivedItems
      .filter(receivedItem => receivedItem.genre === 'BESTSELLER')
      .map(receivedItem => receivedItem.barcode)
  ), [shipment.receivedItems]);

  const getGenre = useCallback(() => {
    for (const receivedItem of shipment.receivedItems) {
      if (receivedItem.genre && receivedItem.genre !== 'BESTSELLER') {
        return receivedItem.genre;
      }
    }
  }, [shipment.receivedItems]);

  const [bestsellerCount, setBestsellerCount] = useState(shipment.bestsellerCount);
  const [bestsellers, setBestsellers] = useState(getBestsellers());
  const [genre, setGenre] = useState(getGenre());
  const [creatingItems, setCreatingItems] = useState(false);
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(d => d >= 10 ? 0 : d + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const addBestseller = useCallback(barcode => {
    let receivedItemExists = false;

    for (const receivedItem of shipment.receivedItems) {
      if (receivedItem.barcode === barcode) {
        receivedItemExists = true;

        break;
      }
    }

    if (!receivedItemExists) return t('Item not on shipment!');
    else if (bestsellers.indexOf(barcode) !== -1) return t('Item has already been read!');
    else setBestsellers(b => [...b, barcode]);
  }, [shipment.receivedItems, bestsellers, t]);

  const removeBestseller = useCallback(barcode => {
    setBestsellers(b => b.filter(_b => _b !== barcode));
  }, []);

  const saveItems = useCallback(async (triggerOnSave = true) => {
    await api.put(`/shipments/${shipment.id}`, {
      bestsellerCount,
      bestsellers,
      genre
    });

    if (triggerOnSave && typeof onSave === 'function') onSave();
  }, [onSave, bestsellerCount, bestsellers, genre, shipment.id]);

  const saveAndCreateItems = useCallback(async () => {
    setCreatingItems(true);
    setDots(0);

    await saveItems(false);
    await api.post(`/shipments/${shipment.id}/create-items`);

    setCreatingItems(false);

    if (typeof onSave === 'function') onSave();
  }, [saveItems, onSave, shipment.id]);

  const bestsellerListDiv = (
    <div style={{ marginBottom: '6px' }}>
      <strong>{bestsellers.length} / {bestsellerCount}</strong> {t('bestsellers read')}

      {bestsellers.length > 0 && ':'}

      <ul>
        {bestsellers.map(barcode => (
          <li key={barcode}>
            {barcode}

            <Button
              variant="link"
              size="sm"
              onClick={() => removeBestseller(barcode)}
              style={{ padding: 0, border: 0, verticalAlign: 'text-bottom' }}
            >
              <X />
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );

  const canHaveBestsellers = bestsellerCount > 0 || (
    shipment.Order && shipment.Order.locations.split(',').every(l => l.length > 3 && ['a', 'm'].includes(l[3]))
  );

  const bestsellersDiv = (
    <div style={{ marginBottom: '10px' }}>
      <BestsellerCountForm
        shipment={shipment}
        bestsellers={bestsellers}
        bestsellerCount={bestsellerCount}
        setBestsellerCount={setBestsellerCount}
      />

      {bestsellerCount > 0 && bestsellerListDiv}

      {(bestsellers.length < bestsellerCount) && <BestsellersForm addBestseller={addBestseller} />}
    </div>
  );

  return (
    <>
      {canHaveBestsellers && bestsellersDiv}
      {shipment.comment && <div style={{ marginBottom: '10px' }}><b>{t('Comment')} </b> {shipment.comment}</div>}
      <GenreForm
        genre={genre}
        setGenre={setGenre}
      />

      <div style={{ marginTop: '20px' }}>
        <Button
          variant="primary"
          size="sm"
          onClick={saveAndCreateItems}
          disabled={bestsellers.length !== bestsellerCount}
        >
          {t('Save and Create Items')}
        </Button>

        <Button
          variant="secondary"
          size="sm"
          style={{ marginLeft: '6px' }}
          onClick={saveItems}
        >
          {t('Save')}
        </Button>

        {creatingItems && (
          <div style={{ marginTop: '10px' }}>
            <Spinner size="sm" animation="border" /> {t('Creating items') + '.'.repeat(dots)}
          </div>
        )}
      </div>
    </>
  );
};

export default ReceivedItemsConfiguration;
