import validateFormItem from './validateFormItem';
import validateUrl from './url';
import validatePassword from './password';
import validateEmail from './email';
import validateUsername from './username';
import validateJSON from './json';

export {
  validateFormItem,
  validateUrl,
  validatePassword,
  validateEmail,
  validateUsername,
  validateJSON
};

interface Item {
  message: string;
}

interface Other {
  otherName: string;
}

export type FormItemValidation =
  | { type: 'has-changed' } & Item
  | { type: 'same-as' } & Item & Other
  | { type: 'different-from' } & Item & Other
  | { type: 'url' } & Item
  | { type: 'password' } & Item
  | { type: 'email' } & Item
  | { type: 'username' } & Item
  | { type: 'json' } & Item;
