export type MarginSide = 'left' | 'right' | 'top' | 'bottom';
export type MarginValue = 'auto' | number;

export const getMargin = (value?: MarginValue): string =>
  (typeof value !== 'string')
    ? `${value ?? 0}px`
    : value;

const useMargin = (side: MarginSide, value: MarginValue): React.CSSProperties => {
  const key = ['margin', side[0].toUpperCase(), side.slice(1)].join('');

  return { [key]: value };
};

export default useMargin;
