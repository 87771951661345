import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import ReservationsReceipt from './ReservationsReceipt';
import ReservationsTable from './ReservationsTable';
import Loading from '../api/Loading';

const Reservations = ({ patronBarcode, enablePagination, enableFilters }) => {
  const { t } = useTranslation();

  const [reservations, loading, error] = useAPI('GET', '/reservations' + (
    patronBarcode
      ? `/${patronBarcode}`
      : ''
  ), {}, [patronBarcode]);

  const reservationsReceiptDiv = (
    <div style={{ marginBottom: '10px' }}>
      <ReservationsReceipt reservations={reservations} />
    </div>
  );

  const reservationsTable = (
    <ReservationsTable
      reservations={reservations}
      enablePagination={enablePagination}
      enableFilters={enableFilters}
      noPatronBarcodeColumn={!!patronBarcode}
    />
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch reservations');
  }

  return (
    <>
      {(patronBarcode && reservations.length > 0) && reservationsReceiptDiv}

      {
        reservations.length === 0
          ? <strong>{t('No reservations for patron')} {patronBarcode}</strong>
          : reservationsTable
      }
    </>
  );
};

export default Reservations;
