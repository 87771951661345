import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';

import OnClickModal from '../layout/OnClickModal';
import CreateAccountForm from './CreateAccountForm';

const CreateAccountButton = ({ update }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const button = (
    <Button size="sm">
      {t('Create Account')}
    </Button>
  );

  return (
    <OnClickModal
      title={t('Create Account')}
      button={button}
      forceClose={forceClose}
      onOpen={() => setForceClose(false)}
      closeButton
    >
      <CreateAccountForm
        update={update}
        onSuccessfulSubmit={() => {
          update();
          setForceClose(true);
        }}
      />
    </OnClickModal>
  );
};

export default CreateAccountButton;
