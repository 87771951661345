import { useTranslation } from 'react-i18next';

import useMARC from '../../hooks/useMARC';

const BibMARC = ({ bibId }) => {
  const { t } = useTranslation();

  const [marc, loading, error] = useMARC(bibId);

  if (loading) {
    return t('Loading MARC...');
  }

  if (error) {
    return t('Could not fetch MARC');
  }

  return (
    <>
      <h3>{t('MARC')}</h3>

      <pre>{marc}</pre>
    </>
  );
};

export default BibMARC;
