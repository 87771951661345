import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { isSearchTermAllowed } from '../../services/search';

import { Button, Form, Row, Col } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

import BottomTooltip from '../layout/BottomTooltip';
import ClickSelect from '../api/ClickSelect';

const EditSearchButton = ({ toggleEditing, marginTop }) => {
  const { t } = useTranslation();

  return (
    <BottomTooltip content={t('Edit search term')}>
      <Button
        variant="link"
        onClick={toggleEditing}
        style={{
          padding: 0,
          border: 0
        }}
      >
        <PencilSquare size={24} style={{ marginTop: '-8px' }} />
      </Button>
    </BottomTooltip>
  );
};

const NormalTitle = ({ query, toggleEditing }) => {
  const { t } = useTranslation();

  return (
    <h3>
      {t('Search')}: <ClickSelect>{query}</ClickSelect>

      &nbsp;<EditSearchButton toggleEditing={toggleEditing} />
    </h3>
  );
};

const EditingTitle = ({ query, setQuery, toggleEditing }) => {
  const { t } = useTranslation();

  const [editedQuery, setEditedQuery] = useState(query);
  const [updateFiltersTimeout, setUpdateFiltersTimeout] = useState(null);

  useEffect(() => {
    setUpdateFiltersTimeout(u => {
      if (u !== null) clearTimeout(u);

      return setTimeout(() => {
        if (isSearchTermAllowed(editedQuery)) {
          setQuery(editedQuery);
          setUpdateFiltersTimeout(null);
        }
      }, 2000);
    });
  }, [editedQuery, setQuery]);

  const formOnSubmit = useCallback(event => {
    event.preventDefault();

    if (isSearchTermAllowed(editedQuery)) {
      toggleEditing();

      setQuery(editedQuery);

      if (updateFiltersTimeout !== null) {
        clearTimeout(updateFiltersTimeout);
        setUpdateFiltersTimeout(null);
      }
    }
  }, [editedQuery, setQuery, toggleEditing, updateFiltersTimeout]);

  return (
    <h3>
      <Row>
        <Col xs="auto" style={{ paddingRight: 0 }}>
          {t('Search')}:
        </Col>

        <Col xs="auto" style={{ paddingRight: 0, paddingLeft: '8px' }}>
          <Form onSubmit={formOnSubmit}>
            <Form.Control
              size="sm"
              value={editedQuery}
              onChange={event => setEditedQuery(event.target.value)}
              style={{ marginTop: '2px' }}
              autoFocus
            />
          </Form>
        </Col>

        <Col xs="auto" style={{ paddingLeft: '2px' }}>
          <EditSearchButton toggleEditing={toggleEditing} />
        </Col>
      </Row>
    </h3>
  );
};

const SearchTitle = ({ query, setQuery }) => {
  const [editing, setEditing] = useState(false);

  const toggleEditing = () => setEditing(e => !e);

  if (!editing) return <NormalTitle query={query} toggleEditing={toggleEditing} />;
  else return <EditingTitle query={query} setQuery={setQuery} toggleEditing={toggleEditing} />;
};

export default SearchTitle;
