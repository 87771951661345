import useAPI from './useAPI';

const useSignum = (itemId) => {
  const [data, loading, error] = useAPI('GET', '/items/signum', {
    params: {
      sierraItemRecordId: itemId
    }
  }, [itemId]);

  if (!data) return [null, null, loading, error];
  else return [data.signum, data.image, loading, error];
};

export default useSignum;
