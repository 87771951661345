import { useTranslation, T } from '../common/i18n';
import { NavLink, ExternalLink } from '../common/navigation';
import { Center, ClickPopover, Heading, useMargins } from '../common/layout';

import { LinksView } from '../links';

const LinkServiceAd: React.FC = () => (
  <Center>
    <Heading level={5} style={useMargins(0, 0, 6, 0)}>
      <ExternalLink to="https://linkit.lirp.fi">
        <T>Juuri Link Service</T>
      </ExternalLink>
    </Heading>
  </Center>
);

const LinksButton: React.FC = ({ ...props }) => (
  <NavLink {...props}>
    <T>Links</T>
  </NavLink>
);

const LinksPopover: React.FC = () => (
  <ClickPopover
    id="click-popover-links"
    title={useTranslation('Links')}
    button={<LinksButton />}
    closeButton
  >
    <LinkServiceAd />

    <hr />

    <LinksView />
  </ClickPopover>
);

export default LinksPopover;
