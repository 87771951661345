import { useT } from '../../../common/i18n';

import type { APITableFormatterHook } from '..';

// TODO
const useDateTimeRelativeFormatter: APITableFormatterHook = () => {
  const t = useT();

  return (cell): any => cell ?? t('never');
};

export default useDateTimeRelativeFormatter;
