import { useTranslation } from '../../common/i18n';

import { useLinkGroupForm } from '..';

import type { LinkGroup } from '../../api/models';
import type { Dialog } from '../../layout/dialogs';

const useEditLinkGroupDialog = (group: LinkGroup): Dialog => {
  if (typeof group.id !== 'number') {
    throw new Error(`Invalid group id ${group.id}`);
  }

  return {
    type: 'api-form-put',
    id: `edit-group-${group.id}`,
    title: useTranslation('Edit Group'),
    path: '/links/groups',
    recordId: group.id,
    form: useLinkGroupForm(group)
  };
};

export default useEditLinkGroupDialog;
