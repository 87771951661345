import { useGetLocationName } from '../../../sierra';

import type { SelectOption } from '..';
import type { Location3 } from '../../../sierra';

export const useLocationOptions = (locations: Location3[]): SelectOption[] => {
  const getLocationName = useGetLocationName();

  return locations.map(location => ({
    label: getLocationName(location),
    value: location
  }));
};
