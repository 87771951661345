import { useState, useEffect } from 'react';
import { Provider } from 'react-redux';

import config from '../../config';
import { hasRole } from '../../services/auth';
import api from '../../services/api';
import useAPI from '../../hooks/useAPI';
import { setPreferences } from '../../services/preferences';
import { store } from '../../../state';

import { BrowserRouter } from 'react-router-dom';

import Navigation from '../nav/Navigation';
import AppContainer from './AppContainer';
import Broadcasts from './Broadcasts';

import { DialogView } from '../../../layout/dialogs';
import { ToastView } from '../../../layout/toasts';

const AppAuthenticated = () => {
  const [navbarHeight, setNavbarHeight] = useState(null);

  const [
    notificationBubbles,
    /* loading */,
    /* error */,
    /* headers */,
    updateNotificationBubbles
  ] = useAPI('GET', '/notifications/bubbles');

  useEffect(() => {
    const interval = setInterval(() => {
      updateNotificationBubbles();
    }, 15 * 60 * 1000);

    return () => clearInterval(interval);
  }, [updateNotificationBubbles]);

  useEffect(() => {
    if (hasRole('SHARED')) {
      setPreferences(config.sharedAccountPreferences);
    } else {
      api.get('/preferences').then(setPreferences);
    }
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Broadcasts />

        <Navigation
          setNavbarHeight={setNavbarHeight}
          notificationBubbles={notificationBubbles || {}}
        />

        {navbarHeight && <AppContainer navbarHeight={navbarHeight} />}

        <DialogView />
        <ToastView />
      </BrowserRouter>
    </Provider>
  );
};

export default AppAuthenticated;
