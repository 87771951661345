const TitledDiv = ({ title, children }) => (
  <>
    <div>
      <strong>
        {title}
      </strong>
    </div>

    <div>
      {children}
    </div>
  </>
);

export default TitledDiv;
