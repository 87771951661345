import { CaretUpSquareFill, CaretDownSquare } from 'react-bootstrap-icons';

type Renderer = (t: any) => React.ReactNode;

interface Options {
  renderer: Renderer;
  onlyOneExpanding: boolean;
  showExpandColumn: boolean;
  expandByColumnOnly: boolean;
  hideExpandHeaderColumn: boolean;
  expandColumnRenderer: (props: { expanded: boolean }) => React.ReactNode;
  expanded?: boolean[];
}

const expandRow = (renderer: Renderer, expanded = false, onlyOneExpanding = true): Options => {
  const options: Options = {
    renderer,
    onlyOneExpanding,
    showExpandColumn: true,
    expandByColumnOnly: true,
    hideExpandHeaderColumn: true,
    expandColumnRenderer: ({ expanded }: { expanded: boolean }): React.ReactNode => (
      <div className="expand-row-caret">
        {expanded ? <CaretUpSquareFill /> : <CaretDownSquare />}
      </div>
    )
  };

  if (expanded) options.expanded = [expanded];

  return options;
};

export default expandRow;
