import useAPI from './useAPI';

const useLocationInfos = locationCodes => {
  const [locationInfos, loading, error] = useAPI('GET', '/locations');

  if (loading || error || !locationInfos) {
    // eslint-disable-next-line no-console
    if (error) console.error('Could not fetch location infos:', error);

    return locationCodes.map(locationCode => ({
      code: locationCode,
      name: null
    }));
  } else {
    return locationCodes.map(locationCode => (
      locationInfos.find(locationInfo => locationInfo.code === locationCode) ??
      { code: locationCode, name: null }
    ));
  }
};

export default useLocationInfos;
