import { Container, Center, BannerImage } from '../common/layout';
import { T } from '../common/i18n';

import { Authorized } from '../users';
import { DeveloperStatistics } from '../developer';
import { Version } from '../application';
import { APIStatus } from '../api';

const Header: React.FC = () => (
  <>
    <Center>
      <BannerImage />
    </Center>

    <Center>
      <h1>
        <T>Welcome!</T>
      </h1>
    </Center>
  </>
);

const Content: React.FC = () =>
  <DeveloperStatistics />;

const Footer: React.FC = () => (
  <>
    <Center>
      <Version />
    </Center>

    <Center>
      <APIStatus />
    </Center>
  </>
);

const Dashboard: React.FC = () => (
  <Container>
    <Header />

    <hr />

    <Authorized role="DEVELOPER">
      <Content />

      <hr />
    </Authorized>

    <Footer />
  </Container>
);

export default Dashboard;
