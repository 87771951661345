import { useEffect } from 'react';

import config from '../../legacy/config';

import { Heading } from '.';

const VisiblePageTitle: React.FC<{
  title: string,
  children?: JSX.Element
}> = ({ title, children }) => (
  children ??
  title
    ? <Heading level={3}>{title}</Heading>
    : null
);

const PageTitle: React.FC<{
  title: string,
  children?: JSX.Element,
  hidden?: boolean
}> = ({ title, hidden, children }) => {
  if (title.length === 0) {
    throw new Error('Page title cannot be empty!');
  }

  useEffect(() => {
    const dev = (process.env.REACT_APP_DEV_MODE ? ' [dev]' : '');

    document.title = `${title} - ${config.appName}` + dev;

    return (): void => { document.title = config.appName; };
  }, [title]);

  return hidden
    ? null
    : <VisiblePageTitle title={title}>{children}</VisiblePageTitle>;
};

export default PageTitle;
