import { useTranslation } from '../../common/i18n';

import type { LinkGroup } from '../../api/models';
import type { Dialog } from '../../layout/dialogs';

const useDeleteLinkGroupDialog = (group: LinkGroup): Dialog => {
  if (!group.id) {
    throw new Error(`Invalid group id ${group.id}`);
  }

  return {
    type: 'api-delete',
    id: `delete-group-${group.id}`,
    path: '/links/groups',
    recordId: group.id,
    content: `${useTranslation('Delete group')} "${group.name}"?`
  };
};

export default useDeleteLinkGroupDialog;
