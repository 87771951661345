import BootstrapTabs from 'react-bootstrap/Tabs';

const useStyle = (style?: React.CSSProperties): React.CSSProperties => ({
  marginBottom: '10px',
  ...style
});

const Tabs: React.FC<{
  defaultActiveKey: string,
  style?: React.CSSProperties
}> = ({ defaultActiveKey, style, children, ...props }) => (
  <BootstrapTabs
    defaultActiveKey={defaultActiveKey}
    style={useStyle(style)}
    {...props}
  >
    {children}
  </BootstrapTabs>
);

export default Tabs;
