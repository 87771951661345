import { isBarcodeValid } from './item';

export const isItemId = str => {
  return /^\.?i[0-9]{7}[0-9]*[0-9xa]?$/.test(str.toLowerCase());
};

export const isBibId = str => {
  return /^\.?b[0-9]{7}[0-9]*[0-9xa]?$/.test(str.toLowerCase());
};

export const isOrderId = str => {
  return /^\.?o[0-9]{7}[0-9]*[0-9xa]?$/.test(str.toLowerCase());
};

export const isItemBarcode = str => {
  return isBarcodeValid(str);
};

export const isPatronBarcode = str => {
  return /^2[0-9]{13}$/.test(str);
};

export const onBarcodeScanned = result => {
  if (result.text.startsWith(`https://${window.location.hostname}`)) window.location = result.text;
  else window.location = `/items/view/${result}`;
};

export const onRFIDScanned = result => {
  window.location = `/items/view/${result}`;
};

export const isSearchTermAllowed = searchTerm => {
  const normalizeString = str => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\W/g, '').toLowerCase();
  };

  const normalizedSearchTerm = normalizeString(searchTerm);

  const blockedSearchTerms = [
    'the', 'th', 'ma', 'ha', 'ka',
    'la', 'sa', 'le', 'he', 'ko',
    'pa', 'st', 'so', 'ba', 'va',
    'si', 'ro', 'mo', 'li', 'co',
    'ta', 'be', 'su', 'ra', 'ca',
    'mi', 'ho', 'de', 'ja', 'pe',
    'me', 'al'
  ];

  return (normalizedSearchTerm.length > 1 && !blockedSearchTerms.includes(normalizedSearchTerm));
};
