import { useEffect } from 'react';

import { useSelector, useDispatch } from '../../../state';

import {
  fetchAll as fetchAllBroadcasts,
  selectActive as selectActiveBroadcasts
} from '../../../state/slices/broadcasts';

import BroadcastAlert from '../layout/BroadcastAlert';
import Authorized from '../auth/Authorized';

const updateIntervalSeconds = 60;

const Broadcasts = () => {
  const dispatch = useDispatch();
  const broadcasts = useSelector(selectActiveBroadcasts);

  useEffect(() => {
    const updateBroadcasts = () => dispatch(fetchAllBroadcasts());
    const interval = setInterval(updateBroadcasts, updateIntervalSeconds * 1000);

    updateBroadcasts();

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Authorized role="USER">
      {broadcasts !== null && broadcasts.map(({ id, variant, content }) => (
        <BroadcastAlert
          key={id}
          id={id}
          variant={variant}
          content={content}
        />
      ))}
    </Authorized>
  );
};

export default Broadcasts;
