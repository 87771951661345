import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import DynamicView from '../api/DynamicView';
import BibDetails from '../bibs/BibDetails';
import OrderDetails from './OrderDetails';
import ShipmentDetails from './ShipmentDetails';
import Loading from '../api/Loading';

const ShipmentView = ({ shipmentId }) => {
  const { t } = useTranslation();

  const [shipment, loading, error] = useAPI('GET', `/shipments/${shipmentId}`, {}, [shipmentId]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch shipment');
  }

  if (shipment === null) {
    return `${t('No shipment found with ID')} ${shipmentId}`;
  }

  return (
    <DynamicView defaultTab="shipmentDetails">
      <ShipmentDetails
        title={t('Shipment Details')}
        key="shipmentDetails"
        shipment={shipment}
      />

      {shipment.Order && (
        <OrderDetails
          title={t('Order Details')}
          key="orderDetails"
          order={shipment.Order}
        />
      )}

      {shipment.Order && shipment.Order.Bib && (
        <BibDetails
          title={t('Bib Details')}
          key="bibDetails"
          bib={shipment.Order.Bib}
        />
      )}
    </DynamicView>
  );
};

export default ShipmentView;
