import { useT } from '../../common/i18n';

import type { FormItem } from '../../layout/forms';

const usePasswordChangeForm = (): FormItem[] => {
  const t = useT();

  return [
    {
      type: 'password',
      name: 'password',
      label: t('Old password'),
      required: true
    },
    {
      type: 'password',
      name: 'new-password-1',
      label: t('New password'),
      required: true
    },
    {
      type: 'password',
      name: 'new-password-2',
      label: `${t('New password')} (${t('again')})`,
      required: true,
      validation: [
        {
          type: 'same-as',
          otherName: 'new-password-1',
          message: t('Passwords do not match')
        },
        {
          type: 'different-from',
          otherName: 'password',
          message: t('No change in password')
        },
        {
          type: 'password',
          message: t('Password does not meet criteria')
        }
      ]
    }
  ];
};

export default usePasswordChangeForm;
