import { useTranslation } from 'react-i18next';
import { useEffect, useCallback, useState } from 'react';

import OnClickModal from '../layout/OnClickModal';

const RFIDScannerModal = ({ scanButton, onScan }) => {
  const { t } = useTranslation();
  const [serialNumber, setSerialNumber] = useState(null);
  const [scanLog, setScanLog] = useState(['scan log initialized']);

  const [RFIDScannerEnabled, setRFIDScannerEnabled] = useState(false);
  const scan = useCallback(async () => {
    if ('NDEFReader' in window) {
      try {
        const ndef = new window.NDEFReader();

        let abortController = new AbortController();
        // await ndef.scan();
        // setScanLog([...scanLog, 'scan started successfully']);

        const scanstart = async () => {
          abortController = new AbortController();
          await ndef.scan({ signal: abortController.signal });

          abortController.signal.onabort = () => {
            setScanLog(currentItems => [...currentItems, 'scan aborted']);
          };

          ndef.onreadingerror = () => {
            setScanLog(currentItems => [...currentItems, 'scan error']);
          };

          ndef.onreading = event => {
            onReading(event);
            // setScanLog([...scanLog, 'scan reading fired']);
          };
          setScanLog(currentItems => [...currentItems, 'scanstart fired!!!!!!!!!!!']);
        };

        const scanstop = async () => {
          await abortController.abort();
          setScanLog(currentItems => [...currentItems, 'scanstop fired']);
        };

        scanstart();
        window.document.onvisibilitychange = async event => {
          if (window.document.hidden) {
            // all nfc operations are automatically suspended when document is hidden.
            // scanstop();
            // log("nfc skannaus on keskeytetty automaattisest.")
            // setSerialNumber('hidden');
            await scanstop();
            setScanLog(currentItems => [...currentItems, 'window hidden']);
          } else {
            setTimeout(async () => {
              try {
                await scanstart(); // Restart scanning with a slight delay
                setScanLog(currentItems => [...currentItems, 'NFC scanning restarted after wake-up']);
              } catch (error) {
                console.error('Failed to restart NFC scan', error);
                setScanLog(currentItems => [...currentItems, `Error restarting scan: ${error.message}`]);
              };
            }, 1000); // Adjust delay as necessary
          };
        };
      } catch (error) {
        console.log(`Error! Scan failed to start: ${error}.`);
      };
    }
  }, []);

  const onReading = ({ serialNumber }) => {
    setSerialNumber(serialNumber.split(':').reverse().join('').toUpperCase());
    // onScan(serialNumber.split(':').reverse().join('').toUpperCase());
    // setScanLog([...scanLog].push(`scan success: ${serialNumber.split(':').reverse().join('').toUpperCase()}`));
    // write entry to scan log
    setScanLog(currentItems => [...currentItems, `scan success: ${serialNumber.split(':').reverse().join('').toUpperCase()}`]);
  };

  useEffect(() => {
    scan();
  }, [scan]);

  return (
    <OnClickModal
      title={t('Looking for RFID...')}
      button={scanButton}

      onOpen={() => setRFIDScannerEnabled(true)}
      onClose={() => setRFIDScannerEnabled(false)}
    >
      {RFIDScannerEnabled && <h1>{serialNumber}</h1>}
      {scanLog.map((log, index) => {
        return (
          <div key={index}>
            <p>{log}</p>
          </div>
        );
      })}
    </OnClickModal>
  );
};

export default RFIDScannerModal;
