import { useState, useEffect, useCallback } from 'react';

import { useSelector } from '../state/store';
import { selectResourceUpdatedDate } from '../state/slices/api';

import { fetchData, getResourceForPath } from '.';

import type {
  AxiosResponseHeaders,
  CancelTokenSource
} from 'axios';

import type { Params } from '.';

type Headers = AxiosResponseHeaders | null;
type Total = number | null;
type RequestError = Error | null;
type CancelToken = CancelTokenSource | null;

interface ReturnType<T> {
  data: T | null,
  total: Total,
  loading: boolean,
  error: RequestError,
  headers: Headers,
  update: () => void
}

const useGet = <T>(
  path: string,
  params?: Params
): ReturnType<T> => {
  if (path.includes('undefined')) {
    // eslint-disable-next-line no-console
    console.error('GET path contains undefined:', path);
  }

  const [data, setData] = useState<T | null>(null);
  const [total, setTotal] = useState<Total>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<RequestError>(null);
  const [headers, setHeaders] = useState<Headers>(null);
  const [cancelToken, setCancelToken] = useState<CancelToken>(null);

  const resourceUpdatedDate = useSelector(selectResourceUpdatedDate(getResourceForPath(path)));

  const update = useCallback(() => {
    // Cancel previous request
    cancelToken?.cancel('Operation canceled by user');

    setError(null);

    fetchData('GET', path, params ?? {}, {
      setData,
      setTotal,
      setLoading,
      setError,
      setHeaders,
      setCancelToken
    });
  }, [cancelToken, params, path]);

  useEffect(() => {
    update();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceUpdatedDate]);

  return { data, total, loading, error, headers, update };
};

export default useGet;
