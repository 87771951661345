import { useTranslation } from 'react-i18next';

import api from '../../services/api';

import { Form, Button } from 'react-bootstrap';

const FindBibByBarcode = ({ onSubmit }) => {
  const { t } = useTranslation();

  if (!onSubmit) throw new Error('FindBibByBarcode not given onSubmit!');

  const onFormSubmit = async event => {
    event.preventDefault();

    const bib = await api.get(`/items/barcode/${event.target.barcode.value}/bib`);

    onSubmit(bib);

    event.target.barcode.value = '';
  };

  return (
    <>
      <h5>{t('Find by Barcode')}</h5>

      <Form onSubmit={onFormSubmit} inline>
        <Form.Control
          name="barcode"
          size="sm"
          placeholder={t('Enter barcode...')}
          style={{ marginRight: '6px' }}
        />

        <Button
          size="sm"
          type="submit"
        >
          {t('Search')}
        </Button>
      </Form>
    </>
  );
};

export default FindBibByBarcode;
