import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getMarkAsMissingProblem } from '../services/item';

const fetchProblem = async (item, { t, setProblem, setLoading }) => {
  setLoading(true);

  const problem = await getMarkAsMissingProblem(item, t);

  setProblem(problem);
  setLoading(false);
};

// Returns array [problem, loading]
const useMarkAsMissingProblem = item => {
  const { t } = useTranslation();

  const [problem, setProblem] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProblem(item, { t, setProblem, setLoading });
  }, [item, t]);

  return [problem, loading];
};

export default useMarkAsMissingProblem;
