import { T } from '../common/i18n';
import { Modal, ErrorCondition } from '../common/layout';
import { Button } from '../common/layout/buttons';

import { useDeleteRecord } from '.';

import type { Params } from '.';

const Buttons: React.FC<{
  deleteRecord: () => void,
  deleting: boolean,
  onHide: () => void
}> = ({ deleteRecord, deleting, onHide }) => (
  <>
    <Button variant="secondary" disabled={deleting} onClick={onHide}>
      <T>Cancel</T>
    </Button>

    <Button variant="danger" disabled={deleting} onClick={deleteRecord}>
      <T>Delete</T>
    </Button>
  </>
);

const APIDeleteModal: React.FC<{
  show: boolean,
  onHide: () => void,
  path: string,
  recordId: number,
  title?: string,
  showCloseButton?: boolean,
  params?: Params
}> = ({ path, recordId, show, onHide, title, showCloseButton, params, children }) => {
  const { deleteRecord, deleting, error } = useDeleteRecord(path, recordId, onHide, params);

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title}
      closeButton={showCloseButton}

      footer={
        <Buttons
          deleteRecord={deleteRecord}
          deleting={deleting}
          onHide={onHide}
        />
      }
    >
      {children}

      <ErrorCondition error={error}>
        <T>Could not delete resource</T>:
      </ErrorCondition>
    </Modal>
  );
};

export default APIDeleteModal;
