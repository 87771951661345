import checks from './inventoryChecks';
import api from './api';

export const getProblems = async (tag, t, rules, enabledRules, onFirstProblem, items) => {
  if (!t) throw new Error('No t given');

  const problems = [];

  if (!tag.item) problems.push(t('Item record not found'));

  let firstProblem = false;

  for (const enabledRule of enabledRules) {
    if (typeof checks[enabledRule] !== 'function') {
      throw new Error(`Enabled rule ${enabledRule} has no check!`);
    }

    const result = await checks[enabledRule](tag, t, rules, items);

    if (
      typeof result === 'string' ||
      (
        typeof result === 'object' &&
        result !== null &&
        result !== undefined
      )
    ) {
      if (!firstProblem) {
        if (typeof onFirstProblem === 'function') {
          onFirstProblem();
        }

        firstProblem = true;
      }

      problems.push(result);
    }
  }

  return problems;
};

export const incrementStatistic = async statisticName => (
  await api.put(`/inventory/statistics/increment/${statisticName}`)
);
