import { useTranslation } from 'react-i18next';

import { Form, Button } from 'react-bootstrap';

const QuickBibSearch = () => {
  const { t } = useTranslation();

  const onSubmit = event => {
    event.preventDefault();

    // const searchTerm = event.target.searchTerm.value;
  };

  return (
    <>
      <h5>{t('Bib Search')}</h5>

      <Form onSubmit={onSubmit} inline>
        <Form.Control
          name="searchTerm"
          size="sm"
          placeholder={t('Search bibs...')}
          style={{ marginRight: '6px' }}
        />

        <Button
          size="sm"
          type="submit"
        >
          {t('Search')}
        </Button>
      </Form>
    </>
  );
};

export default QuickBibSearch;
