import axios from 'axios';

import { store } from '../state';
import { getURL, getResourceForPath } from '.';

import type { FormData, FormItem } from '../layout/forms';
import type { Method } from '.';

const submitForm = async (
  method: Method,
  path: string,
  form: FormItem[],
  data: FormData
): Promise<void> => {
  if (path.includes('undefined')) {
    // eslint-disable-next-line no-console
    console.error(method, 'path contains undefined:', path);
  }

  await axios({
    method,
    url: getURL(path),
    data
  });

  store.dispatch({
    type: 'api/resourceUpdated',
    payload: getResourceForPath(path)
  });
};

export default submitForm;
