import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import type { SortOrder } from '.';

interface Props {
  page: number;
  sizePerPage: number;
  sortField: string;
  sortOrder: SortOrder;
}

const useSetSearchParams = (): ((props: Props) => void) => {
  const history = useHistory();

  return useCallback(({ page, sizePerPage, sortField, sortOrder }: Props): void => {
    const searchParams = new URLSearchParams(window.location.search);

    if (page) searchParams.set('page', page.toString());
    if (sizePerPage) searchParams.set('pageSize', sizePerPage.toString());
    if (sortField) searchParams.set('sortField', sortField);
    if (sortOrder) searchParams.set('sortOrder', sortOrder);

    history.replace({
      search: searchParams.toString()
    });
  }, [history]);
};

export default useSetSearchParams;
