import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import sierraGlobals from '../../sierraGlobals';
import { setItemStatus, setCallNumber, setLocation, setBarcode, setCity } from '../../services/item';
import { getUserLocation } from '../../services/user';

import { Button, Form, Spinner } from 'react-bootstrap';

const BarcodeEditor = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Form.Group>
      <Form.Label style={{ marginRight: '6px' }}>
        {t('Barcode')}
      </Form.Label>

      <Form.Control
        name="barcode"
        size="sm"
        defaultValue={item.barcode}
        placeholder={t('Barcode')}
      />
    </Form.Group>
  );
};

const ItemStatusEditor = ({ item }) => {
  const { t } = useTranslation();

  const [itemStatus, setItemStatus] = useState(item.fixedFieldItemStatus);

  return (
    <Form.Group>
      <Form.Label>
        {t('Item Status')}
      </Form.Label>

      {itemStatus !== '-' && (
        <Button
          variant="link"
          size="sm"
          style={{ verticalAlign: 'baseline', paddingRight: 0 }}
          onClick={() => setItemStatus('-')}
        >
          [{t('HYLLYSSÄ')}]
        </Button>
      )}

      {itemStatus !== 'm' && (
        <Button
          variant="link"
          size="sm"
          style={{ verticalAlign: 'baseline' }}
          onClick={() => setItemStatus('m')}
        >
          [{t('KADONNUT')}]
        </Button>
      )}

      <Form.Control
        as="select"
        name="itemStatus"
        size="sm"
        placeholder={t('Item Status')}
        value={itemStatus}
        onChange={event => setItemStatus(event.target.value)}
      >
        {
          Object.keys(sierraGlobals.itemStatuses).map(itemStatus => (
            <option key={itemStatus} value={itemStatus}>
              {itemStatus} {sierraGlobals.itemStatuses[itemStatus]}
            </option>
          ))
        }
      </Form.Control>

      {(itemStatus === 'p' && item.fixedFieldItemStatus !== 'p') && (
        <span style={{ color: 'var(--danger)' }}>
          {t('Item\'s city will not be changed!')}
        </span>
      )}
    </Form.Group>
  );
};

const CallNumberEditor = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Form.Group>
      <Form.Label>
        {t('Call Number')}
      </Form.Label>

      <Form.Control
        name="callNumber"
        size="sm"
        defaultValue={item.callNumber}
        placeholder={t('Call Number')}
      />
    </Form.Group>
  );
};

const LocationEditor = ({ item }) => {
  const { t } = useTranslation();

  const [location, setLocation] = useState(item.fixedFieldLocation);

  const autoFixLocation = `${getUserLocation()}${item.fixedFieldLocation.slice(3)}`;

  return (
    <Form.Group>
      <Form.Label>
        {t('Location')}
      </Form.Label>

      {['e', 'v'].includes(location[0]) && location !== autoFixLocation && (
        <Button
          variant="link"
          size="sm"
          style={{ verticalAlign: 'baseline' }}
          onClick={() => setLocation(autoFixLocation)}
        >
          [{autoFixLocation}]
        </Button>
      )}

      <Form.Control
        name="location"
        size="sm"
        placeholder={t('Location')}
        value={location}
        onChange={event => setLocation(event.target.value)}
      />
    </Form.Group>
  );
};

const CityEditor = ({ item }) => {
  const { t } = useTranslation();

  const [city, setCity] = useState(item.fixedFieldCity);

  const options = Object.entries(sierraGlobals.cities).map(([code, name]) => (
    <option key={code} value={code}>
      {code} {name}
    </option>
  ));

  return (
    <Form.Group>
      <Form.Label>
        {t('City')}
      </Form.Label>

      <Form.Control
        name="city"
        as="select"
        size="sm"
        value={city}
        onChange={event => setCity(event.target.value)}
      >
        {options}
      </Form.Control>

      {(city === 'd' && item.fixedFieldCity !== 'd') && (
        <span style={{ color: 'var(--danger)' }}>
          {t('Item\'s status will not be changed!')}
        </span>
      )}
    </Form.Group>
  );
};

const ItemEditor = ({ item, barcode, itemStatus, callNumber, location, city, secondaryButton, onSave }) => {
  const { t } = useTranslation();

  if (!barcode && !itemStatus && !callNumber && !location && !city) {
    throw new Error('ItemEditor rendered without anything to edit');
  }

  const [saving, setSaving] = useState(false);

  const submitButtonRef = useRef(null);
  const secondaryButtonClicked = useRef(false);

  const onSubmit = async event => {
    event.preventDefault();

    setSaving(true);

    const changes = {
      barcode: false,
      itemStatus: false,
      callNumber: false,
      location: false,
      city: false
    };

    if (barcode) {
      const newBarcode = event.target.barcode.value;

      if (newBarcode !== item.barcode) {
        await setBarcode(item.sierraItemRecordId, newBarcode);
        changes.barcode = true;
      }
    }

    if (itemStatus) {
      const newItemStatus = event.target.itemStatus.value;

      if (newItemStatus !== item.fixedFieldItemStatus) {
        await setItemStatus(item.sierraItemRecordId, newItemStatus);
        changes.itemStatus = true;
      }
    }

    if (callNumber) {
      const newCallNumber = event.target.callNumber.value;

      if (newCallNumber !== item.callNumber) {
        await setCallNumber(item.sierraItemRecordId, newCallNumber);
        changes.callNumber = true;
      }
    }

    if (location) {
      const newLocation = event.target.location.value;

      if (newLocation !== item.fixedFieldLocation) {
        await setLocation(item.sierraItemRecordId, newLocation);
        changes.location = true;
      }
    }

    if (city) {
      const newCity = event.target.city.value;

      if (newCity !== item.fixedFieldCity) {
        await setCity(item.sierraItemRecordId, newCity);
        changes.city = true;
      }
    }

    setSaving(false);

    if (onSave) onSave(changes);

    if (secondaryButtonClicked.current) {
      secondaryButtonClicked.current = false;

      if (typeof secondaryButton.onClick === 'function') {
        secondaryButton.onClick();
      }
    }
  };

  const onSecondaryButtonClick = async () => {
    secondaryButtonClicked.current = true;
    submitButtonRef.current.click();
  };

  return (
    <Form onSubmit={onSubmit}>
      {barcode && <BarcodeEditor item={item} />}
      {callNumber && <CallNumberEditor item={item} />}
      {location && <LocationEditor item={item} />}
      {itemStatus && <ItemStatusEditor item={item} />}
      {city && <CityEditor item={item} />}

      <Button
        size="sm"
        type="submit"
        disabled={saving}
        ref={submitButtonRef}
        style={{ marginRight: '6px' }}
      >
        {t('Save')}
      </Button>

      {secondaryButton && (
        <Button
          size="sm"
          variant="secondary"
          onClick={onSecondaryButtonClick}
        >
          {secondaryButton.text}
        </Button>
      )}

      {saving && (
        <div style={{ marginTop: '10px' }}>
          <Spinner size="sm" animation="border" /> {t('Saving...')}
        </div>
      )}
    </Form>
  );
};

export default ItemEditor;
