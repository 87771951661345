import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';

import { getUserCity } from '../../services/user';

import { Form, Button, Dropdown, DropdownButton, ButtonGroup, Spinner } from 'react-bootstrap';
import { Trash, Magic, ListTask } from 'react-bootstrap-icons';

import OnClickModal from '../layout/OnClickModal';

const BibItemTableCollectionTaskDropdownButton = ({ selectedItems, update }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);
  const [text, setText] = useState('');
  const [deleteComment, setDeleteComment] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const city = getUserCity();

  const areAllSelectedItemsFromUsersCity = () => {
    const result = selectedItems.every(item => item.fixedFieldLocation[0] === city && item.fixedFieldCity === city);
    return result;
  };

  const orderItemDeletion = async () => {
    // send api post for each item
    for (const item of selectedItems) {
      await api.post(`/items/${item.sierraItemRecordId}/order-deletion`, { deleteComment });
    }

    if (typeof update === 'function') update();
  };

  const cancelItemDeletion = async () => {
    // send api post for each item
    for (const item of selectedItems) {
      await api.post(`/items/${item.sierraItemRecordId}/cancel-deletion`);
    }

    if (typeof update === 'function') update();
  };

  const createOtherTask = async () => {
    // send api post for each item
    for (const item of selectedItems) {
      await api.post(`/items/${item.sierraItemRecordId}/order-collection-task`, {
        text
      });
    }

    if (typeof update === 'function') update();
  };

  const orderOtherTaskButton = (
    <Dropdown.Item
      disabled={selectedItems.length === 0 || !areAllSelectedItemsFromUsersCity()}
    >
      <ListTask style={{ marginRight: '6px' }} />

      {t('Order Other Collection Task')} ({selectedItems.length})

    </Dropdown.Item>
  );

  const orderItemDeletionsWithCommentButton = (
    <Dropdown.Item
      disabled={selectedItems.length === 0 || !areAllSelectedItemsFromUsersCity()}
    >
      <Trash style={{ marginRight: '6px' }} />

      {t('Order Item Deletion (with comment)')} ({selectedItems.length})

    </Dropdown.Item>
  );

  return (
    <DropdownButton
      as={ButtonGroup}
      size="sm"
      disabled={selectedItems.length === 0 || !areAllSelectedItemsFromUsersCity()}
      style={{ marginRight: '4px' }}
      title={
        <>
          <Magic style={{
            verticalAlign: 'middle',
            marginTop: '-4px'
          }} />

          &nbsp;

          {t('Collection Tasks')}

        </>
      }
    >
      <Dropdown.Item
        onClick={orderItemDeletion}
        disabled={selectedItems.length === 0 || !areAllSelectedItemsFromUsersCity()}
      >
        <Trash style={{ marginRight: '6px' }} />

        {t('Order Item Deletion')} ({selectedItems.length})

      </Dropdown.Item>

      <OnClickModal
        button={orderItemDeletionsWithCommentButton}
        title={t('Order Item Deletion (with comment)')}
        onOpen={() => setForceClose(false)}
        forceClose={forceClose}
        footer={
          <>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setForceClose(true)}
              disabled={submitting}
            >
              {t('Cancel')}
            </Button>

            <Button
              variant="primary"
              size="sm"
              disabled={submitting || typeof deleteComment !== 'string' || deleteComment.length === 0}
              onClick={async () => {
                setError(null);
                setSubmitting(true);

                try {
                  await orderItemDeletion(deleteComment);

                  setDeleteComment('');
                  setForceClose(true);
                } catch (err) {
                  setError(err);
                }

                setSubmitting(false);
              }}
            >
              {submitting && (
                <Spinner size="sm" animation="border" style={{ marginRight: '4px' }} />
              )}

              {t('Order deletions')}
            </Button>

            {error && (
              <div style={{ color: 'var(--danger)' }}>
                {t('Action failed')}: {error?.message}
              </div>
            )}
          </>
        }
        closeButton
      >
        <Form.Label>
          {t('Task description')} ({t('markdown supported')})
        </Form.Label>

        <Form.Control
          as="textarea"
          rows={6}
          defaultValue={''}
          onChange={event => setDeleteComment(event.target.value)}
        />
      </OnClickModal>

      <Dropdown.Divider />

      <OnClickModal
        button={orderOtherTaskButton}
        title={t('Order Collection Task')}
        onOpen={() => setForceClose(false)}
        forceClose={forceClose}
        footer={
          <>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setForceClose(true)}
              disabled={submitting}
            >
              {t('Cancel')}
            </Button>

            <Button
              variant="primary"
              size="sm"
              disabled={submitting || typeof text !== 'string' || text.length === 0}
              onClick={async () => {
                setError(null);
                setSubmitting(true);

                try {
                  await createOtherTask(text);

                  setText('');
                  setForceClose(true);
                } catch (err) {
                  setError(err);
                }

                setSubmitting(false);
              }}
            >
              {submitting && (
                <Spinner size="sm" animation="border" style={{ marginRight: '4px' }} />
              )}

              {t('Create Task')}
            </Button>

            {error && (
              <div style={{ color: 'var(--danger)' }}>
                {t('Action failed')}: {error?.message}
              </div>
            )}
          </>
        }
        closeButton
      >
        <Form.Label>
          {t('Task description')} ({t('markdown supported')})
        </Form.Label>

        <Form.Control
          as="textarea"
          rows={6}
          defaultValue={''}
          onChange={event => setText(event.target.value)}
        />
      </OnClickModal>
    </DropdownButton>
  );
};

export default BibItemTableCollectionTaskDropdownButton;
