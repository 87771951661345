import { useTranslation } from 'react-i18next';

import { formatDate, formatDateTimeRelative } from '../../services/date';
import { formatPrice, formatOrderStatus } from '../../services/order';
import useSierraOrderRecord from '../../hooks/useSierraOrderRecord';

import DetailsTable from '../api/DetailsTable';
import SierraID from '../api/SierraID';
import JSONModal from '../api/JSONModal';
import Authorized from '../auth/Authorized';

const OrderJSON = ({ order }) => {
  const { t } = useTranslation();

  const [sierraOrderRecord, loading, error] = useSierraOrderRecord(order.sierraOrderRecordId);

  return (
    <JSONModal
      data={[
        {
          name: t('Lirp Order Record'),
          data: order
        },
        {
          name: t('Sierra Order Record'),
          data: loading
            ? t('Loading...')
            : error
              ? t('Could not fetch order record')
              : sierraOrderRecord?.data
                ? JSON.parse(sierraOrderRecord.data)
                : t('Sierra order record not in database')
        }
      ]}

      style={{
        marginRight: '4px',
        marginBottom: '4px'
      }}
    />
  );
};

const useDetails = order => {
  const { t } = useTranslation();

  const details = [
    {
      label: t('Sierra IDs'),
      value: (
        <>
          <SierraID code="o" id={order.sierraOrderRecordId} />, <SierraID code="b" id={order.sierraBibRecordId} />
        </>
      )
    },
    {
      label: t('Accounting Unit'),
      value: order.accountingUnit
    },
    {
      label: t('Price'),
      value: formatPrice(order)
    },
    {
      label: t('Sierra CODE1'),
      value: order.code1
    },
    {
      label: t('Locations'),
      value: order.locations.split(',').join(', ')
    },
    {
      label: t('Fund'),
      value: order.fund
    },
    {
      label: t('Order Status'),
      value: formatOrderStatus(order)
    },
    {
      label: t('Order Date'),
      value: formatDate(order.orderDate)
    },
    {
      label: t('Received Date'),
      value: formatDate(order.receivedDate)
    },
    {
      label: t('Copies'),
      value: order.copies
    },
    {
      label: t('Vendor'),
      value: order.vendor
    },
    {
      label: t('Language'),
      value: order.lang
    },
    {
      label: t('Country'),
      value: order.country
    },
    {
      label: t('Suppressed'),
      value: order.suppressed
    },
    {
      label: t('Created At'),
      value: formatDateTimeRelative(order.sierraCreatedAt, t)
    },
    {
      label: t('Updated At'),
      value: formatDateTimeRelative(order.sierraUpdatedAt, t)
    }
  ];

  return details;
};

const OrderDetails = ({ order }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('Order Details')}</h3>

      <Authorized role="DEVELOPER">
        <OrderJSON order={order} />
      </Authorized>

      <DetailsTable details={useDetails(order)} />
    </>
  );
};

export default OrderDetails;
