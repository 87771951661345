import { useTranslation } from 'react-i18next';

import useSierraItemRecord from '../../../hooks/useSierraItemRecord';

import JSONModal from '../../api/JSONModal';

const ItemJSON = ({ item }) => {
  const { t } = useTranslation();

  const [sierraItemRecord, loading, error] = useSierraItemRecord(item.sierraItemRecordId);

  return (
    <JSONModal
      data={[
        {
          name: t('Lirp Item Record'),
          data: item
        },
        {
          name: t('Sierra Item Record'),
          data: loading
            ? t('Loading...')
            : error
              ? t('Could not fetch item record')
              : sierraItemRecord?.data
                ? JSON.parse(sierraItemRecord.data)
                : t('Sierra item record not in database')
        }
      ]}

      style={{
        marginRight: '4px',
        marginBottom: '4px'
      }}
    />
  );
};

export default ItemJSON;
