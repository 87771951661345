import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserCity, getUserLocation } from '../../services/user';
import { hasRole } from '../../services/auth';

import { Button, Form } from 'react-bootstrap';

import RoleSelector from './RoleSelector';

const AccountForm = ({ account, error, submitText, onSubmit, showPassword, noRoles, noEmail }) => {
  const { t } = useTranslation();

  const [roles, setRoles] = useState(
    noRoles
      ? []
      : (account ? account.Roles.map(role => role.name) : [])
  );

  const formOnSubmit = event => {
    event.preventDefault();

    if (
      event.nativeEvent &&
      event.nativeEvent.submitter &&
      event.nativeEvent.submitter.id !== 'account-form-submit'
    ) {
      return;
    }

    const account = {
      username: event.target.newUsername.value,
      password: event.target.newPassword.value,
      city: event.target.city.value,
      location: event.target.location.value
    };

    if (!noEmail) account.email = event.target.newEmail.value;
    if (!noRoles) account.roles = roles;

    onSubmit(account);
  };

  return (
    <Form autoComplete="off" onSubmit={formOnSubmit}>
      {/* fake inputs for browser autocomplete */}
      <input name="username" type="text" style={{ display: 'none' }} />
      <input name="password" type="password" style={{ display: 'none' }} />

      <Form.Group>
        <Form.Label>
          {t('Username')}
        </Form.Label>

        <Form.Control
          name="newUsername"
          size="sm"
          defaultValue={account ? account.username : ''}
          autoComplete="off"
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {(account && account.password === undefined) ? t('New Password') : t('Password')}
        </Form.Label>

        <Form.Control
          name="newPassword"
          type={showPassword ? 'text' : 'password'}
          size="sm"
          defaultValue={account ? account.password : ''}
          autoComplete="off"
          required={!account}
        />
      </Form.Group>

      {!noEmail && (
        <Form.Group>
          <Form.Label>
            {t('Email Address')}
          </Form.Label>

          <Form.Control
            name="newEmail"
            type="email"
            size="sm"
            defaultValue={account ? account.email : ''}
            autoComplete="off"
            required
          />
        </Form.Group>
      )}

      <Form.Group>
        <Form.Label>
          {t('City')}
        </Form.Label>

        <Form.Control
          name="city"
          size="sm"
          defaultValue={account ? account.city : getUserCity()}
          disabled={!hasRole('DEVELOPER')}
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {t('Location')}
        </Form.Label>

        <Form.Control
          name="location"
          size="sm"
          defaultValue={account ? account.location : getUserLocation()}
          required
        />
      </Form.Group>

      {!noRoles && (
        <RoleSelector
          roles={roles}
          setRoles={setRoles}
        />
      )}

      <Button
        type="submit"
        size="sm"
        style={{ marginTop: '10px' }}
        id="account-form-submit"
      >
        {submitText || t('Submit')}
      </Button>

      <div style={{ color: 'var(--danger)', marginTop: '6px' }}>
        {error}
      </div>
    </Form>
  );
};

export default AccountForm;
