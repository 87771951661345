import { useTranslation } from 'react-i18next';

import expandRow from '../../services/expandRow';
import { getItemColumns } from '../../services/tableColumns';

import APITable from '../api/APITable';
import ItemView from './ItemView';
import BackToQueryButton from '../api/BackToQueryButton';

const ItemTable = ({ query, enableQueryParams, simple, hideBackToQueryButton }) => {
  const { t } = useTranslation();

  // TODO user selectable columns
  return (
    <>
      {(hideBackToQueryButton || (query && !simple)) && (
        <BackToQueryButton resource="items" query={query} />
      )}

      <APITable
        resource="items"
        keyField="sierraItemRecordId"
        columns={getItemColumns(t)}
        enableQueryParams={enableQueryParams}
        query={query}
        simple={simple}

        expandRow={expandRow(row => (
          <ItemView itemId={row.sierraItemRecordId} />
        ))}
      />
    </>
  );
};

export default ItemTable;
