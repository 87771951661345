import { useDispatch } from '../../state';
import { open as openDialog } from '../../state/slices/dialogs';

import type { Dialog } from '.';

const useDialog = (dialog: Dialog): (() => void) => {
  const dispatch = useDispatch();

  return (): void => {
    dispatch(openDialog(dialog));
  };
};

export default useDialog;
