import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createAccount, getAccountErrorMessage } from '../../services/account';

import AccountForm from './AccountForm';

const CreateAccountForm = ({ update, onSuccessfulSubmit }) => {
  const { t } = useTranslation();

  const [formError, setFormError] = useState(null);

  const onSubmit = async ({ username, password, city, location, email, roles }) => {
    try {
      await createAccount({ username, password, city, location, email, roles });

      if (typeof onSuccessfulSubmit === 'function') onSuccessfulSubmit();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Could not create account:', err);

      setFormError(getAccountErrorMessage(err, t));
    }
  };

  return <AccountForm error={formError} onSubmit={onSubmit} />;
};

export default CreateAccountForm;
