import { useTranslation } from 'react-i18next';

import { Tabs, Tab } from 'react-bootstrap';

import ReceivedItemsTable from './ReceivedItemsTable';
import ShipmentView from './ShipmentView';
import TabWrapper from '../layout/TabWrapper';
import BibMARC from '../bibs/BibMARC';

const ShipmentTabView = ({ shipment, update, tab, setTab }) => {
  const { t } = useTranslation();
  return (
    <Tabs activeKey={tab} onSelect={key => setTab(key)}>
      <Tab eventKey="receivedItemsTable" title={t('Received Items')}>
        <TabWrapper center>
          <ReceivedItemsTable
            shipment={shipment}
            onItemRemoved={update}
            update={update}
          />
        </TabWrapper>
      </Tab>

      <Tab eventKey="shipmentView" title={t('Shipment View')}>
        <TabWrapper>
          <ShipmentView shipmentId={shipment.id} />
        </TabWrapper>
      </Tab>
      <Tab eventKey="BibMarc" title={t('Marc View')}>
        <TabWrapper>
          <BibMARC bibId={shipment.Order.Bib.sierraBibRecordId} />
        </TabWrapper>
      </Tab>
    </Tabs>
  );
};

export default ShipmentTabView;
