import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../../config';
import useItemsInCirculation from '../../../hooks/useItemsInCirculation';
import useLastItemScan from '../../../hooks/useLastItemScan';
import { formatTransitInfo, formatItemType } from '../../../services/item';
import { formatLocationCode, formatLocationInfo, formatCity } from '../../../services/location';
import { formatDateTimeRelative } from '../../../services/date';
import { hasRole } from '../../../services/auth';

import { Button } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';

import DetailsTable from '../../api/DetailsTable';
import SierraID from '../../api/SierraID';
import ClickSelect from '../../api/ClickSelect';

import OnClickModal from '../../layout/OnClickModal';
import TopTooltip from '../../layout/TopTooltip';

import { ItemScan } from '../ItemScans';
import ItemEditor from '../ItemEditor';
import Signum from '../Signum';
import PictureBookLabel from '../PictureBookLabel';
import AdditionalLabel from '../AdditionalLabel';
import Authorized from '../../auth/Authorized';
import Price from '../../api/Price';
import EditButton from '../../api/EditButton';
import ItemStatus from '../ItemStatus';

import AlertHoldCount from './AlertHoldCount';
import ItemDetailsButtons from './ItemDetailsButtons';

const ItemStatusWrapper = ({ item, onChange }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const button = (
    <TopTooltip
      content={t('Cannot be edited while item is checked out')}
      disabled={!item.checkedOut}
    >
      <EditButton disabled={item.checkedOut} />
    </TopTooltip>
  );

  return (
    <ItemStatus item={item}>
      <Authorized role="EDIT-ITEMS">
        <OnClickModal
          button={button}
          title={t('Edit Item Status')}
          forceClose={forceClose}
          onOpen={() => setForceClose(item.checkedOut)}
          closeButton
        >
          <ItemEditor
            item={item}
            itemStatus

            onSave={() => {
              setForceClose(true);

              if (typeof onChange === 'function') onChange();
            }}
          />
        </OnClickModal>
      </Authorized>
    </ItemStatus>
  );
};

const CallNumber = ({ item, onChange }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const invalidCity = (hasRole('DEVELOPER') ? false : !config.callNumberCities.includes(item.fixedFieldCity));

  const button = (
    <TopTooltip
      content={t('Cannot be edited, invalid city') + ` ${formatCity(item.fixedFieldCity)}`}
      disabled={!invalidCity}
    >
      <EditButton disabled={invalidCity} />
    </TopTooltip>
  );

  return (
    <>
      {item.callNumber}

      <Authorized role="EDIT-ITEMS">
        <OnClickModal
          button={button}
          title={t('Edit Call Number')}
          forceClose={forceClose}
          onOpen={() => setForceClose(invalidCity)}
          closeButton
        >
          <ItemEditor
            item={item}
            callNumber

            onSave={() => {
              setForceClose(true);

              if (typeof onChange === 'function') onChange();
            }}
          />
        </OnClickModal>
      </Authorized>
    </>
  );
};

const Barcode = ({ item, onChange }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const button = (
    <TopTooltip
      content={t('Cannot be edited while item is checked out')}
      disabled={!item.checkedOut}
    >
      <EditButton disabled={item.checkedOut} />
    </TopTooltip>
  );

  return (
    <>
      <ClickSelect>
        {item.barcode}
      </ClickSelect>

      <Authorized role="EDIT-ITEMS">
        <OnClickModal
          button={button}
          title={t('Edit Barcode')}
          forceClose={forceClose}
          onOpen={() => setForceClose(item.checkedOut)}
          closeButton
        >
          <ItemEditor
            item={item}
            barcode

            onSave={() => {
              setForceClose(true);

              if (typeof onChange === 'function') onChange();
            }}
          />
        </OnClickModal>
      </Authorized>
    </>
  );
};

const Location = ({ item, onChange }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  return (
    <>
      {formatLocationInfo(item.location)}

      <Authorized role="EDIT-ITEMS">
        <OnClickModal
          button={<EditButton />}
          title={t('Edit Location')}
          forceClose={forceClose}
          onOpen={() => setForceClose(false)}
          closeButton
        >
          <ItemEditor
            item={item}
            location

            onSave={() => {
              setForceClose(true);

              if (typeof onChange === 'function') onChange();
            }}
          />
        </OnClickModal>
      </Authorized>
    </>
  );
};

const City = ({ item, onChange }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  return (
    <>
      {formatCity(item.fixedFieldCity)}

      <Authorized role="EDIT-ITEMS">
        <OnClickModal
          button={<EditButton />}
          title={t('Edit City')}
          forceClose={forceClose}
          onOpen={() => setForceClose(false)}
          closeButton
        >
          <ItemEditor
            item={item}
            city

            onSave={() => {
              setForceClose(true);

              if (typeof onChange === 'function') onChange();
            }}
          />
        </OnClickModal>
      </Authorized>
    </>
  );
};

const ItemDetails = ({ item, title, hideItemScansButton, hideViewItemButton, onChange, onClose, closeButton, hideTitle, hideInventoryButton }) => {
  const { t } = useTranslation();

  const itemsInCirculation = useItemsInCirculation(item.sierraBibRecordId);
  const lastItemScans = useLastItemScan(item.sierraItemRecordId);

  const lastItemScan = (lastItemScans ? lastItemScans.ItemScan : null);
  const lastRfidItemScan = (lastItemScans ? lastItemScans.RfidItemScan : null);

  const isLabelCity = config.labelCities.includes(item.fixedFieldCity);
  const isPictureBook = /^[1,2,4,8]\.79($| )/.test(item.callNumber) && !config.genres.includes(item.genre);

  const details = [
    {
      label: t('Picture Book Label'),
      value: isLabelCity && (
        isPictureBook &&
        !config.toddlerGenres.includes(item.genre) &&
        <PictureBookLabel item={item} />
      )
    },
    {
      label: t('Spine Label'),
      value: isLabelCity && (
        isPictureBook ||
        config.toddlerGenres.includes(item.genre) ||
        config.noSpineLabelItemTypes.includes(item.fixedFieldItemType) ||
        <Signum itemId={item.sierraItemRecordId} height="2rem" />
      )
    },
    {
      label: t('Additional Label'),
      value: isLabelCity && config.additionalLabelGenres.includes(item.genre) && <AdditionalLabel item={item} />
    },
    {
      label: t('Sierra IDs'),
      value: (
        <>
          <SierraID code="i" id={item.sierraItemRecordId} />, <SierraID code="b" id={item.sierraBibRecordId} />
        </>
      )
    },
    {
      label: t('Barcode'),
      value: (item.barcode && <Barcode item={item} onChange={onChange} />)
    },
    {
      label: t('In Circulation'),
      value: itemsInCirculation
    },
    {
      label: t('Item Hold Count'),
      value: (item.holdCount === 0 ? 0 : <AlertHoldCount holdCount={item.holdCount} />)
    },
    {
      label: t('Circulation History'),
      value: (
        <>
          {new Date().getFullYear()}: <strong>{item.fixedFieldCirculationThisYear}</strong>, {new Date().getFullYear() - 1}: <strong>{item.fixedFieldCirculationLastYear}</strong>
        </>
      )
    },
    {
      label: t('Total Circulation'),
      value: (
        <>
          {t('Checkouts')}: <strong>{item.fixedFieldTotalCheckouts}</strong>, {t('Renewals')}: <strong>{item.fixedFieldTotalRenewals}</strong>
        </>
      )
    },
    {
      label: t('Call Number'),
      value: (item.callNumber && <CallNumber item={item} onChange={onChange} />)
    },
    {
      label: t('Genre'),
      value: item.genre
    },
    {
      label: t('Item Status'),
      value: (item.fixedFieldItemStatus && <ItemStatusWrapper item={item} onChange={onChange} />)
    },
    {
      label: t('Transit Info'),
      value: formatTransitInfo(item, t)
    },
    {
      label: t('Item Type'),
      value: formatItemType(item)
    },
    {
      label: t('Reservable'),
      value: (item.itemType ? (item.itemType.reservable ? t('yes') : t('no')) : null)
    },
    {
      label: t('Location'),
      value: <Location item={item} onChange={onChange} />
    },
    {
      label: t('City'),
      value: (item.fixedFieldCity && <City item={item} onChange={onChange} />)
    },
    {
      label: t('Price'),
      value: item.fixedFieldPrice && <Price value={item.fixedFieldPrice} />
    },
    {
      label: t('Current Checkout'),
      value: item.checkedOut && (
        <>
          <div style={{ marginBottom: '10px' }}>
            <strong>
              {formatDateTimeRelative(item.fixedFieldCurrentLoanOutDate, t)}
            </strong>
          </div>

          <div>
            {formatLocationCode(item.fixedFieldLastCheckoutLocationId, item.lastCheckoutLocation)}
          </div>
        </>
      )
    },
    {
      label: t('Last Checkout'),
      value: item.fixedFieldLastCheckoutDate && (
        <>
          <div style={{ marginBottom: '10px' }}>
            <strong>
              {formatDateTimeRelative(item.fixedFieldLastCheckoutDate, t)}
            </strong>
          </div>

          <div>
            {
              !item.checkedOut
                ? formatLocationCode(item.fixedFieldLastCheckoutLocationId, item.lastCheckoutLocation)
                : '-'
            }
          </div>
        </>
      )
    },
    {
      label: t('Last Checkin'),
      value: item.fixedFieldLastCheckinDate && (
        <>
          <div style={{ marginBottom: '10px' }}>
            <strong>
              {formatDateTimeRelative(item.fixedFieldLastCheckinDate, t)}
            </strong>
          </div>

          <div>
            {formatLocationCode(item.fixedFieldLastCheckinLocationId, item.lastCheckinLocation)}
          </div>
        </>
      )
    },
    {
      label: t('Last Sierra Scan'),
      value: lastItemScan && <ItemScan itemScan={lastItemScan} />
    },
    {
      label: t('Last Lirp Scan'),
      value: lastRfidItemScan && <ItemScan itemScan={lastRfidItemScan} />
    },
    {
      label: t('Item Created At'),
      value: formatDateTimeRelative(item.sierraCreatedAt, t)
    },
    {
      label: t('Item Updated At'),
      value: formatDateTimeRelative(item.sierraUpdatedAt, t)
    }
  ];

  return (
    <>
      {hideTitle || (
        <h3>
          {title || t('Item Details')}

          {closeButton && (
            <Button
              variant="link"
              size="sm"
              onClick={onClose}
              style={{ padding: 0, border: 0, verticalAlign: 'middle', marginTop: '-4px' }}
            >
              <X size={24} />
            </Button>
          )}
        </h3>
      )}

      <ItemDetailsButtons
        item={item}
        hideItemScansButton={hideItemScansButton}
        hideViewItemButton={hideViewItemButton}
        hideInventoryButton={hideInventoryButton}
        update={onChange}
      />

      <DetailsTable details={details} />
    </>
  );
};

export default ItemDetails;
