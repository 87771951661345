import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import ExpiredHoldShelfItemsTable from '../holdshelf/ExpiredHoldShelfItemsTable';
import HoldShelfStatistics from '../holdshelf/HoldShelfStatistics';
import ExpiredHoldShelfItemsInstructions from '../holdshelf/ExpiredHoldShelfItemsInstructions';
import Loading from '../api/Loading';

const ExpiredHoldShelfItemsPage = ({ location, locationName }) => {
  const { t } = useTranslation();

  const [items, loading, /* error */, /* headers */, update] = useAPI('GET', `/holdshelf/expired/items/${location}`);

  useEffect(() => {
    const interval = setInterval(update, 600 * 1000);

    return () => clearInterval(interval);
  }, [update]);

  if (items === null) {
    if (loading) return <Loading />;
    else return t('Could not fetch expired hold shelf items');
  }

  return (
    <>
      <ExpiredHoldShelfItemsInstructions
        expiredHoldShelfItems={items}
        location={location}
      />

      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <HoldShelfStatistics location={location} />
      </div>

      <ExpiredHoldShelfItemsTable
        expiredHoldShelfItems={items}
        update={update}
        location={location}
      />
    </>
  );
};

export default ExpiredHoldShelfItemsPage;
