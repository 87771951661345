import { Badge } from 'react-bootstrap';

const InfoBadge = ({ text, variant }) => {
  return (
    <Badge variant={variant}>
      {text}
    </Badge>
  );
};

export default InfoBadge;
