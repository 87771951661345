import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateSharedAccount, deleteSharedAccount } from '../../services/sharedAccount';

import { Button } from 'react-bootstrap';
import { PencilSquare, Trash } from 'react-bootstrap-icons';

import AccountEditor from './AccountEditor';
import AccountConfirmDelete from './AccountConfirmDelete';
import OnClickModal from '../layout/OnClickModal';

const EditButton = () => (
  <Button variant="link" size="sm" style={{ padding: 0, border: 0 }}>
    <PencilSquare size={20} style={{ color: 'royalblue' }} />
  </Button>
);

const DeleteButton = () => (
  <Button variant="link" size="sm" style={{ padding: 0, border: 0 }}>
    <Trash size={20} style={{ color: 'crimson' }} />
  </Button>
);

const SharedAccountOptionsColumn = ({ account, update }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  return (
    <>
      <OnClickModal
        title={t('Edit shared account')}
        button={<EditButton />}
        forceClose={forceClose}
        onOpen={() => setForceClose(false)}
        closeButton
      >
        <AccountEditor
          account={account}
          onSubmit={async ({ username, password, city, location }) => {
            await updateSharedAccount(account.id, username, password, city, location);
            update();
            setForceClose(true);
          }}
          showPassword
          noRoles
          noEmail
        />
      </OnClickModal>

      <span style={{ marginRight: '8px' }} />

      <OnClickModal
        title={t('Confirm deletion')}
        button={<DeleteButton />}
        forceClose={forceClose}
        onOpen={() => setForceClose(false)}
        closeButton
      >
        <AccountConfirmDelete
          account={account}
          onConfirm={async () => {
            await deleteSharedAccount(account.id);
            update();
            setForceClose(true);
          }}
          onCancel={() => setForceClose(true)}
        />
      </OnClickModal>
    </>
  );
};

export default SharedAccountOptionsColumn;
