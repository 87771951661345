import { getUserLocation } from './services/user';
import { getYearsAgo } from './services/date';

const config = {};

config.appName = 'Lirp';

config.api = {
  host: window.location.hostname,
  hostNoWWW: window.location.hostname.replace(/^www\./, ''),
  port: (
    process.env.REACT_APP_DEV_MODE
      ? (process.env.REACT_APP_API_PORT || 4002)
      : 443
  ),
  path: '/api/v1',
  pathV2: '/api/v2',
  url: '',
  urlV2: ''
};

config.api.url = [
  'https://',
  config.api.host,
  ':',
  config.api.port,
  config.api.path
].join('');

config.api.webSocketUrl = [
  'wss://',
  config.api.host,
  ':',
  config.api.port,
  config.api.path
].join('');

config.api.urlV2 = [
  'https://',
  config.api.host,
  ':',
  config.api.port,
  config.api.pathV2
].join('');

config.api.webSocketUrlV2 = [
  'wss://',
  config.api.host,
  ':',
  config.api.port,
  config.api.pathV2
].join('');

config.topicGenres = [
  // fin
  'PERHE',
  'TUTTI JA POTTA',
  'PÄIVÄKOTI JA KOULU',
  'HAUSKA OPPIA',
  'YSTÄVYYS JA ERILAISUUS',
  'TUNTEET',
  'VAIKEAT ASIAT',
  'PIKKU POTILAS',
  'ERI MAIDEN LAPSIA',
  'LUONTO',
  'VUODENAJAT',
  'ELÄIMET',
  'AUTOT JA LIIKENNE',
  'ELÄMÄNKATSOMUS',
  'PRINSESSAT JA SANKARIT',
  'PIILOKUVAKIRJAT',

  // swe
  'FAMILJEN',
  'DAGHEM OCH SKOLA',
  'BOKSTÄVER OCH SIFFROR',
  'KÄNSLOR',
  'SVÅRA SAKER',
  'BARN I VÄRLDEN',
  'SJUK - HOS LÄKAREN',
  'DJUR',
  'NATUR',
  'ÅRSTIDER',
  'LIVSÅSKÅDNING',
  'BILAR OCH MASKINER',
  'PRINSESSOR OCH HJÄLTAR',

  // eng
  'FAMILY',
  'FRIENDSHIP',
  'FEELINGS',
  'ABC AND NUMBERS',
  'NATURE'
];

config.toddlerGenres = [
  // fin
  'ENSIKIRJAT',

  // swe
  'PEKBÖCKER',

  // eng
  'TODDLERS'
];

config.childrensSeriesGenres = [
  'SAMU JA SALLA',
  'MUUMIT',
  'MIINA JA MANU',
  'PUUHA-PETE',
  'RASMUS NALLE',
  'DISNEY',
  'MUMIN'
];

config.movieGenres = [
  'SUOMI',
  'TV',
  'KOMEDIA',
  'TOIMINTA',
  'ANIME'
];

config.musicGenres = [
  'VINYYLI',
  'KASETTI',
  'BOXI',
  'MINIBOXI',
  'KOKOELMAT',
  'SUOMIROCK'
];

config.genres = [
  'BESTSELLER',
  'SPECIAL',
  'POKKARI',
  'ÄÄNIKIRJAT',
  'LJUDBÖCKER',
  'AUDIOBOOKS',
  'JÄNNITYS',
  'SPÄNNING',
  'THRILLER',
  'VIIHDE',
  'UNDERHÅLLNING',
  'ROMANCE',
  'FANTASIA',
  'FANTASY',
  'KAUHU',
  'HORROR',
  'SKRÄCK',
  'SCIFI',
  'VITSIT',
  'SOTA',
  'MANGA',
  'JOJO',
  'HELPPOLUKUISET',
  'BÖRJA LÄSA',
  'SUURAAKKOSET',
  'TAVUTETUT',
  'ISOTEKSTISET',
  'STORSTIL',
  'LARGE PRINT',
  'SELKOKIRJAT',
  'LL-BÖCKER',
  'EASY READERS',
  'JOULU',
  'JULEN',
  'CHRISTMAS',
  'OPI SUOMEA',
  'HEVOSET',
  'HÄSTAR',

  ...config.topicGenres,
  ...config.toddlerGenres,
  ...config.childrensSeriesGenres,
  ...config.movieGenres,
  ...config.musicGenres
];

config.genresAlphabetical = [...config.genres];
config.genresAlphabetical.sort();

config.additionalLabelGenres = [
  'ISOTEKSTISET',
  'SELKOKIRJAT',
  'OPI SUOMEA',
  'BESTSELLER',
  'STORSTIL',
  'LL-BÖCKER',
  'LARGE PRINT',
  'EASY READERS',

  ...config.topicGenres
];

config.shelfViewColors = {
  normal: '#cccccc',
  highlighted: '#ccffcc',
  problem: '#f55050'
};

config.encoreUrl = 'https://helmet.finna.fi/Record/helmet.';

config.missingStatus = 'm';

config.inventory = {
  rules: {
    isil: ['FI-Em'],
    dsfid: ['06'],
    afi: ['C2', '07'],
    itemStatus: {
      type: 'not',
      values: ['-']
    },
    city: {
      type: 'not',
      values: ['e']
    },
    location: {
      type: 'not',
      values: [`${getUserLocation()}al`]
    },
    language: {
      type: 'not',
      values: ['fin']
    },
    itemType: {
      type: 'not',
      values: [100] // TODO
    },
    callNumber: {
      type: 'not',
      values: []
    },
    genre: {
      type: 'not',
      values: []
    },
    circulation: {
      group: 'AND',
      thisYear: {
        operator: '<=',
        value: 0
      },
      lastYear: {
        operator: '<=',
        value: 0
      }
    },
    floatable: {
      minCopiesInOwnLocation: 5,
      maxCopiesInTargetLocation: 0,
      targetLocations: ['e01', 'e10', 'e23', 'e76']
    },
    zeroesDate: getYearsAgo(1)
  },
  defaultEnabledRules: [
    'barcode',
    'tagValid',
    'itemStatus',
    'city',
    'location',
    'itemHold',
    'checkedOut'
  ]
};

config.reception = {
  expectedTagIsil: ['FI-Em', 'FI-Vantaa'],
  expectedTagDsfid: '06',
  expectedTagAfi: ['00', 'C2', '07']
};

// TODO query from server
config.accountRoles = [
  'USER',
  'ADMIN',
  'DEVELOPER',
  'COLLECTION-MANAGER',
  'MANAGE-SHARED-ACCOUNTS',
  'CHANGE-LOCATION',
  'PROCESS-SHIPMENTS',
  'HANDLE-HOLDS',
  'RECEPTION',
  'INVENTORY',
  'EDIT-LINKS',
  'ITEM-SEARCH',
  'CREATE-COLLECTION-TASKS',

  'QUERY-ITEMS',
  'VIEW-ITEMS',
  'EDIT-ITEMS',

  'QUERY-BIBS',
  'VIEW-BIBS',
  'EDIT-BIBS',

  'QUERY-ORDERS',
  'VIEW-ORDERS',
  'EDIT-ORDERS'
];

config.labelCities = ['e', 'v'];
config.callNumberCities = ['e', 'v'];

config.noSpineLabelItemTypes = [
  // Lehdet
  103, 203,

  // Lautapelit
  126, 127, 226, 227,

  // Esineet
  151, 152, 153, 154, 251, 252, 253
];

config.bibListPublicUrl = [
  'https://',
  window.location.hostname,
  (window.location.port !== 443 ? `:${window.location.port}` : ''),
  '/view-list/'
].join('');

config.sharedAccountPreferences = {
  sierraIdFormat: 'full'
};

config.bibCoverImageUrl = 'https://media.kirjavalitys.fi/library/cover/helmet';

export default config;
