import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useRadar from '../../hooks/useRadar';

import { Row, Col } from 'react-bootstrap';

import Authorized from '../auth/Authorized';
import ItemDescription from './ItemDescription';

import { CoverImage } from '../../../bibs';

const UnsupportedError = () => {
  const { t } = useTranslation();

  return t('Reader does not support radar mode');
};

const InventoryRadar = ({ item, rssi, rssiMax, lastSeen }) => {
  const { t } = useTranslation();

  const { percentage } = useRadar(rssi, rssiMax);

  const percentRef = useRef(null);

  useEffect(() => {
    if (!percentRef.current) return;

    const minFontSize = 16;
    const maxFontSize = 128;

    const fontSize = (+rssi === 0)
      ? minFontSize
      : Math.max(minFontSize, Math.round(maxFontSize * percentage - minFontSize));

    percentRef.current.style.fontSize = `${fontSize}px`;
  }, [percentage, rssi]);

  if (item.rssi === 1 && (rssi === null || rssi === 1)) {
    return <UnsupportedError />;
  }

  return (
    <>
      <center>
        <strong>
          <div ref={percentRef}>
            {percentage === null ? '-' : Math.ceil(percentage * 100)} %
          </div>
        </strong>

        {lastSeen && (
          <div>
            {t('Last seen at')} {lastSeen.toISOString().split('T')[1].slice(0, -5)}
          </div>
        )}
      </center>

      {item.item && (
        <>
          <hr />

          <Row>
            {item.item.Bib && (
              <Col xs="auto">
                <CoverImage isbn={item.item.Bib.marcISBN} materialType={item.item.Bib.fixedFieldMaterialType} />
              </Col>
            )}

            <Col>
              <ItemDescription item={item.item} noEditButton />
            </Col>
          </Row>
        </>
      )}

      <Authorized role="DEVELOPER">
        <hr />

        <div>
          {t('Tag')} <code>{item.tagId}</code>
        </div>

        <div>
          {t('First RSSI')} <strong>{item.rssi}</strong>
        </div>

        <div>
          {t('Current RSSI')} <strong>{`${rssi}`}</strong>
        </div>

        <div>
          {t('Max RSSI')} <strong>{typeof rssiMax === 'number' ? Math.round(rssiMax * 10) / 10 : `${rssiMax}`}</strong>
        </div>

        <div>
          {t('Last seen')} <strong>{lastSeen ? lastSeen.toISOString().split('T')[1].slice(0, -5) : `${lastSeen}`}</strong>
        </div>
      </Authorized>
    </>
  );
};

export default InventoryRadar;
