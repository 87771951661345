import api from './api';

export const validateAccount = (username, password, city, location, allowNullPassword = false) => {
  if (!username || username.length < 3) throw new Error('Invalid username');
  if (username.length > 64) throw new Error('Username too long');
  if (!allowNullPassword) {
    if (!password || password.length < 8) throw new Error('Invalid password');
    if (password.length > 64) throw new Error('Password too long');
  }
  if (!city || city.length !== 1) throw new Error('Invalid city');
  if (!location || location.length !== 3) throw new Error('Invalid location');
};

export const getAccountErrorMessage = (err, t) => {
  if (err.response) {
    if (err.response.data === 'Username already in use') {
      return t('Username already in use');
    } else if (err.response.data === 'Email already in use') {
      return t('Email address already in use');
    } else if (err.response.data === 'Invalid email') {
      return t('Invalid email address');
    }
  }

  if (err.message === 'Invalid password') {
    return t('Password must be at least 8 characters long');
  } else if (err.message === 'Password too long') {
    return t('Password too long');
  } else if (err.message === 'Invalid username') {
    return t('Username must be at least 3 characters long');
  } else if (err.message === 'Username too long') {
    return t('Username too long');
  } else if (err.message === 'Invalid city') {
    return t('Invalid city');
  } else if (err.message === 'Invalid location') {
    return t('Invalid location');
  } else if (err.message === 'Invalid email') {
    return t('Invalid email address');
  } else {
    // eslint-disable-next-line no-console
    console.log('Could not save account:', err);

    return t('Could not save account');
  }
};

export const createAccount = async ({ username, password, city, location, email, roles }) => {
  validateAccount(username, password, city, location);

  await api.post('/accounts', {
    username,
    password,
    city,
    location,
    email,
    roles
  });
};

export const updateAccount = async ({ id, username, password, city, location, email, roles }) => {
  validateAccount(username, password, city, location, true);

  await api.put(`/accounts/${id}`, {
    username,
    password,
    city,
    location,
    email,
    roles
  });
};

export const deleteAccount = async id => (
  await api.delete(`/accounts/${id}`)
);

export const getRoleLabel = (role, t) => (
  {
    USER: t('User'),
    ADMIN: t('Admin'),
    DEVELOPER: t('Developer'),
    'COLLECTION-MANAGER': t('Collection Manager'),
    'MANAGE-SHARED-ACCOUNTS': t('Manage Shared Accounts'),
    'CHANGE-LOCATION': t('Change Location'),
    'PROCESS-SHIPMENTS': t('Process Shipments'),
    'HANDLE-HOLDS': t('Handle Holds'),
    RECEPTION: t('Reception'),
    'VIEW-ITEMS': t('View Items'),
    'VIEW-BIBS': t('View Bibs'),
    'VIEW-ORDERS': t('View Orders'),
    'EDIT-ITEMS': t('Edit Items'),
    'EDIT-BIBS': t('Edit Bibs'),
    'EDIT-ORDERS': t('Edit Orders'),
    'QUERY-ITEMS': t('Query Items'),
    'QUERY-BIBS': t('Query Bibs'),
    'QUERY-ORDERS': t('Query Orders'),
    INVENTORY: t('Inventory'),
    'EDIT-LINKS': t('Edit Links'),
    'ITEM-SEARCH': t('Item Search'),
    'CREATE-COLLECTION-TASKS': t('Create Collection Tasks')
  }[role] || role
);
