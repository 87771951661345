type ArgType = string | number | boolean | null | undefined;

const useString = (arr: TemplateStringsArray, ...args: ArgType[]): string | null => {
  let result = '';

  for (let i = 0; i < arr.length; i++) {
    result += arr[i];

    if (i !== arr.length - 1) {
      if (!['string', 'number'].includes(typeof args[i])) {
        return null;
      }

      result += args[i];
    }
  }

  return result;
};

export default useString;
