import { useTranslation } from 'react-i18next';

import { InnerItemStatus } from './ItemStatus';

const DeletionOrderedItemStatus = ({ item, children, allocationType }) => {
  const { t } = useTranslation();

  const text = {
    3: t('Deletion Ordered'),
    4: t('Other Task Ordered'),
    5: t('Floating Order Ordered'),
    6: t('Homing Order Ordered'),
    default: t('Task Ordered')
  }[allocationType ?? 'default'];

  return (
    <>
      <div>
        <InnerItemStatus item={item}>
          {children}
        </InnerItemStatus>
      </div>

      <div style={{ color: 'var(--danger)' }}>
        <strong>
          {text}
        </strong>
      </div>
    </>
  );
};

export default DeletionOrderedItemStatus;
