export const itemStatuses = {
  '!': 'ODOTTAA NOUTOA',
  $: 'KORVATTU',
  '-': 'HYLLYSSÄ',
  d: 'KÄSITTELYSSÄ',
  k: 'KORJATTAVANA',
  m: 'KADONNUT',
  n: 'LASKUTETTU',
  o: 'EI LAINATA',
  p: 'POISTETTU',
  s: 'ON SEARCH',
  t: 'MATKALLA',
  z: 'ILM PALAUT'
};

export type ItemStatusCode = keyof typeof itemStatuses;
export type ItemStatusName = typeof itemStatuses[ItemStatusCode];
