import { Route as RouterRoute } from 'react-router-dom';

import { PageTitle, Container, useMargins } from '../common/layout';

import { Authorized } from '../users';
import { useRoutes } from '.';

const InnerRouteContainer: React.FC = ({ children }) => (
  <Container style={useMargins('auto', 'auto', 20, 0)}>
    {children}
  </Container>
);

const RouteContainer: React.FC<{
  container?: boolean
}> = ({ container, children }) =>
  container
    ? <InnerRouteContainer>{children}</InnerRouteContainer>
    : <>{children}</>;

const Routes: React.FC = () => (
  <>
    {useRoutes().map(route => (
      <Authorized key={route.path} role={route.role} roleNot={route.roleNot}>
        <RouterRoute path={route.path} exact={route.exact}>
          <RouteContainer container={route.container}>
            {route.title && (
              <PageTitle title={route.title} hidden={route.titleHidden} />
            )}

            {route.content}
          </RouteContainer>
        </RouterRoute>
      </Authorized>
    ))}
  </>
);

export default Routes;
