import sierraGlobals from '../sierraGlobals';

export const formatPrice = order => {
  if (order.price === undefined || order.price === null) return null;

  if (typeof order.price !== 'number') {
    // eslint-disable-next-line no-console
    console.error('Non-numeric price encountered:', order);

    return order.price;
  }

  return (order.price / 100).toFixed(2);
};

export const formatOrderStatus = order => {
  if (!order) return null;
  else if (!sierraGlobals.orderStatuses[order.status]) return order.status;
  else return `${order.status} (${sierraGlobals.orderStatuses[order.status]})`;
};

export const parseOrderId = sierraOrderRecordId => (
  Number(sierraOrderRecordId.replace(/[Aa]$/, '').replace(/^\.?o?/, ''))
);

export const getOrderQueryForBibId = bibId => [{
  type: 'GROUP',
  value: 'AND',
  isNegated: false,
  children: [{
    field: 'sierra_bib_record_id',
    value: bibId.toString(),
    operator: 'EQUAL'
  }]
}];
