import BootstrapRow from 'react-bootstrap/Row';
import BootstrapColumn from 'react-bootstrap/Col';

import type { ReactNode } from 'react';

const OppositeSidedContent: React.FC<{
  left: ReactNode,
  right: ReactNode
}> = ({
  left,
  right
}) => (
  <BootstrapRow>
    <BootstrapColumn xs="auto">
      {left}
    </BootstrapColumn>

    <BootstrapColumn xs="auto" className="ml-auto">
      {right}
    </BootstrapColumn>
  </BootstrapRow>
);

export default OppositeSidedContent;
