import api from './api';

export const saveLocalData = data => {
  if (
    typeof data.id !== 'number' ||
    !data.username ||
    !data.roles ||
    !data.location ||
    !data.accessToken
  ) {
    // eslint-disable-next-line no-console
    console.error('Invalid data:', data);

    throw new Error('Invalid data');
  }

  localStorage.setItem('id', data.id.toString());
  localStorage.setItem('username', data.username);
  localStorage.setItem('email', data.email);
  localStorage.setItem('roles', JSON.stringify(data.roles));
  localStorage.setItem('city', data.city);
  localStorage.setItem('location', data.location);
  localStorage.setItem('locationName', data.locationName || null);
  localStorage.setItem('accessToken', data.accessToken);
  localStorage.setItem('refreshToken', data.refreshToken);
};

export const getUserId = () => (
  Number(localStorage.getItem('id'))
);

export const getUserLocation = () => (
  localStorage.getItem('location') || null
);

export const getUserCity = () => (
  localStorage.getItem('city') || null
);

export const getUserLocationName = () => (
  localStorage.getItem('locationName') ||
  localStorage.getItem('location') ||
  null
);

export const setUserLocation = async newLocation => {
  const data = await api.put(`/users/location/${newLocation}`);

  saveLocalData(data);

  window.location.reload();
};
