import { useTranslation, T } from '../../common/i18n';
import { PageTitle, Space } from '../../common/layout';
import { useQueryParam } from '../../common/navigation';

import { BibView, parseBibId } from '../../bibs';

const InvalidBibId: React.FC<{
  bibId: string
}> = ({ bibId }) => (
  <>
    <T>Invalid Sierra Bib ID</T>
    <Space />
    {bibId}
  </>
);

const BibViewPage: React.FC = () => {
  const bibIdParam = useQueryParam('id');
  const parsedBibId = parseBibId(bibIdParam);

  return (
    <>
      <PageTitle title={useTranslation('Bib Details')} />

      {(parsedBibId !== null)
        ? <BibView bibId={parsedBibId} />
        : <InvalidBibId bibId={bibIdParam ?? 'null'} />}
    </>
  );
};

export default BibViewPage;
