export const bindings = [
  'kovakantinen', 'pehmeäkantinen', 'kierreselkä',

  'epub', 'mp3', 'pdf',
  'pelipakkaus',

  'irtolehtiä', 'lösblad', 'loose leaf book',
  'kotelo', 'fodral', 'case',
  'kansio', 'mapp', 'folder', 'file', 'portfolio', 'binder'
];
