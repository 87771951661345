import sierraGlobals from '../sierraGlobals';

export const formatPrice = price => {
  if (price === undefined || price === null) return null;

  if (typeof price !== 'number') {
    // eslint-disable-next-line no-console
    console.error('Non-numeric price encountered:', price);

    return price;
  }

  return (price / 100).toFixed(2);
};

const getBibProblems = (order, t) => {
  const bib = order.Bib;
  const problems = [];
  if (order.accountingUnit === 4) {
    if (bib.marcVantaaClass === null) {
      problems.push(t('No VANTAA class'));
    }
  } else {
    if (bib.marcEspooClass === null) {
      problems.push(t('No Espoo class'));
    }
  }

  if (bib.marc091 === null) {
    problems.push(t('No MARC 091'));
  } else if (!/^[0-9]{2}(\.[0-9]{2})?$/.test(bib.marc091)) {
    problems.push(t('Invalid MARC 091'));
  }

  if (bib.fixedFieldLang === null) {
    problems.push(t('No language'));
  }

  if (bib.suppressed) {
    problems.push(t('Bib record is suppressed'));
  }

  if (bib.fixedFieldMaterialType === null) {
    problems.push(t('No material type'));
  }

  const materialTypes = Object.keys(sierraGlobals.materialTypes);
  const ageRatedMaterialTypes = sierraGlobals.ageRatedMaterialTypes;

  if (!materialTypes.includes(bib.fixedFieldMaterialType)) {
    problems.push([
      t('Invalid material type'),
      `(${bib.fixedFieldMaterialType})`
    ].join(' '));
  }

  if (
    ageRatedMaterialTypes.includes(bib.fixedFieldMaterialType) &&
    bib.rdaAgeRating === null
  ) {
    problems.push(t('No age rating'));
  }

  return problems;
};

export const getCreateItemProblems = (shipment, t) => {
  /*
   * Requirements for items:
   * - Item not already created
   * - 094 Espoo Class is set
   * - 091 is set (follows regex ^[0-9]{2}(\.[0-9]{2})?$)
   * - fixedFieldLang is set
   * - bib not suppressed
   * - if materialType is 'g', 's', or 'h':
   *   - rdaAgeRating is set
   * - order status must be 'a'
   * - order record must exist
   * - material type is valid
   */

  const problems = [];

  if (!shipment.Order) {
    problems.push(t('Order record does not exist'));
  } else if (!shipment.Order.Bib) {
    problems.push(t('No bib record found'));
  }

  if (problems.length > 0) return problems;

  problems.push(...getBibProblems(shipment.Order, t));

  if (['a', 'q'].indexOf(shipment.Order.status) === -1) {
    problems.push(t('Order status not "paid"'));
  }

  return problems;
};

export const getCreateItemStatus = (shipment, t) => {
  if (!t) throw new Error('getCreateItemStatus not given t!');

  let allItemsCreated = true;
  let someItemsCreated = false;

  for (const receivedItem of shipment.receivedItems) {
    if (receivedItem.sierraItemRecordId === null) allItemsCreated = false;
    else someItemsCreated = true;
  }

  const receivedItemCount = shipment.receivedItems.length;
  const shipmentItemCount = shipment.copiesOnShipment;

  return {
    missingReceivedItems: (receivedItemCount !== shipmentItemCount),
    problems: getCreateItemProblems(shipment, t),
    status: (shipment.Order ? shipment.Order.status : null),
    allItemsCreated,
    someItemsCreated,
    labelsPrinted: shipment.labelsPrinted,
    receivedDateSet: (shipment.Order && shipment.Order.receivedDate !== null)
  };
};
