import { Badge } from 'react-bootstrap';

const AlertBadge = ({ verticalAlign, style }) => {
  return (
    <Badge pill variant="danger" style={{ verticalAlign, ...style }}>
      !
    </Badge>
  );
};

export default AlertBadge;
