import Button from './Button';
import IconButton from './IconButton';
import SubmitButton from './Submit';
import Buttons from './Buttons';
import LinkButton from './Link';

import type { Props as ButtonProps } from './Button';

import {
  Close as CloseButton,
  Edit as EditButton,
  Delete as DeleteButton
} from './Common';

export {
  CloseButton,
  EditButton,
  DeleteButton,

  SubmitButton,
  Button,
  IconButton,
  Buttons,
  LinkButton
};

export type ButtonType =
  | 'button'
  | 'reset'
  | 'submit';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'light'
  | 'dark'
  | 'link';

export type IButton = ButtonProps & {
  id: string;
  content: React.ReactNode;
};
