import { useState, useEffect, useRef } from 'react';

import { renderShelf } from '../../../services/itemShelfView';

// Import genre images
import alienGenreImage from '../../../../resources/genreimages/alien.png';
import christmastreeGenreImage from '../../../../resources/genreimages/christmastree.png';
import circleGenreImage from '../../../../resources/genreimages/circle.png';
import detectiveGenreImage from '../../../../resources/genreimages/detective.png';
import dragonGenreImage from '../../../../resources/genreimages/dragon.png';
import finGenreImage from '../../../../resources/genreimages/fin.png';
import heartGenreImage from '../../../../resources/genreimages/heart.png';
import horseGenreImage from '../../../../resources/genreimages/horse.png';
import roosterGenreImage from '../../../../resources/genreimages/rooster.png';
import skullGenreImage from '../../../../resources/genreimages/skull.png';
import smileyGenreImage from '../../../../resources/genreimages/smiley.png';
import suurGenreImage from '../../../../resources/genreimages/suur.png';
import tankGenreImage from '../../../../resources/genreimages/tank.png';
import tavuGenreImage from '../../../../resources/genreimages/tavu.png';
import jojoGenreImage from '../../../../resources/genreimages/jojo.png';

const ItemShelfViewCanvas = ({ shelfData, highlighter, highlightItemsSet, height }) => {
  const divRef = useRef(null);
  const canvasRef = useRef(null);

  const alienGenreRef = useRef(null);
  const christmastreeGenreRef = useRef(null);
  const circleGenreRef = useRef(null);
  const detectiveGenreRef = useRef(null);
  const dragonGenreRef = useRef(null);
  const finGenreRef = useRef(null);
  const heartGenreRef = useRef(null);
  const horseGenreRef = useRef(null);
  const roosterGenreRef = useRef(null);
  const skullGenreRef = useRef(null);
  const smileyGenreRef = useRef(null);
  const suurGenreRef = useRef(null);
  const tankGenreRef = useRef(null);
  const tavuGenreRef = useRef(null);
  const jojoGenreRef = useRef(null);

  const [hasLoaded, setHasLoaded] = useState({
    alien: false,
    christmastree: false,
    circle: false,
    detective: false,
    dragon: false,
    fin: false,
    heart: false,
    horse: false,
    rooster: false,
    skull: false,
    smiley: false,
    suur: false,
    tank: false,
    tavu: false,
    jojo: false
  });

  const [canvasStyle, setCanvasStyle] = useState({});
  const [canvasWidth, setCanvasWidth] = useState(null);
  const [canvasHeight, setCanvasHeight] = useState(null);
  const [canvasContext, setCanvasContext] = useState(null);

  const previous = shelfData.previous;
  const current = shelfData.current;
  const next = shelfData.next;

  useEffect(() => {
    const canvas = canvasRef.current;
    const width = divRef.current.offsetWidth;

    const canvasWidth = Math.floor(width);
    const canvasHeight = height || Math.floor(Math.min(width, 2 * window.innerHeight / 3));

    canvas.width = canvasWidth * 2;
    canvas.height = canvasHeight * 2;

    setCanvasStyle({
      width: canvasWidth,
      heigh: canvasHeight
    });

    const ctx = canvas.getContext('2d');
    ctx.scale(2, 2);

    setCanvasWidth(canvasWidth);
    setCanvasHeight(canvasHeight);
    setCanvasContext(ctx);
  }, [divRef, height]);

  useEffect(() => {
    const genreRefs = {
      alien: alienGenreRef,
      christmastree: christmastreeGenreRef,
      circle: circleGenreRef,
      detective: detectiveGenreRef,
      dragon: dragonGenreRef,
      fin: finGenreRef,
      heart: heartGenreRef,
      horse: horseGenreRef,
      rooster: roosterGenreRef,
      skull: skullGenreRef,
      smiley: smileyGenreRef,
      suur: suurGenreRef,
      tank: tankGenreRef,
      tavu: tavuGenreRef,
      jojo: jojoGenreRef
    };

    for (const key in genreRefs) {
      if (!genreRefs[key] || !genreRefs[key].current || !hasLoaded[key]) {
        return;
      }
    }

    renderShelf({
      ctx: canvasContext,
      previous,
      current,
      next,
      canvasWidth,
      canvasHeight,
      genreRefs,
      highlighter,
      highlightItemsSet
    });
  }, [
    canvasContext, canvasWidth, canvasHeight, previous, current, next, hasLoaded, highlighter, highlightItemsSet,
    alienGenreRef,
    christmastreeGenreRef,
    circleGenreRef,
    detectiveGenreRef,
    dragonGenreRef,
    finGenreRef,
    heartGenreRef,
    horseGenreRef,
    roosterGenreRef,
    skullGenreRef,
    smileyGenreRef,
    suurGenreRef,
    tankGenreRef,
    tavuGenreRef,
    jojoGenreRef
  ]);

  return (
    <div ref={divRef}>
      <canvas ref={canvasRef} style={canvasStyle} />

      <img
        ref={alienGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, alien: true })}
        src={alienGenreImage}
        alt=""
        hidden
      />

      <img
        ref={christmastreeGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, christmastree: true })}
        src={christmastreeGenreImage}
        alt=""
        hidden
      />

      <img
        ref={circleGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, circle: true })}
        src={circleGenreImage}
        alt=""
        hidden
      />

      <img
        ref={detectiveGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, detective: true })}
        src={detectiveGenreImage}
        alt=""
        hidden
      />

      <img
        ref={dragonGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, dragon: true })}
        src={dragonGenreImage}
        alt=""
        hidden
      />

      <img
        ref={finGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, fin: true })}
        src={finGenreImage}
        alt=""
        hidden
      />

      <img
        ref={heartGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, heart: true })}
        src={heartGenreImage}
        alt=""
        hidden
      />

      <img
        ref={horseGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, horse: true })}
        src={horseGenreImage}
        alt=""
        hidden
      />

      <img
        ref={roosterGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, rooster: true })}
        src={roosterGenreImage}
        alt=""
        hidden
      />

      <img
        ref={skullGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, skull: true })}
        src={skullGenreImage}
        alt=""
        hidden
      />

      <img
        ref={smileyGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, smiley: true })}
        src={smileyGenreImage}
        alt=""
        hidden
      />

      <img
        ref={suurGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, suur: true })}
        src={suurGenreImage}
        alt=""
        hidden
      />

      <img
        ref={tankGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, tank: true })}
        src={tankGenreImage}
        alt=""
        hidden
      />

      <img
        ref={tavuGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, tavu: true })}
        src={tavuGenreImage}
        alt=""
        hidden
      />

      <img
        ref={jojoGenreRef}
        onLoad={() => setHasLoaded({ ...hasLoaded, jojo: true })}
        src={jojoGenreImage}
        alt=""
        hidden
      />
    </div>
  );
};

export default ItemShelfViewCanvas;
