import { useTranslation } from 'react-i18next';

import expandRow from '../../services/expandRow';
import { formatDateRelative } from '../../services/date';

import APITable from '../api/APITable';
import BackToQueryButton from '../api/BackToQueryButton';
import OrderView from './OrderView';

const useColumns = () => {
  const { t } = useTranslation();

  // TODO user selectable columns
  const columns = [
    {
      dataField: 'sierraOrderRecordId',
      text: t('ID'),
      sort: true
    },
    {
      dataField: 'Bib.marcTitle',
      text: t('Title')
    },
    {
      dataField: 'Bib.marcAuthor',
      text: t('Author')
    },
    {
      dataField: 'locations',
      text: t('Locations'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        typeof cell === 'string'
          ? cell.split(',').join(', ')
          : '-'
      )
    },
    {
      dataField: 'price',
      text: t('Price'),
      sort: true
    },
    {
      dataField: 'copies',
      text: t('Copies'),
      sort: true
    },
    {
      dataField: 'orderDate',
      text: t('Order Date'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => formatDateRelative(cell, t)
    }
  ];

  return columns;
};

const OrderTable = ({ query, enableQueryParams }) => (
  <>
    {query && <BackToQueryButton resource="orders" query={query} />}

    <APITable
      resource="orders"
      keyField="sierraOrderRecordId"
      columns={useColumns()}
      defaultSortOrder="desc"
      enableQueryParams={enableQueryParams}
      expandRow={expandRow(row => <OrderView orderId={row.sierraOrderRecordId} />)}
      query={query}
    />
  </>
);

export default OrderTable;
