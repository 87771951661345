import { ErrorCondition, useMargins } from '../common/layout';
import { Button, SubmitButton } from '../common/layout/buttons';
import { T } from '../common/i18n';
import { OuterModal, ModalBody, ModalFooter } from '../common/layout/Modal';

import { FormContainer, FormContent } from '../layout/forms';
import { useFormOnSubmit } from '.';

import type { Props as ModalProps } from '../common/layout/Modal';
import type { Props as APIFormProps } from './Form';

type Props = ModalProps & APIFormProps;

const Footer: React.FC<{
  submitting: boolean,
  onHide?: () => void
}> = ({
  submitting,
  onHide
}) => (
  <>
    <Button variant="secondary" onClick={onHide}>
      <T>Cancel</T>
    </Button>

    <SubmitButton submitting={submitting}/>
  </>
);

const APIFormModal: React.FC<Props> = ({
  method,
  path,
  form,
  onHide,
  ...props
}) => {
  const { onSubmit, submitting, error } = useFormOnSubmit(method, path, form, onHide);

  return (
    <OuterModal
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      closeButton
      {...props}
    >
      <FormContainer onSubmit={onSubmit}>
        <ModalBody>
          <FormContent form={form} />

          <ErrorCondition error={error} style={useMargins(0, 0, 10, 0)}>
            <T>Failed to submit form</T>:
          </ErrorCondition>
        </ModalBody>

        <ModalFooter>
          <Footer submitting={submitting} onHide={onHide} />
        </ModalFooter>
      </FormContainer>
    </OuterModal>
  );
};

export default APIFormModal;
