import { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import { getProblems } from '../../services/inventory';
import useQueryParamState from '../../hooks/useQueryParamState';
import useMassreader from '../../hooks/useMassreader';
import useSounds from '../../hooks/useSounds';
import { hasRole } from '../../services/auth';
import { downloadFile } from '../../services/file';
import { getUserLocation } from '../../services/user';
import useReaderInfo from '../../hooks/useReaderInfo';

import { Dropdown, ButtonGroup, Button, Form, Row, Col } from 'react-bootstrap';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import { Download, XSquare } from 'react-bootstrap-icons';
import { Prompt } from 'react-router-dom';

import InventoryTable from '../items/InventoryTable';
import MassreaderSelector from '../api/MassreaderSelector';
import OnClickModal from '../layout/OnClickModal';
import InventoryComment from '../items/InventoryComment';

import {
  ItemStatusFilter,
  LocationFilter,
  CallNumberFilter,
  GenreFilter,
  NotScannedAfterFilter,
  CirculationFilter,
  FloatableFilter,
  CityFilter,
  LanguageFilter,
  ItemTypeFilter,
  ZeroesFilter
} from '../items/InventoryFilters';

const InventoryButtons = ({ items, exportFile, clearList, disableBells }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const onClickClearList = () => {
    clearList();
    setForceClose(true);
  };

  return (
    <>
      <Dropdown size="sm" as={ButtonGroup} style={{ marginRight: '6px' }}>
        <OnClickModal
          forceClose={forceClose}
          onOpen={() => setForceClose(false)}

          button={onClick => (
            <Button
              variant="danger"
              size="sm"
              disabled={items.length === 0}
              onClick={onClick}
            >
              <XSquare style={{ marginTop: '-2px' }} />

              &nbsp;

              {t('Clear List')}
            </Button>
          )}
        >
          <div style={{ marginBottom: '10px' }}>
            <strong>
              {t('Are you sure you want to clear all items on list?')} {t('This cannot be undone.')}
            </strong>
          </div>

          <Button
            variant="danger"
            size="sm"
            onClick={onClickClearList}
            style={{ marginRight: '10px' }}
          >
            {t('Clear List')}
          </Button>

          <Button
            variant="secondary"
            size="sm"
            onClick={() => setForceClose(true)}
          >
            {t('Cancel')}
          </Button>
        </OnClickModal>

        <Dropdown.Toggle
          variant="danger"
          disabled={items.length === 0}
          split
        />

        <Dropdown.Menu>
          <Dropdown.Item onClick={disableBells}>
            {t('Disable all bells')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {/* TODO
      <Button size="sm" onClick={null} style={{ marginRight: '6px' }}>
        <Upload style={{ marginTop: '-2px' }} /> {t('Import')}
      </Button>
      */}

      <Dropdown size="sm" as={ButtonGroup}>
        <Button size="sm" onClick={() => exportFile('sierra-txt')}>
          <Download style={{ marginTop: '-2px' }} /> {t('Export')} ({t('Sierra')} .txt)
        </Button>

        <Dropdown.Toggle split />

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => exportFile('json')}>
            {t('Export')} (.json)
          </Dropdown.Item>

          <Dropdown.Item onClick={() => exportFile('csv')}>
            {t('Export')} (.csv)
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const InventoryFiltersForm = ({ rules, setRules, enabledRules, setEnabledRules, notScannedAfter, setNotScannedAfter }) => {
  const { t } = useTranslation();

  const ruleOptions = [
    { key: 'isil', label: t('Tag ISIL') },
    { key: 'dsfid', label: t('Tag DSFID') },
    { key: 'afi', label: t('Tag AFI') },
    { key: 'tagValid', label: t('Tag Valid') },
    { key: 'barcode', label: t('Barcode') },
    { key: 'itemStatus', label: t('Item Status') },
    { key: 'city', label: t('City') },
    { key: 'location', label: t('Location') },
    { key: 'callNumber', label: t('Call Number') },
    { key: 'genre', label: t('Genre') },
    { key: 'language', label: t('Language') },
    { key: 'itemType', label: t('Item Type') },
    { key: 'bibHold', label: t('Bib Hold') },
    { key: 'itemHold', label: t('Item Hold') },
    { key: 'noItemHold', label: t('No Item Hold') },
    { key: 'checkedOut', label: t('Checked Out') },
    { key: 'notScannedAfter', label: t('Not Scanned After') },
    { key: 'circulation', label: t('Circulation') },
    { key: 'floatable', label: t('Floatable') },
    { key: 'zeroes', label: t('Zeroes List') }
  ];

  const rule = ruleName => enabledRules.includes(ruleName);

  const onSubmit = event => event.preventDefault();

  // TODO paddingLeft -> paddingRight
  return (
    <>
      <Form onSubmit={onSubmit} inline>
        <Form.Group as={Row}>
          <Form.Label xs="auto" column="sm">
            {t('Enabled Rules')}
          </Form.Label>

          <Col xs="auto" style={{ paddingLeft: 0, minWidth: '240px' }}>
            <DropdownMultiselect
              name="enabledRules"
              options={ruleOptions}
              selected={enabledRules}
              placeholder={t('Nothing selected')}
              placeholderMultipleChecked={`${enabledRules.length} ${t('selected')}`}
              selectDeselectLabel={enabledRules.length === ruleOptions.length ? t('Deselect all') : t('Select all')}
              handleOnChange={selected => setEnabledRules(selected)}
            />
          </Col>
        </Form.Group>
      </Form>

      <Form style={{ marginTop: '4px' }} onSubmit={onSubmit}>
        {rule('itemStatus') && <ItemStatusFilter rules={rules} setRules={setRules} />}
        {rule('city') && <CityFilter rules={rules} setRules={setRules} />}
        {rule('location') && <LocationFilter rules={rules} setRules={setRules} />}
        {rule('callNumber') && <CallNumberFilter rules={rules} setRules={setRules} />}
        {rule('genre') && <GenreFilter rules={rules} setRules={setRules} />}
        {rule('language') && <LanguageFilter rules={rules} setRules={setRules} />}
        {rule('itemType') && <ItemTypeFilter rules={rules} setRules={setRules} />}
        {rule('notScannedAfter') && (
          <NotScannedAfterFilter
            rules={rules}
            setRules={setRules}
            notScannedAfter={notScannedAfter}
            setNotScannedAfter={setNotScannedAfter}
          />
        )}
        {rule('circulation') && <CirculationFilter rules={rules} setRules={setRules} />}
        {rule('floatable') && <FloatableFilter rules={rules} setRules={setRules} />}
        {rule('zeroes') && <ZeroesFilter rules={rules} setRules={setRules} />}
      </Form>
    </>
  );
};

const InventoryPage = () => {
  const { t } = useTranslation();

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const [reader, setReader] = useQueryParamState('reader', 'none');
  const [items, setItems] = useState([]);
  const [rules, setRules] = useQueryParamState('rules', config.inventory.rules);
  const [enabledRules, setEnabledRules] = useQueryParamState('enabledRules', config.inventory.defaultEnabledRules);
  const [notifys, setNotifys] = useState({});
  const [showAll, setShowAll] = useQueryParamState('showAll', false);
  const [itemsRead, setItemsRead] = useState(0);
  const [notScannedAfter, setNotScannedAfter] = useQueryParamState('notScannedAfter', yesterday.toISOString().split('T')[0]);
  const [showSignum, setShowSignum] = useQueryParamState('showSignum', true);
  const [scrollOnItem, setScrollOnItem] = useQueryParamState('autoScroll', true);
  const [autoNotify, setAutoNotify] = useQueryParamState('autoNotify', true);
  const [recentlySeen, setRecentlySeen] = useState({});
  const [lastRfidScanLocation, setLastRfidScanLocation] = useState(null);
  const [radarItem, setRadarItem] = useState(null);
  const [radarRssi, setRadarRssi] = useState(null);
  const [rssiMax, setRssiMax] = useState(128);
  const [radarLastSeen, setRadarLastSeen] = useState(null);

  const bottomElementRef = useRef(null);

  const play = useSounds();

  const [readerInfo, updateReaderInfo] = useReaderInfo(reader);

  useEffect(() => {
    const interval = setInterval(() => {
      // Clear old recently seens
      setRecentlySeen(r => {
        const newRecentlySeen = {};

        const treshold = new Date();
        treshold.setSeconds(treshold.getSeconds() - 1);

        for (const [tagId, date] of Object.entries(r)) {
          if (date.getTime() >= treshold.getTime()) {
            newRecentlySeen[tagId] = date;
          }
        }

        return newRecentlySeen;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const addItem = useCallback((item, problems = []) => {
    setItems(i => [...i, { ...item, problems }]);

    if (scrollOnItem && bottomElementRef.current) {
      bottomElementRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [scrollOnItem]);

  // TODO useCallback alert
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const alert = tag => {
    play('alert');

    /*
    if (typeof tag.rssi !== 'number' || isNaN(tag.rssi)) {
      console.error('Invalid RSSI:', tag.rssi);

      return;
    }

    const strength = tag.rssi / 256;

    const minKey = 40;
    const maxKey = 52;

    // [28, 64]
    const key = Math.floor((maxKey - minKey) * strength + minKey)
    const duration = (1 - strength) / 2;

    playSounds(getKey(key), duration, 5, 1);
    */
  };

  const onTagsRead = async tags => {
    // Max RSSI
    for (const tag of tags) {
      if (typeof tag.rssi === 'number') {
        if (tag.rssi > rssiMax) setRssiMax(tag.rssi);
        else if (tag.rssi < rssiMax - 1 && rssiMax > 64) setRssiMax(r => r - 0.025);
      }
    }

    if (radarItem) {
      for (const tag of tags) {
        if (radarItem.tagId === tag.tagId) {
          if (typeof tag.rssi !== 'number' || isNaN(tag.rssi) || tag.rssi < 0) {
            // eslint-disable-next-line no-console
            return console.error('Radar item has invalid RSSI:', tag.rssi);
          }

          setRadarRssi(Number(tag.rssi));
          setRadarLastSeen(new Date());

          return;
        }
      }

      if (radarLastSeen === null || radarLastSeen.getTime() < new Date().getTime() - 600) {
        setRadarRssi(0);
      }
    }
  };

  const onTagRead = useCallback(async (tag, unique, tagIndex) => {
    if (radarItem) return;

    if (unique) {
      // Not scanned after rule
      if (
        enabledRules.includes('notScannedAfter') &&
        tag.item &&
        tag.item.LastItemScan &&
        new Date(tag.item.LastItemScan.lastScanDate) >= new Date(notScannedAfter)
      ) {
        return;
      }

      // Skip boxes
      if (!tag.item && typeof tag.barcode === 'string' && tag.barcode.startsWith('box')) {
        return;
      }

      setTimeout(() => play('ding'), tagIndex * 10);

      const problems = await getProblems(tag, t, rules, enabledRules, () => alert(tag), items);

      if (!tag.item) {
        if (tag.deletedItem) {
          problems.push([
            t('Item has been deleted from Sierra.'),
            t('Historic data is shown and may not be accurate.')
          ].join(' '));
        } else if (tag.deletedBib) {
          problems.push([
            t('Item has been deleted from Sierra.'),
            t('Showing data of bib corresponding to tag\'s ISBN.')
          ].join(' '));
        }
      }

      if (problems.length > 0) {
        addItem(tag, problems);

        if (autoNotify) setNotifys(n => ({ ...n, [tag.tagId]: true }));
      } else if (showAll) {
        addItem(tag);
      }

      setItemsRead(i => i + 1);
    } else if (notifys[tag.tagId]) {
      alert(tag);

      setRecentlySeen(r => ({ ...r, [tag.tagId]: new Date() }));
    }

    if (tag.location && tag.location !== lastRfidScanLocation) {
      setLastRfidScanLocation(tag.location);
    }
  }, [notifys, play, rules, enabledRules, showAll, t, notScannedAfter, addItem, autoNotify, items, lastRfidScanLocation, alert, radarItem]);

  const [/* sendJsonMessage */, /* removeTag */, connectionStatus] = useMassreader(reader, onTagRead, false, onTagsRead);

  const getExportData = useCallback(() => ({
    date: new Date().toISOString(),
    reader,
    items,
    rules,
    enabledRules,
    notifys,
    showAll,
    itemsRead,
    notScannedAfter,
    scrollOnItem
  }), [reader, items, rules, enabledRules, notifys, showAll, itemsRead, notScannedAfter, scrollOnItem]);

  const exportFile = useCallback(type => {
    if (type === 'json') {
      downloadFile(getExportData(), 'inventory-#.json', 'application/json');
    } else if (type === 'sierra-txt') {
      const textFile = 'RECORD #(Nide)\r\n' + items
        .filter(tag => (
          tag.item &&
          typeof tag.item.sierraItemRecordId === 'number' &&
          !isNaN(tag.item.sierraItemRecordId)
        ))
        .map(tag => tag.item.sierraItemRecordId)
        .map(itemId => `"i${itemId}A"`)
        .join('\r\n');

      downloadFile(textFile, 'inventory-#-sierra.txt', 'text/plain');
    } else if (type === 'csv') {
      // TODO
    } else throw new Error(`Invalid export type ${type}`);
  }, [getExportData, items]);

  const removeTag = useCallback(tagId => {
    if (notifys[tagId]) {
      setNotifys({ ...notifys, [tagId]: false });
    }

    setItems(i => i.filter(item => item.tagId !== tagId));
  }, [notifys]);

  const clearList = useCallback(() => {
    setItems([]);
    setNotifys({});
    setItemsRead(0);
  }, []);

  const userLocation = getUserLocation();

  return (
    <>
      <Prompt message={(location, action) => (
        (itemsRead > 0 && action === 'PUSH')
          ? t('Are you sure you want to leave?')
          : true
      )} />

      {hasRole('DEVELOPER') && (
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          {t('Connection')}: {connectionStatus}
        </div>
      )}

      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <div style={{ marginBottom: '4px' }}>
          <MassreaderSelector reader={reader} setReader={setReader} />
        </div>

        {readerInfo && (
          <InventoryComment reader={readerInfo} updateReader={updateReaderInfo} />
        )}

        {(lastRfidScanLocation !== null && lastRfidScanLocation !== userLocation) && (
          <div>
            <strong style={{ color: 'red' }}>
              {t('Warning')}:
            </strong>

            &nbsp;

            {t('User location does not match reader location!')} ({t('Reader location')}: <strong>{lastRfidScanLocation}</strong>)
          </div>
        )}

        <InventoryFiltersForm
          rules={rules}
          setRules={setRules}
          enabledRules={enabledRules}
          setEnabledRules={setEnabledRules}
          notScannedAfter={notScannedAfter}
          setNotScannedAfter={setNotScannedAfter}
        />
      </div>

      {reader !== 'none' && (
        <InventoryTable
          items={items}
          rules={rules}
          enabledRules={enabledRules}
          notifys={notifys}
          setNotifys={setNotifys}
          removeTag={removeTag}
          showSignum={showSignum}
          recentlySeen={recentlySeen}
          radarRssi={radarRssi}
          setRadarItem={setRadarItem}
          rssiMax={Math.ceil(rssiMax)}
          radarLastSeen={radarLastSeen}
        >
          <Form style={{ marginTop: '4px' }}>
            <Form.Check
              type="checkbox"
              label={t('Show spine labels')}
              checked={showSignum}
              onChange={() => setShowSignum(s => !s)}
            />

            <Form.Check
              type="checkbox"
              label={t('Add all read items to list')}
              checked={showAll}
              onChange={() => setShowAll(s => !s)}
            />

            <Form.Check
              type="checkbox"
              label={t('Automatically scroll to new items')}
              checked={scrollOnItem}
              onChange={() => setScrollOnItem(s => !s)}
            />

            <Form.Check
              type="checkbox"
              label={t('Automatically enable bell mode on new items')}
              checked={autoNotify}
              onChange={() => setAutoNotify(s => !s)}
            />
          </Form>

          <Row style={{ marginBottom: '6px' }}>
            <Col sm="auto" className="mt-auto">
              <strong>
                {itemsRead} {t('items read')}, {items.length} {t('items on list')}
              </strong>
            </Col>

            <Col sm="auto" className="ml-auto">
              <InventoryButtons
                items={items}
                exportFile={exportFile}
                clearList={clearList}
                disableBells={() => setNotifys({})}
              />
            </Col>
          </Row>
        </InventoryTable>
      )}

      <span ref={bottomElementRef} />
    </>
  );
};

export default InventoryPage;
