/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO

import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@forevolve/bootstrap-dark/dist/css/toggle-bootstrap-dark.min.css';

import '@ounai/react-bootstrap-table2/dist/react-bootstrap-table2.min.css';
import '@ounai/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { store } from '../state';
import { useIsAuthenticated } from '../users';
import { LoginPage } from '../pages';
import { DialogView } from '../layout/dialogs';
import { Broadcasts } from '.';

import '../style/App.css';
import '../style/dark-theme-fixes.css';

// TODO
import AppAuthenticated from '../legacy/components/app/AppAuthenticated';
import Navigation from '../legacy/components/nav/Navigation';
import Routes from '../legacy/components/app/Routes';

// TODO
/*
const AppAuthenticated2: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Broadcasts />

      <Navigation />

      <Container fluid>
        <Switch>
          <Routes />
        </Switch>
      </Container>

      <DialogView />
      <ToastView />
    </BrowserRouter>
  </Provider>
);
*/

const App: React.FC = () =>
  useIsAuthenticated()
    ? <AppAuthenticated />
    : <LoginPage />;

export default App;
