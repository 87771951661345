import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import paginationFactory from '@ounai/react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from '@ounai/react-bootstrap-table2-filter';

import expandRow from '../../services/expandRow';
import { hasRole } from '../../services/auth';

import BootstrapTable from '@ounai/react-bootstrap-table2';

import ItemView from '../items/ItemView';
import ClickSelect from '../api/ClickSelect';

const ReservationsTable = ({
  reservations,
  enablePagination,
  enableFilters,
  noPatronBarcodeColumn
}) => {
  const { t } = useTranslation();

  const filter = placeholder => (
    enableFilters
      ? textFilter({
        delay: 0,
        placeholder: `${placeholder}...`,
        activeStyle: {
          backgroundColor: 'gold'
        }
      })
      : null
  );

  const sort = reservations.length > 1;

  const columns = [
    {
      dataField: 'id',
      text: t('ID'),
      hidden: true
    },
    {
      dataField: 'indexString',
      text: t('Index'),
      sort,
      filter: filter(t('Index')),
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        let isNull = false;

        const handleNull = index => {
          if (index !== null) {
            return index;
          }

          isNull = true;

          return (order === 'asc') ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        };

        const indexA = handleNull(rowA.index_day_id);
        const indexB = handleNull(rowB.index_day_id);

        return (!isNull && rowA.index_id !== rowB.index_id)
          ? (order === 'asc' ? (rowB.index_id - rowA.index_id) : (rowA.index_id - rowB.index_id))
          : (order === 'asc' ? (indexB - indexA) : (indexA - indexB));
      },
      formatter: (cell, row, rowIndex, formatExtraData) => cell ?? '-'
    },
    {
      dataField: 'book_code',
      text: t('Barcode'),
      sort,
      filter: filter(t('Barcode')),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <ClickSelect pre>
          {cell}
        </ClickSelect>
      )
    },
    {
      dataField: 'book_writer',
      text: t('Author'),
      sort,
      filter: filter(t('Author'))
    },
    {
      dataField: 'book_name',
      text: t('Title'),
      sort,
      filter: filter(t('Title'))
    },
    {
      dataField: 'book_class',
      text: t('Call Number'),
      sort,
      filter: filter(t('Call Number'))
    },
    {
      dataField: 'date_due',
      text: t('Pickup Due Date'),
      sort,
      filter: filter(t('Pickup Due Date')),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        (new Date(cell).getTime() < new Date().getTime() - 1000 * 60 * 60 * 24)
          ? <span style={{ color: 'var(--danger)' }}>{cell}</span>
          : cell
      )
    },
    {
      dataField: 'patron_barcode',
      text: t('Patron Barcode'),
      sort,
      filter: filter(t('Patron Barcode')),
      hidden: noPatronBarcodeColumn,
      formatter: (cell, row, rowIndex, formatExtraData) => <ClickSelect pre>{cell}</ClickSelect>
    },
    {
      dataField: 'patron_contacted',
      text: t('Patron Contacted'),
      sort,
      filter: filter(t('Patron Contacted')),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell
          ? (row.patron_contacted_date || t('yes'))
          : <span style={{ color: 'var(--danger)' }}>{t('no')}</span>
      )
    }
  ];

  const paginationOptions = {
    sizePerPageList: [
      { text: '100', value: 100 },
      { text: '500', value: 500 },
      { text: '1000', value: 1000 },
      { text: t('All'), value: reservations.length }
    ],
    sizePerPage: 100,
    hidePageListOnlyOnePage: false,
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
      <span style={{ marginLeft: '6px' }} className="react-bootstrap-table-pagination-total">
        {t('Showing reservations')} {from} - {to} / {size}.
      </span>
    )
  };

  const data = useMemo(() => reservations.map(reservation => ({
    ...reservation,
    indexString: (
      (reservation.index_var && reservation.index_day_id)
        ? `${reservation.index_var} ${reservation.index_day_id}`
        : null
    )
  })), [reservations]);

  return (
    <>
      <strong>
        {reservations.length} {t('items')}
      </strong>

      <BootstrapTable
        keyField="id"
        columns={columns}
        data={data}
        pagination={enablePagination ? paginationFactory(paginationOptions) : null}
        filter={enableFilters ? filterFactory() : null}
        filterPosition="top"
        classes="w-auto api-table mw-100"
        wrapperClasses="table-responsive"

        expandRow={
          hasRole('VIEW-ITEMS')
            ? expandRow(row => <ItemView barcode={row.book_code} />)
            : undefined
        }

        defaultSorted={[{
          dataField: 'id',
          order: 'desc'
        }]}

        striped
        bootstrap4
      />
    </>
  );
};

export default ReservationsTable;
