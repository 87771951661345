import { useT } from '../common/i18n';

import { APITable } from '../api';

import type { APITableColumn } from '../api';

// TODO
const useColumns = (): APITableColumn[] => {
  const t = useT();

  return [
    {
      field: 'sierraBibRecordId',
      label: t('Sierra ID')
    }
  ];
};

const BibTable: React.FC<{
  enableQueryParams: boolean
}> = ({ enableQueryParams }) => (
  <APITable
    resource="bibs"
    keyField="sierraBibRecordId"
    columns={useColumns()}
    enableQueryParams={enableQueryParams}
  />
);

export default BibTable;
