import { Button } from '../common/layout/buttons';

import { FormContent } from '../layout/forms';
import { useToast } from '../layout/toasts';

import type { IInfo } from '../common/layout';
import type { FormItem, SelectOption } from '../layout/forms';
import type { Toast } from '../layout/toasts';

const options: SelectOption[] = [
  {
    label: 'first',
    value: 'first'
  },
  {
    label: 'second',
    value: 'second'
  }
];

const info: IInfo = {
  type: 'popover',
  contentType: 'text',
  content: 'info\n\nmultiline'
};

const form: FormItem[] = [
  {
    type: 'text',
    name: 'text',
    placeholder: 'text',
    label: 'Text',
    info
  },
  {
    type: 'text-array',
    name: 'text-array',
    placeholder: 'text-array',
    label: 'Text Array',
    info
  },
  {
    type: 'number',
    name: 'number',
    placeholder: 'number',
    label: 'Number',
    info
  },
  {
    type: 'textarea',
    name: 'textarea',
    placeholder: 'textarea',
    label: 'Text Area',
    info
  },
  {
    type: 'password',
    name: 'password',
    placeholder: 'password',
    label: 'Password',
    info
  },
  {
    type: 'select',
    name: 'select',
    options,
    label: 'Select',
    info
  },
  {
    type: 'multiselect',
    name: 'multiselect',
    options,
    label: 'Multiselect',
    info
  },
  {
    type: 'checkbox',
    name: 'checkbox',
    label: 'Checkbox',
    info
  },
  {
    type: 'radio',
    name: 'radio',
    label: 'Radio',
    info
  }
];

const toast: Toast = {
  type: 'info',
  id: 'test-toast',
  title: 'Toast!',
  content: 'Hello, toast!'
};

const ToastButton: React.FC = () => (
  <Button
    variant="primary"
    onClick={useToast(toast)}
    large
  >
    Toast!
  </Button>
);

const Sandbox: React.FC = () => (
  <>
    <ToastButton />

    <FormContent form={form} />
  </>
);

export default Sandbox;
