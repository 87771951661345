import sierraGlobals from '../../sierraGlobals';

import TopTooltip from '../layout/TopTooltip';

const BCode3 = ({ code }) => {
  const [/* eng */, fin, desc] = sierraGlobals.BCode3s[code];

  // TODO eng or fin based on language preference

  return (
    <TopTooltip content={desc}>
      <span>
        {code} ({fin})
      </span>
    </TopTooltip>
  );
};

export default BCode3;
