import { Badge } from 'react-bootstrap';

const CountBadge = ({ count, variant = 'danger' }) => {
  if (count <= 0) return null;
  return (
    <Badge pill variant={variant} style={{ marginLeft: '4px' }}>
      {count}
    </Badge>
  );
};

export default CountBadge;
