import { hasRole } from '../../services/auth';

import { Route } from 'react-router-dom';
import { NavDropdown, Nav } from 'react-bootstrap';

export const AuthorizedNavLink = ({ role, roleNot, as, to, children }) => (
  <Authorized role={role} roleNot={roleNot}>
    <Nav.Link as={as} to={to}>
      {children}
    </Nav.Link>
  </Authorized>
);

export const AuthorizedNavDropdown = ({ role, roleNot, title, children }) => (
  <Authorized role={role} roleNot={roleNot}>
    <NavDropdown title={title}>
      {children}
    </NavDropdown>
  </Authorized>
);

export const AuthorizedNavDropdownItem = ({ role, roleNot, as, to, children }) => (
  <Authorized role={role} roleNot={roleNot}>
    <NavDropdown.Item as={as} to={to}>
      {children}
    </NavDropdown.Item>
  </Authorized>
);

export const AuthorizedRoute = ({ role, roleNot, path, children }) => (
  <Authorized role={role} roleNot={roleNot}>
    <Route path={path} exact>
      {children}
    </Route>
  </Authorized>
);

export const Authorized = ({ role, roleNot, children }) => {
  if (roleNot && hasRole(roleNot)) return null;
  else if (hasRole(role) || hasRole('DEVELOPER')) return children;
  else return null;
};

export default Authorized;
