// Hooks
import useT from './useT';
import useTranslation from './useTranslation';

// Components
import T from './T';
import Tp from './Tp';

export {
  useT,
  useTranslation,

  T,
  Tp
};
