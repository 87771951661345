import { useTranslation } from 'react-i18next';

import useSierraBibRecord from '../../hooks/useSierraBibRecord';

import JSONModal from '../api/JSONModal';

const BibJSON = ({ bib }) => {
  const { t } = useTranslation();

  const [sierraBibRecord, loading, error] = useSierraBibRecord(bib.sierraBibRecordId);

  return (
    <JSONModal
      data={[
        {
          name: t('Lirp Bib Record'),
          data: bib
        },
        {
          name: t('Sierra Bib Record'),
          data: loading
            ? t('Loading...')
            : error
              ? t('Could not fetch bib record')
              : sierraBibRecord?.data
                ? JSON.parse(sierraBibRecord.data)
                : t('Sierra item record not in database')
        }
      ]}

      style={{
        marginRight: '4px',
        marginBottom: '4px'
      }}
    />
  );
};

export default BibJSON;
