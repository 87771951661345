import { useTranslation } from 'react-i18next';

import { formatItemStatus } from '../../services/item';
import { formatDateTimeRelative } from '../../services/date';

import { Link } from 'react-router-dom';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import { Row, Col } from 'react-bootstrap';

import BibTitleAndAuthor from '../bibs/BibTitleAndAuthor';

import { CoverImage } from '../../../bibs';

const ItemDescription = ({ item }) => {
  const { t } = useTranslation();

  const itemType = (item.itemType && item.itemType.description.replace(/ [0-9]+$/, ''));
  const itemLocation = (item.location && item.location.name);

  return (
    <>
      <h3>
        {item.callNumber}

        <Link to={`/items/view/id/${item.sierraItemRecordId}`} style={{ marginLeft: '8px' }}>
          <BoxArrowUpRight size={20} style={{ verticalAlign: 'baseline' }} />
        </Link>
      </h3>

      <BibTitleAndAuthor bib={item.Bib} />

      <div style={{ marginTop: '4px', marginBottom: '4px' }}>
        <div>
          {formatItemStatus(item, t)}
        </div>

        <div>
          {itemType}
          {(itemType && itemLocation) && ', '}
          {itemLocation}
        </div>
      </div>

      <pre style={{ marginBottom: 0 }}>
        {item.barcode}
      </pre>
    </>
  );
};

const ItemDetailsRow = ({ item }) => (
  <Row>
    {item.Bib && (
      <Col xs="auto" style={{ paddingRight: 0 }}>
        <CoverImage isbn={item.Bib.marcISBN} materialType={item.Bib.fixedFieldMaterialType} />
      </Col>
    )}

    <Col>
      <ItemDescription item={item} />
    </Col>
  </Row>
);

const ItemScanBrowserElement = ({ itemScan, highlight }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: '8px' }}>
      <strong>
        {formatDateTimeRelative(itemScan.scanDate, t)}
      </strong>

      <div style={{
        backgroundColor: (highlight ? '#ddffdd' : '#eeeeee'),
        padding: '4px'
      }}>
        {
          itemScan.scannedItem
            ? <ItemDetailsRow item={itemScan.scannedItem} />
            : <strong>{t('Deleted item')}</strong>
        }
      </div>
    </div>
  );
};

export default ItemScanBrowserElement;
