export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
export type HeadingLevelString = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const Heading: React.FC<{
  level?: HeadingLevel,
  style?: React.CSSProperties
}> = ({ level, style, children }) => {
  switch (level ?? 3) {
    case 1: return <h1 style={style}>{children}</h1>;
    case 2: return <h2 style={style}>{children}</h2>;
    case 3: return <h3 style={style}>{children}</h3>;
    case 4: return <h4 style={style}>{children}</h4>;
    case 5: return <h5 style={style}>{children}</h5>;
    case 6: return <h6 style={style}>{children}</h6>;
  }
};

export default Heading;
