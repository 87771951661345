import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { numberField, textField, listField, dateField } from '../../services/queryBuilder';
import sierraGlobals from '../../sierraGlobals';

import QueryBuilder from '../api/QueryBuilder';

import { MassUpdateCreateFromQueryButton } from '../../../sierra/mass-update';

const useFields = () => {
  const { t } = useTranslation();

  const bindings = sierraGlobals.bindings.map(binding => ({
    value: binding,
    label: binding
  }));

  const mapObject = obj => Object.entries(obj).map(([key, value]) => ({
    value: key,
    label: `${key} ${value}`
  }));

  const BCode3s = Object.entries(sierraGlobals.BCode3s).map(([key, value]) => ({
    value: key,
    label: `${key} ${value[1] ?? value[0]}`
  }));

  const fields = [
    numberField('sierra_bib_record_id', t('Sierra Bib ID')),
    textField('marc_control_number', t('Control Number')),
    textField('marc_isbn', t('ISBN')),
    textField('marc_issn', t('ISSN')),
    textField('marc_ean13', t('EAN13')),
    textField('marc_title', t('Title')),
    textField('marc_author', t('Author')),
    textField('marc_series_info', t('Series Info')),
    textField('marc_physical_description', t('Physical Description')),
    listField('marc_binding_info', t('Binding'), bindings),
    textField('marc091', t('MARC Field 091')),
    numberField('marc_published_year', t('Year Published')),
    textField('marc_espoo_class', t('Espoo Class')),
    textField('marc_helsinki_class', t('Helsinki Class')),
    textField('marc_kauniainen_class', t('Kauniainen Class')),
    textField('marc_vantaa_class', t('Vantaa Class')),
    textField('fixed_field_lang', t('Language Code')),
    listField('fixed_field_bcode3', t('Sierra BCode3'), BCode3s),
    listField('fixed_field_material_type', t('Material Type'), mapObject(sierraGlobals.materialTypes)),
    textField('fixed_field_bib_level', t('Bib Level')),
    textField('fixed_field_country', t('Country')),
    numberField('hold_count', t('Hold Count')),
    dateField('sierra_created_at', t('Sierra Created At')),
    dateField('sierra_updated_at', t('Sierra Updated At')),
    dateField('created_at', t('Created At')),
    dateField('updated_at', t('Updated At'))
  ];

  return fields;
};

const BibQueryBuilder = ({ query: initialQuery }) => {
  const [query, setQuery] = useState(null);

  return (
    <>
      <QueryBuilder
        resource="bibs"
        fields={useFields()}
        query={initialQuery}
        onChange={setQuery}
      />

      <MassUpdateCreateFromQueryButton
        recordType="b"
        query={query}
      />
    </>
  );
};

export default BibQueryBuilder;
