import { useState } from 'react';

import api from '.';

import type { Params } from '.';

interface ReturnType {
  post: () => void;
  posting: boolean;
  error: Error | null;
}

const usePostRequest = (
  path: string,
  onSuccess?: () => void,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data?: any,
  params?: Params
): ReturnType => {
  const [posting, setPosting] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const post = async (): Promise<void> => {
    setPosting(true);

    try {
      await api.post(path, data, { params });

      if (onSuccess) onSuccess();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Could not POST ${path}:`, err);

      if (err instanceof Error) {
        setError(err);
      } else {
        throw new Error(`Invalid error object caught: ${err}`);
      }
    }

    setPosting(false);
  };

  return { post, posting, error };
};

export default usePostRequest;
