import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import config from '../../config';

import { Button } from 'react-bootstrap';

import OnClickModal from '../layout/OnClickModal';
import ImageInstructionStep from '../api/ImageInstructionStep';

import instruction0 from '../../../resources/sierrainstructions/01_select_clear_expired_holds.png';
import instruction1 from '../../../resources/sierrainstructions/02_click_show_button.png';
import instruction2 from '../../../resources/sierrainstructions/03_open_printer_menu.png';
import instruction3 from '../../../resources/sierrainstructions/04_choose_email_printer.png';
import instruction4 from '../../../resources/sierrainstructions/05_write_in_email_address.png';
import instruction5 from '../../../resources/sierrainstructions/06_click_print.png';
import instruction6 from '../../../resources/sierrainstructions/07_check_updated_time.png';
import instruction7 from '../../../resources/sierrainstructions/08_click_clear_button.png';
import instruction8 from '../../../resources/sierrainstructions/09_reopen_printer_menu.png';
import instruction9 from '../../../resources/sierrainstructions/10_choose_receipt_printer.png';

const SierraInstructionListItem = ({ location }) => {
  const { t } = useTranslation();

  return (
    <li>
      <OnClickModal
        title={t('Sierra Instructions')}
        button={
          <Button variant="link" style={{ padding: 0, border: 0, verticalAlign: 'top', textAlign: 'left' }}>
            {t('Send the list of items to be cleared in Sierra.')}
          </Button>
        }
        closeButton
      >
        <ImageInstructionStep image={instruction0}>
          1. {t('Open Sierra and select the "Clear Expired Holds and Holdshelf" function.')}
        </ImageInstructionStep>

        <ImageInstructionStep image={instruction1}>
          2. {t('Click "Show".')}
        </ImageInstructionStep>

        <ImageInstructionStep image={instruction2}>
          3. {t('Open the printer dialog from File -> Select Printer -> Standard Printer.')}
        </ImageInstructionStep>

        <ImageInstructionStep image={instruction3}>
          4. {t('Select "email address".')}
        </ImageInstructionStep>

        <ImageInstructionStep image={instruction4}>
          5. {t('Write in')} {location}@{config.api.hostNoWWW}
        </ImageInstructionStep>

        <ImageInstructionStep image={instruction5}>
          6. {t('Click "print".')}
        </ImageInstructionStep>

        <ImageInstructionStep image={instruction6}>
          7. {t('Refresh this page and check that the email has been received.')}
        </ImageInstructionStep>

        <ImageInstructionStep image={instruction7}>
          8. {t('Click "clear" in Sierra.')}
        </ImageInstructionStep>

        <ImageInstructionStep image={instruction8}>
          9. {t('Open the printer dialog (again) from File -> Select Printer -> Standard Printer.')}
        </ImageInstructionStep>

        <ImageInstructionStep image={instruction9}>
          10. {t('Choose the receipt printer.')}
        </ImageInstructionStep>
      </OnClickModal>
    </li>
  );
};

const ExpiredIndexesInstructionListItem = ({ expiredHoldShelfItems, location }) => {
  const { t } = useTranslation();

  const [
    expiredIndexes,
    /* loading */,
    error,
    /* headers */,
    updateExpiredIndexes
  ] = useAPI('GET', `/holdshelf/expired/indexes/${location}`);

  useEffect(() => {
    const interval = setInterval(updateExpiredIndexes, 900 * 1000);

    return () => clearInterval(interval);
  }, [updateExpiredIndexes]);

  // eslint-disable-next-line no-console
  if (error) console.error('Could not load expired indexes');

  const foundExpiredIndexes = ((expiredHoldShelfItems && expiredIndexes) && expiredIndexes.filter(index => {
    for (const item of expiredHoldShelfItems) {
      if (!item.holdShelfIndex || item.markedAsFound) continue;

      const indexVar = item.holdShelfIndex.split(' ')[0];

      if (index === indexVar) return true;
    }

    return false;
  }));

  const foundExpiredIndexesString = (foundExpiredIndexes && foundExpiredIndexes.length > 0)
    ? foundExpiredIndexes.join(', ')
    : t('all expired indexes cleared');

  if (expiredIndexes === null) {
    return null;
  }

  return (
    <li>
      {t('Fetch and check in all items with indexes')}:

      &nbsp;

      <strong>{foundExpiredIndexesString}</strong>
    </li>
  );
};

const ExpiredHoldShelfItemsInstructions = ({ expiredHoldShelfItems, location }) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <strong>
          {t('Instructions')}
        </strong>
      </div>

      <ol>
        <SierraInstructionListItem location={location} />
        <ExpiredIndexesInstructionListItem expiredHoldShelfItems={expiredHoldShelfItems} location={location} />
        <li>{t('Fetch and check in the rest of the items in the following list.')} {t('The items will disappear from the list once they have been checked in or marked as missing.')}</li>
        <li>{t('Mark items that could not be found as missing.')}</li>
      </ol>

      {t('You can use the "Found" checkbox to mark any items you have collected but not yet checked in.')} {t('Items that are marked as found will be removed from the list during the next night.')}
    </>
  );
};

export default ExpiredHoldShelfItemsInstructions;
