import { useTranslation } from 'react-i18next';

import { Form, Row, Col } from 'react-bootstrap';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';

const InventoryTableEnabledColumnsForm = ({ columns, enabledColumns, setEnabledColumns }) => {
  const { t } = useTranslation();

  const options = columns.map(column => ({
    key: column.dataField,
    label: column.text
  }));

  return (
    <Form onSubmit={event => event.preventDefault()} inline>
      <Form.Group as={Row}>
        <Form.Label xs="auto" column="sm">
          {t('Enabled Columns')}
        </Form.Label>

        <Col xs="auto" style={{ paddingLeft: 0, minWidth: '200px' }}>
          <DropdownMultiselect
            name="enabledColumns"
            options={options}
            selected={enabledColumns}
            placeholder={t('Nothing selected')}
            placeholderMultipleChecked={`${enabledColumns.length} ${t('selected')}`}
            selectDeselectLabel={t('Select all')}
            handleOnChange={selected => setEnabledColumns(selected)}
          />
        </Col>
      </Form.Group>
    </Form>
  );
};

export default InventoryTableEnabledColumnsForm;
