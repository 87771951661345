import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAnnouncements from '../../hooks/useAnnouncements';

import { Button, NavDropdown, Row, Col, ProgressBar } from 'react-bootstrap';
import { StopCircle } from 'react-bootstrap-icons';

import OnClickModal from '../layout/OnClickModal';

const AnnouncementsE01 = () => {
  const { t } = useTranslation();
  const playAnnouncement = useAnnouncements();

  const [playing, setPlaying] = useState(false);
  const [name, setName] = useState(null);
  const [stop, setStop] = useState(null);

  const [progress, setProgress] = useState(null);
  const [duration, setDuration] = useState(null);
  const [stopTimeout, setStopTimeout] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => playing && setProgress(p => p + 100), 100);

    return () => clearInterval(interval);
  }, [playing]);

  const getDisplayName = name => ({
    closing15min: t('Closing in 15 minutes'),
    closing5min: t('Closing in 5 minutes'),
    closed: t('Closed'),
    wearMask: t('Wear Mask')
  }[name ?? t('Unknown announcement')]);

  const play = announcement => {
    const {
      duration,
      stop: stopAnnoucement
    } = playAnnouncement(announcement);

    if (duration !== null) {
      setPlaying(true);
      setName(announcement);
      setProgress(0);

      setStop(s => stopAnnoucement);
      setDuration(duration);

      setStopTimeout(setTimeout(() => setPlaying(false), duration));
    }
  };

  const stopPlaying = () => {
    if (playing && typeof stop === 'function') {
      stop();

      setPlaying(false);

      clearTimeout(stopTimeout);
    }
  };

  const PlayCol = ({ name, variant }) => (
    <Col xs="auto" style={{ paddingRight: 0 }}>
      <Button variant={variant ?? 'primary'} size="sm" onClick={() => play(name)} disabled={playing}>
        {getDisplayName(name)}
      </Button>
    </Col>
  );

  return (
    <>
      {playing && (
        <>
          <div style={{ marginBottom: '10px' }}>
            {t('Playing')} <strong>{getDisplayName(name)}</strong>...
          </div>

          <Row style={{ marginBottom: '10px' }}>
            <Col xs="auto" style={{ paddingRight: '6px' }}>
              <Button
                size="sm"
                variant="link"
                onClick={stopPlaying}
                style={{ padding: 0, border: 0 }}
              >
                <StopCircle size={20} style={{ marginTop: '-16px', color: 'var(--danger)' }} />
              </Button>
            </Col>

            <Col style={{ paddingLeft: 0 }}>
              <ProgressBar variant="info" now={Math.min(100, 100 * (progress / duration))} />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <PlayCol name="closing15min" />
        <PlayCol name="closing5min" />
        <PlayCol name="closed" />
      </Row>

      {/*
      <Row style={{ marginTop: '10px' }}>
        <PlayCol variant="warning" name="wearMask" />
      </Row>
      */}
    </>
  );
};

const Content = ({ location }) => {
  if (location === 'e01') return <AnnouncementsE01 />;
  else throw new Error(`Invalid location ${location}`);
};

const AnnouncementsModal = ({ location, locationName }) => {
  const { t } = useTranslation();

  const button = (
    <NavDropdown.Item>
      {t('Announcements')}
    </NavDropdown.Item>
  );

  return (
    <OnClickModal
      title={t('Announcements') + ' ' + (locationName ?? location)}
      button={button}
      closeButton
    >
      <Content location={location} />
    </OnClickModal>
  );
};

export default AnnouncementsModal;
