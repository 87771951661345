import { useTranslation } from 'react-i18next';

import { formatDateTimeRelative } from '../../services/date';
import { formatLocationCode } from '../../services/location';

import TitledDiv from '../api/TitledDiv';

const SierraItemScanDetails = ({ sierraItemScan }) => {
  const { t } = useTranslation();

  const location = (
    (sierraItemScan.LocationCodeLogin && sierraItemScan.LocationCodeLogin.LocationCode && sierraItemScan.LocationCodeLogin.LocationCode.name)
      ? sierraItemScan.LocationCodeLogin.LocationCode.name
      : sierraItemScan.location
  );

  return (
    <>
      <div>
        {location}, {formatDateTimeRelative(sierraItemScan.scanDate, t, true)}
      </div>

      <div>
        Sierra / {formatLocationCode(sierraItemScan.login, sierraItemScan.LocationCodeLogin && sierraItemScan.LocationCodeLogin.LocationCode)}
      </div>
    </>
  );
};

const LIRPItemScanDetails = ({ mirrorItemScan }) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        {mirrorItemScan.location}, {formatDateTimeRelative(mirrorItemScan.scanDate, t, true)}
      </div>

      <div>
        {t('Lirp user')}
      </div>
    </>
  );
};

const LastItemScanDetails = ({ lastItemScan }) => {
  const { t } = useTranslation();

  const sierraItemScan = lastItemScan.ItemScan;
  const mirrorItemScan = lastItemScan.RfidItemScan;

  let itemScan = null;

  if (sierraItemScan && !mirrorItemScan) itemScan = <SierraItemScanDetails sierraItemScan={sierraItemScan} />;
  else if (!sierraItemScan && mirrorItemScan) itemScan = <LIRPItemScanDetails mirrorItemScan={mirrorItemScan} />;
  else if (sierraItemScan && mirrorItemScan) {
    if (new Date(sierraItemScan.scanDate) > new Date(mirrorItemScan.scanDate)) {
      itemScan = <SierraItemScanDetails sierraItemScan={sierraItemScan} />;
    } else {
      itemScan = <LIRPItemScanDetails mirrorItemScan={mirrorItemScan} />;
    }
  }

  return itemScan === null
    ? null
    : <TitledDiv title={t('Last Scan')}>{itemScan}</TitledDiv>;
};

export default LastItemScanDetails;
