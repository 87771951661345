import { locations, bindings, cities, itemStatuses, bibLevels, orderStatuses, materialTypes } from '../sierra';

const sierraGlobals = {};

// Defined in src/sierra/index.ts
sierraGlobals.cities = cities;
sierraGlobals.itemStatuses = itemStatuses;
sierraGlobals.bindings = bindings;
sierraGlobals.locations = locations;
sierraGlobals.bibLevels = bibLevels;
sierraGlobals.orderStatuses = orderStatuses;
sierraGlobals.materialTypes = materialTypes;

sierraGlobals.ownLocations = [
  'e01', 'e02', 'e10', 'e14', 'e15', 'e17', 'e23', 'e25', 'e30',
  'e32', 'e33', 'e36', 'e71', 'e73', 'e76', 'e78', 'e81', 'e97',
  'ekp', 'k01'
];

sierraGlobals.nonFloatingLocations = ['e02', 'ekp'];

sierraGlobals.floatingLocations = sierraGlobals.ownLocations.filter(location => (
  !sierraGlobals.nonFloatingLocations.includes(location)
));

sierraGlobals.ageRatedMaterialTypes = [
  'g', 's', 'h'
];

sierraGlobals.sections = [
  'al', 'll', 'nl', 'ml'
];

sierraGlobals.BCode3s = {
  e: [
    'DVD/VID FICTIO',
    'DVD/BD/VID KAU',
    'DVD-, video- ja BD-nimekkeet, jotka luokitetaan kaunokirjaluokkaan.'
  ],
  f: [
    'DVD/VID MUSIC',
    'DVD/BD/VID MUS',
    'DVD-, video- ja BD-nimekkeet, jotka luokitetaan musiikkiluokkaan.'
  ],
  g: [
    'DVD/VID LANG',
    'DVD/BD/VID KIE',
    [
      'DVD-, video- ja BD-nimekkeet, jotka luokitetaan kielitieteen luokkaan.',
      'DVD-, video- ja BD-muotoiset sanakirjat ja sanastot luokasta riippumatta.'
    ].join(' ')
  ],
  h: [
    'DVD/VID NONFI',
    'DVD/BD/VID TIE',
    [
      'DVD-, video- ja BD-nimekkeet, jotka luokitetaan johonkin muuhun tietokirjaluokkaan',
      'kuin musiikin tai kielitieteen luokkaan.'
    ].join(' ')
  ],
  j: [
    'RECORDING MUS',
    'ÄÄNITE MUSA',
    'Äänitenimekkeet (CD, LP, kasetti), jotka luokitetaan musiikkiluokkaan.'
  ],
  k: [
    'TALKBOOK FICT',
    'ÄÄNIKIR KAUNO',
    'Äänitenimekkeet (CD, LP, kasetti), jotka luokitetaan kaunokirjaluokkaan.'
  ],
  l: [
    'RECORDING LANG',
    'ÄÄNITE KIELET',
    'Äänitenimekkeet (CD, LP, kasetti), jotka luokitetaan kielitieteen luokkaan.'
  ],
  m: [
    'TALKBOOK MUS',
    'ÄÄNIKIR MUSA',
    [
      'Äänitenimekkeet (CD, LP, kasetti),',
      'jotka luokitetaan musiikkiluokkaan ja joita voi pitää äänikirjoina,',
      'esim. muusikon elämäkerta tai muistelma tai musiikin historia äänikirjana.'
    ].join(' ')
  ],
  o: [
    'RECORDING OTHER',
    'ÄÄNITE MUU',
    [
      'Äänitenimekkeet (CD, LP, kasetti),',
      'jotka luokitetaan johonkin muuhun tietokirjaluokkaan',
      'kuin musiikin tai kielitieteen luokkaan,',
      'mutta joita ei voi pitää äänikirjoina,',
      'esim. voimisteluohjeita, äänitehosteita tai luonnonääniä sisältävä äänite.'
    ].join(' ')
  ],
  p: [
    'TALKBOOK NONFI',
    'ÄÄNIKIR TIETO',
    [
      'Äänitenimekkeet (CD, LP, kasetti),',
      'jotka luokitetaan johonkin muuhun tietokirjaluokkaan',
      'kuin musiikin tai kielitieteen luokkaan ja joita voi pitää äänikirjoina,',
      'esim. elämäkerta, muistelma tai matkakertomus äänikirjana.'
    ].join(' ')
  ],
  q: [
    'BOOK / MAG FICT',
    'KIR/LEH KAUNO',
    'Kirja- ja lehtinimekkeet, jotka luokitetaan kaunokirjaluokkaan, mutta eivät ole sarjakuvakirjoja tai –lehtiä.'
  ],
  s: [
    'BOOK / MAG CARTO',
    'KIR/LEH SARJAK',
    'Kaunokirjaluokkin luokitettavat sarjakuvakirjat tai –lehdet. Koodia s käytetään myös silloin, kun harvinaiskielisellä kaunokirjallisuudella ei ole erillistä sarjakuvaluokkaa. Tietokirjaluokkiin sijoitetuille sarjakuville ei anneta koodia s.'
  ],
  t: [
    'BOOK / MAG NONFI',
    'KIR/LEH TIETO',
    'Kirja- ja lehtinimekkeet, jotka luokitetaan johonkin muuhun tietokirjaluokkaan kuin musiikin tai kielitieteen luokkaan.'
  ],
  u: [
    'BOOK / MAG LANG',
    'KIR/LEH KIELET',
    'Kirja- ja lehtinimekkeet, jotka luokitetaan kielitieteen luokkaan. Sanakirjat ja sanastot luokasta riippumatta.'
  ],
  v: [
    'BOOK / MAG MUS',
    'KIR/LEH MUSA',
    'Kirja- ja lehtinimekkeet, jotka luokitetaan musiikkiluokkaan.'
  ],
  w: [
    'OTHER MAT FICT',
    'MUU MAT KAUNO',
    'Muihin materiaaleihin kuuluvat nimekkeet, jotka luokitetaan kaunokirjaluokkaan, esim. kaunokirjjoihin kuuluvat e-kirjat'
  ],
  x: [
    'OTHER MAT MUS',
    'MUU MAT MUSA',
    'Muihin materiaaleihin kuuluvat nimekkeet, jotka luokitetaan musiikkiluokkaan. Tähän esim. musiikkiluokkiin sijoitetut nuotit.'
  ],
  y: [
    'OTHER MAT LANG',
    'MUU MAT KIELET',
    'Muihin materiaaleihin kuuluvat nimekkeet, jotka luokitetaan kielitieteen luokkaan. Muihin materialeihin kuuluvat sanakirjat ja sanastot luokasta riippumatta.'
  ],
  z: [
    'OTHER MAT NONF',
    'MUU MAT TIETO',
    'Muihin materiaaleihin kuuluvat nimekkeet, jotka luokitetaan johonkin muuhun tietokirjaluokkaan kuin musiikin tai kielitieteen luokkaan. Tähän esim. tietokirjaluokkiin sijoitetut kartat, pelit, CD-ROM-levyt ja e-kirjat.'
  ],
  a: [
    'ACQUISITION',
    'HANKINTA',
    'Hankintavaiheessa oleva valittu tai tilattu teos, joka näytetään HelMetissä. Verkkokaupassa valittujen teosten tietoihin tulee on tämä koodi, kun tietueet ajetaan HelMet- tietokantaan. Kun  teos luetteloidaan, koodin arvo on muutettava joksikin seuraavista: e, f, g, h, j, k, l, m, o, p, q, s, t, u, v, w, x, y z'
  ],
  b: [
    'SUPPRESS BTJ',
    'PIILOTETTU BTJ',
    'BTJ:n tietojen piilotuskoodi. BTJ:stä saataviin täysiin nimeketitoihin tulee tämä koodi, kun tietueet ajetaan HelMet-tietokantaan. Koodi estää nimekkeitä näkymästä yleisöhauissa. Kun BTJ:n tietue otetaan käyttöön, koodin arvo on muutettava joksikin seuraavista: e, f, g, h, j, k, l, m, o, p, q, s, t, u, v, w, x, y z Kun BTJ:n tietue korvaa Sierrassa jo olevan nimeketietueen, alkuperäisen tietueen BCODE3-arvo säilyy.'],
  c: [
    'SUPPRESS ACQ',
    'PIILOTETTU HANKINTA',
    [
      'Hankintavaiheessa oleva valittu tai tilattu teos, jota ei näytetä HelMetissä.',
      'Kun teos luetteloidaan, koodin arvo on muutettava joksikin seuraavista:',
      'e, f, g, h, j, k, l, m, o, p, q, s, t, u, v, w, x, y z'
    ].join(' ')
  ],
  n: [
    'SUPPRESSION',
    'PIILOTETTU',
    [
      'Piilotuskoodi. Tällä koodilla merkitty nimeke ei näy yleisöhauissa.',
      'Jos nimeke halutaan mukaan hakuihin, koodin arvo on muutettava joksikin seuraavista:',
      'e, f, g, h, j, k, l, m, o, p, q, s, t, u, v, w, x, y z'
    ].join(' ')
  ],
  d: [
    'DELETE CODE',
    'POISTETTU KOODI',
    'Nimekkeen poistokoodi, ei käytetä.'
  ],
  4: [
    'CANCELLED',
    'PERUTTU',
    [
      'Nimeke ei ilmesty. Tilaukset perutaan.',
      'Nimeketietue jolla on BCODE3:n arvo 4 näytetään edelleen HelMet-haussa, mutta sitä ei voi varata.'
    ].join(' ')
  ]
};

export default sierraGlobals;
