import { useCallback } from 'react';
import useSound from 'use-sound';

import dingSound from '../../resources/sounds/ding.wav';
import alertSound from '../../resources/sounds/alert.wav';
import radarSound from '../../resources/sounds/radar.wav';

const useSounds = () => {
  const [playDing] = useSound(dingSound);
  const [playAlert] = useSound(alertSound);
  const [playRadar] = useSound(radarSound);

  const play = useCallback(soundName => {
    if (soundName === 'ding') playDing();
    else if (soundName === 'alert') playAlert();
    else if (soundName === 'radar') playRadar();
    else throw new Error(`Sound ${soundName} does not exist!`);
  }, [playDing, playAlert, playRadar]);

  return play;
};

export default useSounds;
