import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

import type { Dialog } from '../../layout/dialogs';

interface DialogsState {
  open: Dialog[];
}

const initialState: DialogsState = {
  open: []
};

const reducers = {
  open: (state: DialogsState, action: PayloadAction<Dialog>): void => {
    state.open.push(action.payload);
  },
  closeFirst: (state: DialogsState): void => {
    state.open = state.open.slice(1);
  },
  closeAll: (state: DialogsState): void => {
    state.open = [];
  }
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers
});

export const selectOpen = (state: RootState): Dialog[] =>
  state.dialogs.open;

export const {
  open,
  closeFirst,
  closeAll
} = dialogsSlice.actions;

export default dialogsSlice.reducer;
