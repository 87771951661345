import { T } from '../common/i18n';
import { ErrorCondition, useMargins, SuccessMessage } from '../common/layout';
import { SubmitButton } from '../common/layout/buttons';

import { FormContainer, FormContent } from '../layout/forms';
import { useFormOnSubmit } from '.';

import type { FormItem, FormData } from '../layout/forms';
import type { Method } from '.';

export interface Props {
  method: Method;
  path: string;
  form: FormItem[];
  submitText?: string;
  onSuccess?: (data: FormData) => void;
  successMessage?: string | React.ReactNode;
}

const APIForm: React.FC<Props> = ({
  method,
  path,
  form,
  submitText,
  onSuccess,
  successMessage
}) => {
  const {
    onSubmit,
    submitting,
    submitted,
    error
  } = useFormOnSubmit(method, path, form, onSuccess);

  const messageMargins = useMargins(0, 0, 10, 0);

  return (
    <FormContainer onSubmit={onSubmit}>
      <FormContent form={form} />

      {(form.length > 0) && (
        <SubmitButton submitting={submitting}>
          {submitText}
        </SubmitButton>
      )}

      {(submitted && successMessage) && (
        <SuccessMessage style={messageMargins}>
          {successMessage}
        </SuccessMessage>
      )}

      <ErrorCondition error={error} style={messageMargins}>
        <T>Failed to submit form</T>:
      </ErrorCondition>
    </FormContainer>
  );
};

export default APIForm;
