import BootstrapModal from 'react-bootstrap/Modal';

interface ContainerProps {
  animation?: boolean;
  show?: boolean;
  onHide?: () => void;
  backdrop?: boolean | 'static';
  keyboard?: boolean;
}

interface OuterModalProps {
  closeButton?: boolean;
  title?: React.ReactNode;
}

interface ModalProps {
  footer?: React.ReactNode;
}

export type Props = ContainerProps & OuterModalProps & ModalProps;

export const ModalContainer: React.FC<ContainerProps> = ({
  animation,
  show,
  onHide,
  backdrop,
  children,
  ...props
}) => (
  <BootstrapModal
    animation={animation}
    show={show ?? true}
    backdrop={backdrop}
    onHide={onHide}
    {...props}
  >
    {children}
  </BootstrapModal>
);

export const OuterModal: React.FC<ContainerProps & OuterModalProps> = ({
  closeButton,
  title,
  children,
  ...props
}) => (
  <ModalContainer {...props}>
    {(closeButton || title) && (
      <BootstrapModal.Header closeButton={closeButton}>
        <BootstrapModal.Title>
          {title}
        </BootstrapModal.Title>
      </BootstrapModal.Header>
    )}

    {children}
  </ModalContainer>
);

export const ModalBody: React.FC = ({ children }) => (
  <BootstrapModal.Body>
    {children}
  </BootstrapModal.Body>
);

export const ModalFooter: React.FC = ({ children }) => (
  <BootstrapModal.Footer>
    {children}
  </BootstrapModal.Footer>
);

const Modal: React.FC<Props> = ({
  footer,
  children,
  ...props
}) => (
  <OuterModal {...props}>
    <ModalBody>
      {children}
    </ModalBody>

    {footer && (
      <ModalFooter>
        {footer}
      </ModalFooter>
    )}
  </OuterModal>
);

export default Modal;
