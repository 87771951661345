import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';

import { Button, Form, Col, Row } from 'react-bootstrap';

import OnClickModal from '../layout/OnClickModal';

const CommentEditor = ({ reader, comment, onSubmit }) => {
  const { t } = useTranslation();

  if (!reader) throw new Error('Invalid reader!');
  if (typeof reader.id !== 'number') throw new Error(`Invalid reader ID ${reader.id}`);

  const onFormSubmit = async event => {
    event.preventDefault();

    const newComment = event.target.comment.value;

    if (newComment !== comment) {
      await api.post(`/rfid/readers/${reader.id}/comment`, {
        comment: newComment
      });
    }

    if (typeof onSubmit === 'function') onSubmit();
  };

  const deleteComment = async () => {
    if (comment !== null) {
      await api.post(`/rfid/readers/${reader.id}/comment`, {
        comment: null
      });
    }

    if (typeof onSubmit === 'function') onSubmit();
  };

  return (
    <Form onSubmit={onFormSubmit}>
      <Form.Group>
        <Form.Label>
          {t('Comment')}
        </Form.Label>

        <Form.Control
          name="comment"
          as="textarea"
          size="sm"
          defaultValue={comment}
          autoSelect
        />
      </Form.Group>

      <Row>
        <Col xs="auto">
          <Button
            size="sm"
            type="submit"
          >
            {t('Save')}
          </Button>
        </Col>

        <Col className="ml-auto" xs="auto">
          <Button
            size="sm"
            variant="danger"
            onClick={deleteComment}
          >
            {t('Delete Comment')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const CommentButton = ({ comment }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="link"
      size="sm"
      style={{ marginLeft: '6px', padding: 0, border: 0, verticalAlign: 'baseline' }}
    >
      [{comment ? t('edit comment') : t('add comment')}]
    </Button>
  );
};

const InventoryComment = ({ reader, updateReader }) => {
  const [forceClose, setForceClose] = useState(false);

  const comment = reader?.Comment?.comment ?? '';

  return (
    <div style={{ marginBottom: '10px' }}>
      {comment.length > 0 && `"${comment}"`}

      <OnClickModal
        button={<CommentButton comment={comment} />}
        forceClose={forceClose}
        onOpen={() => setForceClose(false)}
      >
        <CommentEditor
          reader={reader}
          comment={comment}
          onSubmit={() => {
            setForceClose(true);
            updateReader();
          }}
        />
      </OnClickModal>
    </div>
  );
};

export default InventoryComment;
