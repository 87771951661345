const BibTitleAndAuthor = ({ bib }) => {
  if (!bib) return null;

  return (
    <>
      <strong>{bib.marcTitle}</strong> {bib.marcAuthor && `/ ${bib.marcAuthor}`}
    </>
  );
};

export default BibTitleAndAuthor;
