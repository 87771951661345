import { useT } from '../../i18n';
import { Loading } from '..';
import { Button } from '.';

import type { Props as ButtonProps } from './Button';

interface SubmitProps {
  submitting: boolean;
}

const SubmitButton: React.FC<ButtonProps & SubmitProps> = ({
  submitting,
  disabled,
  children,
  ...props
}) => {
  const t = useT();

  return (
    <Button type="submit" variant="primary" disabled={submitting || disabled} {...props}>
      {submitting
        ? <Loading text={t('Submitting...')} />
        : (children ?? t('Submit'))}
    </Button>
  );
};

export default SubmitButton;
