import { useCommons } from '../../layout/forms';
import { useOrderPriorityFormItem } from '.';

import type { Link } from '../../api/models';
import type { FormItem } from '../../layout/forms';

const useLinkForm = (link?: Link): FormItem[] => {
  const commons = useCommons();

  return [
    commons.nameRequired(link?.name),
    commons.description(link?.description),
    commons.urlRequired(link?.url),
    commons.keywords(link?.keywords),
    commons.visible(link?.visible ?? true),
    useOrderPriorityFormItem(link?.orderPriority)
  ];
};

export default useLinkForm;
