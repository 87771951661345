import BootstrapAlert from 'react-bootstrap/Alert';

type AlertVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';

const Alert: React.FC<{
  variant?: AlertVariant,
  heading?: React.ReactNode
}> = ({ variant, heading, children }) => (
  <BootstrapAlert variant={variant ?? 'primary'}>
    {heading && (
      <BootstrapAlert.Heading>
        {heading}
      </BootstrapAlert.Heading>
    )}

    {children}
  </BootstrapAlert>
);

export default Alert;
