import { useState } from 'react';

import { Alert } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

import { hasRole } from '../../services/auth';
import api from '../../services/api';
import { getUserLocation } from '../../services/user';

const BroadcastAlert = ({ id, variant, content, notDismissible }) => {
  const userLocation = getUserLocation();

  const [show, setShow] = useState(
    !(JSON.parse(localStorage.getItem('dismissedBroadcasts')) || []).includes(id) ||
    (!hasRole('DEVELOPER') && content.startsWith(userLocation + ':'))
  );

  const onClose = () => {
    if (hasRole('DEVELOPER')) {
      api.delete(`/broadcasts/${id}`);
    } else {
      const dismissedBroadcasts = JSON.parse(localStorage.getItem('dismissedBroadcasts')) || [];

      while (dismissedBroadcasts.length > 100) dismissedBroadcasts.shift();

      dismissedBroadcasts.push(id);

      localStorage.setItem('dismissedBroadcasts', JSON.stringify(dismissedBroadcasts));
    }

    setShow(false);
  };

  if (
    !show ||
    (!hasRole('DEVELOPER') && /^[EeVvHhKk][0-9]{2}:/.test(content) && !content.startsWith(userLocation + ':'))
  ) {
    return null;
  }

  return (
    <Alert
      className="broadcast-alert"
      variant={variant}
      dismissible={!notDismissible}
      onClose={onClose}
    >
      <center>
        <ReactMarkdown>
          {
            (!hasRole('DEVELOPER') && content.startsWith(userLocation + ':'))
              ? content.slice(4).trim()
              : content
          }
        </ReactMarkdown>
      </center>
    </Alert>
  );
};

export default BroadcastAlert;
