import { useTranslation } from 'react-i18next';

import { hasRole } from '../../services/auth';
import useAPI from '../../hooks/useAPI';

import { Button } from 'react-bootstrap';

import OnClickModal from '../layout/OnClickModal';
import BookshelfView from './BookshelfView';
import Loading from '../api/Loading';

const SubjectHeadingButton = ({ subjectHeading, disabled }) => (
  <Button
    disabled={disabled}
    variant="link"
    size="sm"
    style={{
      padding: 0,
      border: 0,
      marginRight: '10px'
    }}
  >
    {subjectHeading}
  </Button>
);

const SubjectHeadingTitle = ({ subjectHeading }) => {
  const { t } = useTranslation();

  return (
    <>
      {t('Subject Heading')}: <strong>{subjectHeading}</strong>
    </>
  );
};

const SubjectHeadingBookshelf = ({ subjectHeading, sourceBib }) => {
  const { t } = useTranslation();

  const filters = {};

  if (sourceBib) {
    if (sourceBib.fixedFieldLang) filters.bibLanguages = [sourceBib.fixedFieldLang];
    if (sourceBib.fixedFieldMaterialType) filters.bibMaterialTypes = [sourceBib.fixedFieldMaterialType];
  }

  const [bibs, loading, error] = useAPI('GET', '/bibs', {
    params: {
      simple: true,
      query: `(${subjectHeading})`,
      sortField: 'sierraBibRecordId',
      sortOrder: 'desc',
      filters
    }
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch bibs');
  }

  return <BookshelfView bibs={bibs} />;
};

const SubjectHeadings = ({ subjectHeadings, sourceBib }) => {
  const disabled = !hasRole('DEVELOPER'); // TODO

  return subjectHeadings.map(({ content }, index) => (
    <OnClickModal
      key={content}
      size="lg"
      button={<SubjectHeadingButton subjectHeading={content} disabled={disabled} />}
      title={<SubjectHeadingTitle subjectHeading={content} />}
      forceClose={disabled}
      closeButton
    >
      <SubjectHeadingBookshelf
        subjectHeading={content}
        sourceBib={sourceBib}
      />
    </OnClickModal>
  ));
};

export default SubjectHeadings;
