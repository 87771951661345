import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import sierraGlobals from '../../sierraGlobals';

import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';

const MaterialTypeSelector = ({ defaultValue, onChange, sm, defaultAllSelected }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(defaultValue ?? []);

  const onDropdownChange = selected => {
    setSelected(selected);

    if (typeof onChange === 'function') {
      if (selected.length === Object.keys(sierraGlobals.materialTypes).length) {
        onChange([]);
      } else {
        onChange(selected);
      }
    }
  };

  const options = Object.entries(sierraGlobals.materialTypes).map(([code, name]) => ({
    key: code,
    label: `${code} ${name}`
  }));

  return (
    <div className={sm ? 'dropdown-sm-container' : ''}>
      <DropdownMultiselect
        name="materialTypes"
        options={options}
        selected={defaultValue}
        placeholder={defaultAllSelected ? `${t('All')} (${t('default')})` : t('Nothing selected')}
        selectDeselectLabel={t('Select all')}
        placeholderMultipleChecked={selected.length === options.length ? t('All') : selected.join(', ')}
        handleOnChange={onDropdownChange}
      />
    </div>
  );
};

export default MaterialTypeSelector;
