import { TODO } from '../../common/placeholders';

import type { MassUpdateOperation } from '../../api/models';

const OperationsList: React.FC<{
  operations: MassUpdateOperation[]
}> = ({ operations }) =>
  <TODO OperationsList operations={operations} />;

export default OperationsList;
