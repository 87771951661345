import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserLocation } from '../../services/user';
import { setLocation } from '../../services/item';
import { incrementStatistic } from '../../services/inventory';

import { Button, Spinner } from 'react-bootstrap';

const InvalidLocationFix = ({ item }) => {
  const { t } = useTranslation();

  const [fixing, setFixing] = useState(false);
  const [fixed, setFixed] = useState(false);

  const userLocation = getUserLocation();
  const fixedLocation = userLocation + item.fixedFieldLocation.slice(3);

  const fixLocation = async () => {
    setFixing(true);

    await setLocation(item.sierraItemRecordId, fixedLocation);
    incrementStatistic('locationChanges');

    setFixing(false);
    setFixed(true);
  };

  return (
    <>
      <span style={{ marginRight: '6px' }}>
        {t('Invalid location')} {item.fixedFieldLocation}
      </span>

      {(
        item.fixedFieldLocation.slice(0, 3) !== userLocation &&
        ['e', 'v'].includes(item.fixedFieldLocation[0])
      ) && (
        <div>
          {fixing && <Spinner size="sm" animation="border" style={{ marginRight: '4px' }} />}

          <Button
            size="sm"
            variant="link"
            disabled={fixed}
            onClick={fixLocation}
            style={{ padding: 0, border: 0, verticalAlign: 'baseline' }}
          >
            [{
              fixed
                ? `${t('location set to')} ${fixedLocation}`
                : `${t('set location to')} ${fixedLocation}`
            }]
          </Button>
        </div>
      )}
    </>
  );
};

export default InvalidLocationFix;
