import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserLocation } from '../../services/user';
import { setItemStatus, setLocation } from '../../services/item';

import { Button, ProgressBar } from 'react-bootstrap';

import OnClickModal from '../layout/OnClickModal';
import Authorized from '../auth/Authorized';

const FloatingModal = ({ item, floatableLocations, counts }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);
  const [floated, setFloated] = useState(false);
  const [floatedLocation, setFloatedLocation] = useState(null);

  const ownLocation = getUserLocation();
  const maxCount = Object.values(counts).reduce((acc, cur) => Math.max(acc, cur), 0);

  const floatItem = async newLocation => {
    setFloated(true);

    await setItemStatus(item.sierraItemRecordId, 't');
    await setLocation(item.sierraItemRecordId, newLocation);

    setFloatedLocation(newLocation);
    setForceClose(true);
  };

  const floatButton = (
    <Button
      size="sm"
      variant="link"
      disabled={floated}
      style={{
        padding: 0,
        border: 0,
        verticalAlign: 'baseline'
      }}
    >
      [{
        (floated && floatedLocation)
          ? `${t('floated')}: ${floatedLocation}`
          : t('float')
      }]
    </Button>
  );

  return (
    <Authorized role="EDIT-ITEMS">
      <OnClickModal
        title={t('Floating')}
        button={floatButton}
        forceClose={forceClose}
        onOpen={() => setForceClose(floated)}
        closeButton
      >
        <div>
          <div>
            <strong>{ownLocation}</strong> - {counts[ownLocation]} ({t('own')})
          </div>

          <ProgressBar now={(counts[ownLocation] / maxCount) * 100} />
        </div>

        {floatableLocations.map(floatableLocation => (
          <div key={floatableLocation} style={{ marginTop: '6px' }}>
            <div>
              <span style={{ marginRight: '6px' }}>
                <strong>{floatableLocation}</strong> - {counts[floatableLocation.slice(0, 3)]}
              </span>

              <Button
                size="sm"
                variant="link"
                onClick={() => floatItem(floatableLocation)}
                disabled={floated}
                style={{
                  padding: 0,
                  border: 0,
                  verticalAlign: 'baseline'
                }}
              >
                [{t('float')}]
              </Button>
            </div>

            <ProgressBar now={(counts[floatableLocation.slice(0, 3)] / maxCount) * 100} />
          </div>
        ))}
      </OnClickModal>
    </Authorized>
  );
};

export default FloatingModal;
