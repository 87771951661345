import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import { formatDateTimeRelative } from '../../services/date';
import { formatLocationCode, formatLocationInfo } from '../../services/location';

import { Button, Form } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

import OnClickModal from '../layout/OnClickModal';
import ItemScanBrowser from './ItemScanBrowser';
import RfidItemScanBrowser from './RfidItemScanBrowser';
import Loading from '../api/Loading';

const ItemScanLoginElement = ({ login, locationCode }) => {
  const { t } = useTranslation();

  return (
    <div>
      {t('Sierra')} / {formatLocationCode(login, locationCode) || login}
    </div>
  );
};

const ItemScanLocationInfoElement = ({ locationInfo }) => {
  if (!locationInfo) {
    return null;
  }

  return (
    <div>
      {formatLocationInfo(locationInfo)}
    </div>
  );
};

const RFIDItemScanReaderElement = ({ reader, comments }) => {
  const { t } = useTranslation();

  return reader
    ? (reader.massreader ? t('RFID Massreader') : t('RFID Reader')) +
      ' ' +
      reader.name +
      ((comments && comments.length > 0) ? ` (${comments.map(({ comment }) => comment).join('; ')})` : '')
    : t('Lirp user');
};

export const ItemScan = ({ itemScan }) => {
  const { t } = useTranslation();

  const browseButton = (
    <Button
      variant="link"
      style={{
        padding: 0,
        marginLeft: '6px',
        verticalAlign: 'baseline'
      }}
    >
      <Search />
    </Button>
  );

  const itemScanBrowserModal = (
    <OnClickModal
      title={`${t('Item Scans')} ${itemScan.login}` }
      button={browseButton}
      closeButton
    >
      <ItemScanBrowser
        itemScanId={itemScan.id}
        location={itemScan.location}
        login={itemScan.login}
      />
    </OnClickModal>
  );

  const rfidItemScanBrowserModal = (
    <OnClickModal
      title={`${t('RFID Item Scans')}` }
      button={browseButton}
      closeButton
    >
      <RfidItemScanBrowser
        itemScanId={itemScan.id}
        location={itemScan.location}
      />
    </OnClickModal>
  );

  return (
    <div style={{ marginBottom: '8px' }}>
      <div>
        <strong>
          {formatDateTimeRelative(itemScan.scanDate, t)}
        </strong>

        {(itemScan.id && itemScan.login) ? itemScanBrowserModal : rfidItemScanBrowserModal}
      </div>

      {
        itemScan.login
          ? (
            <ItemScanLoginElement
              login={itemScan.login}
              locationCode={itemScan.LocationCodeLogin && itemScan.LocationCodeLogin.LocationCode}
            />
            )
          : <RFIDItemScanReaderElement reader={itemScan.RfidReader} comments={itemScan.Comments} />
      }

      <ItemScanLocationInfoElement locationInfo={itemScan.LocationInfo} />
    </div>
  );
};

const ItemScansFiltersForm = ({ limit, setLimit, hideRFID, setHideRFID }) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={event => event.preventDefault()} inline>
      <Form.Label style={{ marginRight: '6px' }}>
        {t('Show')}
      </Form.Label>

      <Form.Control
        as="select"
        size="sm"
        defaultValue={limit}
        onChange={event => setLimit(event.target.value === 'all' ? 'all' : Number(event.target.value))}
        style={{ marginRight: '10px' }}
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="all">{t('All')}</option>
      </Form.Control>

      <Form.Check
        label={t('Show only Sierra scans')}
        value={hideRFID}
        onChange={() => setHideRFID(h => !h)}
      />
    </Form>
  );
};

const ItemScans = ({ itemId, hideTitle, count }) => {
  const { t } = useTranslation();

  const defaultLimit = 10;

  const [limit, setLimit] = useState(count || defaultLimit);
  const [hideRFID, setHideRFID] = useState(false);

  const params = {};

  if (limit !== 'all') params.limit = limit;

  const [itemScans, loading, error] = useAPI('GET', `/items/${itemId}/scans`, { params }, [limit]);

  if (loading) return <Loading />;
  else if (error) return t('Could not fetch item scans');
  else {
    return (
      <>
        {hideTitle || <h3>{t('Item Scans')}</h3>}

        {typeof count === 'number' || (
          <div style={{ marginBottom: '4px' }}>
            <ItemScansFiltersForm
              limit={limit}
              setLimit={setLimit}
              hideRFID={hideRFID}
              setHideRFID={setHideRFID}
            />
          </div>
        )}

        {
          itemScans.length === 0
            ? <strong>{t('No item scans found.')}</strong>
            : itemScans.filter(itemScan => !hideRFID || itemScan.login).map(itemScan => (
              <ItemScan key={itemScan.id} itemScan={itemScan} />
            ))
        }
      </>
    );
  }
};

export default ItemScans;
