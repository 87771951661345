import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

import { MapContainer, TileLayer, LayersControl } from 'react-leaflet';

import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
  tooltipAnchor: [16, -28]
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = ({ height, children }) => {
  const position = [60.2174829, 24.8095368];
  const zoom = 13;

  if (!height) throw new Error('Map: no height given');

  const mapStyle = {
    height: `${height}px`,
    width: '100%'
  };

  return (
    <MapContainer center={position} zoom={zoom} style={mapStyle}>
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>

        {children}
      </LayersControl>
    </MapContainer>
  );
};

export default Map;
