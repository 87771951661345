import { useTranslation } from 'react-i18next';

import ReceiptButton from '../api/ReceiptButton';

const ReservationReceiptElement = ({ reservation }) => {
  const { t } = useTranslation();

  const indexDiv = (
    <div>
      <strong style={{ fontSize: '36px' }}>
        {reservation.index_var} {reservation.index_day_id}
      </strong>
    </div>
  );

  return (
    <div style={{ marginBottom: '20px' }}>
      {reservation.index_var && indexDiv}
      <div><strong>{t('Barcode')}:</strong> {reservation.book_code}</div>
      <div><strong>{t('Author')}:</strong> {reservation.book_writer}</div>
      <div><strong>{t('Title')}:</strong> {reservation.book_name}</div>
      <div><strong>{t('Call Number')}:</strong> {reservation.book_class}</div>
      {reservation.patron_name && <div><strong>{t('Patron Alias')}:</strong> {reservation.patron_name}</div>}
    </div>
  );
};

const ReservationsReceipt = ({ reservations }) => {
  const reservationElements = reservations.map(reservation => (
    <ReservationReceiptElement
      key={`${reservation.index_var} ${reservation.index_day_id} ${reservation.book_code}`}
      reservation={reservation}
    />
  ));

  return (
    <ReceiptButton>
      {reservationElements}
    </ReceiptButton>
  );
};

export default ReservationsReceipt;
