import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import filterFactory, { textFilter } from '@ounai/react-bootstrap-table2-filter';

import api from '../../services/api';
import useAPI from '../../hooks/useAPI';
import expandRow from '../../services/expandRow';
import { formatMaterialType } from '../../services/bib';
import { formatOrderStatus } from '../../services/order';
import { getCreateItemStatus } from '../../services/shipment';
import { formatDateRelative } from '../../services/date';
import { downloadFile } from '../../services/file';

import { Button } from 'react-bootstrap';
import BootstrapTable from '@ounai/react-bootstrap-table2';
import { Download } from 'react-bootstrap-icons';

import BibTitleAndAuthor from '../bibs/BibTitleAndAuthor';
import ClickSelect from '../api/ClickSelect';
import Loading from '../api/Loading';
import ShipmentTabView from './ShipmentTabView';
import SierraID from '../api/SierraID';

import { CoverImage } from '../../../bibs';

const ShipmentStatus = ({ shipment, receivedCount }) => {
  const { t } = useTranslation();

  const status = getCreateItemStatus(shipment, t);

  if (status.missingReceivedItems) {
    return (
      <strong>
        {receivedCount} / {shipment.copiesOnShipment} {t('scanned')}
      </strong>
    );
  } else if (status.problems.length > 0) {
    return status.problems.map(problem => (
      <div key={problem}>
        - {problem}
      </div>
    ));
  } else if (status.allItemsCreated) {
    const receivedDateSierraFormat = new Date(shipment.itemsCreatedDate || shipment.receptionDate)
      .toISOString().split('T')[0].split('-').reverse().join('-');

    return (
      <>
        {t('All items created')}

        {(shipment.Order.receivedDate === null) && (
          <div style={{ marginTop: '6px' }}>
            <strong>
              {t('Order has no received date')} (<ClickSelect>{receivedDateSierraFormat}</ClickSelect>)
            </strong>
          </div>
        )}

        {shipment.labelsPrinted || (
          <div style={{ marginTop: '6px' }}>
            <strong>
              {t('Spine labels not printed yet')}
            </strong>
          </div>
        )}

        {status.status === 'q' && (
          <div style={{ marginTop: '6px' }}>
            <strong>
              {t('Order paid in part')}
            </strong>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {(status.someItemsCreated
          ? t('Some items created')
          : t('Items ready to be created'))}
        {(shipment.Order.Bib.fixedFieldCatalogueDate === null) &&
          (
            < div style={{ marginTop: '6px' }
            }>
              <strong>
                {t('Check if full marc available')}
              </strong>
            </div >
          )}
      </>
    );
  }
};

const ShipmentBootstrapTable = ({ shipments, loading, error, update }) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState('receivedItemsTable');

  const getStatusSortValue = useCallback(shipment => {
    if (shipment.receivedItems.length !== shipment.copiesOnShipment) {
      return Number.POSITIVE_INFINITY;
    }

    const status = getCreateItemStatus(shipment, t);

    if (status.problems.length > 0) return status.problems.length;
    else if (!status.someItemsCreated) return -1;
    else if (status.someItemsCreated && !status.allItemsCreated) return -2;
    else if (status.allItemsCreated) return -3;
  }, [t]);

  const filter = placeholder => (
    textFilter({
      delay: 0,
      placeholder: `${placeholder}...`,
      activeStyle: {
        backgroundColor: 'gold'
      }
    })
  );

  const columns = [
    {
      dataField: 'coverImage',
      text: t('Cover'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <>
          {(row.Order && row.Order.Bib) && (
            <div style={{ marginBottom: '4px' }}>
              <CoverImage isbn={row.Order.Bib.marcISBN} materialType={row.Order.Bib.fixedFieldMaterialType} />
            </div>
          )}

          <strong>
            <center>
              {t('Copies')}: {row.copiesOnShipment}
            </center>
          </strong>
        </>
      )
    },
    {
      dataField: 'id',
      text: t('ID'),
      hidden: true
    },
    {
      dataField: 'sierraOrderRecordId',
      text: t('Sierra IDs'),
      filter: filter(t('Sierra IDs')),
      filterValue: (cell, row) => `o${cell}A${row.Order ? (`b${row.Order.sierraBibRecordId}A`) : ''}`,
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => row.Order && (
        <>
          <pre style={{ marginBottom: 0 }}>
            <SierraID code="o" id={row.sierraOrderRecordId} />
          </pre>

          <pre style={{ marginBottom: 0 }}>
            <SierraID code="b" id={row.sierraBibRecordId} />
          </pre>
        </>
      )
    },
    {
      dataField: 'Order.sierraBibRecordId',
      text: t('Sierra Bib ID'),
      sort: true,
      hidden: true
    },
    {
      dataField: 'productCode',
      text: t('Shipment Number & Product Code'),
      filter: filter(t('Product Code')),
      filterValue: (cell, row) => `${row.shipmentId} ${row.productCode} `,
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <>
          <div>
            {row.shipmentId}
          </div>

          <div>
            {row.productCode}
          </div>
        </>
      )
    },
    {
      dataField: 'Order.status',
      text: t('Sierra Order Status'),
      sort: true,
      filter: filter(t('Status')),
      formatter: (cell, row, rowIndex, formatExtraData) => (row.Order ? formatOrderStatus(row.Order) : null)
    },
    {
      dataField: 'title',
      text: t('Work'),
      sort: true,
      filter: filter(t('Work')),
      filterValue: (cell, row) => (
        (row.Order && row.Order.Bib)
          ? (row.Order.Bib.marcTitle + (row.Order.Bib.marcAuthor ? ` / ${row.Order.Bib.marcAuthor}` : ''))
          : ''
      ),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        (row.Order && row.Order.Bib) && <BibTitleAndAuthor bib={row.Order.Bib} />
      )
    },
    {
      dataField: 'language',
      text: t('Language'),
      filter: filter(t('Language')),
      sort: true
    },
    {
      dataField: 'Order.Bib.fixedFieldMaterialType',
      text: t('Material Type'),
      filter: filter(t('Material Type')),
      sort: true,
      filterValue: (cell, row) => (
        (row.Order && row.Order.Bib) &&
        formatMaterialType(row.Order.Bib)
      ),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        (row.Order && row.Order.Bib) &&
        formatMaterialType(row.Order.Bib)
      )
    },
    {
      dataField: 'comment',
      text: t('Comment'),
      filter: filter(t('Comment')),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell === null
          ? '-'
          : cell.split(';').map((e, i) => <div key={i}>{e}</div>)
      )
    },
    {
      dataField: 'receptionDate',
      text: t('Reception Date'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        cell === null
          ? '-'
          : formatDateRelative(cell, t)
      )
    },
    {
      dataField: 'shipmentStatus',
      text: t('Status'),
      isDummyField: true,
      headerStyle: () => ({ minWidth: '200px' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <ShipmentStatus
          shipment={row}
          receivedCount={row.receivedItems.length}
        />
      ),
      style: (cell, row, rowIndex, colIndex) => {
        const status = getCreateItemStatus(row, t);

        if (status.missingReceivedItems) return { backgroundColor: 'var(--secondary)' };
        else if (status.problems.length > 0) return { backgroundColor: 'var(--danger)' };
        else if (
          status.receivedDateSet &&
          status.labelsPrinted &&
          status.allItemsCreated &&
          status.status === 'q'
        ) return { backgroundColor: 'var(--warning)' };
        else if (status.allItemsCreated) return { backgroundColor: 'var(--success)' };
        else return { backgroundColor: 'var(--info)' };
      },
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const valA = getStatusSortValue(rowA);
        const valB = getStatusSortValue(rowB);

        if (order === 'asc') return valB - valA;
        else return valA - valB;
      },
      filter: filter(t('Status')),
      // TODO: What value does SiniN want to sort this field with? now seems to be -3, -2, -1 etc.
      filterValue: (cell, row) => getStatusSortValue(row)
    }
  ];

  if (!shipments) {
    if (loading) return <Loading />;
    else if (error) return t('Could not fetch shipments');
  }

  return (
    <>
      <strong>
        {shipments.length} {t('shipments on list')}
      </strong>

      <BootstrapTable
        keyField="id"
        data={shipments}
        columns={columns}
        classes="w-auto api-table mw-100"
        wrapperClasses="table-responsive"
        filter={filterFactory()}
        filterPosition="top"

        expandRow={expandRow(row => (
          <ShipmentTabView
            shipment={row}
            update={update}
            tab={tab}
            setTab={setTab}
          />
        ))}

        defaultSorted={[{
          dataField: 'shipmentStatus',
          order: 'desc'
        }]}

        bootstrap4
      />
    </>
  );
};

const ShipmentTable = ({ query, enableQueryParams }) => {
  const { t } = useTranslation();

  const [shipments, loading, error, /* headers */, update] = useAPI('GET', '/shipments');

  useEffect(() => {
    const interval = setInterval(update, 10 * 1000);

    return () => clearInterval(interval);
  }, [update]);

  const downloadProcessedShipmentsFile = async () => {
    const res = await api.getRes('/shipments/processed');
    const filename = res.headers['content-disposition'].split('=')[1];

    downloadFile(res.data, filename);
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Button size="sm" onClick={downloadProcessedShipmentsFile}>
          <Download />

          &nbsp;{t('Create import file')}
        </Button>
      </div>

      <ShipmentBootstrapTable
        shipments={shipments}
        loading={loading}
        error={error}
        update={update}
      />
    </>
  );
};

export default ShipmentTable;
