import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { authenticate } from '../../services/auth';

import { Form, Button } from 'react-bootstrap';

const LoginForm = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [incorrect, setIncorrect] = useState(false);

  useEffect(() => {
    // Set HTML body class for CSS styles
    document.body.className = 'body-login';
  }, []);

  const onFormSubmit = async event => {
    event.preventDefault(true);

    try {
      await authenticate(event.target.username.value, event.target.password.value);

      window.location.reload();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Authentication failed:', err);

      setError(err);
      setIncorrect(err?.response?.data === 'Incorrect username or password');
    }
  };

  return (
    <Form className="form-login text-centered" onSubmit={onFormSubmit}>
      <h1 className="h3 mb-3 font-weight-normal">
        {t('Authentication required')}
      </h1>

      <Form.Group>
        <Form.Control
          name="username"
          placeholder={t('Username')}
          required
          autoFocus
        />
      </Form.Group>

      <Form.Group>
        <Form.Control
          name="password"
          type="password"
          required
          placeholder={t('Password')}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        block
      >
        {t('Log in')}
      </Button>

      <div style={{ color: 'red', marginTop: '6px' }}>
        {error
          ? incorrect
            ? t('Incorrect username or password')
            : [t('Login failed'), ': ', error.message].join('')
          : null}
      </div>
    </Form>
  );
};

export default LoginForm;
