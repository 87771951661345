import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useSignum from '../../hooks/useSignum';
import { printLabel } from '../../services/item';
import { hasRole } from '../../services/auth';

import { Button } from 'react-bootstrap';
import { Printer } from 'react-bootstrap-icons';

import Loading from '../api/Loading';

const Signum = ({ itemId, height, noPrinting, loading, errorText, noError, title }) => {
  const { t } = useTranslation();

  const [text, image, apiLoading, error] = useSignum(itemId);

  const [printingLabel, setPrintingLabel] = useState(false);
  const [printingError, setPrintingError] = useState(null);

  const style = {
    imageRendering: '-webkit-optimize-contrast'
  };

  if (height) {
    style.height = height;
    style.width = 'auto';
  }

  const onClickPrint = async () => {
    setPrintingLabel(true);

    try {
      await printLabel(itemId);
    } catch (err) {
      setPrintingError(t('Could not print spine label'));
    }

    setPrintingLabel(false);
  };

  if (apiLoading) {
    return loading ?? <Loading />;
  }

  if (error) {
    return noError
      ? null
      : errorText ?? t('Could not fetch signum data');
  }

  if (typeof text === 'string' && text.length > 0) {
    if (!image) return text;

    return (
      <>
        <img title={title ?? text} alt={text} src={image} style={style} />

        {hasRole('PROCESS-SHIPMENTS') && !noPrinting && (
          <>
            <Button
              variant="link"
              onClick={onClickPrint}
              style={{
                marginLeft: '6px',
                padding: 0,
                border: 0,
                verticalAlign: 'middle'
              }}
              disabled={printingLabel}
            >
              <Printer size={24} />
            </Button>

            <span style={{ marginLeft: '6px', color: 'red' }}>
              {printingError}
            </span>
          </>
        )}
      </>
    );
  } else return null;
};

export default Signum;
