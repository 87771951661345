import { ListItem, UnorderedList, HiddenTooltip, Space } from '../common/layout';
import { ButtonsDropdown } from '../layout/buttons';
import { useT } from '../common/i18n';

import { Authorized } from '../users';

import {
  LinkList,
  DescriptionInfoTooltip,
  useCreateLinkGroupDialog,
  useEditLinkGroupDialog,
  useDeleteLinkGroupDialog,
  useCreateLinkDialog
} from '.';

import type { Color } from '../common/layout';
import type { DropdownButtonItem } from '../layout/buttons/DropdownButton';
import type { LinkGroup } from '../api/models';

interface Props {
  group: LinkGroup;
}

const addButtonColor: Color = 'dark';

const useDropdownButtons = (group: LinkGroup): DropdownButtonItem[] => {
  const t = useT();

  if (!group.id) {
    throw new Error(`Invalid group id ${group.id}`);
  }

  return [
    {
      type: 'edit',
      text: t('Edit Group'),
      dialog: useEditLinkGroupDialog(group)
    },
    {
      type: 'delete',
      text: t('Delete Group'),
      disabled: (
        (group.children && group.children.length > 0) ||
        (group.links && group.links.length > 0)
      ),
      dialog: useDeleteLinkGroupDialog(group)
    },
    {
      type: 'divider'
    },
    {
      type: 'icon',
      icon: 'folder-plus',
      text: t('Add Group'),
      color: addButtonColor,
      dialog: useCreateLinkGroupDialog(group.id)
    },
    {
      type: 'icon',
      icon: 'link',
      text: t('Add Link'),
      color: addButtonColor,
      dialog: useCreateLinkDialog(group.id)
    }
  ];
};

const isGroupHidden = (group: LinkGroup): boolean =>
  (group.global === false && Array.isArray(group.locations) && group.locations.length === 0);

const GroupListItem: React.FC<Props> = ({ group }) => (
  <ListItem>
    <Authorized role="EDIT-LINKS">
      <ButtonsDropdown
        id={`group-dropdown-${group.id}`}
        buttons={useDropdownButtons(group)}
      />
    </Authorized>

    {isGroupHidden(group) && (
      <>
        <HiddenTooltip id={`hidden-group-${group.id}`} />
        <Space />
      </>
    )}

    <strong>
      {group.name}
    </strong>

    <DescriptionInfoTooltip
      id={`group-info-tooltip-${group.id}`}
      description={group.description}
      keywords={group.keywords}
      locations={group.locations}
      orderPriority={group.orderPriority}
    />

    {group.children && <GroupList groups={group.children} />}

    {group.links && <LinkList links={group.links} />}
  </ListItem>
);

const GroupList: React.FC<{ groups: LinkGroup[] }> = ({ groups }) => (
  <UnorderedList>
    {groups.map((group, i) => (
      <GroupListItem key={i} group={group} />
    ))}
  </UnorderedList>
);

export default GroupList;
