import axios from 'axios';

import { getURL } from '.';

const handleFetchError = err => {
  if (axios.isCancel(err)) {
    // eslint-disable-next-line no-console
    console.log('Request cancelled by user:', err);
  } else if (axios.isAxiosError(err)) {
    // eslint-disable-next-line no-console
    console.log('Axios request failed:', err);
  } else {
    // eslint-disable-next-line no-console
    console.log('Error fetching API data:', err);
  }
};

const fetchData = async (
  method, path, params,
  { setData, setTotal, setLoading, setError, setHeaders, setCancelToken }
) => {
  setLoading(true);

  try {
    const cancelToken = axios.CancelToken.source();

    setCancelToken(cancelToken);

    const {
      data: res,
      headers
    } = await axios.get(getURL(path), {
      params,
      cancelToken: cancelToken.token
    });

    if (res !== null && res !== undefined) {
      setData(res?.data ?? null);
      setTotal(res?.total ?? null);
      setHeaders(headers);
    } else {
      throw new Error(`Invalid API response: ${res}`);
    }
  } catch (err) {
    if (err instanceof Error) {
      handleFetchError(err);
      setError(err);
    } else if (!(err instanceof axios.Cancel)) {
      // eslint-disable-next-line no-console
      console.error('Invalid error thrown while fetching data:', err);

      setError(new Error(`Invalid error thrown while fetching data (${err})`));
    }
  } finally {
    setLoading(false);
  }
};

export default fetchData;
