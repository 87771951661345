type BootstrapColor =
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'teal'
  | 'cyan'
  | 'white'
  | 'gray'
  | 'gray-dark'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'light'
  | 'dark';

export type Color = BootstrapColor
  | 'edit'
  | 'delete';

const getBootstrapColor = (color?: Color): BootstrapColor | undefined => {
  if (color === undefined) return undefined;

  switch (color) {
    case 'edit':
      return 'primary';
    case 'delete':
      return 'danger';
    default:
      return color;
  }
};

const useColor = (color?: Color): React.CSSProperties => ({
  color: (color ? `var(--${getBootstrapColor(color)})` : undefined)
});

export default useColor;
