import expandRow from './expandRow';

import useColor from './useColor';
import useMargin from './useMargin';
import useMargins from './useMargins';
import usePadding from './usePadding';
import usePaddings from './usePaddings';
import useString from './useString';

import BannerImage from './BannerImage';
import Center from './Center';
import ClickPopover from './ClickPopover';
import Container from './Container';
import Heading from './Heading';
import PageTitle from './PageTitle';
import Popover from './Popover';
import Table from './Table';
import OppositeSidedContent from './OppositeSidedContent';
import TitleWithCloseButton from './TitleWithCloseButton';
import Space from './Space';
import UnorderedList from './UnorderedList';
import ListItem from './ListItem';
import Tooltip from './Tooltip';
import HoverTooltip from './HoverTooltip';
import Middot from './Middot';
import Icon from './Icon';
import InfoHoverTooltip from './InfoHoverTooltip';
import Modal from './Modal';
import Markdown from './Markdown';
import ErrorMessage from './ErrorMessage';
import ErrorCondition from './ErrorCondition';
import Loading from './Loading';
import Stack from './Stack';
import Alert from './Alert';
import SuccessMessage from './SuccessMessage';
import Row from './Row';
import Col from './Col';
import HiddenTooltip from './HiddenTooltip';
import Info from './Info';
import InfoClickPopover from './InfoClickPopover';
import Tabs from './Tabs';
import Tab from './Tab';

export type { Color } from './useColor';

export {
  expandRow,

  useColor,
  useMargin,
  useMargins,
  usePadding,
  usePaddings,
  useString,

  BannerImage,
  Center,
  ClickPopover,
  Container,
  Heading,
  PageTitle,
  Popover,
  Table,
  OppositeSidedContent,
  TitleWithCloseButton,
  Space,
  UnorderedList,
  ListItem,
  Tooltip,
  HoverTooltip,
  Middot,
  Icon,
  InfoHoverTooltip,
  Modal,
  Markdown,
  ErrorMessage,
  ErrorCondition,
  Loading,
  Stack,
  Alert,
  SuccessMessage,
  Row,
  Col,
  HiddenTooltip,
  Info,
  InfoClickPopover,
  Tabs,
  Tab
};

export type Placement =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right';

export interface IInfo {
  type: 'tooltip' | 'popover';
  content: string;
  contentType?: 'text' | 'markdown';
  placement?: Placement;
}
