import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface APIState {
  resourceUpdates: Record<string, number>
}

const initialState: APIState = {
  resourceUpdates: {}
};

const reducers = {
  resourceUpdated: (state: APIState, action: PayloadAction<string>): void => {
    state.resourceUpdates[action.payload] = new Date().getTime();
  }
};

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers
});

export const selectResourceUpdatedDate = (resource: string) =>
  (state: RootState): number | null =>
    state.api.resourceUpdates[resource] ?? null;

export const {
  resourceUpdated
} = apiSlice.actions;

export default apiSlice.reducer;
