import { Button } from './buttons';
import { ClickPopover, Icon } from '.';

import type { Placement } from '.';

interface Props {
  id: string;
  placement?: Placement;
}

const infoIconStyle: React.CSSProperties = {
  marginLeft: '6px',
  marginTop: 0
};

const InfoIconButton: React.VFC = ({ ...props }) => (
  <Button {...props}>
    <Icon
      icon="info-circle"
      size={16}
      style={infoIconStyle}
    />
  </Button>
);

const InnerPopover: React.FC<Props> = ({ id, placement, children }) => (
  <ClickPopover
    id={id}
    placement={placement}
    button={<InfoIconButton />}
  >
    {children}
  </ClickPopover>
);

const InfoClickPopover: React.FC<Props> = ({ children, ...props }) =>
  children
    ? <InnerPopover {...props}>{children}</InnerPopover>
    : null;

export default InfoClickPopover;
