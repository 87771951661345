import BootstrapDropdown from 'react-bootstrap/Dropdown';

import { Icon, useColor } from '../../common/layout';
import { ButtonsDropdownButton } from '.';

import type { Color } from '../../common/layout';
import type { DropdownButtonItem } from './DropdownButton';

interface Props {
  id: string;
  buttons: DropdownButtonItem[];
}

const dropdownStyle: React.CSSProperties = {
  marginRight: '6px',
  display: 'inline'
};

const toggleStyle: React.CSSProperties = {
  fontSize: '12px',
  padding: 0,
  border: 0
};

const toggleButtonColor: Color = 'secondary';

const ButtonsDropdown: React.FC<Props> = ({ id, buttons }) => (
  <BootstrapDropdown style={dropdownStyle}>
    <BootstrapDropdown.Toggle
      size="sm"
      variant="link"
      id={id}
      style={{ ...toggleStyle, ...useColor(toggleButtonColor) }}
    >
      <Icon icon="gear-fill" />
    </BootstrapDropdown.Toggle>

    <BootstrapDropdown.Menu>
      {buttons.map((button, index) => (
        <ButtonsDropdownButton key={index} button={button} />
      ))}
    </BootstrapDropdown.Menu>
  </BootstrapDropdown>
);

export default ButtonsDropdown;
