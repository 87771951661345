import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import DynamicView from '../api/DynamicView';
import ItemDetails from './details/ItemDetails';
import BibDetails from '../bibs/BibDetails';
import ItemScans from './ItemScans';
import Loading from '../api/Loading';

const ItemView = ({ itemId, barcode, alwaysTabbed, hideInventoryButton }) => {
  const { t } = useTranslation();

  if (typeof barcode === 'string' && /^[0-9]+,[0-9]*\.$/.test(barcode)) {
    barcode = barcode.split(',')[0];
  }

  const [
    item,
    loading,
    error,
    /* headers */,
    update
  ] = useAPI('GET', (itemId ? `/items/${itemId}` : `/items/barcode/${barcode}`), {}, [itemId, barcode]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch item');
  }

  if (item === null) {
    // Item not in DB

    if (itemId) return `${t('No item found for Sierra ID')} ${itemId}`;
    else return `${t('No item found for barcode')} ${barcode}`;
  }

  return (
    <DynamicView defaultTab="itemDetails" alwaysTabbed={alwaysTabbed}>
      <ItemDetails
        title={t('Item Details')}
        key="itemDetails"
        item={item}
        onChange={update}
        hideInventoryButton={hideInventoryButton}
        hideItemScansButton
      />

      <BibDetails
        title={t('Bib Details')}
        key="bibDetails"
        bib={item.Bib}
      />

      <ItemScans
        title={t('Item Scans')}
        key="itemScans"
        itemId={item.sierraItemRecordId}
      />
    </DynamicView>
  );
};

export default ItemView;
