import { getElementValue } from '.';

import type { OnSubmit, PureOnSubmit, FormData } from '.';

const handleArrayElement = (result: FormData, element: HTMLFormElement, name: string): void => {
  if (result[name] === undefined) {
    result[name] = [];
  }

  if (Array.isArray(result[name])) {
    const value = getElementValue(element, true);

    if (typeof value === 'string') {
      // @ts-ignore: As can be seen above, it is very clearly an array
      result[name].push(value);
    } else if (Array.isArray(value)) {
      // @ts-ignore: As can be seen above, it is very clearly an array
      result[name].push(...value);
    } else if (value !== false && value !== null) {
      throw new Error(`Invalid array value ${value}`);
    }
  } else {
    throw new Error(`Duplicate form element name ${element.name}`);
  }
};

const handleScalarElement = (result: FormData, element: HTMLFormElement): void => {
  if (result[element.name] !== undefined) {
    throw new Error(`Duplicate form element name ${element.name}`);
  }

  result[element.name] = getElementValue(element, false);
};

const handleElement = (result: FormData, element: HTMLFormElement): void => {
  // Skip elements that have no name, i.e. the submit button
  if (typeof element.name === 'string' && element.name.trim().length > 0) {
    if (element.name.endsWith('[]')) {
      const name = element.name.slice(0, -2);

      handleArrayElement(result, element, name);
    } else {
      handleScalarElement(result, element);
    }
  }
};

const useOnSubmit: ((onSubmit?: OnSubmit) => PureOnSubmit) = onSubmit => (event: React.FormEvent): void => {
  event.preventDefault();

  // Workaround for multiselect dropdown's "select all" button submitting the form
  // @ts-ignore: this attribute does exist
  if (event.nativeEvent?.submitter?.className?.includes('actions-btn')) {
    return;
  }

  if (onSubmit) {
    const result: FormData = {};

    // @ts-ignore: event.target can be iterated even if TS does not think so
    for (const element of event.target) {
      handleElement(result, element);
    }

    onSubmit(result);
  }
};

export default useOnSubmit;
