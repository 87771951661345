import useAPI from './useAPI';

// fetchItems:
//  true -> return list of items in circulation
//  false -> return only count of items in circulation
const useItemsInCirculation = (bibId, fetchItems = false) => {
  const [items, loading, error] = useAPI('GET', `/bibs/${bibId}/items`, {
    params: {
      status: ['!', 't', '-', 'o'],
      count: !fetchItems
    }
  }, [fetchItems]);

  if (items === null && (loading || error)) {
    return null;
  } else {
    return (fetchItems ? items : items.count);
  }
};

export default useItemsInCirculation;
