import useSound from 'use-sound';

import closing15min from '../../resources/announcements/closing15min.mp3';
import closing5min from '../../resources/announcements/closing5min.mp3';
import closed from '../../resources/announcements/closed.mp3';

import wearMask from '../../resources/announcements/wearMask.mp3';

const useAnnouncements = () => {
  const opt = {
    interrupt: true
  };

  const announcements = {};

  announcements.closing15min = useSound(closing15min, opt);
  announcements.closing5min = useSound(closing5min, opt);
  announcements.closed = useSound(closed, opt);
  announcements.wearMask = useSound(wearMask, opt);

  const play = announcement => {
    if (!announcements[announcement]) {
      throw new Error(`Announcement ${announcement} does not exist!`);
    }

    announcements[announcement][0]();

    return announcements[announcement][1];
  };

  return play;
};

export default useAnnouncements;
