/* eslint-disable valid-typeof */

import { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import useQueryParams from './useQueryParams';

const useQueryParamState = (key, defaultValue, forceType) => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const type = useRef((defaultValue === null ? null : typeof defaultValue));
  let queryParamValue = queryParams.get(key);

  if (forceType) type.current = forceType;

  if (queryParamValue) {
    if (type.current === 'number') queryParamValue = Number(queryParamValue);
    if (type.current === 'object') queryParamValue = JSON.parse(queryParamValue);
    if (type.current === 'boolean') queryParamValue = (queryParamValue === 'true');

    if (type.current === 'nullableBoolean') {
      if (queryParamValue === 'true') queryParamValue = true;
      else if (queryParamValue === 'false') queryParamValue = false;
      else queryParamValue = null;
    }
  }

  const [value, setStateValue] = useState(queryParamValue ?? defaultValue);

  if (typeof key !== 'string') throw new Error('Key must be a string!');

  const setValue = useCallback(newValue => {
    if (typeof newValue === 'function') newValue = newValue(value);

    const searchParams = new URLSearchParams(window.location.search);

    if (newValue === null || newValue === undefined || newValue === '') {
      searchParams.delete(key);
    } else if (type.current !== 'nullableBoolean' && type.current !== null && typeof newValue !== type.current) {
      throw new Error(`Invalid type! Expected ${type.current} but got ${typeof newValue}`);
    } else {
      if (type.current === null) type.current = typeof newValue;

      searchParams.set(
        key,
        typeof newValue === 'object'
          ? JSON.stringify(newValue)
          : newValue
      );
    }

    history.replace({
      search: searchParams.toString()
    });

    setStateValue(newValue);
  }, [history, key, value]);

  return [value, setValue];
};

export default useQueryParamState;
