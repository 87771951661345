import { useParams } from 'react-router-dom';

import useQueryParams from '../../hooks/useQueryParams';
import { submitRfidScan } from '../../services/item';

import ItemView from '../items/ItemView';

// /items/view/id/:id
// /items/view/:barcode
const ItemViewPage = () => {
  const params = useParams();
  const queryParams = useQueryParams();

  if (params.barcode && queryParams.get('rfid') === 'true') {
    submitRfidScan(params.barcode);
  }

  return <ItemView itemId={params.id} barcode={params.barcode} />;
};

export default ItemViewPage;
