import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createSharedAccount } from '../../services/sharedAccount';
import { getAccountErrorMessage } from '../../services/account';

import AccountForm from './AccountForm';

const CreateSharedAccountForm = ({ update, onSuccessfulSubmit }) => {
  const { t } = useTranslation();

  const [formError, setFormError] = useState(null);

  const onSubmit = async account => {
    try {
      await createSharedAccount(account.username, account.password, account.city, account.location);

      if (onSuccessfulSubmit) onSuccessfulSubmit();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Could not create shared account:', err);

      setFormError(getAccountErrorMessage(err, t));
    }
  };

  return (
    <AccountForm
      error={formError}
      onSubmit={onSubmit}
      showPassword
      noRoles
      noEmail
    />
  );
};

export default CreateSharedAccountForm;
