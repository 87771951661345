import api from './api';

import { saveLocalData } from './user';

export const isAuthenticated = () =>
  (typeof localStorage.getItem('accessToken') === 'string');

export const authenticate = async (username, password) => {
  const data = await api.post('/auth/login', {
    username,
    password
  });

  saveLocalData(data);
};

export const logout = async () => {
  localStorage.clear();

  window.location.reload();
};

export const hasRole = role => {
  try {
    const roles = JSON.parse(localStorage.getItem('roles'));

    if (roles && roles.includes('DEVELOPER') && role !== 'SHARED') return true;
    else if (typeof role === 'string') return (role && roles && roles.includes(role));
    else {
      // Role is array of roles
      for (const r of role) {
        if (hasRole(r)) {
          return true;
        }
      }

      return false;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('hasRole caught:', err);

    return false;
  }
};

export const getUsername = () =>
  (localStorage.getItem('username') ?? null);
