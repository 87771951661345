import type { SortOrder } from '.';

interface Props {
  sortField: string;
  sortOrder: SortOrder
}

interface Sorting {
  dataField: string;
  order: SortOrder;
}

const useDefaultSorting = ({ sortField, sortOrder }: Props): [Sorting] => [{
  dataField: sortField,
  order: sortOrder
}];

export default useDefaultSorting;
