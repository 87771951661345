import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import expandRow from '../../services/expandRow';
import { getQueryForBarcode } from '../../services/item';
import { getUserCity } from '../../services/user';
import sierraGlobals from '../../sierraGlobals';

import BootstrapTable from '@ounai/react-bootstrap-table2';
import { Form } from 'react-bootstrap';

import ItemTable from './ItemTable';
import Loading from '../api/Loading';
import ClickSelect from '../api/ClickSelect';

const ItemBarcodeDuplicatesTableFiltersForm = ({ city, setCity }) => {
  const { t } = useTranslation();

  const options = Object.keys(sierraGlobals.cities).map(city => (
    <option key={city} value={city}>
      {city} {sierraGlobals.cities[city]}
    </option>
  ));

  return (
    <Form inline>
      <Form.Group>
        <Form.Label>
          {t('City')}
        </Form.Label>

        <Form.Control
          as="select"
          size="sm"
          defaultValue={city}
          onChange={event => setCity(event.target.value)}
          style={{ marginLeft: '6px' }}
        >
          {options}
        </Form.Control>
      </Form.Group>
    </Form>
  );
};

const ItemBarcodeDuplicatesBootstrapTable = ({ city }) => {
  const { t } = useTranslation();

  const [duplicates, loading, error] = useAPI('GET', '/items/duplicate-barcodes', {
    params: {
      city
    }
  }, [city]);

  const columns = [
    {
      dataField: 'count',
      text: t('Count'),
      headerStyle: () => ({ width: '100px' })
    },
    {
      dataField: 'barcode',
      text: t('Barcode'),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <ClickSelect pre>{cell}</ClickSelect>
      )
    }
  ];

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch list of duplicate barcodes');
  }

  return (
    <>
      <strong>
        {duplicates.length} {t('duplicate barcodes')}
      </strong>

      <BootstrapTable
        keyField="barcode"
        columns={columns}
        data={duplicates}

        expandRow={expandRow(row => (
          <ItemTable
            query={JSON.stringify(getQueryForBarcode(row.barcode))}
            hideBackToQueryButton
          />
        ))}
      />
    </>
  );
};

const ItemBarcodeDuplicatesTable = () => {
  const [city, setCity] = useState(getUserCity());

  return (
    <>
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <ItemBarcodeDuplicatesTableFiltersForm
          city={city}
          setCity={setCity}
        />
      </div>

      <ItemBarcodeDuplicatesBootstrapTable city={city} />
    </>
  );
};

export default ItemBarcodeDuplicatesTable;
