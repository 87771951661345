import { useState, useEffect, useRef } from 'react';

import useSounds from './useSounds';

const debug = false;

const useRadar = (stateRssi, stateRssiMax) => {
  const [beepTimeout, setBeepTimeout] = useState(null);
  const [timeoutScheduled, setTimeoutScheduled] = useState(null);
  const [lastBeep, setLastBeep] = useState(null);
  const [percentage, setPercentage] = useState(null);

  const rssi = useRef(stateRssi);
  const rssiMax = useRef(stateRssiMax);

  const lastSeenRef = useRef(null);
  const lastBeepRef = useRef(null);

  const play = useSounds();

  const getBeepInterval = () => {
    const minLength = 50;
    const maxLength = 1500;
    const exponent = 2;

    const percentage = 1 - Math.pow(1 - (rssi.current / rssiMax.current), exponent);

    setPercentage(percentage);

    return minLength + (maxLength - minLength) - (percentage * (maxLength - minLength));
  };

  const onTimeout = () => {
    play('radar');

    const lastSeenMS = new Date().getTime() - lastSeenRef.current.getTime();

    if (rssi.current === 0) {
      // eslint-disable-next-line no-console
      if (debug) console.log(`<${new Date().getTime()}>`, 'Beep [-]');

      setBeepTimeout(null);
    } else if (lastSeenMS <= 1200) {
      const interval = getBeepInterval();

      // eslint-disable-next-line no-console
      if (debug) console.log(`<${new Date().getTime()}>`, `Beep [${Math.round(interval)} ms]`);

      scheduleTimeout(interval);
    }

    setLastBeep(new Date());
    lastBeepRef.current = new Date();
  };

  const scheduleTimeout = ms => {
    clearTimeout(beepTimeout);

    const timeout = setTimeout(onTimeout, ms);

    // eslint-disable-next-line no-console
    if (debug) console.log(`Next beep in ${Math.round(ms)} ms`);

    setBeepTimeout(timeout);
    setTimeoutScheduled(new Date());
  };

  useEffect(() => {
    rssi.current = +stateRssi;

    const lastBeepMS = lastBeep ? new Date().getTime() - lastBeep.getTime() : null;

    const interval = getBeepInterval();

    if (rssi.current > 0) {
      // eslint-disable-next-line no-console
      if (debug) console.log(`${rssi.current} / ${rssiMax.current}, last beep ${lastBeepMS} ms ago, [timeout=${beepTimeout}, intervalMS=${Math.round(interval)}]`);

      if (lastBeepMS === null || lastBeepMS > interval) {
        // eslint-disable-next-line no-console
        if (debug) console.log(`<${new Date().getTime()}> iBeep`);

        play('radar');
      }

      if (
        beepTimeout === null ||
        new Date().getTime() > timeoutScheduled.getTime() + interval
      ) {
        clearTimeout(beepTimeout);
        scheduleTimeout(interval);
      }
    } else {
      clearTimeout(beepTimeout);
    }

    lastSeenRef.current = new Date();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRssi]);

  useEffect(() => { rssiMax.current = stateRssiMax; }, [stateRssiMax]);

  return { percentage };
};

export default useRadar;
