import { useTranslation } from 'react-i18next';

const ItemDeletionInstructions = () => {
  const { t } = useTranslation();

  return (
    <><div>
      {t('By pressing the confirm button, status of item will be changed to deleted. Process the deleted item along with other reserved items and send them to Espoo logistics. If your agency hands out well conditioned deleted items to patrons, you may blacken the barcode vertically with black permanent maker and place the item in appropriate shelf or container.')}
    </div> </>
  );
};

export default ItemDeletionInstructions;
