import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';

import ClickSelect from '../api/ClickSelect';

const ExpandButton = ({ count, expanded, setExpanded }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="link"
      size="sm"
      onClick={() => setExpanded(e => !e)}
      style={{
        padding: 0,
        border: 0,
        verticalAlign: 'baseline'
      }}
    >
      ({expanded ? t('Hide') : `+${count}`})
    </Button>
  );
};

const ExpandableList = ({ primary, all, clickSelect }) => {
  const [expanded, setExpanded] = useState(false);

  const buttonCount = (
    (typeof all === 'object')
      ? (all.length - 1)
      : 0
  );

  return (
    <>
      {!expanded && (
        <span style={{ marginRight: '6px' }}>
          {clickSelect ? <ClickSelect>{primary}</ClickSelect> : primary}
        </span>
      )}

      {buttonCount > 0 && (
        <ExpandButton
          count={buttonCount}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      )}

      {expanded && all.map(content => (
        <div style={{ marginBottom: '4px' }} key={content}>
          {clickSelect ? <ClickSelect>{content}</ClickSelect> : content}
        </div>
      ))}
    </>
  );
};

export default ExpandableList;
