import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import sierraGlobals from '../../sierraGlobals';

import { Form } from 'react-bootstrap';

import Loading from '../api/Loading';

const LocationSelector = ({ defaultValue, onChange, showEmptyOption, showAllOption }) => {
  const { t } = useTranslation();

  const [locationInfos, loading, error] = useAPI('GET', '/locations');

  if (loading) {
    return <Loading text={t('Loading locations...')} />;
  }

  if (error) {
    return t('Could not fetch locations');
  }

  return (
    <Form.Control
      as="select"
      size="sm"
      defaultValue={defaultValue}
      onChange={event => onChange(event.target.value)}
    >
      {showEmptyOption && (
        <option value="">
          ({t('no selection')})
        </option>
      )}

      {showAllOption && (
        <option value="all">
          ({t('all')})
        </option>
      )}

      {sierraGlobals.ownLocations.map(ownLocation => (
        <option key={ownLocation} value={ownLocation}>
          {ownLocation} {locationInfos && locationInfos.find(l => l.code === ownLocation).name}
        </option>
      ))}
    </Form.Control>
  );
};

export default LocationSelector;
