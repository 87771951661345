import useDialog from './useDialog';

import DialogView from './View';

import type { Params } from '../../api';
import type { FormItem } from '../../layout/forms';
import type { ContentType } from '..';

export {
  useDialog,

  DialogView
};

interface CommonDialog {
  id: string;
  title?: string;
  showCloseButton?: boolean;
}

interface InfoDialog {
  contentType?: ContentType;
  content: string;
}

interface APIDialog {
  path: string;
  params?: Params;
}

interface APIRecordId {
  recordId: number;
}

interface APIData {
  data?: any;
}

interface APIForm {
  form: FormItem[];
}

type DialogContent =
  | { type: 'info' } & InfoDialog

  | { type: 'api-post' } & InfoDialog & APIDialog & APIData
  | { type: 'api-put' } & InfoDialog & APIDialog & APIRecordId & APIData
  | { type: 'api-patch' } & InfoDialog & APIDialog & APIRecordId & APIData
  | { type: 'api-delete' } & InfoDialog & APIDialog & APIRecordId

  | { type: 'api-form-post' } & APIDialog & APIForm
  | { type: 'api-form-put' } & APIDialog & APIRecordId & APIForm
  | { type: 'api-form-patch' } & APIDialog & APIRecordId & APIForm;

export type Dialog = CommonDialog & DialogContent;
