import { useState, useLayoutEffect, useRef } from 'react';

import useWindowSize from '../../hooks/useWindowSize';

import { Row, Col, Tab, Tabs } from 'react-bootstrap';

const RowViewCol = ({ children, setMaxContentHeight, width }) => {
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setMaxContentHeight(maxContentHeight => (
        Math.max(maxContentHeight, ref.current.clientHeight)
      ));
    }
  }, [ref, setMaxContentHeight]);

  return (
    <Col ref={ref} style={{ maxWidth: `${width}%` }}>
      {children}
    </Col>
  );
};

const RowView = ({ setRowHeight, setMaxContentHeight, children }) => {
  const ref = useRef(null);
  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    if (ref.current) {
      setRowHeight(ref.current.clientHeight);
    }
  }, [ref, windowSize.width, setRowHeight]);

  const cols = children.map(element => (
    <RowViewCol
      key={element.key}
      setMaxContentHeight={setMaxContentHeight}
      width={Math.floor(100 / (children.length || 1))}
    >
      {element}
    </RowViewCol>
  ));

  return (
    <Row ref={ref}>
      {cols}
    </Row>
  );
};

const TabView = ({ defaultActiveKey, children }) => {
  const tabs = children.map(element => (
    <Tab key={element.key} title={element.props.title} eventKey={element.key}>
      {element}
    </Tab>
  ));

  return (
    <Tabs defaultActiveKey={defaultActiveKey} style={{ marginBottom: '10px' }}>
      {tabs}
    </Tabs>
  );
};

const DynamicView = ({ defaultTab, alwaysTabbed, children }) => {
  const [enableTabView, setEnableTabView] = useState(alwaysTabbed || false);

  const [rowHeight, setRowHeight] = useState(0);
  const [maxContentHeight, setMaxContentHeight] = useState(0);

  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    if (rowHeight === 0 || maxContentHeight === 0) return;

    if (rowHeight > maxContentHeight || window.innerHeight > window.innerWidth) {
      setEnableTabView(true);
    }
  }, [rowHeight, maxContentHeight, windowSize.width]);

  if (enableTabView) {
    return (
      <TabView defaultActiveKey={defaultTab}>
        {children.filter(child => (
          child !== null &&
          child !== undefined &&
          typeof child !== 'boolean'
        ))}
      </TabView>
    );
  } else {
    return (
      <div style={{ maxWidth: '100vw' }}>
        <RowView
          setRowHeight={setRowHeight}
          setMaxContentHeight={setMaxContentHeight}
        >
          {children.filter(child => (
            child !== null &&
            child !== undefined &&
            typeof child !== 'boolean'
          ))}
        </RowView>
      </div>
    );
  }
};

export default DynamicView;
