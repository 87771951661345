import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import paginationFactory from '@ounai/react-bootstrap-table2-paginator';
import filterFactory from '@ounai/react-bootstrap-table2-filter';

import useAPI from '../../hooks/useAPI';
import { getUserLocation } from '../../services/user';
import { getBibItemColumns } from '../../services/tableColumns';

import BootstrapTable from '@ounai/react-bootstrap-table2';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

import Loading from '../api/Loading';
import Authorized from '../auth/Authorized';
import JSONModal from '../api/JSONModal';
import BibItemTableCollectionTaskDropdownButton from './BibItemTableCollectionTaskDropdownButton';

const BibItemTableButtons = ({ bibId, items, showOnlyCurrentLocation, setShowOnlyCurrentLocation, showOnlyOnShelf, setShowOnlyOnShelf, showOnlyCurrentCity, setShowOnlyCurrentCity, selectedItems }) => {
  const { t } = useTranslation();

  const queryItemsQuery = [{
    type: 'GROUP',
    value: 'AND',
    isNegated: false,
    children: [{
      field: 'sierra_bib_record_id',
      value: bibId.toString(),
      operator: 'EQUAL'
    }]
  }];

  const queryItemsButton = (
    <Button
      as={Link}
      to={`/items?query=${JSON.stringify(queryItemsQuery)}`}
      size="sm"
      style={{ marginRight: '4px' }}
    >
      <BoxArrowUpRight style={{
        verticalAlign: 'middle',
        marginTop: '-4px'
      }} />

      &nbsp;{t('Query Items')}
    </Button>
  );

  const jsonButton = (
    <Authorized role="DEVELOPER">
      <JSONModal data={items} />
    </Authorized>
  );

  const createCollectionTasks = (
    <Authorized role="CREATE-COLLECTION-TASKS">
      <BibItemTableCollectionTaskDropdownButton selectedItems={selectedItems} />
    </Authorized>
  );

  return (
    <>
      {queryItemsButton}
      {createCollectionTasks}
      {jsonButton}

      <div style={{ marginTop: '6px' }}>
        <span onClick={() => setShowOnlyCurrentLocation(s => !s)}>
          <Form.Check
            label={t('Only show items in current location')}
            checked={showOnlyCurrentLocation}
            readOnly
          />
        </span>

        <span onClick={() => setShowOnlyOnShelf(s => !s)}>
          <Form.Check
            label={t('Only show items on shelf')}
            checked={showOnlyOnShelf}
            readOnly
          />
        </span>
        <span onClick={() => setShowOnlyCurrentCity(s => !s)}>
          <Form.Check
            label={t('Only show items owned by current city')}
            checked={showOnlyCurrentCity}
            readOnly
          />
        </span>
      </div>
    </>
  );
};

const BibItemTableContent = ({ items, bibId, noHighlight, currentLocationFilterEnabled, setItem, autoSelect }) => {
  const { t } = useTranslation();

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [autoSelectDone, setAutoSelectDone] = useState(false);

  const [showOnlyCurrentLocation, setShowOnlyCurrentLocation] = useState(currentLocationFilterEnabled || false);
  const [showOnlyOnShelf, setShowOnlyOnShelf] = useState(currentLocationFilterEnabled || false);
  const [showOnlyCurrentCity, setShowOnlyCurrentCity] = useState(currentLocationFilterEnabled || false);

  const currentLocationFilter = item => (!showOnlyCurrentLocation || item.fixedFieldLocation.startsWith(getUserLocation()));
  const onShelfFilter = item => (!showOnlyOnShelf || (['-', 'o'].includes(item.fixedFieldItemStatus) && !item.checkedOut));
  const currentCityFilter = item => (!showOnlyCurrentCity || item.fixedFieldCity.startsWith(getUserLocation()[0]));

  const filteredItems = items.filter(currentLocationFilter).filter(onShelfFilter).filter(currentCityFilter);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      // add row to selected items
      setSelectedItems([...selectedItems, row]);
    } else {
      // remove row from selected items
      setSelectedItems(selectedItems.filter(item => item.sierraItemRecordId !== row.sierraItemRecordId));
    }

    return true; // return true or dont return to approve current select action
  };

  const handleOnSelectAll = (isSelect, rows, e) => {
    if (isSelect) {
      // add rows to selected items
      setSelectedItems([...selectedItems, ...rows]);
    } else {
      // remove rows from selected items
      setSelectedItems(selectedItems.filter(item => !rows.some(row => row.sierraItemRecordId === item.sierraItemRecordId)));
    }
  };

  const itemCountString = (
    filteredItems.length === items.length
      ? items.length
      : [filteredItems.length, items.length].join(' / ')
  );

  useEffect(() => {
    if (autoSelect && !autoSelectDone && selectedItemId === null && filteredItems.length >= 1) {
      setSelectedItemId(filteredItems[0].sierraItemRecordId);
      setItem(filteredItems[0]);
      setAutoSelectDone(true);
    }
  }, [autoSelect, autoSelectDone, filteredItems, selectedItemId, setItem]);

  return (
    <>
      <h3>
        {t('Items')} ({itemCountString})
      </h3>

      <div style={{ marginBottom: '4px' }}>
        <BibItemTableButtons
          bibId={bibId}
          items={items}
          showOnlyCurrentLocation={showOnlyCurrentLocation}
          setShowOnlyCurrentLocation={setShowOnlyCurrentLocation}
          showOnlyOnShelf={showOnlyOnShelf}
          setShowOnlyOnShelf={setShowOnlyOnShelf}
          showOnlyCurrentCity={showOnlyCurrentCity}
          setShowOnlyCurrentCity={setShowOnlyCurrentCity}
          selectedItems={selectedItems}
        />
      </div>

      <BootstrapTable
        keyField="sierraItemRecordId"
        data={filteredItems}
        columns={getBibItemColumns(t, selectedItemId, noHighlight)}
        bordered={false}
        classes="w-auto table-sm table-borderless mw-100"
        /* eslint-disable */
        {...(Authorized({ role: 'CREATE-COLLECTION-TASKS', children: true })) ?
          {
            selectRow: {
              mode: 'checkbox',
              onSelectAll: handleOnSelectAll,
              style: { background: 'pink' },
              onSelect: handleOnSelect
            }
          } : {
          }}
        /* eslint-enable */
        bootstrap4
        striped

        filter={filterFactory()}
        filterPosition="top"

        rowStyle={{
          fontSize: '14px'
        }}

        rowEvents={{
          onClick: (e, row, rowIndex) => {
            if (selectedItemId === row.sierraItemRecordId) {
              setSelectedItemId(null);
              setItem(null);
            } else {
              setSelectedItemId(row.sierraItemRecordId);
              setItem(row);
            }
          }
        }}

        pagination={paginationFactory({
          sizePerPage: 15,
          sizePerPageList: [
            { value: 10, text: '10' },
            { value: 15, text: '15' },
            { value: 25, text: '25' },
            { value: 50, text: '50' },
            { value: items.length, text: t('All') }
          ]
        })}
      />
    </>
  );
};

const BibItemTable = ({ bibId, setItem, noHighlight, currentLocationFilterEnabled, autoSelect, updateCount }) => {
  const { t } = useTranslation();

  const [items, loading, error] = useAPI('GET', `/bibs/${bibId}/items`, {}, [updateCount]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch item list');
  }

  return (
    <BibItemTableContent
      items={items}
      bibId={bibId}
      noHighlight={noHighlight}
      currentLocationFilterEnabled={currentLocationFilterEnabled}
      setItem={setItem}
      autoSelect={autoSelect}
    />
  );
};

export default BibItemTable;
