import sierraGlobals from '../sierraGlobals';

import languages from '../../resources/iso639-2.json';

export const approximateWidth = bib => {
  const minWidth = 8;
  const defaultWidth = 20;

  if (bib.marcPhysicalDescription) {
    const match = bib.marcPhysicalDescription.match(/\b[0-9]+ sivua\b/);

    if (match) {
      const pages = Number(match[0].split(' ')[0]);

      let result;

      if (bib.marcBindingInfo === 'sidottu') result = pages * 0.0665393 + 6.87432;
      else result = pages * 0.065427 + 1.45193;
      // TODO rengaskirja

      return Math.max(result, minWidth);
    }
  }

  return defaultWidth;
};

export const approximateHeight = bib => {
  const defaultHeight = 200;

  if (bib.marcPhysicalDescription) {
    const match = bib.marcPhysicalDescription.match(/\b[0-9]+ cm\b/);

    if (match) {
      const centimeters = Number(match[0].split(' ')[0]);

      return centimeters * 10;
    }
  }

  return defaultHeight;
};

export const parseBibId = sierraBibRecordId => (
  Number(sierraBibRecordId.replace(/[Aa]$/, '').replace(/^\.?b?/, ''))
);

export const formatBibLevel = bib => {
  if (sierraGlobals.bibLevels[bib.fixedFieldBibLevel]) {
    return `${bib.fixedFieldBibLevel} (${sierraGlobals.bibLevels[bib.fixedFieldBibLevel]})`;
  } else {
    return bib.fixedFieldBibLevel;
  }
};

export const formatMaterialType = bib => {
  if (!sierraGlobals.materialTypes[bib.fixedFieldMaterialType]) return bib.fixedFieldMaterialType;
  else return `${bib.fixedFieldMaterialType} (${sierraGlobals.materialTypes[bib.fixedFieldMaterialType]})`;
};

export const formatLanguage = (bib, shortFormat = false) => {
  const code = bib.fixedFieldLang;
  const language = languages[code];

  const format = name => (shortFormat ? name : `${code} (${name})`);

  if (!language) return code;
  else if (language.fi) return format(language.fi);
  else if (language.en) return format(language.en);
  else return code;
};

export const formatCountry = bib => {
  // TODO
  return bib.fixedFieldCountry;
};
