import { useTranslation } from '../i18n';
import { HoverTooltip, Icon } from '.';

const HiddenTooltip: React.FC<{ id: string }> = ({ id }) => (
  <HoverTooltip id={id} content={useTranslation('Hidden')}>
    <Icon icon="eye-slash-fill" />
  </HoverTooltip>
);

export default HiddenTooltip;
