import { InfoHoverTooltip, InfoClickPopover, Markdown } from '.';

import type { IInfo } from '.';

const Content: React.FC<{ info: IInfo }> = ({ info }) => {
  switch (info.contentType ?? 'text') {
    case 'text':
      return <>{info.content}</>;

    case 'markdown':
      return <Markdown>{info.content}</Markdown>;
  }
};

const InnerInfo: React.FC<{ info: IInfo }> = ({ info }) => {
  switch (info.type) {
    case 'tooltip':
      return (
        <InfoHoverTooltip
          id={`${info.type}-${info.contentType}-${info.placement ?? 'default'}-${info.content}`}
          placement={info.placement}
        >
          <Content info={info} />
        </InfoHoverTooltip>
      );

    case 'popover':
      return (
        <InfoClickPopover
          id={`${info.type}-${info.contentType}-${info.placement ?? 'default'}-${info.content}`}
          placement={info.placement}
        >
          <Content info={info} />
        </InfoClickPopover>
      );
  }
};

const Info: React.FC<{ info?: IInfo | null }> = ({ info }) =>
  info
    ? <InnerInfo info={info} />
    : null;

export default Info;
