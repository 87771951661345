import ReactDOM from 'react-dom';

import './legacy/i18n';
import './legacy/axios';

import { App } from './application';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
