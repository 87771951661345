import ItemShelfViewListElement from './ItemShelfViewListElement';

const ItemShelfViewList = ({ shelfData, update }) => (
  <>
    {shelfData.previous.map(item => (
      <ItemShelfViewListElement key={item.sierraItemRecordId} item={item} update={update} />
    ))}

    <ItemShelfViewListElement
      key={shelfData.current.sierraItemRecordId}
      item={shelfData.current}
      update={update}
      highlight
    />

    {shelfData.next.map(item => (
      <ItemShelfViewListElement key={item.sierraItemRecordId} item={item} update={update} />
    ))}
  </>
);

export default ItemShelfViewList;
