import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { hasRole } from '../../services/auth';
import api from '../../services/api';
import { formatDateRelative } from '../../services/date';
import expandRow from '../../services/expandRow';

import { Dropdown, ButtonGroup, Button, Form } from 'react-bootstrap';
import BootstrapTable from '@ounai/react-bootstrap-table2';

import ItemView from '../items/ItemView';
import BibTitleAndAuthor from '../bibs/BibTitleAndAuthor';
import OnClickModal from '../layout/OnClickModal';
import MarkItemAsMissingDialog from '../items/MarkItemAsMissingDialog';
import Blur from '../api/Blur';
import ClickSelect from '../api/ClickSelect';

import { CoverImage } from '../../../bibs';

const ExpiredHoldShelfItemShelfLocation = ({ itemLocation, enableBlurring }) => {
  const secondaryLocation = itemLocation.split(', ').slice(1).join(', ');
  const primaryLocation = itemLocation.split(', ')[0] + (secondaryLocation ? ', ' : '');

  return (
    <>
      {primaryLocation}
      {enableBlurring ? <Blur>{secondaryLocation}</Blur> : secondaryLocation}
    </>
  );
};

const ExpiredHoldShelfItemDropdown = ({ expiredHoldShelfItem, updateTable }) => {
  const { t } = useTranslation();

  const [markAsMissingComplete, setMarkAsMissingComplete] = useState(false);

  const markAsFoundOnChange = async event => {
    await api.put(`/holdshelf/${expiredHoldShelfItem.id}`, {
      markedAsFound: !expiredHoldShelfItem.markedAsFound
    });

    updateTable();
  };

  return (
    <Dropdown as={ButtonGroup}>
      <Button variant="outline-dark">
        <Form.Check
          type="checkbox"
          defaultChecked={expiredHoldShelfItem.markedAsFound}
          onChange={markAsFoundOnChange}
          className="checkbox-30px"
        />
      </Button>

      <Dropdown.Toggle split variant="outline-dark" />

      <Dropdown.Menu>
        <OnClickModal
          forceClose={markAsMissingComplete}
          button={<Dropdown.Item>{t('Mark as missing')}</Dropdown.Item>}
        >
          <MarkItemAsMissingDialog
            item={{
              ...expiredHoldShelfItem.Item,
              sierraItemRecordId: expiredHoldShelfItem.sierraItemRecordId
            }}
            onCancel={() => setMarkAsMissingComplete(true)}
            onSuccess={() => {
              setMarkAsMissingComplete(true);
              updateTable();
            }}
          />
        </OnClickModal>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ExpiredHoldShelfItemDescription = ({ expiredHoldShelfItem, enableBlurring }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3>
        <ExpiredHoldShelfItemShelfLocation
          itemLocation={expiredHoldShelfItem.holdShelfIndex || expiredHoldShelfItem.patronName}
          enableBlurring={enableBlurring}
        />
      </h3>

      <BibTitleAndAuthor bib={expiredHoldShelfItem.Item.Bib} />

      {
        expiredHoldShelfItem.Item && expiredHoldShelfItem.Item.itemType &&
        <div>{expiredHoldShelfItem.Item.itemType.description.replace(/ [0-9]+$/, '')}</div>
      }

      <div>
        {t('Added')}

        &nbsp;

        {formatDateRelative(expiredHoldShelfItem.updatedAt, t, true)}
      </div>

      <pre style={{ marginBottom: 0, marginTop: '6px' }}>
        <ClickSelect>
          {expiredHoldShelfItem.Item.barcode}
        </ClickSelect>
      </pre>
    </>
  );
};

const ExpiredHoldShelfItemsTable = ({ expiredHoldShelfItems, update, location }) => {
  const { t } = useTranslation();

  const [patronNamePrivacy, setPatronNamePrivacy] = useState(true);

  const columnStyle = (cell, row, rowIndex, colIndex) => {
    if (row.markedAsFound) return { backgroundColor: '#81c784' };
  };

  const columns = [
    {
      dataField: 'coverImage',
      text: t('Cover'),
      isDummyField: true,
      style: columnStyle,
      headerStyle: () => ({ width: '100px' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <CoverImage isbn={row.Item?.Bib?.marcISBN} materialType={row.Item?.Bib?.fixedFieldMaterialType} />
      )
    },
    {
      dataField: 'itemDescription',
      text: t('Item'),
      isDummyField: true,
      style: columnStyle,
      formatExtraData: { enableBlurring: patronNamePrivacy },
      formatter: (cell, row, rowIndex, { enableBlurring }) => (
        <ExpiredHoldShelfItemDescription
          expiredHoldShelfItem={row}
          enableBlurring={enableBlurring}
        />
      )
    },
    {
      dataField: 'dropdown',
      text: t('Found'),
      isDummyField: true,
      style: columnStyle,
      headerStyle: () => ({ width: '100px' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <ExpiredHoldShelfItemDropdown
          expiredHoldShelfItem={row}
          updateTable={update}
        />
      )
    }
  ];

  return (
    <>
      <strong>
        {expiredHoldShelfItems.length} {t('items on list')}, {expiredHoldShelfItems.filter(item => item.markedAsFound).length} {t('marked as found')}
      </strong>

      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Form.Check
          type="checkbox"
          onChange={event => setPatronNamePrivacy(event.target.checked)}
          style={{ margin: 0, verticalAlign: 'middle' }}
          defaultChecked
          inline
        />

        <span style={{ fontSize: '14px' }}>
          {t('Hide full names by default, reveal by clicking (recommended for patron privacy)')}
        </span>
      </div>

      <BootstrapTable
        keyField="id"
        columns={columns}
        data={expiredHoldShelfItems}
        bordered={false}
        bootstrap4

        expandRow={
          hasRole('VIEW-ITEMS')
            ? expandRow(row => <ItemView itemId={row.sierraItemRecordId} />)
            : undefined
        }
      />
    </>
  );
};

export default ExpiredHoldShelfItemsTable;
