import { T } from '../../common/i18n';
import { LinkButton } from '../../common/layout/buttons';

import { Authorized } from '../../users';

import type { RecordType } from '.';

const MassUpdateCreateFromQueryButton: React.FC<{
  recordType: RecordType,
  query: any
}> = ({ recordType, query }) => (
  <Authorized role="DEVELOPER">
    <LinkButton
      to={`/mass-updates?recordType=${recordType}&query=${JSON.stringify(query)}`}
      variant="dark"
      disabled={!query}
      style={{ marginLeft: '6px', marginTop: '10px' }}
    >
      <T>Create Mass Update</T>
    </LinkButton>
  </Authorized>
);

export default MassUpdateCreateFromQueryButton;
