import Debug from './Debug';
import DeveloperStatistics from './Statistics';
import LastDatabaseUpdateTime from './LastDatabaseUpdateTime';
import Uptime from './Uptime';
import DatabaseFileSystemStatus from './DatabaseFileSystemStatus';
import Sandbox from './Sandbox';

export const devModeEnabled =
  process.env.REACT_APP_DEV_MODE === 'true';

export {
  Debug,
  DeveloperStatistics,
  LastDatabaseUpdateTime,
  Uptime,
  DatabaseFileSystemStatus,
  Sandbox
};
