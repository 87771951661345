/* eslint-disable quote-props */
/* eslint-disable max-len */

const translation = {
  // Languages
  'Finnish': 'suomi',
  'Swedish': 'ruotsi',
  'English': 'englanti',

  // Boolean
  'yes': 'kyllä',
  'no': 'ei',

  // Login
  'Authentication required': 'Sisäänkirjautuminen',
  'Username': 'Käyttäjätunnus',
  'Password': 'Salasana',
  'Log in': 'Kirjaudu sisään',
  'Incorrect username or password': 'Väärä käyttäjätunnus tai salasana',

  // Navigation
  'Links': 'Linkit',
  'link': 'linkki',
  'group': 'ryhmä',
  'Select Library': 'Kirjaston valinta',
  'Clear Hold Shelf': 'Varaushyllyn selvitys',
  'Logout': 'Kirjaudu ulos',
  'Search': 'Haku',
  'Invalid search term': 'Virheellinen hakusana',
  'Bibs': 'Nimekkeet',
  'Items': 'Niteet',
  'Order': 'Tilaus',
  'Orders': 'Tilaukset',
  'List': 'Lista',
  'Query': 'Haku',
  'Scan': 'Skannaa',
  'Maps': 'Kartat',
  'Most Traveled': 'Eniten matkanneet',
  'Most Traveled Items': 'Eniten matkanneet niteet',
  'Holds': 'Varaukset',
  'Mikro-Väylä Reservations': 'Mikro-Väylä varaushylly',
  'Browse': 'Selaa',
  'Reception': 'Vastaanotto',
  'Shipments': 'Lähetykset',
  'Share': 'Jaa',
  'Share this page': 'Jaa tämä sivu',
  'Duplicate Barcodes': 'Tuplaviivakoodit',
  'duplicate barcodes': 'tuplaviivakoodia',
  'Shared Accounts': 'Yhteiskäyttötunnukset',
  'Create Items': 'Nidevienti',
  'Front Page': 'Etusivu',
  'Search Results': 'Hakutulokset',
  'Bib Query': 'Nimekehaku',
  'Item Query': 'Nidehaku',
  'Order Query': 'Tilaushaku',
  'Admin': 'Ylläpito',
  'Charts': 'Tilastot',
  'Developer': 'Kehittäjä',
  'Logs': 'Logit',
  'Transit Volume Map': 'Logistiikkakartta',
  'Item Scan Charts': 'Nideskannaustilastot',
  'Hold Shelf Chart': 'Varaushyllytilastot',
  'Lists': 'Listat',
  'Select label printer': 'Valitse tarratulostin',
  'Preferences': 'LIRP-asetukset',
  'Account Settings': 'Tilin hallinta',
  'Looking for barcode...': 'Haetaan viivakoodia...',
  'No results for search': 'Ei tuloksia haulle',
  'Switch to dark mode': 'Vaihda tummaan teemaan',
  'Switch to light mode': 'Vaihda vaaleaan teemaan',
  'Sierra ID Format': 'Sierra ID:n muoto',
  'Number Only': 'Vain numero',
  'Full': 'Täysi',
  'e.g.': 'esim.',
  'Changes may only take effect after page has been reloaded.': 'Muutokset saattavat tulla voimaan vasta sivun uudelleenlatauksen jälkeen.',
  'RFID Readers': 'RFID-lukijat',
  'Create Broadcast': 'Sivunlaajuinen viesti',
  'Random Bib': 'Satunnainen nimeke',
  'Random Item': 'Satunnainen nide',
  'Random Order': 'Satunnainen tilaus',
  'Manage RFID Readers': 'RFID-lukijoiden hallinta',
  'Edit account': 'Muokkaa tunnusta',
  'Could not fetch accounts': 'Tunnusten hakeminen epäonnistui',
  'Reader name is empty!': 'Lukijan nimi ei voi olla tyhjä!',
  'Reader name is already in use!': 'Lukijan nimi on jo käytössä!',
  'Add reader': 'Lisää lukija',
  'Edit reader': 'Muokkaa lukijaa',
  'Are you sure you want to delete reader': 'Haluatko varmasti poistaa lukijan',
  'Could not fetch RFID readers': 'RFID-lukijoiden hakeminen epäonnistui',
  'Could not fetch massreaders': 'Haravien hakeminen epäonnistui',
  'No item record found': 'Nidetietuetta ei löytynyt',
  'Search bibs...': 'Hae nimekkeitä...',
  'Enter barcode...': 'Syötä viivakoodi...',
  'Could not fetch shared accounts': 'Yhteiskäyttötunnusten hakeminen epäonnistui',
  'Could not fetch reservations': 'Varausten hakeminen epäonnistui',
  'Could not fetch hold shelf statistics': 'Varaushyllyn tilastojen hakeminen epäonnistui',
  'Find by Barcode': 'Etsi viivakoodilla',
  'Could not fetch bibs': 'Nimekkeiden hakeminen epäonnistui',
  'Could not fetch statistics': 'Tilastojen hakeminen epäonnistui',
  'Could not fetch item scan activity': 'Nideskannien määrän hakeminen epäonnistui',
  'Could not fetch list of duplicate barcodes': 'Tuplaviivakoodien hakeminen epäonnistui',
  'Classification': 'Luokka',
  'no selection': 'ei valintaa',
  'all': 'kaikki',
  'Default Location': 'Oletussijainti',
  'Override Location': 'Pakota oletussijainti',
  'Edit Group': 'Muokkaa ryhmää',
  'Delete Group': 'Poista ryhmä',
  'Add Link': 'Uusi linkki',
  'Edit Link': 'Muokkaa linkkiä',
  'Delete Link': 'Poista linkki',
  'Create New Group': 'Luo uusi ryhmä',
  'Create First Group': 'Luo ensimmäinen ryhmä',
  'No groups have been added yet.': 'Ei ryhmiä.',
  'Keywords': 'Avainsanat',
  'Failed to submit form': 'Lomakkeen lähettäminen epäonnistui',
  'Action failed': 'Toiminto epäonnistui',
  'Error': 'Virhe',
  'Request failed with status code 404': 'Pyyntö epäonnistui (404)',
  'Delete link': 'Poistetaanko linkki',
  'Delete group': 'Poistetaanko linkkiryhmä',
  'Create New Link': 'Luo uusi linkki',
  'comma separated': 'pilkulla erotettuna',
  'Could not delete resource': 'Resurssin poistaminen epäonnistui',
  'Private': 'Yksityinen',
  'Unknown': 'Tuntematon',
  'Visibility': 'Näkyvyys',
  'Selected locations': 'Valitut sijainnit',
  'All locations': 'Kaikki sijainnit',
  'Hidden': 'Piilotettu',
  'Login Failed': 'Sisäänkirjautuminen epäonnistui',
  'Database file system': 'Tietokannan tiedostojärjestelmä',
  'free space': 'vapaata tilaa',
  'No camera device found': 'Yhteensopivaa kameraa ei löytynyt',
  'Password Change': 'Salasanan vaihto',
  'Old password': 'Vanha salasana',
  'New password': 'Uusi salasana',
  'again': 'uudestaan',
  'Change username': 'Vaihda käyttäjätunnus',
  'Change email address': 'Vaihda sähköpostiosoite',
  'Change password': 'Vaihda salasana',
  'Change Username': 'Käyttäjätunnuksen vaihto',
  'Change Email Address': 'Sähköpostiosoitteen vaihto',
  'Change Password': 'Salasanan vaihto',
  'New username': 'Uusi käyttäjätunnus',
  'New email address': 'Uusi sähköpostiosoite',
  'Notice': 'Huom!',
  'You will be logged out after a successful email address change.': 'Onnistuneen sähköpostiosoitteen vaihdon jälkeen tapahtuu automaattinen uloskirjautuminen.',
  'You can then log back in using your username or new email address.': 'Kirjaudu sen jälkeen sisään käyttäjänimellä tai valitsemallasi uudella sähköpostiosoitteella.',
  'Username changed successfully! You will now be logged out.': 'Käyttäjänimi vaihdettu! Kirjaudutaan ulos...',
  'Email address changed successfully! You will now be logged out.': 'Sähköpostiosoite vaihdettu! Kirjaudutaan ulos...',
  'Invalid default location!': 'Virheellinen oletussijainti!',
  'No overriding location given!': 'Pakotettua oletussijaintia ei annettu!',
  'JSON': 'JSON',
  'Login failed': 'Sisäänkirjautuminen epäonnistui',
  'Sierra Bib Record': 'Sierra-nimeketietue',
  'Lirp Bib Record': 'Lirp-nimeketietue',
  'Sandbox': 'Hiekkalaatikko',
  'Password changed successfully!': 'Salasana vaihdettu!',
  'My Account': 'Käyttäjätunnukseni',
  'Browse Bibs': 'Selaa nimekkeitä',
  'Query Bibs': 'Hae nimekkeet',
  'Submitting...': 'Lähetetään...',
  'Visible': 'Näkyvissä',
  'Global': 'Globaali',
  'Invalid URL': 'Virheellinen URL-osoite',
  'No change in username': 'Ei muutosta käyttäjätunnuksessa',
  'No change in email address': 'Ei muutosta sähköpostiosoitteessa',
  'No change in password': 'Ei muutosta salasanassa',
  'Selected username is invalid': 'Valittu käyttäjänimi ei kelpaa',
  'Selected email address is invalid': 'Valittu sähköpostiosoite on virheellinen',
  'Passwords do not match': 'Salasanat eivät täsmää',
  'Password does not meet criteria': 'Valittu salasana ei täytä kriteereitä',
  'Could not fetch bib record': 'Nimeketietueen hakeminen epäonnistui',
  'Sierra item record not in database': 'Nidetietta ei ole tietokannassa',
  'hardcover': 'kovakantinen',
  'softcover': 'pehmeäkantinen',
  'Lirp Item Record': 'Lirp-nidetietue',
  'Sierra Item Record': 'Sierra-nidetietue',
  'Unknown announcement': 'Tuntematon kuulutus',
  'Could not fetch item record': 'Nidetietueen hakeminen epäonnistui',
  'Lirp Order Record': 'Lirp-tilaustietue',
  'Sierra Order Record': 'Sierra-tilaustietue',
  'Could not fetch order record': 'Tilaustietueen hakeminen epäonnistui',
  'Sierra order record not in database': 'Sierra-tilaustietue puuttuu tietokannasta',
  'Users': 'Käyttäjät',
  'Mass Update': 'Massapäivitys',
  'Mass Updates': 'Massapäivitykset',
  'Record Type': 'Tietuetyyppi',
  'Validate': 'Vahvista',
  'Query JSON': 'Kysely-JSON',
  'Operation JSON': 'Toiminto-JSON',
  'Order Priority': 'Järjestysnumero',
  'Juuri Link Service': 'Juuri-linkkipalvelu',
  'No active operations currently in progress.': 'Toimintoja ei ole tällä hetkellä käynnissä.',
  'New operation': 'Uusi toiminto',
  'Execute': 'Suorita',
  'no change': 'ei muutosta',
  'Login expired': 'Istunto vanhentunut',
  'Item Allocations': 'Nideallokaatiot',
  'Tasks': 'Hakulista',
  'Sections': 'Osastot',
  'Adult': 'Aikuiset',
  'Children': 'Lapset',
  'Music': 'Musiikki',
  'Youth': 'Nuoret',
  'Magazines': 'Lehdet',
  'Confirm': 'Vahvista',
  'Yes': 'Kyllä',
  'No': 'Ei',
  'Allocation Count': 'Allokaatioita',
  'Allocation Type ID': 'Allokaatiotyypin ID',
  'Source': 'Lähde',
  'Destination': 'Kohde',
  'Showing item searches': 'Näytetään haut',
  'Checked in': 'Palautettu',
  'Item created at': 'Nide luotu',
  'No found items': 'Piilota löydetyt',
  'Books': 'Kirjat',
  'Discs': 'Levyt',
  'Other': 'Muut',
  'other': 'muut',
  'Title Paging': 'Nimekehaku',
  'Item Paging': 'Nidehaku',
  'Task': 'Kokoelmanhoitotehtävä',
  'Storage': 'Varasto',
  'Espoo Collection': 'Espoo-kokoelma',
  'Kauniainen Collection': 'Kauniainen-kokoelma',
  'Hold Created': 'Varaus tehty',
  'Material Types': 'Aineistolajit',
  'Only holds older than a day': 'Vain päivää vanhemmat nimekevaraukset',
  'Only holds older than two days': 'Vain kahta päivää vanhemmat nimekevaraukset',
  'CDs': 'CD-levyt',
  'Vinyl Records': 'Vinyylilevyt',
  'Audio Cassettes': 'C-kasetit',
  'Sheet Music': 'Nuotit',
  'DVD, BD, VHS': 'DVD, BD, VHS',
  'ROM Discs': 'ROM-levyt',
  'Active': 'Aktiivinen',
  'Expires At': 'Vanhenee',
  'Invalid JSON': 'Virheellinen JSON',
  'Event Count': 'Tapahtumien määrä',
  'Invalid Sierra Bib ID': 'Virheellinen Sierran nimeke-ID',
  'Is Shared': 'Jaettu',
  'Last Login': 'Viimeisin kirjautuminen',
  'Email': 'Sähköposti',
  'Item Deletion Instructions': 'Niteen poisto-ohjeet',
  'max': 'enintään',

  // Locations
  'Sello': 'Sello',
  'Kirjastoauto Espoo': 'Kirjastoauto Espoo',
  'Tapiola': 'Tapiola',
  'Laajalahti': 'Laajalahti',
  'Otaniemi': 'Otaniemi',
  'Haukilahti': 'Haukilahti',
  'Iso Omena': 'Iso Omena',
  'Suurpelto': 'Suurpelto',
  'Nöykkiö': 'Nöykkiö',
  'Pikkulaiva': 'Pikkulaiva',
  'Saunalahti': 'Saunalahti',
  'Soukka': 'Soukka',
  'Viherlaakso': 'Viherlaakso',
  'Laaksolahti': 'Laaksolahti',
  'Entresse': 'Entresse',
  'Kauklahti': 'Kauklahti',
  'Karhusuo': 'Karhusuo',
  'Kalajärvi': 'Kalajärvi',
  'Kotipalvelu Espoo': 'Kotipalvelu Espoo',
  'Hakunila': 'Hakunila',
  'Länsimäki': 'Länsimäki',
  'Tikkurila': 'Tikkurila',
  'Kirjastoauto Vantaa': 'Kirjastoauto Vantaa',
  'Hiekkaharju': 'Hiekkaharju',
  'Koivukylä': 'Koivukylä',
  'Lumon kirjasto': 'Lumon kirjasto',
  'Pointin kirjasto': 'Pointin kirjasto',
  'Myyrmäki': 'Myyrmäki',
  'Martinlaakso': 'Martinlaakso',
  'Pähkinärinne': 'Pähkinärinne',
  'Kivistö': 'Kivistö',
  'Oodi': 'Oodi',
  'Pasila': 'Pasila',
  'Kirjastoauto Helsinki': 'Kirjastoauto Helsinki',
  'Rikhardinkatu': 'Rikhardinkatu',
  'Jätkäsaari': 'Jätkäsaari',
  'Suomenlinna': 'Suomenlinna',
  'Lauttasaari': 'Lauttasaari',
  'Töölö': 'Töölö',
  'Pikku Huopalahti': 'Pikku Huopalahti',
  'Etelä-Haaga': 'Etelä-Haaga',
  'Munkkiniemi': 'Munkkiniemi',
  'Pitäjänmäki': 'Pitäjänmäki',
  'Pohjois-Haaga': 'Pohjois-Haaga',
  'Malminkartano': 'Malminkartano',
  'Kannelmäki': 'Kannelmäki',
  'Kallio': 'Kallio',
  'Vallila': 'Vallila',
  'Arabianranta': 'Arabianranta',
  'Käpylä': 'Käpylä',
  'Maunula': 'Maunula',
  'Oulunkylä': 'Oulunkylä',
  'Paloheinä': 'Paloheinä',
  'Malmi': 'Malmi',
  'Viikki': 'Viikki',
  'Pukinmäki': 'Pukinmäki',
  'Tapanila': 'Tapanila',
  'Suutarila': 'Suutarila',
  'Tapulikaupunki': 'Tapulikaupunki',
  'Puistola': 'Puistola',
  'Jakomäki': 'Jakomäki',
  'Herttoniemi': 'Herttoniemi',
  'Roihuvuori': 'Roihuvuori',
  'Laajasalo': 'Laajasalo',
  'Sakarinmäki': 'Sakarinmäki',
  'Itäkeskus': 'Itäkeskus',
  'Myllypuro': 'Myllypuro',
  'Kontula': 'Kontula',
  'Vuosaari': 'Vuosaari',
  'Aineistohotelli Vantaa': 'Aineistohotelli Vantaa',
  'Tikkurila musiikkivarasto': 'Tikkurila musiikkivarasto',
  'Password Change Required': 'Salasanan vaihto vaaditaan',
  'Edit Links': 'Linkkien muokkaaminen',
  'Item Search': 'Hakulista',
  'Search Item': 'Nidehaku',
  'Create Collection Tasks': 'Kokoelmanhoitotehtävien luominen',
  'Collection Task Instructions': 'Kokoelmanhoitotehtävä',
  'Events': 'Tapahtumat',
  'Record ID': 'Tietueen ID',
  'Active Allocations': 'Aktiiviset allokaatiot',
  'Active Allocation Distribution': 'Aktiivisten allokaatioiden jakauma',
  'Create Mass Update': 'Luo massapäivitys',
  'Mark shipment as manually completed?': 'Merkitäänkö lähetys manuaalisesti suoritetuksi?',
  'Collection Tasks': 'Kokoelmanhoitotehtävät',
  'Delete Item': 'Poista nide',
  'Order Item Deletion': 'Tilaa niteen poisto',
  'Order Item Deletion (with comment)': 'Tilaa niteen poisto kommentilla',
  'Manually take inventory': 'Lisää inventointiskannaus',
  'Float to destination': 'Kelluta sijaintiin',
  'Create floating order': 'Tilaa kellutus',
  'Home item': 'Kelluta kotiin',
  'Homing order': 'Kotiinkutsutehtävä',
  'Create homing order': 'Tilaa niteen kotiutus',
  'Cancel Other Collection Task': 'Peruuta muu tehtävä',
  'Order Item Deletions': 'Luo poistotilaus',
  'Order Other Collection Task': 'Tilaa muu tehtävä',
  'Deletion Ordered': 'Poisto tilattu',
  'Other Task Ordered': 'Muu tehtävä tilattu',
  'Task Ordered': 'Tehtävä tilattu',
  'Item Deletion': 'Niteen poisto',
  'Cancel Item Deletion Task': 'Peruuta poistotilaus',
  'Bib Search': 'Nimekehaku',
  'Collection Task': 'Kokoelmanhoitotehtävä',
  'Floating Task': 'Kellutustehtävä',
  'Float Homing Task': 'Kellutuksen kotiinkutsutehtävä',
  'Search Types': 'Keräystyypit',
  'deletion ordered': 'poistotilaus',
  'deletions ordered': 'poistotilausta',
  'item currently allocated': 'niteellä olemassaoleva tilaus',
  'items currently allocated': 'niteellä olemassaoleva tilaus',
  'of which': 'joista',
  'is a deletion order': 'on poistotilaus',
  'are deletion orders': 'on poistotilauksia',
  'By pressing the confirm button, status of item will be changed to deleted. Process the deleted item along with other reserved items and send them to Espoo logistics. If your agency hands out well conditioned deleted items to patrons, you may blacken the barcode vertically with black permanent maker and place the item in appropriate shelf or container.': 'Painamalla "Vahvista" -nappia, niteen tila päivitetään poistetuksi. Käsittele poistot muiden varausten kanssa samaan tapaan ja lähetä Espoon logistiikkaan kuorman mukana. Kirjastoissa joissa kerätään hyväkuntoisia niteitä kierrätyshyllyyn asiakkaille jaettaviksi, voit mustata viivakoodin pystysuunnassa ja laittaa niteen kierrätyshyllyyn.',
  'Could not fetch collection tasks': 'Kokoelmanhoitotehtävien hakeminen epäonnistui',
  'Satisfied At': 'Suoritettu',
  'Satisfied at': 'Suoritettu',
  'Satisfied': 'Suoritettu',
  'Show only my tasks': 'Näytä vain omat',
  'Cancelled': 'Peruttu',
  'Create Task': 'Luo tehtävä',
  'Order Collection Task': 'Tilaa kokoelmanhoitotehtävä',
  'Task description': 'Tehtävän kuvaus',

  // Query builders
  'Execute Query': 'Tee haku',
  'Back to Query': 'Palaa hakuun',
  'Loading...': 'Ladataan...',
  'No data to show': 'Ei tuloksia',
  'Could not fetch data': 'Haku epäonnistui',

  // Clear hold shelf
  'Cover': 'Kansikuva',
  'Item': 'Nide',
  'Found': 'Löytyi',
  'Instructions': 'Ohjeet',
  'Fetch and check in all items with indexes': 'Kerää ja palauta kaikki niteet varaushyllypaikoilla',
  'Fetch and check in the rest of the items in the following list.': 'Kerää ja palauta loput listalla olevat niteet.',
  'Mark items that could not be found as missing.': 'Merkitse niteet joita ei löytynyt kadonneiksi.',
  'The items will disappear from the list once they have been checked in or marked as missing.': 'Niteet poistuvat listalta palautuksen tai kadonneeksi merkitsemisen jälkeen.',
  'You can use the "Found" checkbox to mark any items you have collected but not yet checked in.': '"Löytyi"-napilla voi merkitä kerättyjä niteitä joita ei vielä ole palautettu.',
  'Items that are marked as found will be removed from the list during the next night.': 'Löydetyiksi merkityt niteet poistuvat listalta ennen seuraavaa varaushyllyn selvitystä.',
  'items on list': 'nidettä listalla',
  'marked as found': 'merkitty löydetyiksi',
  'all expired indexes cleared': 'kaikki vanhentuneet indeksit käsitelty',
  'Hide full names by default, reveal by clicking (recommended for patron privacy)': 'Piilota etunimet (paljasta painamalla nimeä)',
  'Added': 'Lisätty',
  'Item status is still "waiting for pickup", has the hold shelf been cleared in Sierra?': 'Niteen tila on yhä "Odottaa noutoa", onhan varaushyllyn selvitys suoritettu Sierrassa?',
  'Send the list of items to be cleared in Sierra.': 'Lähetä Sierrassa lista selvitettävistä niteistä.',
  'Sierra Instructions': 'Sierra-ohjeet',
  'Email received': 'Sähköposti vastaanotettu',
  'No email received today.': 'Sähköpostia ei ole vastaanotettu tänään.',
  'Show details': 'Näytä lisätiedot',
  'Hide details': 'Piilota lisätiedot',
  'Total items today': 'Lisätty tänään selvitettäväksi',
  'New items': 'Selvitettäviä niteitä sähköpostissa',
  'Items carried over from previous days': 'Aiempina päivinä selvittämättömiksi jääneitä',
  'Cleared items': 'Tänään selvitetty',
  'Scanned': 'Palautettu',
  'Marked as missing': 'Merkitty kadonneiksi',
  'Checked out': 'Lainattu',
  'Total amount of items in hold shelf': 'Niteitä varaushyllyssä yhteensä',
  'This item cannot be marked as missing': 'Kadonneeksi merkitseminen ei onnistu',
  'Use Sierra to clear the item and mark it as missing.': 'Selvitä niteeseen liittyvät ongelmat ja merkitse se kadonneeksi Sierralla.',
  'there is a hold on the item': 'niteessä on varaus',
  'Close': 'Sulje',
  'Mark as missing': 'Merkitse kadonneeksi',
  'Mark this item as missing in Sierra?': 'Merkitäänkö nide kadonneeksi Sierraan?',
  'Cancel': 'Peruuta',
  'last item remaining for this bib': 'nimekkeen viimeinen nide',
  'Could not fetch expired hold shelf items': 'Vanhentuneiden varauksien hakeminen epäonnistui',

  // Clear hold shelf Sierra instructions
  'Open Sierra and select the "Clear Expired Holds and Holdshelf" function.': 'Avaa Sierrassa "Selvitä erääntyneet varaukset ja varaushylly"-toiminto.',
  'Click "Show".': 'Klikkaa "Näytä".',
  'Open the printer dialog from File -> Select Printer -> Standard Printer.': 'Avaa tulostimen valinta valikosta Tiedosto -> Valitse kirjoitin -> Yleiskirjoitin.',
  'Select "email address".': 'Valitse "email -osoite".',
  'Write in': 'Kirjoita',
  'Click "print".': 'Klikkaa "tulosta".',
  'Refresh this page and check that the email has been received.': 'Päivitä tämä sivu ja varmista että sähköposti on vastaanotettu.',
  'Click "clear" in Sierra.': 'Klikkaa "Selvitä" Sierrassa.',
  'Open the printer dialog (again) from File -> Select Printer -> Standard Printer.': 'Avaa tulostimen valinta (uudestaan) valikosta Tiedosto -> Valitse kirjoitin -> Yleiskirjoitin.',
  'Choose the receipt printer.': 'Valitse "oheiskirjoitin" (kuittitulostin).',

  // Search
  'is not a patron barcode': 'ei ole kirjastokortin viivakoodi',

  // Reservation search
  'Index': 'Varaushyllypaikka',
  'Barcode': 'Viivakoodi',
  'Author': 'Tekijä',
  'Title': 'Teoksen nimi',
  'Call Number': 'Hyllyluokka',
  'Patron Alias': 'Alias',
  'Print receipt': 'Tulosta kuitti',
  'Showing reservations': 'Näytetään varaukset',
  'Pickup Due Date': 'Viimeinen noutopäivä',
  'Patron\'s Reservations': 'Asiakkaan noutoa odottavat varaukset',
  'No reservations for patron': 'Ei noudettavia varauksia kirjastokortilla',
  'reservations': 'varausta',
  'Patron Barcode': 'Kirjastokortti',
  'Patron Contacted': 'Ilmoitus lähetetty',

  // Dashboard
  'Welcome!': 'Tervetuloa!',
  'bibs in DB': 'nimekettä tietokannassa',
  'items in DB': 'nidettä tietokannassa',
  'orders in DB': 'tilausta tietokannassa',
  'Last DB update': 'Viimeisin tietokannan päivitys',
  'Logo': 'Logo',
  'Developer Statistics': 'Kehittäjästatistiikka',
  'build': 'koontiversio',
  'Lirp Server': 'Lirp-palvelin',
  'Sierra API': 'Sierra-rajapinta',
  'Uptime': 'Uptime',

  // Bib table
  'Year': 'Vuosi',
  'Hold Count': 'Varauksia',
  'query took': 'kyselyyn kului',

  // Item table
  'Status': 'Tila',
  'Type': 'Tyyppi',
  'City': 'Kaupunki',
  'Location': 'Sijainti',
  'Open item in new tab': 'Avaa nide uuteen välilehteen',
  'Open full screen shelf view': 'Avaa koko näytön hyllynäkymä',
  'Could not fetch list of languages': 'Kielien hakeminen epäonnistui',
  'Amount of items on shelf here.': 'Niteitä hyllyssä tässä kirjastossa.',
  'Amount of items on shelf anywhere, excluding bestsellers.': 'Niteitä hyllyssä kaikissa kirjastoissa, pois lukien bestsellerit.',
  'Amount of items not marked as missing or deleted.': 'Kaikki kierrossa olevat niteet (ei poistettuja, kadonneita tai perinnässä olevia).',

  // Order table
  'ID': 'ID',

  // Bib view
  'Bib': 'Nimeke',
  'Bib Details': 'Nimekkeen tiedot',
  'View Bib': 'Näytä nimeke',
  'Query Items': 'Hae niteet',
  'Filter': 'Rajaa',
  'Clear Filters': 'Poista rajaukset',
  'Edit search term': 'Muuta hakusanaa',
  'Only show items on shelf in current location': 'Näytä vain tämän kirjaston hyllyssä olevat niteet',
  'Only show books': 'Näytä vain kirjat',
  'Only show items in current location': 'Näytä vain tämän kirjaston niteet',
  'Only show items owned by current city': 'Näytä vain tämän kaupungin omistamat',
  'Only show items on shelf': 'Näytä vain hyllyssä olevat niteet',
  'results': 'tulosta',
  'Query Orders': 'Hae tilaukset',
  'Control No.': 'Kontrollinumero',
  'Physical Desc.': 'Ulkoasu',
  'Binding': 'Sidosasu',
  'Year Published': 'Julkaisuvuosi',
  'Classifications': 'Luokat',
  'Espoo Class': 'Espoon luokka',
  'Helsinki Class': 'Helsingin luokka',
  'Kauniainen Class': 'Kauniaisten luokka',
  'Vantaa Class': 'Vantaan luokka',
  'Statistics Group': 'Tilastoryhmä',
  'Language': 'Kieli',
  'Material Type': 'Aineistolaji',
  'Bib Level': 'Bib. taso',
  'Country': 'Maa',
  'Sierra Created': 'Luotu Sierrassa',
  'Sierra Created At': 'Luotu Sierrassa',
  'Sierra Updated': 'Päivitetty Sierrassa',
  'Sierra Updated At': 'Päivitetty Sierrassa',
  'Bib Created At': 'Nimeke luotu',
  'Bib Updated At': 'Nimeke päivitetty',
  'Sierra BCODE3': 'BKOODI3',
  'Suppressed': 'Piilotettu',
  'Cat. Date': 'Luetteloitu',
  'Bib Hold Count': 'Nimekevarauksia',
  'Subject Headings': 'Asiasanat',
  'Subject Heading': 'Asiasana',
  'Created At': 'Luotu',
  'Updated At': 'Päivitetty',
  'Make Search': 'Tee haku',
  'Invalid subject heading': 'Virheellinen asiasana',
  'Invalid subject headings': 'Virheelliset asiasanat',
  'Show Acquisitions': 'Näytä myös hankinnassa olevat',
  'Hide hidden items (BCode3)': 'Piilota BCode3:n piilottamat',
  'Standard No.': 'Standardinro',
  'Bib Fields': 'Lisäkentät',
  'Loading MARC...': 'Ladataan MARC-tietuetta...',
  'Could not fetch MARC': 'MARC-tietueen hakeminen epäonnistui',
  'MARC': 'MARC',
  'Control Number': 'Kontrollinumero',
  'Physical Description': 'Kuvaus',
  'MARC Field 091': 'MARC-kenttä 091',
  'Language Code': 'Kielikoodi',
  'Sierra BCode3': 'Sierra BKOODI3',
  'sidottu': 'sidottu',
  'nidottu': 'nidottu',
  'Could not fetch bib': 'Nimekkeen hakeminen epäonnistui',
  'No bib found for Sierra ID': 'Nimekettä ei löytynyt Sierra ID:llä',
  'Sierra ID': 'Sierra ID',
  'ISBN': 'ISBN',
  'ISSN': 'ISSN',
  'EAN13': 'EAN13',
  'Series Info': 'Sarja',
  'Age Rating': 'Ikäraja',
  'Espoo': 'Espoo',
  'Helsinki': 'Helsinki',
  'Kauniainen': 'Kauniainen',
  'Vantaa': 'Vantaa',
  'Could not fetch item list': 'Niteiden hakeminen epäonnistui',
  'Load More...': 'Lataa seuraavat...',

  // Item view
  'Sierra Item ID': 'Niteen Sierra ID',
  'Item Details': 'Niteen tiedot',
  'Item Scans': 'Skannaushistoria',
  'RFID Item Scans': 'RFID-skannaushistoria',
  'Show only Sierra scans': 'Näytä vain Sierran skannaukset',
  'Scans': 'Skannaushistoria',
  'Item Status': 'Tila',
  'Item Type': 'Nidetyyppi',
  'Reservable': 'Varattavissa',
  'Price': 'Hinta',
  'Total Checkouts': 'Lainattu yhteensä',
  'Total Renewals': 'Uusittu yhteensä',
  'Due Date': 'Eräpäivä',
  'Spine Label': 'Selkätarra',
  'Picture Book Label': 'Kuvakirjatarra',
  'Circ. This Year': 'Kierto tänä vuonna',
  'Circ. Last Year': 'Kierto viime vuonna',
  'Checked Out': 'Lainassa',
  'Agency': 'Kirjasto',
  'Agency ID': 'Kirjasto-ID',
  'Checkout Date': 'Lainauspäivä',
  'Current Checkout': 'Nykyinen lainaus',
  'Last Checkout': 'Edellinen lainaus',
  'Last Checkout Loc.': 'Ed. lainauspaikka',
  'Last Checkout Date': 'Ed. lainauspäivä',
  'Last Checkin': 'Edellinen palautus',
  'Last Checkin Loc.': 'Ed. pal. paikka',
  'Last Checkin Date': 'Ed. pal. päivä',
  'Last Scan': 'Viimeisin skannaus',
  'Last Sierra Scan': 'Viimeisin Sierra-skannaus',
  'Last Lirp Scan': 'Viimeisin Lirp-skannaus',
  'Show': 'Näytä',
  'All': 'Kaikki',
  'hold': 'varaus',
  'Shelf View': 'Hyllynäkymä',
  'Last seen': 'Viimeksi nähty',
  'View Item': 'Näytä nide',
  'Lirp user': 'LIRP-käyttäjä',
  'In Circulation': 'Kpl. kierrossa',
  'Circulating': 'Kierrossa',
  'Available': 'Saatavilla',
  'On Shelf': 'Hyllyssä',
  'unknown': 'tuntematon',
  'Total': 'Yhteensä',
  'Transit Info': 'Matkalla',
  'DUE': 'ERÄPVM',
  'No item scans found.': 'Ei nideskanneja.',
  'Could not fetch item': 'Niteen hakeminen epäonnistui',
  'ITEM FIELDS': 'NITEEN KENTÄT',
  'BIB FIELDS': 'NIMEKKEEN KENTÄT',
  'Item Type ID': 'Nidetyypin ID',
  'Circulation This Year': 'Kierto tänä vuonna',
  'Circulation Last Year': 'Kierto viime vuonna',
  'Total Circulation': 'Kierto yhteensä',
  'Checkouts': 'Lainattu',
  'Renewals': 'Uusittu',
  'Transit From': 'Matkalla kohteesta',
  'Transit To': 'Matkalla kohteeseen',
  'Transit For Hold': 'Matkalla varaukseen',
  'Edit Call Number': 'Muokkaa hyllyluokkaa',
  'Edit Location': 'Muokkaa sijaintia',
  'Edit Barcode': 'Muokkaa viivakoodia',
  'Edit City': 'Muokkaa kaupunkia',
  'No item found for barcode': 'Nidettä ei löydetty viivakoodilla',
  'No item found for Sierra ID': 'Nidettä ei löydetty Sierra ID:llä',
  'Is Fiction': 'Kaunoa',
  'Could not print spine label': 'Selkätarran tulostaminen epäonnistui',
  'Could not fetch item scans': 'Nideskannien hakeminen epäonnistui',
  'Highlighter': 'Korostus',
  'Saving...': 'Tallennetaan...',
  'RFID Massreader': 'RFID-harava',
  'RFID Reader': 'RFID-lukija',
  'Item Hold Count': 'Nidevarauksia',
  'Item\'s city will not be changed!': 'Niteen kaupunki ei päivity automaattisesti!',
  'Item\'s status will not be changed!': 'Niteen tila ei päivity automaattisesti!',
  'Item Created At': 'Nide luotu',
  'Item Updated At': 'Nide päivitetty',
  'Could not fetch signum data': 'Selkätarran hakeminen epäonnistui',
  'Could not fetch items': 'Niteiden hakeminen epäonnistui',
  'Transit Volume': 'Liikenteen volyymi',
  'Mark as printed': 'Merkitse tulostetuksi',
  'Could not fetch shelf data': 'Hyllyn niteiden hakeminen epäonnistui',
  'Could not fetch uninventoried count': 'Haravoimattomien niteiden määrän hakeminen epäonnistui',
  'Could not fetch inventoried count': 'Haravoitujen niteiden määrän hakeminen epäonnistui',
  'Could not fetch RFID item scans': 'RFID-skannien hakeminen epäonnistui',
  'MARC 091': 'MARC-kenttä 091',
  'Scan Count': 'Skannauksien määrä',
  'Could not fetch picture book label': 'Kuvakirjatarran hakeminen epäonnistui',
  'Last Checkout Location ID': 'Viimeisen lainauksen sijainti-ID',
  'Last Checkin Location ID': 'Viimeisen palautuksen sijainti-ID',
  'Additional Label': 'Lisätarra',
  'Cannot be edited, invalid city': 'Muokkaaminen ei onnistu, virheellinen kaupunki',
  'Edit Item Status': 'Tilan muokkaus',
  'Invalid bib': 'Virheelinen nimeke',
  'Cannot be edited while item is checked out': 'Ei muokattavissa kun nide on lainassa',
  'Count': 'Määrä',
  'Variant': 'Variantti',
  'Item scans per hour': 'Nideskanneja/tunti',
  'Item scans per minute': 'Nideskanneja/minuutti',
  'Could not fetch locations': 'Sijaintien hakeminen epäonnistui',
  'Could not fetch missing holds': 'Kadonneiden varausten hakeminen epäonnistui',
  'Filter by location...': 'Suodata sijainnilla...',
  'Filter by login...': 'Suodata tunnuksella...',
  'Ented barcode...': 'Syötä viivakoodi...',
  'Deletion Count': 'Poistettavien niteiden määrä',
  'Order Deletions': 'Tilaa poistot',

  // Order view
  'Sierra CODE1': 'Sierran KOODI1',
  'Sierra Code1': 'Sierran KOODI1',
  'Order status': 'Tilauksen tila',
  'Received Date': 'Vastaanottopvm',
  'Order Status': 'Tilauksen tila',
  'Could not fetch order': 'Tilauksen hakeminen epäonnistui',
  'No order found for Sierra ID': 'Tilausta ei löydetty Sierra ID:llä',
  'ORDER FIELDS': 'Tilauksen kentät',

  // Missing holds
  'Missing Holds': 'Kadonneet varaukset',
  'Last scan': 'Viimeksi skannattu',
  'Treshold': 'Raja',
  'None': 'Ei mitään',
  'Update': 'Päivitä',
  'Feature unavailable': 'Ominaisuus ei käytössä',
  'This list contains items that are waiting for pickup, but have not yet been processed with the Mikro-Väylä machine.': 'Lista sisältää "Odottaa noutoa"-tilassa olevat niteet, joita ei vielä ole käsitelty Mikro-Väylän automaatilla.',
  'Items that have been scanned less than': 'Listalla ei näy niteitä jotka on skannattu alle',
  'ago will not be shown.': 'sitten.',
  'No missing holds within treshold.': 'Ei kadonneita varauksia valitun rajan sisällä.',

  // Dates and times
  'Today': 'Tänään',
  'today': 'tänään',
  'Yesterday': 'Eilen',
  'yesterday': 'eilen',
  'Week': 'Viikko',
  'Day': 'Päivä',
  'Days': 'Päivää',
  'Hours': 'Tuntia',
  'Hour': 'Tunti',
  'hours': 'tuntia',
  'hour': 'tunti',
  'minutes': 'minuuttia',
  'minute': 'minuutti',
  'Never': 'Ei koskaan',
  'never': 'ei koskaan',

  // Item scan browser
  'Load previous': 'Lataa edeltävät',
  'Load next': 'Lataa seuraavat',
  'Deleted item': 'Poistettu nide',

  // Account manager
  'Created at': 'Luotu',
  'Updated at': 'Päivitetty',
  'Manage Shared Accounts': 'Yhteiskäyttötunnusten hallinta',
  'Collection Manager': 'Kokoelmanhoitomanageri',
  'Manage Accounts': 'Käyttäjätunnusten hallinta',
  'Create shared account': 'Luo yhteiskäyttötunnus',
  'Submit': 'Lähetä',
  'Username already in use': 'Käyttäjätunnus on jo käytössä',
  'Password must be at least 8 characters long': 'Salasanan tulee olla vähintään 8 merkkiä pitkä',
  'Username must be at least 3 characters long': 'Käyttäjätunnuksen tulee olla vähintään 3 merkkiä pitkä',
  'Invalid city': 'Virheellinen kaupunki',
  'Invalid location': 'Virheellinen sijainti',
  'Could not save account': 'Käyttäjätunnuksen tallentaminen epäonnistui',
  'Edit shared account': 'Yhteiskäyttötunnuksen muokkaus',
  'Save': 'Tallenna',
  'Confirm deletion': 'Vahvista poisto',
  'Are you sure you want to permanently delete this shared account?': 'Haluatko poistaa tämän yhteiskäyttötunnuksen pysyvästi?',
  'Are you sure you want to permanently delete this account?': 'Haluatko poistaa tämän käyttäjätunnuksen pysyvästi?',
  'Anyone currently signed in with this account will be able to continue using the site until their login expires.': 'Tällä käyttäjätunnuksella kirjautuneet henkilöt pystyvät jatkamaan sivuston käyttöä kunnes heidän kirjautumisensa vanhenevat.',
  'Delete': 'Poista',
  'Last login': 'Viimeisin kirjautuminen',
  'Username too long': 'Liian pitkä käyttäjätunnus',
  'Password too long': 'Liian pitkä salasana',
  'Email address already in use': 'Sähköpostiosoite on jo käytössä',
  'Invalid email address': 'Virheellinen sähköpostiosoite',
  'New Password': 'Uusi salasana',
  'Roles': 'Roolit',
  'User': 'Käyttäjä',
  'Accounts': 'Käyttäjätunnukset',
  'Create Account': 'Luo käyttäjätunnus',
  'Select all': 'Valitse kaikki',
  'Deselect all': 'Tyhjennä valinnat',
  'No roles selected': 'Ei rooleja',
  'Email Address': 'Sähköpostiosoite',
  'accounts on list': 'käyttäjätunnusta listalla',
  'selected': 'valintaa',

  // Inventory
  'Inventory': 'Haravointi',
  'Uninventoried': 'Haravoimattomat',
  'Uninventoried items': 'Haravoimattomat niteet',
  'Reader': 'Lukija',
  'add comment': 'lisää kommentti',
  'edit comment': 'muokkaa kommenttia',
  'Delete Comment': 'Poista kommentti',
  'Add all read items to list': 'Lisää kaikki luetut niteet listalle',
  'Automatically scroll to new items': 'Rullaa listaa automaattisesti uusien niteiden kohdalle',
  'items read': 'nidettä luettu',
  'Enabled Rules': 'Säännöt',
  'Enabled Columns': 'Sarakkeet',
  'Hold': 'Varaus',
  'Tools': 'Työkalut',
  'Clear': 'Tyhjennä',
  'Clear List': 'Tyhjennä lista',
  'Show spine labels': 'Näytä selkätarrat',
  'Invalid barcode': 'Virheellinen viivakoodi',
  'Item record not found': 'Nidetietuetta ei löytynyt',
  'Item has been deleted from Sierra.': 'Nide on poistettu Sierrasta.',
  'Historic data is shown and may not be accurate.': 'Näytetään vanha tietue, tieto ei välttämättä vastaa nykyhetkeä.',
  'Showing data of bib corresponding to tag\'s ISBN.': 'Näytetään tunnisteen ISBN-kenttään liittyvän nimekkeen tiedot.',
  'Invalid item status': 'Virheellinen tila',
  'and city': 'ja kaupunki',
  'Undelete item': 'Peruuta niteen poisto',
  'Item undeleted': 'Niteen poisto peruutettu',
  'Bib has a hold': 'Nimekkessä on varaus',
  'Item has a hold': 'Niteessä on varaus',
  'Item has no hold': 'Niteessä ei ole varausta',
  'Item is checked out': 'Nide on lainassa',
  'Invalid call number': 'Virheellinen hyllyluokka',
  'Invalid genre': 'Virheellinen nidesana',
  'Invalid ISIL': 'Virheellinen ISIL',
  'Invalid DSFID': 'Virheellinen DSFID',
  'Invalid AFI': 'Virheellinen AFI',
  'Invalid tag data': 'Virheellistä dataa RFID-tunnisteessa',
  'Invalid language': 'Virheellinen kieli',
  'Tag ID': 'Tunnisteen ID',
  'Tag ISIL': 'Tunnisteen ISIL',
  'Tag DSFID': 'Tunnisteen DSFID',
  'Tag AFI': 'Tunnisteen AFI',
  'Tag Valid': 'Tunniste ok',
  'Not Scanned After': 'Ei skannattu jälkeen',
  'Nothing selected': 'Ei mitään',
  'Connection Status': 'Yhteyden tila',
  'Connection': 'Yhteys',
  'Last Scan Date': 'Viimeksi skannattu',
  'No Hold': 'Ei varausta',
  'Circulation': 'Kierto',
  'Circulation History': 'Kiertohistoria',
  'under': 'alle',
  'over': 'yli',
  'this year': 'tänä vuonna',
  'last year': 'viime vuonna',
  'is not': 'ei ole',
  'is': 'on',
  'and': 'ja',
  'or': 'tai',
  'Last Checked Out': 'Viimeksi lainattu',
  'Floating': 'Kellutus',
  'Floatable': 'Kellutettavissa',
  'Float': 'Kelluta',
  'float': 'kelluta',
  'floated': 'kellutettu',
  'own': 'oma',
  'select': 'valitse',
  'Copies in own location greater than': 'Nimekkeen niteitä omassa kirjastossa yli',
  'in target location less than': 'kohdekirjastossa alle',
  'target locations': 'kohteet',
  'none': 'ei mitään',
  'set location to': 'aseta sijainniksi',
  'location set to': 'sijainniksi asetettu',
  'set item status to': 'aseta tilaksi',
  'item status set to': 'tilaksi asetettu',
  'Next Item': 'Seuraava nide',
  'Not in Shelf': 'Puuttuu hyllystä',
  'Reader does not find this item': 'Harava ei lue nidettä',
  'Verify that your reader is in reading mode.': 'Varmista että haravasi on lukutilassa.',
  'If the reading mode is enabled and your reader still does not find the item, the item\'s RFID tag may be broken.': 'Jos lukutila on päällä ja haravasi ei silti lue nidettä, niteen RFID-tarra saattaa olla epäkunnossa.',
  'Collect the item.': 'Kerää nide.',
  'Item Collected': 'Nide kerätty',
  'This item has never been seen.': 'Nidettä ei ole koskaan nähty.',
  'This item has never been scanned.': 'Nidettä ei ole koskaan skannattu.',
  'This item has not been seen in over a year.': 'Nidettä ei ole nähty yli vuoteen.',
  'This item has not been seen in over six months.': 'Nidettä ei ole nähty yli puoleen vuoteen.',
  'This item has not been seen in over three months.': 'Nidettä ei ole nähty yli kolmeen kuukauteen.',
  'It should be marked as missing.': 'Kadonneeksi merkitsemistä suositellaan.',
  'Skip without marking as missing': 'Ohita merkitsemättä kadonneeksi',
  'Item has the following problems': 'Niteessä ongelmia',
  'Search First Item': 'Hae ensimmäinen nide',
  'Shelf Inventory': 'Hyllyharavointi',
  'Start Shelf Inventory': 'Aloita hyllyharavointi',
  'Shelf is empty.': 'Hylly on tyhjä.',
  'Import': 'Tuo',
  'Export': 'Vie',
  'Bib Hold': 'Nimekevaraus',
  'Item Hold': 'Nidevaraus',
  'No Item Hold': 'Ei nidevarausta',
  'Are you sure you want to clear all items on list?': 'Haluatko varmasti poistaa kaikki niteet listalta?',
  'This cannot be undone.': 'Toimintoa ei voi peruuttaa.',
  'Are you sure you want to leave?': 'Haluatko varmasti poistua sivulta?',
  'Automatically enable bell mode on new items': 'Ota etsintätila automaattisesti käyttöön uusille niteille',
  'Disable all bells': 'Poista etsintätilat käytöstä',
  'Save and remove from list': 'Tallenna ja poista listalta',
  'Invalid item type': 'Virheellinen nidetyyppi',
  'User location does not match reader location!': 'Sijaintisi eroaa lukijan sijainnista!',
  'Radar Mode': 'Tutka',
  'Reader does not support radar mode': 'Lukija ei tue tutkatilaa',
  'Last seen at': 'Viimeksi nähty',
  'Tag': 'Tunniste',
  'First RSSI': 'Ensimmäinen RSSI',
  'Current RSSI': 'Nykyinen RSSI',
  'Max RSSI': 'Korkein RSSI',
  'Reader location': 'Lukijan sijainti',
  'CONNECTING...': 'YHDISTETÄÄN...',
  'OPEN': 'AUKI',
  'CLOSING...': 'SULJETAAN...',
  'CLOSED': 'SULJETTU',
  'UNINSTANTIATED': 'ALUSTAMATON',
  'Zeroes List': 'Nollalista',
  'Last checkout on': 'Viimeksi lainattu',
  'item created on': 'nide luotu',
  'Last checkout before': 'Viimeksi lainattu ennen',

  // Reception
  'new': 'uusi',
  'Session name cannot be empty!': 'Istunnon nimi ei voi olla tyhjä!',
  'Session name too long!': 'Istunnon nimi liian pitkä!',
  'Add': 'Lisää',
  'Add shipments by number': 'Lisää lähetyksiä',
  'Shipment Number': 'Lähetysnumero',
  'Shipment number': 'Lähetysnumero',
  'Product Code': 'Tuotekoodi',
  'Sierra Order Status': 'Tila Sierrassa',
  'Vendor': 'Toimittaja',
  'shipments on list': 'lähetystä listalla',
  'Rename Session': 'Uudelleennimeä istunto',
  'scanned': 'luettu',
  'barcode': 'viivakoodi',
  'product code': 'tuotekoodi',
  'Owning Location': 'Omistajasijainti',
  'Add items': 'Lisää niteitä',
  'Session': 'Istunto',
  'Shipments in this session': 'Listalla olevat lähetykset',
  'Sierra IDs': 'Sierra ID:t',
  'Add items manually': 'Lisää niteitä',
  'Received Items': 'Vastaanotetut niteet',
  'Edit': 'Muokkaa',
  'No items received for this shipment.': 'Lähetykseen ei ole vielä vastaanotettu niteitä.',
  'Select reader...': 'Valitse lukija...',
  'Select RFID Reader': 'Valitse RFID-lukija',
  'Time': 'Aika',
  'Reason': 'Syy',
  'No shipment found': 'Lähetystä ei löytynyt',
  'All shipments full': 'Vajaata lähetystä ei löytynyt',
  'No product code': 'Ei tuotekoodia',
  'Invalid product code': 'Virheellinen tuotekoodi',
  'Hide': 'Piilota',
  'Invalid tag': 'Virheellinen RFID-tunniste',
  'Invalid tag ISIL': 'Virheellinen ISIL',
  'Invalid tag DSFID': 'Virheellinen DSFID',
  'Invalid tag AFI': 'Virheellinen AFI',
  'Could not save received item': 'Niteen tallentaminen epäonnistui',
  'Item already received': 'Nide on jo vastaanotettu',
  'items could not be automatically added': 'nidettä ei pystytty kohdentamaan automaattisesti',
  'Could not fetch reception sessions': 'Istuntojen hakeminen epäonnistui',
  'Invalid shipment number': 'Virheellinen lähetysnumero',
  'Could not fetch item details': 'Niteen tietojen lataaminen epäonnistui',
  'View Created Item': 'Näytä luotu nide',
  'Created Item': 'Luotu nide',
  'Could not fetch shipments': 'Lähetysten hakeminen epäonnistui',
  'Show cover images': 'Näytä kansikuvat',
  'Show only shipments with unreceived items': 'Näytä vain tilaukset joiden kaikkia niteitä ei ole vastaanotettu',

  // Shipments
  'View': 'Näkymä',
  'Comment': 'Kommentti',
  'Items ready to be created': 'Valmis nidevietäväksi',
  'Check if full marc available': 'Tarkista onko luettelointi tullut',
  'No bib record found': 'Ei bib-tietuetta',
  'No Espoo class': 'Tietueesta puuttuu Espoon luokka',
  'No VANTAA class': 'Tietueesta puuttuu VANTAAN luokka',
  'No MARC 091': 'Tietueesta puuttuu MARC 091',
  'Invalid MARC 091': 'Virheellinen MARC 091 tietueessa',
  'No language': 'Tietueesta puuttuu kieli',
  'Bib record is suppressed': 'Bib-tietue on piilotettu',
  'Order record does not exist': 'Ei tilaustietuetta',
  'Order status not "paid"': 'Tilauksen tila ei "maksettu"',
  'Invalid material type': 'Virheellinen aineistolaji',
  'No age rating': 'Tietueesta puuttuu ikärajatieto',
  'No material type': 'Tietueesta puuttuu aineistolaji',
  'items have no location': 'niteellä ei ole sijainteja',
  'Work': 'Teos',
  'Shipment Number & Product Code': 'Lähetysnumero ja tuotekoodi',
  'Add Bestseller': 'Lisää bestseller',
  'Configure Items': 'Määritä niteet',
  'shipment': 'lähetys',
  'bestsellers read': 'bestselleriä luettu',
  'Item not on shipment!': 'Luettu nide ei kuulu tilaukseen!',
  'Item has already been read!': 'Nide on jo luettu!',
  'Save and Create Items': 'Tallenna ja luo niteet',
  'Category Group': 'Tilastoryhmä',
  'All items created': 'Kaikki niteet luotu',
  'Some items created': 'Osa niteistä luotu',
  'view': 'näytä',
  'No shipment found with ID': 'Lähetystä ei löytynyt lähetysnumerolla',
  'Spine labels not printed yet': 'Selkätarrat tulostamatta',
  'Items must be created before labels can be printed': 'Niteet on luotava ennen tarrojen tulostamista',
  'Creating items': 'Luodaan niteitä',
  'Order paid in part': 'Tilaus maksettu osaksi',
  'BTJ catalogue plan': 'BTJ luetteloi',
  'Create import file': 'Luo tuontitiedosto',
  'Could not fetch shipment': 'Lähetyksen hakeminen epäonnistui',

  // Shipment View
  'Shipment View': 'Lähetysnäkymä',
  'MARC View': 'Marc näkymä',
  'Shipment Details': 'Lähetyksen tiedot',
  'Sierra Order ID': 'Tilauksen Sierra ID',
  'Copies': 'Niteitä',
  'Taxless Price': 'Veroton hinta',
  'Total Price': 'Hinta yhteensä',
  'Tax %': 'Veroprosentti',
  'Account': 'Tili',
  'Bestseller Count': 'Bestsellerien määrä',
  'Order Details': 'Tilauksen tiedot',
  'Sierra Bib ID': 'Nimekkeen Sierra ID',
  'Locations': 'Sijainnit',
  'Order Date': 'Tilauspäivä',
  'Fund': 'Määräraha',
  'Accounting Unit': 'Laskutuskaupunki',
  'Select at least one item to edit': 'Valitse ensin muokattavat niteet',
  'Select at least one item to delete': 'Valitse ensin poistettavat niteet',
  'Shipment is not ready for item creation': 'Lähetys ei ole valmis nidevientiin',
  'Genre': 'Nidesana',
  'Are you sure you want to delete': 'Haluatko varmasti poistaa',
  'this item': 'tämän niteen',
  'items': 'nidettä',
  'item': 'nide',
  'This would delete': 'Tämän suorittaminen poistaisi',
  'item that has already been created in Sierra': 'niteen Sierrasta',
  'items that have already been created in Sierra': 'nidettä Sierrasta',
  'Order has no received date': 'Tilauksesta puuttuu Vastpvm',
  'Reception Date': 'Vastaanotettu',
  'Print Spine Labels': 'Tulosta tarrat',

  // Roles
  'Change Location': 'Kirjaston valinta',
  'Process Shipments': 'Lähetysten käsittely',
  'Handle Holds': 'Varausten hallinta',
  'View Items': 'Niteiden katselu',
  'View Bibs': 'Nimekkeiden katselu',
  'View Orders': 'Tilausten katselu',
  'Edit Items': 'Niteiden muokkaus',
  'Edit Bibs': 'Nimekkeiden muokkaus',
  'Edit Orders': 'Tilausten muokkaus',

  // Developer
  'Select log file...': 'Valitse tiedosto...',
  'Filter lines...': 'Suodata rivejä...',
  'Could not fetch log files': 'Tiedostojen hakeminen epäonnistui',
  'Could not fetch random bib': 'Satunnaisen nimekkeen hakeminen epäonnistui',
  'Could not fetch random item': 'Satunnaisen niteen hakeminen epäonnistui',
  'Could not fetch random order': 'Satunnaisen tilauksen hakeminen epäonnistui',
  'Sierra': 'Sierra',

  // Maps
  'Libraries': 'Kirjastot',

  // Most traveled items
  'Filter by scan location...': 'Suodata sijainnin mukaan...',
  'Treshold days': 'Raja (päivinä)',

  // Bib lists
  'Image': 'Kuva',
  'Name': 'Nimi',
  'Description': 'Kuvaus',
  'Public': 'Julkinen',
  'Owner': 'Omistaja',
  'Colors': 'Värit',
  'Order String': 'Järjestysmerkkijono',
  'Group String': 'Ryhmitysmerkkijono',
  'default': 'oletus',
  'Bib Lists': 'Nimekelistat',
  'Create Bib List': 'Luo nimekelista',
  'Create Sublist': 'Luo alilista',
  'Add Bibs': 'Lisää nimekkeitä',
  'lists': 'listaa',
  'Image URL': 'Kuvan URL',
  'Primary Color': 'Ensisijainen väri',
  'Secondary Color': 'Toissijainen väri',
  'My lists': 'Listani',
  'Shared with me': 'Jaettu kanssani',
  'Public lists': 'Julkiset listat',
  'URL': 'URL-osoite',
  'Could not fetch bib lists': 'Nimekelistojen hakeminen epäonnistui',
  'bibs': 'nimekettä',
  'sublists': 'alilistaa',

  // Query builder
  'Not': 'Ei',
  'Or': 'Tai',
  'And': 'Ja',
  'Add Rule': 'Uusi ehto',
  'Add Group': 'Uusi ryhmä',
  'Select your value': 'Valitse arvo',
  'Larger': 'Suurempi kuin',
  'Smaller': 'Pienempi kuin',
  'Larger or equal': 'Suurempi tai yhtä suuri kuin',
  'Smaller or equal': 'Pienempi tai yhtä suuri kuin',
  'Equal': 'Yhtä suuri kuin',
  'Not equal': 'Ei yhtä suuri kuin',
  'All in': 'Joukossa kaikki',
  'Any in': 'Joukossa mikä tahansa',
  'Not in': 'Joukossa ei mikään',
  'Between': 'Välillä',
  'Not between': 'Ei välillä',
  'Like': 'Kuin',
  'Not like': 'Ei kuin',
  'wild card': 'villikortti',
  'Any': 'Mikä tahansa',

  // Uninventoried segments
  'Segment Browser': 'Haravoidut alueet',
  'Fiction': 'Kaunokirjallisuus',
  'Nonfiction': 'Tietokirjallisuus',
  'fiction': 'kauno',
  'nonfiction': 'tieto',
  'No items': 'Ei niteitä',
  'No genre': 'Genretön',
  'no genre': 'genretön',
  'Series': 'Sarja',
  'Treshold Date': 'Raja',
  'Show uninventoried items': 'Näytä haravoimattomat niteet',
  'Open': 'Avaa',
  'Massreader': 'Massalukija',
  'Loading locations...': 'Ladataan sijainteja...',
  'Edit Item': 'Niteen muokkaus',
  'Set item status to': 'Aseta niteen tilaksi',

  // Create broadcast
  'Primary': 'Ensisijainen',
  'Secondary': 'Toissijainen',
  'Success': 'Onnistuminen',
  'Danger': 'Vaara',
  'Warning': 'Varoitus',
  'Info': 'Info',
  'Light': 'Vaalea',
  'Dark': 'Tumma',
  'Content': 'Sisältö',
  'markdown supported': 'markdown tuettu',
  'Preview': 'Esikatselu',

  // Item statuses
  'HYLLYSSÄ': 'HYLLYSSÄ',
  'POISTETTU': 'POISTETTU',
  'KADONNUT': 'KADONNUT',
  'KORJATTAVANA': 'KORJATTAVANA',

  // Announcements
  'Announcements': 'Kuulutukset',
  'Announcement': 'Kuulutus',
  'Closing in 15 minutes': 'Sulkuun 15 minuuttia',
  'Closing in 5 minutes': 'Sulkuun 5 minuuttia',
  'Closed': 'Suljettu',
  'Playing': 'Soitetetaan',
  'Corona Long': 'Pitkä koronakuulutus',
  'Corona Short': 'Lyhyt koronakuulutus',
  'Wear Mask': 'Maskisuositus'
};

export default translation;
