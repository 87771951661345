import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import useQueryParamState from '../../hooks/useQueryParamState';

import { Form } from 'react-bootstrap';

import Loading from '../api/Loading';

const LogFileSelectorForm = ({ fileNames, selectedFile, setSelectedFile }) => {
  const { t } = useTranslation();

  const options = fileNames.map(fileName => (
    <option key={fileName}>
      {fileName}
    </option>
  ));

  const onChange = event => {
    if (event.target.value === 'none') setSelectedFile(null);
    else setSelectedFile(event.target.value);
  };

  return (
    <Form onSubmit={event => event.preventDefault()} inline>
      <Form.Control
        as="select"
        size="sm"
        onChange={onChange}
        defaultValue={selectedFile}
      >
        <option value="none">
          {t('Select log file...')}
        </option>

        {options}
      </Form.Control>
    </Form>
  );
};

const FilterInput = ({ filter, setFilter }) => {
  const { t } = useTranslation();

  const onChange = event => setFilter(event.target.value);

  return (
    <Form onSubmit={event => event.preventDefault()} inline>
      <Form.Control
        type="text"
        size="sm"
        placeholder={t('Filter lines...')}
        onChange={onChange}
        defaultValue={filter}
      />
    </Form>
  );
};

const LogView = ({ log }) => {
  const [filter, setFilter] = useQueryParamState('filter', '');

  const filterEmpty = line => (line.length !== 0);

  const filterLine = line => {
    try {
      const regex = new RegExp(filter.toLowerCase());

      return regex.test(line.toLowerCase());
    } catch (err) {
      return line.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
    }
  };

  return (
    <>
      <FilterInput
        filter={filter}
        setFilter={setFilter}
      />

      <pre>
        {log.split('\n').filter(filterEmpty).filter(filterLine).reverse().join('\n')}
      </pre>
    </>
  );
};

const DeveloperLogsPage = () => {
  const { t } = useTranslation();

  const [selectedLogFile, setSelectedLogFile] = useQueryParamState('file', null);

  const [logFiles, logFilesLoading, logFilesError] = useAPI('GET', '/logs');

  const [log, logLoading, logError] = useAPI('GET', '/logs', {
    params: {
      file: selectedLogFile
    }
  }, [selectedLogFile]);

  // eslint-disable-next-line no-console
  if (logError) console.error('Error fetching log:', logError);

  if (logFilesLoading) {
    return <Loading />;
  }

  if (logFilesError) {
    return t('Could not fetch log files');
  }

  return (
    <>
      <LogFileSelectorForm
        fileNames={logFiles}
        selectedFile={selectedLogFile}
        setSelectedFile={setSelectedLogFile}
      />

      <hr />

      {
        (selectedLogFile !== null && !logLoading && !logError && typeof log === 'string') &&
        <LogView log={log} />
      }
    </>
  );
};

export default DeveloperLogsPage;
