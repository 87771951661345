import {
  BoxArrowUpLeft,
  BoxArrowUpRight,
  Circle,
  CircleFill,
  Collection,
  Eye,
  EyeFill,
  EyeSlash,
  EyeSlashFill,
  FolderPlus,
  Gear,
  GearFill,
  Link,
  Info,
  InfoCircle,
  InfoCircleFill,
  InfoLg,
  InfoSquare,
  InfoSquareFill,
  PencilSquare,
  Printer,
  Trash,
  X
} from 'react-bootstrap-icons';

import { useColor, useMargins } from '.';

import type { Color } from '.';

export type IconName =
  | 'box-arrow-up-left'
  | 'box-arrow-up-right'
  | 'circle'
  | 'circle-fill'
  | 'collection'
  | 'eye'
  | 'eye-fill'
  | 'eye-slash'
  | 'eye-slash-fill'
  | 'folder-plus'
  | 'gear'
  | 'gear-fill'
  | 'info'
  | 'info-circle'
  | 'info-circle-fill'
  | 'info-lg'
  | 'info-square'
  | 'info-square-fill'
  | 'link'
  | 'pencil-square'
  | 'printer'
  | 'trash'
  | 'x';

export interface Props {
  icon: IconName;
  size?: number;
  color?: Color;
  leftMargin?: number;
  rightMargin?: number;
  style?: React.CSSProperties;
}

const BootstrapIcon: React.FC<Props> = ({ icon, ...props }) => {
  switch (icon) {
    case 'box-arrow-up-left':
      return <BoxArrowUpLeft {...props} />;
    case 'box-arrow-up-right':
      return <BoxArrowUpRight {...props} />;
    case 'circle':
      return <Circle {...props} />;
    case 'circle-fill':
      return <CircleFill {...props} />;
    case 'collection':
      return <Collection {...props} />;
    case 'eye':
      return <Eye {...props} />;
    case 'eye-fill':
      return <EyeFill {...props} />;
    case 'eye-slash':
      return <EyeSlash {...props} />;
    case 'eye-slash-fill':
      return <EyeSlashFill {...props} />;
    case 'folder-plus':
      return <FolderPlus {...props} />;
    case 'gear':
      return <Gear {...props} />;
    case 'gear-fill':
      return <GearFill {...props} />;
    case 'info':
      return <Info {...props} />;
    case 'info-circle':
      return <InfoCircle {...props} />;
    case 'info-circle-fill':
      return <InfoCircleFill {...props} />;
    case 'info-lg':
      return <InfoLg {...props} />;
    case 'info-square':
      return <InfoSquare {...props} />;
    case 'info-square-fill':
      return <InfoSquareFill {...props} />;
    case 'link':
      return <Link {...props} />;
    case 'pencil-square':
      return <PencilSquare {...props} />;
    case 'printer':
      return <Printer {...props} />;
    case 'trash':
      return <Trash {...props} />;
    case 'x':
      return <X {...props} />;
  }
};

const iconStyle: React.CSSProperties = {
  marginTop: '-4px'
};

const Icon: React.FC<Props> = ({ icon, color, leftMargin, rightMargin, style, ...props }) => (
  <BootstrapIcon
    icon={icon}

    style={{
      ...iconStyle,
      ...useColor(color),
      ...useMargins(leftMargin, rightMargin),
      ...style
    }}

    {...props}
  />
);

export default Icon;
