import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface DarkModeState {
  enabled: boolean;
}

const initialState: DarkModeState = {
  enabled: (localStorage.getItem('darkThemeEnabled') === 'true')
};

const reducers = {
  toggle: (state: DarkModeState): void => {
    state.enabled = !state.enabled;
  }
};

const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState,
  reducers
});

export const selectEnabled = (state: RootState): boolean =>
  state.darkMode.enabled;

export const {
  toggle
} = darkModeSlice.actions;

export default darkModeSlice.reducer;
