import { useTranslation } from 'react-i18next';

import { getLanguage } from '../../services/languages';

import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';

const LanguagesSelector = ({ languages, defaultValue, onChange, sm, defaultAllSelected }) => {
  const { t } = useTranslation();

  const topLanguages = ['fin', 'swe', 'eng', 'rus'];

  if (!languages) {
    // eslint-disable-next-line no-console
    console.log('No languages given to LanguagesSelector!');

    languages = [];
  }

  const onDropdownChange = selected => {
    if (typeof onChange === 'function') {
      if (selected.length === Object.keys(languages).length) {
        onChange([]);
      } else {
        onChange(selected);
      }
    }
  };

  const options = [
    ...topLanguages.filter(code => languages.includes(code)).map(code => [code, getLanguage(code)]),
    ...languages.filter(code => !topLanguages.includes(code)).map(code => [code, getLanguage(code)])
  ].map(([code, names]) => ({
    key: code,
    label: (names ? (names.fi ?? names.en ?? `[${code}]`) : `[${code}]`)
  }));

  return (
    <div className={sm ? 'dropdown-sm-container' : ''}>
      <DropdownMultiselect
        name="languages"
        options={options}
        selected={defaultValue}
        placeholder={defaultAllSelected ? `${t('All')} (${t('default')})` : t('Nothing selected')}
        selectDeselectLabel={t('Select all')}
        handleOnChange={onDropdownChange}
      />
    </div>
  );
};

export default LanguagesSelector;
