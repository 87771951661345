import { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../../hooks/useAPI';
import useMassreader from '../../../hooks/useMassreader';
import useSounds from '../../../hooks/useSounds';
import api from '../../../services/api';
import expandRow from '../../../services/expandRow';
import { formatOrderStatus } from '../../../services/order';
import { isBarcodeValid } from '../../../services/item';
import { formatTime } from '../../../services/date';
import config from '../../../config';
import { hasRole } from '../../../services/auth';

import { Button, Form } from 'react-bootstrap';
import BootstrapTable from '@ounai/react-bootstrap-table2';
import { X } from 'react-bootstrap-icons';

import Loading from '../../api/Loading';
import ReceptionTabView from './ReceptionTabView';

import { CoverImage } from '../../../../bibs';

const ReceptionSessionAddShipmentForm = ({ session, saveSession, onAddShipment }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);

  const onSubmit = async event => {
    event.preventDefault();

    try {
      if (!session.saved) await saveSession();

      await api.post(`/reception/sessions/${session.id}/shipments`, {
        shipmentId: Number(event.target.shipmentId.value)
      });

      event.target.shipmentId.value = '';

      onAddShipment();

      setError(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      setError(err);
    }
  };

  return (
    <Form onSubmit={onSubmit} inline>
      <Form.Label>
        {t('Add shipments by number')}
      </Form.Label>

      <Form.Control
        name="shipmentId"
        size="sm"
        style={{ marginLeft: '6px' }}
        placeholder={t('Shipment number')}
      />

      <Button
        type="submit"
        variant="primary"
        size="sm"
        style={{ marginLeft: '4px' }}
      >
        {t('Add')}
      </Button>

      <span style={{ color: 'red', marginLeft: '6px' }}>
        {error && t('Invalid shipment number')}
      </span>
    </Form>
  );
};

const ReceptionSessionAutoAddItemsForm = ({ autoAddReceivedItem }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);

  const onSubmit = event => {
    event.preventDefault();

    const str = event.target.autoAddInput.value;

    if (str.indexOf('.') === -1 || str.indexOf(',') === -1) {
      return setError('Invalid format');
    }

    const barcode = str.split(',')[0];
    const productCode = str.split(',')[1].split('.')[0];

    if (!isBarcodeValid(barcode)) return setError(t('Invalid barcode'));
    else if (productCode.length === 0) return setError(t('No product code'));

    autoAddReceivedItem(barcode, productCode);

    event.target.autoAddInput.value = '';
  };

  return (
    <Form onSubmit={onSubmit} inline>
      <Form.Label>
        {t('Add items')}
      </Form.Label>

      <Form.Control
        name="autoAddInput"
        size="sm"
        style={{ width: '200px', marginLeft: '6px' }}
        placeholder={`<${t('barcode')}>,<${t('product code')}>.`}
      />

      <Button
        type="submit"
        variant="primary"
        size="sm"
        style={{ marginLeft: '4px' }}
      >
        {t('Add')}
      </Button>

      <span style={{ color: 'red', marginLeft: '6px' }}>
        {error}
      </span>
    </Form>
  );
};

const ShipmentStatus = ({ shipment, receivedCount }) => {
  const { t } = useTranslation();

  return (
    <strong className="shipment-status-column">
      {receivedCount} / {shipment.copiesOnShipment} {t('scanned')}
    </strong>
  );
};

const ReceptionSessionShipmentsBootstrapTable = ({ shipments, receivedCounts, addReceivedItem, onItemRemoved, itemsRead, itemsTotal }) => {
  const { t } = useTranslation();

  const [showUnreceived, setShowUnreceived] = useState(false);
  const [showCoverImage, setShowCoverImage] = useState(false);
  const [tab, setTab] = useState('receptionShipmentView');

  const getStatusSortValue = useCallback(shipment => {
    if (shipment.receivedItems.length === shipment.copiesOnShipment) {
      return 0;
    } else if (shipment.receivedItems.length === 0) {
      return Number.POSITIVE_INFINITY;
    } else {
      return shipment.copiesOnShipment - shipment.receivedItems.length;
    }
  }, []);

  const columns = [
    {
      dataField: 'id',
      text: t('ID'),
      hidden: true
    },
    {
      dataField: 'coverImage',
      text: t('Cover'),
      isDummyField: true,
      hidden: !showCoverImage,
      formatter: (cell, row, rowIndex, formatExtraData) => row.Order && row.Order.Bib && (
        <CoverImage isbn={row.Order.Bib.marcISBN} materialType={row.Order.Bib.fixedFieldMaterialType} />
      )
    },
    {
      dataField: 'sierraOrderRecordId',
      text: t('Sierra IDs'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <>
          {cell && (
            <pre style={{ marginBottom: 0 }}>
              o{cell}
            </pre>
          )}

          {row.Order && (
            <pre style={{ marginBottom: 0 }}>
              b{row.Order.sierraBibRecordId}
            </pre>
          )}
        </>
      )
    },
    {
      dataField: 'Order.sierraBibRecordId',
      text: t('Sierra Bib ID'),
      sort: true,
      hidden: true
    },
    {
      dataField: 'shipmentId',
      text: t('Shipment Number'),
      sort: true
    },
    {
      dataField: 'productCode',
      text: t('Product Code'),
      sort: true
    },
    {
      dataField: 'Order.status',
      text: t('Sierra Order Status'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => formatOrderStatus(row.Order)
    },
    {
      dataField: 'title',
      text: t('Title'),
      sort: true
    },
    {
      dataField: 'author',
      text: t('Author'),
      sort: true
    },
    {
      dataField: 'language',
      text: t('Language'),
      sort: true
    },
    {
      dataField: 'vendor',
      text: t('Vendor'),
      sort: true
    },
    {
      dataField: 'shipmentStatus',
      text: t('Status'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, { receivedCounts }) => (
        <ShipmentStatus
          shipment={row}
          receivedCount={receivedCounts[row.id]}
        />
      ),
      formatExtraData: { receivedCounts },
      style: (cell, row, rowIndex, colIndex) => {
        if (receivedCounts[row.id] === row.copiesOnShipment) return { backgroundColor: '#81c784' }; // green
        else if (receivedCounts[row.id] > 0) return { backgroundColor: '#ccffaa' }; // yellow
        else return { backgroundColor: '#cccccc' }; // grey
      },
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const valA = getStatusSortValue(rowA);
        const valB = getStatusSortValue(rowB);

        if (order === 'asc') return valB - valA;
        else return valA - valB;
      }
    }
  ];

  return (
    <>
      <Form.Check
        label={t('Show only shipments with unreceived items')}
        onChange={event => setShowUnreceived(event.target.checked)}
      />

      <Form.Check
        label={t('Show cover images')}
        onChange={event => setShowCoverImage(event.target.checked)}
        style={{ marginBottom: '10px' }}
      />

      <strong>
        {shipments.length} {t('shipments on list')} ({itemsRead} / {itemsTotal} {t('items read')})
      </strong>

      <BootstrapTable
        keyField="id"
        data={showUnreceived ? shipments.filter(s => s.copiesOnShipment !== receivedCounts[s.id]) : shipments}
        columns={columns}
        classes="w-auto api-table mw-100"
        wrapperClasses="table-responsive"

        expandRow={expandRow(row => (
          <ReceptionTabView
            tab={tab}
            setTab={setTab}
            shipment={row}
            addReceivedItem={addReceivedItem}
            onItemRemoved={onItemRemoved}
            receivedCounts={receivedCounts}
          />
        ))}

        defaultSorted={[{
          dataField: 'shipmentId',
          order: 'asc'
        }]}

        bootstrap4
      />
    </>
  );
};

const ReceptionSessionShipmentsTable = ({ session, shipments, loading, error, receivedCounts, receivedCountsInitialized, addReceivedItem, onItemRemoved, itemsRead, itemsTotal }) => {
  const { t } = useTranslation();

  const [displayReceivedCounts, setDisplayReceivedCounts] = useState(receivedCounts.current);

  useEffect(() => {
    const interval = setInterval(() => setDisplayReceivedCounts({ ...receivedCounts.current }), 1000);

    return () => clearInterval(interval);
  }, [receivedCounts]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setDisplayReceivedCounts({ ...receivedCounts.current }), [receivedCountsInitialized]);

  if (!session.saved) return null;
  else if (!shipments || (shipments.length === 0 && (loading || error))) {
    if (loading) return <Loading />;
    else if (error) return t('Could not fetch shipments');
    else return null;
  } return (
    <ReceptionSessionShipmentsBootstrapTable
      shipments={shipments}
      receivedCounts={displayReceivedCounts}
      addReceivedItem={addReceivedItem}
      onItemRemoved={onItemRemoved}
      itemsRead={itemsRead}
      itemsTotal={itemsTotal}
    />
  );
};

const ReceptionSessionRemoveShipmentButton = ({ session, shipmentId, update }) => {
  const onClick = async event => {
    await api.delete(`/reception/sessions/${session.id}/shipments/${shipmentId}`);

    update();
  };

  return (
    <Button
      variant="link"
      size="sm"
      onClick={onClick}
      style={{ padding: 0, border: 0, verticalAlign: 'text-bottom' }}
    >
      <X />
    </Button>
  );
};

const ReceptionSessionShipmentsList = ({ shipments, session, update, loading, error }) => {
  const { t } = useTranslation();

  if (!session.saved || (!shipments && (loading || error))) {
    return null;
  }

  const shipmentIdSet = new Set();

  for (const shipment of shipments) {
    if (!shipmentIdSet.has(shipment.id)) {
      shipmentIdSet.add(shipment.shipmentId);
    }
  }

  const shipmentListItems = [...shipmentIdSet].map(id => (
    <li key={id}>
      {id}

      <ReceptionSessionRemoveShipmentButton
        session={session}
        shipmentId={id}
        update={update}
      />
    </li>
  ));

  if (shipmentListItems.length === 0) {
    return null;
  }

  return (
    <>
      <strong>
        {t('Shipments in this session')}
      </strong>

      <ul>{shipmentListItems}</ul>
    </>
  );
};

const ProblemItemsTable = ({ problemItems }) => {
  const { t } = useTranslation();

  const columns = [
    {
      dataField: 'time',
      text: t('Time'),
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => formatTime(cell)
    },
    {
      dataField: 'barcode',
      text: t('Barcode'),
      sort: true
    },
    {
      dataField: 'productCode',
      text: t('Product Code'),
      sort: true
    },
    {
      dataField: 'reason',
      text: t('Reason'),
      sort: true
    }
  ];

  return (
    <BootstrapTable
      keyField="barcode"
      data={problemItems}
      columns={columns}
      classes="w-auto table-sm table-borderless"
      striped
      bootstrap4
    />
  );
};

const ReceptionSessionView = ({ session, saveSession, reader }) => {
  const { t } = useTranslation();

  const [shipments, loading, error, /* headers */, update] = useAPI('GET', `/reception/sessions/${session.id}/shipments`, {}, [session.id]);

  const play = useSounds();

  const [problemItems, setProblemItems] = useState([]);
  const [showProblemItems, setShowProblemItems] = useState(true);
  const [itemsRead, setItemsRead] = useState(0);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [receivedCountsInitialized, setReceivedCountsInitialized] = useState(false);

  const receivedCounts = useRef({});
  const updateTimeout = useRef(null);
  const readBarcodes = useRef(new Set());

  useEffect(() => {
    if (shipments === null) return;

    setItemsRead(shipments.reduce((acc, cur) => acc + cur.receivedItems.length, 0));
    setItemsTotal(shipments.reduce((acc, cur) => acc + cur.copiesOnShipment, 0));

    for (const shipment of shipments) {
      if (!shipment.receivedItems) throw new Error(`Shipment ${shipment.id} has no received items!`);

      if (!receivedCounts.current[shipment.id]) {
        receivedCounts.current[shipment.id] = shipment.receivedItems.length;
      }

      for (const receivedItem of shipment.receivedItems) {
        readBarcodes.current.add(receivedItem.barcode);
      }
    }

    setReceivedCountsInitialized(true);
  }, [shipments]);

  const addProblemItem = useCallback((item, reason) => setProblemItems(p => [
    ...p,
    { ...item, reason, time: new Date().getTime() }
  ]), []);

  const addReceivedItem = useCallback(async (shipment, barcode, productCode, tag = {}) => {
    try {
      await api.post(`/reception/sessions/${session.id}/shipments/${shipment.id}/received`, {
        barcode,
        productCode,
        tagId: tag.tagId,
        afi: tag.afi,
        dsfid: tag.dsfid,
        isil: tag.isil
      });

      receivedCounts.current[shipment.id]++;
      setItemsRead(i => i + 1);

      if (updateTimeout.current !== null) {
        clearTimeout(updateTimeout.current);
      }

      updateTimeout.current = setTimeout(() => {
        update();

        updateTimeout.current = null;
      }, 1000);
    } catch (err) {
      if (err.response && err.response.data === 'Barcode in use') {
        addProblemItem({ barcode, productCode }, t('Item already received'));
      } else {
        // eslint-disable-next-line no-console
        console.log('Error saving received item:', err);

        addProblemItem(
          { barcode, productCode },
          t('Could not save received item') + (err.response && typeof err.response.data === 'string') ? `: (${err.response.data})` : ''
        );
      }
    }
  }, [session.id, t, update, addProblemItem]);

  const autoAddReceivedItem = useCallback(async (barcode, productCode, tag = {}) => {
    if (readBarcodes.current.has(barcode)) return;

    readBarcodes.current.add(barcode);

    const item = { ...tag, barcode, productCode };

    if (productCode === null || productCode === undefined || productCode === '') {
      return addProblemItem(item, t('No product code'));
    } else if (productCode.length !== 13) {
      return addProblemItem(item, t('Invalid product code'));
    } else if (tag.validISO28560 === false) {
      return addProblemItem(item, t('Invalid tag'));
    } else if (config.reception.expectedTagIsil.indexOf(tag.isil) === -1) {
      return addProblemItem(item, t('Invalid tag ISIL') + ` (${tag.isil})`);
    } else if (tag.dsfid !== config.reception.expectedTagDsfid) {
      return addProblemItem(item, t('Invalid tag DSFID') + ` (${tag.dsfid})`);
    } else if (config.reception.expectedTagAfi.indexOf(tag.afi) === -1) {
      return addProblemItem(item, t('Invalid tag AFI') + ` (${tag.afi})`);
    }

    const fullShipments = [];

    for (const shipment of shipments) {
      if (productCode === shipment.productCode.replace(/[Kk]$/, '')) {
        if (receivedCounts.current[shipment.id] < shipment.copiesOnShipment) {
          return addReceivedItem(shipment, barcode, productCode, tag);
        } else {
          fullShipments.push(shipment);
        }
      }
    }

    if (fullShipments.length > 0) {
      // Shipments found but full
      addProblemItem(
        item,
        t('All shipments full') + ': ' + fullShipments.map(s => s.title + (s.author ? ` / ${s.author}` : '')).join(', ')
      );
    } else {
      // No shipment found
      addProblemItem(item, t('No shipment found'));
    }
  }, [addReceivedItem, shipments, t, addProblemItem, receivedCounts]);

  const onTagRead = useCallback(async tag => {
    play('ding');

    await autoAddReceivedItem(tag.barcode, tag.gs1, tag);
  }, [play, autoAddReceivedItem]);

  const [/* sendJsonMessage */, removeTag, connectionStatus] = useMassreader(reader, onTagRead, true);

  const onItemRemoved = useCallback((shipment, id) => {
    for (const item of shipment.receivedItems) {
      if (item.id === id) {
        removeTag(item.rfidTagId);

        break;
      }
    }

    receivedCounts.current[shipment.id]--;
    setItemsRead(i => i - 1);

    update();
  }, [removeTag, update]);

  return (
    <>
      {hasRole('DEVELOPER') && (
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          {t('Connection Status')}: {connectionStatus}
        </div>
      )}

      <div syle={{ marginTop: '10px' }}>
        <ReceptionSessionAddShipmentForm
          session={session}
          saveSession={saveSession}
          onAddShipment={update}
        />
      </div>

      <div style={{ marginTop: '4px', marginBottom: '10px' }}>
        <ReceptionSessionAutoAddItemsForm
          autoAddReceivedItem={autoAddReceivedItem}
        />
      </div>

      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <ReceptionSessionShipmentsList
          shipments={shipments}
          session={session}
          update={update}
          loading={loading}
          error={error}
        />
      </div>

      {problemItems.length > 0 && (
        <div>
          <strong>
            {problemItems.length} {t('items could not be automatically added')}
          </strong>

          <Button
            variant="link"
            size="sm"
            onClick={() => setShowProblemItems(s => !s)}
            style={{
              marginLeft: '6px',
              padding: 0,
              verticalAlign: 'baseline',
              fontSize: '14px'
            }}
          >
            {showProblemItems ? t('Hide') : t('Show')}
          </Button>

          {showProblemItems && <ProblemItemsTable problemItems={problemItems} />}
        </div>
      )}

      <ReceptionSessionShipmentsTable
        session={session}
        shipments={shipments}
        loading={loading}
        error={error}
        receivedCounts={receivedCounts}
        receivedCountsInitialized={receivedCountsInitialized}
        addReceivedItem={addReceivedItem}
        onItemRemoved={onItemRemoved}
        itemsRead={itemsRead}
        itemsTotal={itemsTotal}
      />
    </>
  );
};

export default ReceptionSessionView;
