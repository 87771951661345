import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import BibView from './BibView';
import Loading from '../api/Loading';

const RandomBibView = () => {
  const { t } = useTranslation();

  const [bib, loading, error] = useAPI('GET', '/bibs/random');

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch random bib');
  }

  return <BibView bibId={bib.sierraBibRecordId} />;
};

export default RandomBibView;
