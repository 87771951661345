import { useT } from '../../common/i18n';

import { appLocations } from '../../sierra';
import { useLocationOptions } from '.';

import type { FormItem, SelectOption, FormItemValidation } from '.';

const text = (name: string, label: string, validation?: FormItemValidation) =>
  (defaultValue?: string | null): FormItem =>
    ({ type: 'text', name, label, validation, defaultValue });

const textRequired = (name: string, label: string, validation?: FormItemValidation) =>
  (defaultValue?: string | null): FormItem =>
    ({ type: 'text', name, label, validation, defaultValue, required: true });

const textArray = (name: string, label: string) =>
  (defaultValue?: string[] | null): FormItem =>
    ({ type: 'text-array', name, label, defaultValue });

const textarea = (name: string, label: string, validation?: FormItemValidation) =>
  (defaultValue?: string | null): FormItem =>
    ({ type: 'textarea', name, label, validation, defaultValue });

const checkbox = (name: string, label: string) =>
  (defaultValue?: boolean | null): FormItem =>
    ({ type: 'checkbox', name, label, defaultValue });

// const select = (name: string, label: string, options: SelectOption[]) =>
//   (defaultValue?: string | null): FormItem =>
//     ({ type: 'select', name, label, options, defaultValue });

const multiselect = (name: string, label: string, options: SelectOption[]) =>
  (defaultValue?: string[] | null): FormItem =>
    ({ type: 'multiselect', name, label, options, defaultValue });

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const useCommons = () => {
  const t = useT();

  return {
    name: text('name', t('Name')),
    nameRequired: textRequired('name', t('Name')),
    description: textarea('description', t('Description')),
    visible: checkbox('visible', t('Visible')),
    private: checkbox('private', t('Private')),
    global: checkbox('global', t('Global')),
    keywords: textArray('keywords', `${t('Keywords')} (${t('comma separated')})`),
    appLocations: multiselect('locations', t('Locations'), useLocationOptions(appLocations)),
    url: text('url', t('URL'), {
      type: 'url',
      message: t('Invalid URL')
    }),
    urlRequired: textRequired('url', t('URL'), {
      type: 'url',
      message: t('Invalid URL')
    }),
    passwordRequired: {
      type: 'password',
      name: 'password',
      label: t('Password'),
      required: true
    } as FormItem,
    json: textarea('json', t('JSON'), {
      type: 'json',
      message: t('Invalid JSON')
    })
  };
};

export default useCommons;
