import hasRole from './hasRole';
import logout from './logout';

import useLocation from './useLocation';
import useIsAuthenticated from './useIsAuthenticated';

import Authorized from './Authorized';
import AccountSettings from './AccountSettings';
import UsernameChangeForm from './UsernameChangeForm';
import EmailChangeForm from './EmailChangeForm';
import PasswordChangeForm from './PasswordChangeForm';
import UserTable from './Table';

export * from './forms';

export {
  hasRole,
  logout,

  useLocation,
  useIsAuthenticated,

  Authorized,
  AccountSettings,
  UsernameChangeForm,
  EmailChangeForm,
  PasswordChangeForm,
  UserTable
};

export type Role =
  | 'SHARED'
  | 'USER'
  | 'ADMIN'
  | 'DEVELOPER'
  | 'COLLECTION-MANAGER'
  | 'MANAGE-SHARED-ACCOUNTS'
  | 'CHANGE-LOCATION'
  | 'PROCESS-SHIPMENTS'
  | 'HANDLE-HOLDS'
  | 'RECEPTION'
  | 'INVENTORY'
  | 'EDIT-LINKS'
  | 'QUERY-ITEMS'
  | 'VIEW-ITEMS'
  | 'EDIT-ITEMS'
  | 'QUERY-BIBS'
  | 'VIEW-BIBS'
  | 'EDIT-BIBS'
  | 'QUERY-ORDERS'
  | 'VIEW-ORDERS'
  | 'EDIT-ORDERS';
