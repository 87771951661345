import { ErrorMessage } from '.';

const ErrorCondition: React.FC<{
  error?: Error | null,
  style?: React.CSSProperties
}> = ({ error, style, children }) =>
  error
    ? <ErrorMessage error={error} style={style}>{children}</ErrorMessage>
    : null;

export default ErrorCondition;
