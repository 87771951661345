import { useT } from '../common/i18n';

import { APITable, useTableFormatters } from '../api';

import type { APITableColumn } from '../api';

// TODO
const useColumns = (): APITableColumn[] => {
  const t = useT();
  const { city, location3, dateTimeRelative, email, bool } = useTableFormatters();

  return [
    {
      field: 'id',
      label: t('ID')
    },
    {
      field: 'username',
      label: t('Username')
    },
    {
      field: 'city',
      label: t('City'),
      formatter: city
    },
    {
      field: 'location',
      label: t('Location'),
      formatter: location3
    },
    {
      field: 'lastLogin',
      label: t('Last Login'),
      formatter: dateTimeRelative
    },
    {
      field: 'email',
      label: t('Email'),
      formatter: email
    },
    {
      field: 'isShared',
      label: t('Is Shared'),
      formatter: bool
    },
    {
      field: 'mustChangePassword',
      label: t('Password Change Required'),
      formatter: bool
    }
  ];
};

const UserTable: React.FC<{
  enableQueryParams: boolean
}> = ({ enableQueryParams }) => (
  <APITable
    resource="users"
    keyField="id"
    columns={useColumns()}
    enableQueryParams={enableQueryParams}
  />
);

export default UserTable;
