import { useTranslation } from 'react-i18next';

import config from '../../config';
import { getRoleLabel } from '../../services/account';
import { hasRole } from '../../services/auth';

import { Form } from 'react-bootstrap';

import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';

const RoleSelector = ({ roles, setRoles }) => {
  const { t } = useTranslation();

  const options = config.accountRoles
    .filter(accountRole => accountRole !== 'DEVELOPER' || hasRole('DEVELOPER'))
    .map(accountRole => ({
      key: accountRole,
      label: getRoleLabel(accountRole, t)
    }));

  const onChange = selected => setRoles(selected);

  return (
    <>
      <Form.Label>
        {t('Roles')}
      </Form.Label>

      <DropdownMultiselect
        name="roles"
        options={options}
        selected={roles}
        placeholder={t('No roles selected')}
        selectDeselectLabel={t('Select all')}
        handleOnChange={onChange}
      />
    </>
  );
};

export default RoleSelector;
