import { parseMARC } from '../services/marc';
import useAPI from './useAPI';

// Returns array [marc, loading, error]
const useMARC = bibId => {
  const [marc, loading, error] = useAPI('GET', `/bibs/${bibId}/marc`);

  if (loading || error) return [null, loading, error];
  else return [parseMARC(marc), false, null];
};

export default useMARC;
