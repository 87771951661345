import { useTranslation } from 'react-i18next';

import useSignum from '../../hooks/useSignum';
import config from '../../config';

const getBackgroundColor = classification => {
  if (classification[0] === '1') return '#fefefe';
  else if (classification[0] === '2') return '#fbdc10';
  else if (classification[0] === '4') return '#e6a2a9';
  else if (classification[0] === '8') return '#d30000e';
};

const LetterPictureBookLabel = ({ item }) => {
  const { t } = useTranslation();

  const [signum, /* image */, loading, error] = useSignum(item.sierraItemRecordId);

  if (error) {
    return t('Could not fetch picture book label');
  } else if (!signum || loading) {
    return null;
  }

  const letter = signum.replace(/[0-9. ]/g, '')[0];

  if (letter === null) return null;

  const tableStyle = {
    border: '1px solid',
    backgroundColor: getBackgroundColor(item.classification),
    display: 'table',
    top: 0,
    left: 0,
    width: '100px',
    height: '100px'
  };

  const cellStyle = {
    display: 'table-cell',
    verticalAlign: 'middle'
  };

  const containerStyle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  };

  const letterStyle = {
    color: 'black',
    fontSize: '90px',
    lineHeight: '80px'
  };

  const classificationStyle = {
    color: 'black'
  };

  return (
    <div style={tableStyle}>
      <div style={cellStyle}>
        <div style={containerStyle}>
          <div style={letterStyle}>
            {letter}
          </div>

          <div style={classificationStyle}>
            {item.classification}
          </div>
        </div>
      </div>
    </div>
  );
};

const TopicPictureBookLabel = ({ item }) => {
  const style = {
    backgroundColor: getBackgroundColor(item.classification),
    maxWidth: 'max-content',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '2px',
    paddingBottom: '2px',
    fontSize: '18px',
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontFamily: 'Arial',
    color: 'black'
  };

  return (
    <div style={style}>
      {item.genre[0]}{item.genre.slice(1).toLowerCase()}
    </div>
  );
};

const PictureBookLabel = ({ item }) => {
  if (config.topicGenres.indexOf(item.genre) !== -1) return <TopicPictureBookLabel item={item} />;
  else return <LetterPictureBookLabel item={item} />;
};

export default PictureBookLabel;
