import useAPI from './useAPI';

const useReaderInfo = readerName => {
  const [data, loading, error, /* headers */, update] = useAPI('GET', `/rfid/readers/name/${readerName ?? 'none'}`, {}, [readerName]);

  return (!readerName || readerName === 'none' || loading || error || !data)
    ? [null, () => {}]
    : [data, update];
};

export default useReaderInfo;
