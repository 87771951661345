import { Space, Icon, useColor, useMargins } from '../common/layout';
import { T } from '../common/i18n';

import { APIWrap, useGet } from '.';

import type { Status } from './models';

const useBadgeStyle = (errorRate: number): React.CSSProperties => {
  const low = useColor('success');
  const medium = useColor('warning');
  const high = useColor('danger');

  return errorRate < 5
    ? low
    : errorRate < 50
      ? medium
      : high;
};

const Badge: React.FC<{ errorRate: number }> = ({ errorRate }) =>
  <Icon icon="circle-fill" style={useBadgeStyle(errorRate)} />;

const BadgeWrapper: React.FC<{ errorRate?: number }> = ({ errorRate }) =>
  typeof errorRate === 'number'
    ? <Badge errorRate={errorRate} />
    : <Icon icon="circle" />;

const StatusElement: React.FC<{
  errorRate?: number,
  style?: React.CSSProperties
}> = ({ errorRate, style, children }) => (
  <span style={style}>
    <BadgeWrapper errorRate={errorRate} />

    <Space />

    {children}
  </span>
);

const APIStatus: React.FC = () => {
  const { data: status, loading, error } = useGet<Status>('/status');

  return (
    <APIWrap loading={loading} error={error}>
      <StatusElement errorRate={status?.lirpErrorRate} style={useMargins(0, 10)}>
        <T>Lirp Server</T>
      </StatusElement>

      <StatusElement errorRate={status?.sierraErrorRate}>
        <T>Sierra API</T>
      </StatusElement>
    </APIWrap>
  );
};

export default APIStatus;
