import { useColor } from '.';

const SuccessMessage: React.FC<{
  style?: React.CSSProperties
}> = ({ style, children }) => (
  <div style={{ ...useColor('success'), ...style }}>
    {typeof children === 'string'
      ? <strong>{children}</strong>
      : children}
  </div>
);

export default SuccessMessage;
