import { getMargin } from './useMargin';

import type { MarginValue } from './useMargin';

const usePadding = (
  leftPadding?: MarginValue,
  rightPadding?: MarginValue,
  topPadding?: MarginValue,
  bottomPadding?: MarginValue
): React.CSSProperties => ({
  paddingLeft: getMargin(leftPadding),
  paddingRight: getMargin(rightPadding),
  paddingTop: getMargin(topPadding),
  paddingBottom: getMargin(bottomPadding)
});

export default usePadding;
