import AlertBadge from '../../api/AlertBadge';

const AlertHoldCount = ({ holdCount }) => (
  <>
    {holdCount}

    &nbsp;

    <AlertBadge verticalAlign="middle" />
  </>
);

export default AlertHoldCount;
