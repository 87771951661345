import ReactMarkdown from 'react-markdown';

const Markdown: React.FC<{
  children: string
}> = ({ children }) => (
  <ReactMarkdown className="react-markdown">
    {children}
  </ReactMarkdown>
);

export default Markdown;
