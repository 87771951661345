import { useT } from '../../common/i18n';

import { useSelector } from '../../state';
import { selectUsername } from '../../state/slices/currentUser';
import { useCommons } from '../../layout/forms';

import type { FormItem } from '../../layout/forms';

const useUsernameChangeForm = (): FormItem[] => {
  const t = useT();
  const commons = useCommons();

  return [
    {
      type: 'text',
      name: 'username',
      label: t('New username'),
      required: true,
      defaultValue: useSelector(selectUsername),
      validation: [
        {
          type: 'has-changed',
          message: t('No change in username')
        },
        {
          type: 'username',
          message: t('Selected username is invalid')
        }
      ]
    },
    commons.passwordRequired
  ];
};

export default useUsernameChangeForm;
