import { useT } from '../../../common/i18n';

import type { APITableFormatterHook } from '..';

const useBooleanFormatter: APITableFormatterHook = () => {
  const t = useT();

  return (cell): any =>
    cell ? t('Yes') : t('No');
};

export default useBooleanFormatter;
