import { useTranslation } from 'react-i18next';

import expandRow from '../../../services/expandRow';

import BootstrapTable from '@ounai/react-bootstrap-table2';

import BibView from '../BibView';

import { CoverImage } from '../../../../bibs';

const BibListBibsTable = ({ bibs }) => {
  const { t } = useTranslation();

  const columns = [
    {
      dataField: 'cover',
      text: t('Cover'),
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <CoverImage isbn={row.marcISBN} materialType={row.fixedFieldMaterialType} />
      )
    },
    {
      dataField: 'sierraBibRecordId',
      text: t('Sierra ID')
    },
    {
      dataField: 'BibListBib.description',
      text: t('Description')
    },
    {
      dataField: 'BibListBib.groupString',
      text: t('Group String')
    },
    {
      dataField: 'marcTitle',
      text: t('Title')
    },
    {
      dataField: 'marcAuthor',
      text: t('Author')
    }
  ];

  return (
    <BootstrapTable
      keyField="sierraBibRecordId"
      columns={columns}
      data={bibs}
      classes="w-auto api-table mw-100"
      wrapperClasses="table-responsive"
      expandRow={expandRow(row => <BibView bibId={row.sierraBibRecordId} />)}
      bootstrap4
    />
  );
};

export default BibListBibsTable;
