import { useTranslation } from 'react-i18next';

import useAPI from '../../../hooks/useAPI';

import { Tabs, Tab } from 'react-bootstrap';

import MyBibLists from './MyBibLists';
import BibListTable from './BibListTable';
import TabWrapper from '../../layout/TabWrapper';

const BibLists = () => {
  const { t } = useTranslation();

  const [myLists, /* loading */, /* error */, /* headers */, updateMyLists] = useAPI('GET', '/bib-lists/own');
  const [sharedLists] = useAPI('GET', '/bib-lists/shared');
  const [publicLists] = useAPI('GET', '/bib-lists/public');

  return (
    <Tabs defaultActiveKey="myLists">
      <Tab eventKey="myLists" title={t('My lists') + (myLists ? ` (${myLists.length})` : '')}>
        <TabWrapper>
          {myLists && <MyBibLists
            myLists={myLists}
            update={updateMyLists}
          />}
        </TabWrapper>
      </Tab>

      <Tab eventKey="sharedLists" title={t('Shared with me') + (sharedLists ? ` (${sharedLists.length})` : '')}>
        <TabWrapper>
          {sharedLists && <BibListTable bibLists={sharedLists} />}
        </TabWrapper>
      </Tab>

      <Tab eventKey="publicLists" title={t('Public lists') + (publicLists ? ` (${publicLists.length})` : '')}>
        <TabWrapper>
          {publicLists && <BibListTable bibLists={publicLists} />}
        </TabWrapper>
      </Tab>
    </Tabs>
  );
};

export default BibLists;
