import { useTranslation } from 'react-i18next';

import AlertBadge from '../api/AlertBadge';

const BibMARC091 = ({ bib }) => {
  const { t } = useTranslation();

  if (!bib.marc091) {
    return (
      <span style={{ color: 'red' }}>
        {t('none')}

        &nbsp;

        <AlertBadge />
      </span>
    );
  }

  return bib.marc091;
};

export default BibMARC091;
