import { Tabs, Tab } from '../common/layout';
import { useTranslation } from '../common/i18n';

import { PagedTitleTable } from '../paged-titles';
import { ItemAllocationTable, ItemAllocationDistributionTable } from '../item-allocations';

const ItemAllocationsPage: React.FC = () => (
  <Tabs defaultActiveKey="active-paged-titles">
    <Tab eventKey="active-paged-titles" title={useTranslation('Active Paged Titles')}>
      <PagedTitleTable enableQueryParams={false} />
    </Tab>

    <Tab eventKey="active-allocations" title={useTranslation('Active Allocations')}>
      <ItemAllocationTable enableQueryParams={false} />
    </Tab>

    <Tab eventKey="active-allocation-distribution" title={useTranslation('Active Allocation Distribution')}>
      <ItemAllocationDistributionTable enableQueryParams={false} />
    </Tab>
  </Tabs>
);

export default ItemAllocationsPage;
