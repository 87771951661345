import { Space, useString } from '../common/layout';
import { T } from '../common/i18n';

import { APIWrap, useGet } from '../api';

import type { Uptime as IUptime } from '../api/models';

const Uptime: React.FC = () => {
  const { data: uptime, loading, error } = useGet<IUptime>('/developer/uptime');

  const validate = (n?: number): number | null =>
    (typeof n === 'number' && !isNaN(n) && n > 0)
      ? n
      : null;

  return (
    <APIWrap loading={loading} error={error}>
      <T>Uptime</T>:

      <Space />

      <strong>
        {useString`${validate(uptime?.days)}d `}
        {useString`${validate(uptime?.hours)}h `}
        {useString`${validate(uptime?.minutes)}m `}

        {uptime?.seconds}s
      </strong>
    </APIWrap>
  );
};

export default Uptime;
