import { ListItem, UnorderedList, Space, HiddenTooltip } from '../common/layout';
import { ExternalLink } from '../common/navigation';
import { useT } from '../common/i18n';

import { Authorized } from '../users';
import { ButtonsDropdown } from '../layout/buttons';
import { DescriptionInfoTooltip, useEditLinkDialog, useDeleteLinkDialog } from '.';

import type { Link } from '../api/models';
import type { DropdownButtonItem } from '../layout/buttons/DropdownButton';

interface Props {
  link: Link;
}

const useDropdownButtons = (link: Link): DropdownButtonItem[] => {
  const t = useT();

  if (!link.id) {
    throw new Error(`Invalid link id ${link.id}`);
  }

  return [
    {
      type: 'edit',
      text: t('Edit Link'),
      dialog: useEditLinkDialog(link)
    },
    {
      type: 'delete',
      text: t('Delete Link'),
      dialog: useDeleteLinkDialog(link)
    }
  ];
};

const LinkListItem: React.FC<Props> = ({ link }) => (
  <ListItem>
    <Authorized role="EDIT-LINKS">
      <ButtonsDropdown
        id={`link-dropdown-${link.id}`}
        buttons={useDropdownButtons(link)}
      />
    </Authorized>

    {!link.visible && (
      <>
        <HiddenTooltip id={`hidden-link-${link.id}`} />
        <Space />
      </>
    )}

    <ExternalLink to={link.url}>
      {link.name}
    </ExternalLink>

    <DescriptionInfoTooltip
      id={`link-info-tooltip-${link.id}`}
      description={link.description}
      keywords={link.keywords}
      orderPriority={link.orderPriority}
    />
  </ListItem>
);

const LinkList: React.FC<{ links: Link[] }> = ({ links }) => (
  <UnorderedList>
    {links.map((link, i) => (
      <LinkListItem key={i} link={link} />
    ))}
  </UnorderedList>
);

export default LinkList;
