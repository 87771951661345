import { FormContent } from '../../layout/forms';
import { useMassUpdateItemForm } from '.';

import type { FormDataValue } from '../../layout/forms';
import type { Operation } from '.';

const MassUpdateItemForm: React.FC<{
  setOperation: React.Dispatch<Operation>
}> = ({ setOperation }) => (
  <FormContent
    form={useMassUpdateItemForm()}

    onChange={(name: string, value: FormDataValue): void => {
      // eslint-disable-next-line no-console
      console.log('TODO MassUpdateItemForm onChange', name, value);
    }}
  />
);

export default MassUpdateItemForm;
