import { useString } from '../../common/layout';
import { useTranslation } from '../../common/i18n';

import { useLinkGroupForm } from '..';

import type { Dialog } from '../../layout/dialogs';

const useCreateLinkGroupDialog = (parentGroupId?: number): Dialog => ({
  type: 'api-form-post',
  id: `create-link-group-${parentGroupId ?? 'root'}`,
  title: useTranslation('Create New Group'),
  path: '/links/groups' + (useString`/${parentGroupId}` ?? ''),
  form: useLinkGroupForm()
});

export default useCreateLinkGroupDialog;
