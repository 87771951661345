import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import sierraGlobals from '../../sierraGlobals';
import { getUserLocation } from '../../services/user';

import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import Loading from '../api/Loading';

const MultiLibrarySelector = ({
  defaultValue,
  onChange,
  defaultAllSelected,
  includeUserLocation,
  includeAll
}) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(
    defaultValue
      ? defaultValue.filter(l => includeUserLocation || l !== getUserLocation())
      : []
  );

  const [locationInfos] = useAPI('GET', '/locations');

  const options = !locationInfos
    ? null
    : locationInfos.filter(locationInfo => (
      (locationInfo.code.length === 3) &&
      (includeUserLocation || locationInfo.code !== getUserLocation()) &&
      (includeAll || sierraGlobals.floatingLocations.includes(locationInfo.code))
    )).map(locationInfo => ({
      key: locationInfo.code,
      label: [locationInfo.code, locationInfo.name].join(' ')
    }));

  if (options === null) {
    return <Loading />;
  }

  return (
    <DropdownMultiselect
      name="libraries"
      buttonClass="btn-light btn-sm"
      selected={selected}
      options={options}
      placeholder={defaultAllSelected ? `${t('All')} (${t('default')})` : t('none')}
      selectDeselectLabel={t('Select all')}
      placeholderMultipleChecked={selected.length === options.length ? t('All') : selected.join(', ')}

      handleOnChange={selected => {
        setSelected(selected);

        if (typeof onChange === 'function') {
          onChange((defaultAllSelected && selected.length === options.length) ? [] : selected);
        }
      }}
    />
  );
};

export default MultiLibrarySelector;
