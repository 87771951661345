import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import DynamicView from '../api/DynamicView';
import BibDetails from '../bibs/BibDetails';
import OrderDetails from './OrderDetails';
import Loading from '../api/Loading';

const OrderView = ({ orderId }) => {
  const { t } = useTranslation();

  const [order, loading, error] = useAPI('GET', `/orders/${orderId}`, {}, [orderId]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch order');
  }

  if (order === null) {
    return `${t('No order found for Sierra ID')} ${orderId}`;
  }

  return (
    <DynamicView defaultTab="orderDetails">
      <OrderDetails
        title={t('Order Details')}
        key="orderDetails"
        order={order}
      />

      <BibDetails
        title={t('Bib Details')}
        key="bibDetails"
        bib={order.Bib}
      />
    </DynamicView>
  );
};

export default OrderView;
