import { useTranslation } from 'react-i18next';

import useQueryParams from '../../hooks/useQueryParams';

import { getUserLocation, getUserLocationName } from '../../services/user';

import { AuthorizedRoute } from '../auth/Authorized';
import PageTitle from '../api/PageTitle';

import { Routes as NewRoutes } from '../../../application';

import {
  BibViewPage as NewBibViewPage
} from '../../../pages';

import BibViewPage from '../pages/BibViewPage';

import ItemShelfViewPage from '../pages/ItemShelfViewPage';
import DeveloperLogsPage from '../pages/DeveloperLogsPage';
import ExpiredHoldShelfItemsPage from '../pages/ExpiredHoldShelfItemsPage';
import ItemSearchPage from '../pages/ItemSearchPage';
import ItemViewPage from '../pages/ItemViewPage';
import OrderViewPage from '../pages/OrderViewPage';
import ReceptionPage from '../pages/ReceptionPage';
import InventoryPage from '../pages/InventoryPage';
import ShelfInventoryPage from '../pages/ShelfInventoryPage';
import PatronReservationsPage from '../pages/PatronReservationsPage';
import BibSearchPage from '../pages/BibSearchPage';

import RandomBibView from '../bibs/RandomBibView';
import RandomItemView from '../items/RandomItemView';
import RandomOrderView from '../orders/RandomOrderView';

import BibTable from '../bibs/BibTable';
import BibQueryBuilder from '../bibs/BibQueryBuilder';
import BibLists from '../bibs/lists/BibLists';

import UninventoriedSegments from '../items/UninventoriedSegments';
import ItemTable from '../items/ItemTable';
import ItemQueryBuilder from '../items/ItemQueryBuilder';
import ItemBarcodeDuplicatesTable from '../items/ItemBarcodeDuplicatesTable';
import MostTraveledItemsTable from '../items/MostTraveledItemsTable';
import CollectionTaskTable from '../items/CollectionTaskTable';

import OrderTable from '../orders/OrderTable';
import OrderQueryBuilder from '../orders/OrderQueryBuilder';
import ShipmentTable from '../orders/ShipmentTable';

import Search from '../api/Search';

import TransitVolumeMap from '../maps/TransitVolumeMap';

import ItemScanCharts from '../charts/ItemScanCharts';
import HoldShelfChart from '../charts/HoldShelfChart';

import AccountsManager from '../admin/AccountsManager';
import SharedAccountsManager from '../admin/SharedAccountsManager';
import RFIDReadersManager from '../admin/RFIDReadersManager';

import MissingHoldsTable from '../holds/MissingHoldsTable';

import Reservations from '../holdshelf/Reservations';

const Routes = ({ containerHeight }) => {
  const { t } = useTranslation();

  const queryParams = useQueryParams();

  const location = getUserLocation();
  const locationName = getUserLocationName();

  return (
    <>
      <AuthorizedRoute role="ITEM-SEARCH" path="/item-searches">
        <PageTitle>{t('Item Searches')} ({locationName || location})</PageTitle>

        <ItemSearchPage
          location={location}
          locationName={locationName}
        />
      </AuthorizedRoute>
      <AuthorizedRoute role="HANDLE-HOLDS" path="/holdshelf/clear">
        <PageTitle>{t('Clear Hold Shelf')} ({locationName || location})</PageTitle>

        <ExpiredHoldShelfItemsPage
          location={location}
          locationName={locationName}
        />
      </AuthorizedRoute>
      <AuthorizedRoute role="HANDLE-HOLDS" path="/holdshelf/patron/:patronBarcode">
        <PageTitle>{t('Patron\'s Reservations')} ({locationName || location})</PageTitle>

        <PatronReservationsPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="HANDLE-HOLDS" path="/holdshelf">
        <PageTitle>{t('Mikro-Väylä Reservations')} ({locationName || location})</PageTitle>

        <Reservations enablePagination enableFilters />
      </AuthorizedRoute>

      <AuthorizedRoute role="HANDLE-HOLDS" path="/holds/missing">
        <PageTitle>{t('Missing Holds')} ({locationName || location})</PageTitle>

        <MissingHoldsTable
          location={location}
          locationName={locationName}
        />
      </AuthorizedRoute>

      <AuthorizedRoute role="USER" path="/search">
        <PageTitle>{t('Search Results')}: {queryParams.get('query')}</PageTitle>

        <Search searchTerm={queryParams.get('query')} />
      </AuthorizedRoute>

      <AuthorizedRoute role="DEVELOPER" path="/bibs/random">
        <RandomBibView />
      </AuthorizedRoute>
      <AuthorizedRoute role="DEVELOPER" path="/bibs/lists">
        <PageTitle>{t('Bib Lists')}</PageTitle>

        <BibLists />
      </AuthorizedRoute>
      <AuthorizedRoute role="DEVELOPER" path="/bibs/view/new/:id">
        {/* TODO */}
        <NewBibViewPage />
        <hr />
        <BibViewPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="VIEW-BIBS" path="/bibs/view/:id">
        <PageTitle hidden>{t('Bib Details')}</PageTitle>

        <BibViewPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="QUERY-BIBS" path="/bibs/query">
        <PageTitle>{t('Bib Query')}</PageTitle>

        <BibQueryBuilder query={queryParams.get('query')} />
      </AuthorizedRoute>
      <AuthorizedRoute role="VIEW-BIBS" path="/bibs/search">
        <PageTitle hidden>{t('Search')}: {queryParams.get('query')}</PageTitle>

        <BibSearchPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="QUERY-BIBS" path="/bibs">
        <PageTitle>{t('Bibs')}</PageTitle>

        <BibTable query={queryParams.get('query')} enableQueryParams />
      </AuthorizedRoute>

      <AuthorizedRoute role="DEVELOPER" path="/items/random">
        <RandomItemView />
      </AuthorizedRoute>
      <AuthorizedRoute role="INVENTORY" path="/items/inventory/segments">
        <PageTitle>{t('Segment Browser')}</PageTitle>

        <UninventoriedSegments />
      </AuthorizedRoute>
      <AuthorizedRoute role="INVENTORY" path="/items/inventory">
        <PageTitle>{t('Inventory')}</PageTitle>

        <InventoryPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="DEVELOPER" path="/items/shelf-inventory">
        <PageTitle hidden>{t('Shelf Inventory')}</PageTitle>

        <ShelfInventoryPage containerHeight={containerHeight} />
      </AuthorizedRoute>
      <AuthorizedRoute role="DEVELOPER" path="/items/duplicate-barcodes">
        <PageTitle>{t('Duplicate Barcodes')}</PageTitle>

        <ItemBarcodeDuplicatesTable />
      </AuthorizedRoute>
      <AuthorizedRoute role="DEVELOPER" path="/items/most-traveled">
        <PageTitle>{t('Most Traveled Items')}</PageTitle>

        <MostTraveledItemsTable enableQueryParams />
      </AuthorizedRoute>
      <AuthorizedRoute role="VIEW-ITEMS" path="/items/shelf/id/:id">
        <PageTitle>{t('Shelf View')}</PageTitle>

        <ItemShelfViewPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="VIEW-ITEMS" path="/items/shelf/:barcode">
        <PageTitle>{t('Shelf View')}</PageTitle>

        <ItemShelfViewPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="VIEW-ITEMS" path="/items/view/id/:id">
        <PageTitle hidden>{t('Item Details')}</PageTitle>

        <ItemViewPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="VIEW-ITEMS" path="/items/view/:barcode">
        <PageTitle hidden>{t('Item Details')}</PageTitle>

        <ItemViewPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="QUERY-ITEMS" path="/items/query">
        <PageTitle>{t('Item Query')}</PageTitle>

        <ItemQueryBuilder query={queryParams.get('query')} />
      </AuthorizedRoute>
      <AuthorizedRoute role="VIEW-ITEMS" path="/items">
        <PageTitle>{t('Items')}</PageTitle>

        <ItemTable query={queryParams.get('query')} enableQueryParams />
      </AuthorizedRoute>
      <AuthorizedRoute role="CREATE-COLLECTION-TASKS" path="/items/collection-tasks">
        <PageTitle>{t('Collection Tasks')}</PageTitle>

        <CollectionTaskTable enableQueryParams />
      </AuthorizedRoute>

      <AuthorizedRoute role="DEVELOPER" path="/orders/random">
        <RandomOrderView />
      </AuthorizedRoute>
      <AuthorizedRoute role="RECEPTION" path="/orders/reception">
        <PageTitle>{t('Reception')}</PageTitle>

        <ReceptionPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="PROCESS-SHIPMENTS" path="/orders/shipments">
        <PageTitle>{t('Shipments')}</PageTitle>

        <ShipmentTable query={queryParams.get('query')} enableQueryParams />
      </AuthorizedRoute>
      <AuthorizedRoute role="VIEW-ORDERS" path="/orders/view/:id">
        <OrderViewPage />
      </AuthorizedRoute>
      <AuthorizedRoute role="QUERY-ORDERS" path="/orders/query">
        <PageTitle>{t('Order Query')}</PageTitle>

        <OrderQueryBuilder query={queryParams.get('query')} />
      </AuthorizedRoute>
      <AuthorizedRoute role="VIEW-ORDERS" path="/orders">
        <PageTitle>{t('Orders')}</PageTitle>

        <OrderTable query={queryParams.get('query')} enableQueryParams />
      </AuthorizedRoute>

      <AuthorizedRoute role="ADMIN" path="/admin/accounts">
        <PageTitle>{t('Manage Accounts')}</PageTitle>

        <AccountsManager />
      </AuthorizedRoute>
      <AuthorizedRoute role="MANAGE-SHARED-ACCOUNTS" path="/admin/shared-accounts">
        <PageTitle>{t('Manage Shared Accounts')}</PageTitle>

        <SharedAccountsManager />
      </AuthorizedRoute>
      <AuthorizedRoute role="ADMIN" path="/admin/rfid-readers">
        <PageTitle>{t('Manage RFID Readers')}</PageTitle>

        <RFIDReadersManager />
      </AuthorizedRoute>

      <AuthorizedRoute role="DEVELOPER" path="/developer/logs">
        <PageTitle>{t('Logs')}</PageTitle>

        <DeveloperLogsPage />
      </AuthorizedRoute>

      <AuthorizedRoute role="DEVELOPER" path="/maps/transit">
        <PageTitle hidden>{t('Transit Volume Map')}</PageTitle>

        <TransitVolumeMap height={containerHeight} />
      </AuthorizedRoute>

      <AuthorizedRoute role="DEVELOPER" path="/charts/item-scans">
        <PageTitle hidden>{t('Item Scan Charts')}</PageTitle>

        <ItemScanCharts pageHeight={containerHeight} />
      </AuthorizedRoute>
      <AuthorizedRoute role="DEVELOPER" path="/charts/holdshelf">
        <PageTitle hidden>{t('Hold Shelf Chart')}</PageTitle>

        <HoldShelfChart pageHeight={containerHeight} />
      </AuthorizedRoute>

      <NewRoutes />
    </>
  );
};

export default Routes;
