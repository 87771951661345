import { useState } from 'react';

import { Modal } from 'react-bootstrap';

const OnClickModalHeader = ({ title, closeButton }) => {
  if (!title && !closeButton) {
    return null;
  }

  return (
    <Modal.Header closeButton={closeButton}>
      <Modal.Title>
        {title}
      </Modal.Title>
    </Modal.Header>
  );
};

const OnClickModal = ({ size, title, button, closeButton, forceClose, onOpen, onClose, footer, children }) => {
  const [showModal, setShowModal] = useState(false);

  const onClick = () => {
    setShowModal(true);

    if (onOpen) onOpen();
  };

  const modalOnHide = () => {
    setShowModal(false);

    if (onClose) onClose();
  };

  return (
    <>
      {typeof button === 'function'
        ? button(onClick)
        : <span onClick={onClick}>{button}</span>}

      <Modal
        show={showModal && !forceClose}
        onHide={modalOnHide}
        size={size}
      >
        <OnClickModalHeader
          title={title}
          closeButton={closeButton}
        />

        <Modal.Body>
          {children}
        </Modal.Body>

        {footer && (
          <Modal.Footer>
            {footer}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default OnClickModal;
