import { T } from '../common/i18n';
import { Button } from '../common/layout/buttons';
import { Icon, useMargins } from '../common/layout';

import { useDialog } from '../layout/dialogs';
import { Authorized, useLocation } from '../users';
import { useGet, APIWrap } from '../api';
import { GroupList, useCreateLinkGroupDialog } from '.';

import type { LinkGroup } from '../api/models';

const NoGroupsMessage: React.FC = () => (
  <div>
    <T>No groups have been added yet.</T>
  </div>
);

const CreateGroup: React.FC<{
  first: boolean,
  style?: React.CSSProperties
}> = ({ first, style }) => (
  <Button onClick={useDialog(useCreateLinkGroupDialog())} style={style}>
    <Icon
      size={16}
      icon="folder-plus"
      rightMargin={6}
    />

    <strong>
      {first
        ? <T>Create First Group</T>
        : <T>Create New Group</T>}
    </strong>
  </Button>
);

const LinksView: React.FC = () => {
  const { data, loading, error } = useGet<LinkGroup[]>(`/links/${useLocation()}`);

  const isData = (data !== null && data.length > 0);

  return (
    <APIWrap loading={loading} error={error}>
      {isData
        ? <GroupList groups={data} />
        : <NoGroupsMessage />}

      <Authorized role="EDIT-LINKS">
        <CreateGroup
          first={!isData}
          style={useMargins(0, 0, (isData ? 10 : 0), 0)}
        />
      </Authorized>
    </APIWrap>
  );
};

export default LinksView;
