import { useTranslation } from '../common/i18n';

import { APIForm } from '../api';
import { useEmailChangeForm, logout } from '.';

const EmailChangeForm: React.FC = () => (
  <APIForm
    method="POST"
    path="/users/me/email-change"
    form={useEmailChangeForm()}
    submitText={useTranslation('Change email address')}
    successMessage={useTranslation('Email address changed successfully! You will now be logged out.')}
    onSuccess={(): void => { setTimeout(logout, 3000); }}
  />
);

export default EmailChangeForm;
