import { useEffect } from 'react';

import { useSelector } from '../state/store';
import { selectEnabled as selectDarkModeEnabled } from '../state/slices/darkMode';

const useDarkMode = (): void => {
  const darkModeEnabled = useSelector(selectDarkModeEnabled);

  useEffect(() => {
    if (darkModeEnabled) {
      document.body.classList.remove('bootstrap');
      document.body.classList.add('bootstrap-dark');

      localStorage.setItem('darkThemeEnabled', 'true');
    } else {
      document.body.classList.add('bootstrap');
      document.body.classList.remove('bootstrap-dark');

      localStorage.setItem('darkThemeEnabled', 'false');
    }
  }, [darkModeEnabled]);
};

export default useDarkMode;
