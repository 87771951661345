import { useState, useEffect } from 'react';
import axios from 'axios';

import config from '../config';

const fetchData = async (
  method,
  path,
  { params, body },
  { setData, setLoading, setError, setHeaders, setCancelToken }
) => {
  setLoading(true);

  try {
    const cancelToken = axios.CancelToken.source();

    setCancelToken(cancelToken);

    const { data, headers } = await axios({
      method,
      url: config.api.url + path,
      params,
      data: body,
      cancelToken: cancelToken.token
    });

    setData(data);
    setHeaders(headers);
  } catch (err) {
    if (axios.isCancel(err)) return;

    // eslint-disable-next-line no-console
    console.log('Error fetching API data:', err);

    setError(err);
  }

  setLoading(false);
};

// Returns array [data, loading, error, headers, update]
const useAPI = (method, path, { params, body } = {}, dependencies) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [cancelToken, setCancelToken] = useState(null);

  const update = () => {
    // Cancel previous request
    if (cancelToken !== null) cancelToken.cancel();

    setError(null);

    fetchData(method, path, { params, body }, { setData, setLoading, setError, setHeaders, setCancelToken });
  };

  useEffect(() => {
    update();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies || []);

  return [data, loading, error, headers, update];
};

export default useAPI;
