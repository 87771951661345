import { forwardRef } from 'react';
import BootstrapPopover from 'react-bootstrap/Popover';

import { TitleWithCloseButton } from '.';

import type { HeadingLevelString } from './Heading';

export interface Props {
  id: string;
  titleHeading?: HeadingLevelString;
  title?: string;
  onClickClose: (() => void) | null;
  children: React.ReactNode;
}

const Popover: React.FC<Props> = forwardRef<HTMLDivElement, Props>(({
  id,
  titleHeading,
  title,
  children,
  onClickClose,
  ...props
}, ref) => (
  <BootstrapPopover id={id} ref={ref} {...props}>
    {title && (
      <BootstrapPopover.Title as={titleHeading ?? 'h3'}>
        {typeof onClickClose === 'function'
          ? <TitleWithCloseButton title={title} onClickClose={onClickClose} />
          : title}
      </BootstrapPopover.Title>
    )}

    <BootstrapPopover.Content>
      {children}
    </BootstrapPopover.Content>
  </BootstrapPopover>
));

export default Popover;
