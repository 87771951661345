import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BarcodeScanner from './BarcodeScanner';
import OnClickModal from '../layout/OnClickModal';

const BarcodeScannerModal = ({ scanButton, onScan }) => {
  const { t } = useTranslation();

  const [barcodeScannerEnabled, setBarcodeScannerEnabled] = useState(false);

  return (
    <OnClickModal
      title={t('Looking for barcode...')}
      button={scanButton}
      onOpen={() => setBarcodeScannerEnabled(true)}
      onClose={() => setBarcodeScannerEnabled(false)}
    >
      {barcodeScannerEnabled && <BarcodeScanner onScan={onScan} />}
    </OnClickModal>
  );
};

export default BarcodeScannerModal;
