import { Row, Col, usePadding } from '..';
import { Button } from '.';

import type { IButton } from '.';

const ButtonCol: React.FC = ({ children, ...props }) => (
  <Col xs="auto" style={usePadding('right', 0)}>
    <Button {...props}>
      {children}
    </Button>
  </Col>
);

const Buttons: React.FC<{
  buttons: IButton[],
  style?: React.CSSProperties
}> = ({ buttons, style }) => (
  <Row style={style}>
    {buttons.map(({ id, content, ...buttonProps }) => (
      <ButtonCol key={id} {...buttonProps}>
        {content}
      </ButtonCol>
    ))}
  </Row>
);

export default Buttons;
