import { textFilter } from '@ounai/react-bootstrap-table2-filter';

import { formatItemType } from './item';
import { formatLocationInfo } from './location';
import { hasRole } from './auth';
import sierraGlobals from '../sierraGlobals';
import { formatLanguage } from './bib';

import ClickSelect from '../components/api/ClickSelect';
import BibTitleAndAuthor from '../components/bibs/BibTitleAndAuthor';
import SierraID from '../components/api/SierraID';
import StandardNumbersDisplay from '../components/bibs/StandardNumbersDisplay';
import SeriesInfo from '../components/bibs/SeriesInfo';
import ItemStatus from '../components/items/ItemStatus';
import TooltippedLocation from '../components/items/TooltippedLocation';

import { CoverImage } from '../../bibs';

import {
  OnShelfHereCount,
  ReservableCount,
  CirculatingCount
} from '../components/items/ItemCount';

const itemStatusFormatter = (cell, row) => <ItemStatus item={row} />;

const emptyFormatter = cell => cell ?? '-';

const bibFormatters = {
  cover: (cell, row, rowIndex, formatExtraData) => (
    <CoverImage
      isbn={row.marcISBN}
      materialType={row.fixedFieldMaterialType}
    />
  ),

  sierraBibRecordId: cell => (
    <SierraID code="b" id={cell} />
  ),

  work: (cell, row) => (
    <>
      <div>
        <BibTitleAndAuthor bib={row} />
      </div>

      {row.marcSeriesInfo && (
        <div style={{ marginTop: '10px' }}>
          <SeriesInfo bib={row} />
        </div>
      )}
    </>
  ),

  standardNumbers: (cell, row) => (
    (!row.marcISBN && !row.marcISSN && !row.marcEAN13)
      ? '-'
      : <StandardNumbersDisplay bib={row} />
  ),

  fixedFields: (cell, row) => (
    <>
      <div style={{ marginBottom: '4px' }}>
        <strong>
          {sierraGlobals.materialTypes[row.fixedFieldMaterialType]}
        </strong>
      </div>

      <div style={{ marginBottom: '4px' }}>
        {formatLanguage(row, true)}
      </div>

      <div>
        {row.fixedFieldCatalogueDate}
      </div>
    </>
  ),

  copies: (cell, row) => (
    <>
      <div style={{ marginBottom: '4px' }}>
        <OnShelfHereCount itemsOnShelfHere={row.itemsOnShelfHere} />
      </div>

      <div style={{ marginBottom: '4px' }}>
        <ReservableCount itemsReservable={row.itemsReservable} />
      </div>

      <div style={{ marginBottom: '4px' }}>
        <CirculatingCount itemsInCirculation={row.itemsInCirculation} />
      </div>
    </>
  )
};

const bibItemFormatters = {
  fixedFieldLocation: (cell, row) => (
    <TooltippedLocation item={row} />
  ),

  fixedFieldItemStatus: itemStatusFormatter
};

const itemFormatters = {
  sierraItemRecordId: cell => (
    <SierraID code="i" id={cell} />
  ),

  barcode: cell => (
    <ClickSelect pre>{cell}</ClickSelect>
  ),

  fixedFieldItemStatus: itemStatusFormatter,

  fixedFieldItemType: (cell, row) => (
    formatItemType(row)
  ),

  fixedFieldLocation: (cell, row) => (
    formatLocationInfo(row.location)
  )
};

const column = (dataField, text, opts) => ({
  dataField,
  text,
  ...opts
});

export const getBibColumns = (t, simple = false) => [
  column('cover', t('Cover'), {
    isDummyField: true,
    formatter: bibFormatters.cover,
    headerStyle: () => ({ width: '104px' })
  }),

  column('sierraBibRecordId', t('Sierra ID'), {
    hidden: !hasRole('DEVELOPER'), // TODO preference
    sort: !simple,
    formatter: bibFormatters.sierraBibRecordId
  }),

  column('work', t('Work'), {
    isDummyField: true,
    formatter: bibFormatters.work
  }),

  column('marcPublishedYear', t('Year'), {
    sort: !simple,
    formatter: emptyFormatter
  }),

  column('standardNumbers', t('Standard No.'), {
    isDummyField: true,
    formatter: bibFormatters.standardNumbers
  }),

  column('fixedFields', t('Bib Fields'), {
    isDummyField: true,
    formatter: bibFormatters.fixedFields
  }),

  column('copies', t('Copies'), {
    isDummyField: true,
    formatter: bibFormatters.copies
  }),

  column('holdCount', t('Hold Count'), {
    sort: !simple,
    formatter: emptyFormatter
  }),

  column('_searchPriority', 'Prio', {
    hidden: !simple || !hasRole('DEVELOPER'),
    formatter: (cell, row) => `${cell}${row._exactMatch ? '*' : ''}`
  })
];

export const getBibItemColumns = (t, selectedItemId = null, noHighlight = false) => {
  const filter = placeholder => textFilter({
    delay: 0,
    placeholder,
    style: { fontSize: '14px' },
    activeStyle: {
      backgroundColor: 'gold'
    }
  });

  const style = (cell, row, rowIndex, colIndex) => {
    if (!noHighlight && row.sierraItemRecordId === selectedItemId) {
      return { backgroundColor: '#61a9ee' }; // light blue
    }
  };

  return [
    column('sierraItemRecordId', t('Sierra ID'), {
      hidden: true,
      formatter: emptyFormatter,
      style
    }),

    column('fixedFieldCity', t('K'), {
      hidden: !hasRole('CHANGE-LOCATION'),
      sort: true,
      style,
      filter: filter(t('City'))
    }),

    column('barcode', t('Barcode'), {
      sort: true,
      style,
      filter: filter(t('Barcode'))
    }),

    column('callNumber', t('Call Number'), {
      sort: true,
      style,
      filter: filter(t('Call Number'))
    }),

    column('fixedFieldLocation', t('Location'), {
      sort: true,
      style,
      filter: filter(t('Location')),
      formatter: bibItemFormatters.fixedFieldLocation
    }),

    column('fixedFieldItemStatus', t('Status'), {
      sort: true,
      style,
      filter: filter(t('Status')),
      formatter: bibItemFormatters.fixedFieldItemStatus
    })
  ];
};

export const getItemColumns = (t, additionalColumns = []) => {
  return [
    column('sierraItemRecordId', t('Sierra ID'), {
      hidden: !hasRole('DEVELOPER'), // TODO preference
      sort: true,
      formatter: itemFormatters.sierraItemRecordId
    }),

    column('barcode', t('Barcode'), {
      sort: true,
      formatter: itemFormatters.barcode
    }),

    column('Bib.marcTitle', t('Title'), {
      sort: true,
      formatter: emptyFormatter
    }),

    column('Bib.marcAuthor', t('Author'), {
      sort: true,
      formatter: emptyFormatter
    }),

    column('callNumber', t('Call Number'), {
      sort: true,
      formatter: emptyFormatter
    }),

    column('fixedFieldItemStatus', t('Status'), {
      sort: true,
      formatter: itemFormatters.fixedFieldItemStatus
    }),

    column('fixedFieldItemType', t('Item Type'), {
      sort: true,
      formatter: itemFormatters.fixedFieldItemType
    }),

    column('fixedFieldLocation', t('Location'), {
      sort: true,
      formatter: itemFormatters.fixedFieldLocation
    }),

    column('Bib.marcPublishedYear', t('Year'), {
      sort: true,
      formatter: itemFormatters.marcPublishedYear
    }),

    column('holdCount', t('Hold Count'), {
      sort: true,
      hidden: !hasRole('DEVELOPER'), // TODO preference
      formatter: emptyFormatter
    }),

    ...additionalColumns
  ];
};
