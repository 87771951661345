import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import finnishTranslation from '../resources/translations/fi';

const resources = {
  en: {
    translation: {}
  },
  fi: {
    translation: finnishTranslation
  }
};

const config = {
  resources,
  lng: 'fi',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
};

i18n.use(initReactI18next).init(config);

export default i18n;
