import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as contentRange from 'content-range';
import paginationFactory from '@ounai/react-bootstrap-table2-paginator';

import useAPI from '../../hooks/useAPI';
import useQueryParams from '../../hooks/useQueryParams';

import BootstrapTable from '@ounai/react-bootstrap-table2';

import Loading from './Loading';

const APITable = ({
  resource,
  keyField,
  columns,
  query,
  filters,
  simple,
  expandRow,
  enableQueryParams,
  defaultSortField,
  defaultSortOrder,
  defaultSizePerPage,
  hideSizePerPage,
  sizeIndicator,
  errorIndicator
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const queryParams = useQueryParams();

  let queryPage, querySizePerPage, querySortField, querySortOrder;

  if (enableQueryParams) {
    queryPage = Number(queryParams.get('page'));
    querySizePerPage = Number(queryParams.get('pageSize'));

    querySortField = queryParams.get('sortField');
    querySortOrder = queryParams.get('sortOrder');

    if (!query) query = queryParams.get('query');
  }

  const [page, setPage] = useState(queryPage || 1);
  const [sizePerPage, setSizePerPage] = useState(querySizePerPage || defaultSizePerPage || 25);
  const [sortField, setSortField] = useState(querySortField || defaultSortField || keyField);
  const [sortOrder, setSortOrder] = useState(querySortOrder || defaultSortOrder || 'asc');

  useEffect(() => {
    if (enableQueryParams) {
      const searchParams = new URLSearchParams(window.location.search);

      if (page) searchParams.set('page', page);
      if (sizePerPage) searchParams.set('pageSize', sizePerPage);
      if (sortField) searchParams.set('sortField', sortField);
      if (sortOrder) searchParams.set('sortOrder', sortOrder);

      history.replace({ search: searchParams.toString() });
    }
  }, [page, sizePerPage, sortField, sortOrder, enableQueryParams, history]);

  const [data, loading, error, headers] = useAPI('GET', `/${resource}`, {
    params: {
      limit: sizePerPage,
      offset: (page - 1) * sizePerPage,
      sortField,
      sortOrder,
      query,
      filters,
      simple
    }
  }, [sizePerPage, page, sortField, sortOrder, query, filters, simple]);

  /*
  useEffect(() => {
    console.log(
      'APITable update triggered!',
      'sizePerPage', sizePerPage,
      'page', page,
      'sortField', sortField,
      'sortOrder', sortOrder,
      'query', query,
      'filters', filters,
      'simple', simple
    );
  }, [sizePerPage, page, sortField, sortOrder, query, filters, simple]);
  */

  let totalSize;

  if (!loading && !error) {
    if (simple) {
      if (data.length === sizePerPage) totalSize = (page + 1) * sizePerPage;
      else totalSize = page * sizePerPage;
    } else {
      const range = contentRange.parse(headers['content-range']);

      if (range) {
        totalSize = range.size;
      } else {
        // eslint-disable-next-line no-console
        console.log('Content-Range header missing!');
      }
    }
  }

  if (error) throw error;

  return (
    <>
      {(typeof errorIndicator === 'function' && !loading && !error && headers) && errorIndicator(headers)}

      {(typeof sizeIndicator === 'function' && !loading && !error && data) && (
        <strong>
          {sizeIndicator(data.length + sizePerPage * (page - 1), data.length >= sizePerPage, headers)}
        </strong>
      )}

      <BootstrapTable
        keyField={keyField}
        columns={columns}
        data={loading ? [] : data}
        expandRow={expandRow}
        rowStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}

        classes="w-auto api-table mw-100"
        wrapperClasses="table-responsive"

        striped
        remote
        bootstrap4

        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize,
          showTotal: (!loading && !simple),
          hideSizePerPage
        })}

        noDataIndication={() => {
          if (loading) return <Loading />;
          else if (error) return t('Could not fetch data');
          else return t('No data to show');
        }}

        onTableChange={(type, { page, sizePerPage, sortField, sortOrder }) => {
          setPage(page);
          setSizePerPage(sizePerPage);
          setSortField(sortField);
          if (sortOrder) setSortOrder(sortOrder);
        }}

        defaultSorted={[{
          dataField: sortField,
          order: sortOrder
        }]}
      />
    </>
  );
};

export default APITable;
