import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import CreateAccountButton from './CreateAccountButton';
import AccountsTable from './AccountsTable';

const AccountsManager = () => {
  const { t } = useTranslation();

  const [accounts, loading, error, /* headers */, update] = useAPI('GET', '/accounts');

  return (
    <>
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <CreateAccountButton update={update} />
      </div>

      {
        error
          ? t('Could not fetch accounts')
          : <AccountsTable accounts={accounts} loading={loading} update={update} />
      }
    </>
  );
};

export default AccountsManager;
