import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import { incrementStatistic } from '../../services/inventory';

import Loading from '../api/Loading';
import QuickItemBrowser from './QuickItemBrowser';

const UninventoriedItemsViewer = ({ location, tresholdDate, filters, updateCount, setCurrentUninventoried, setForceClose, onClose }) => {
  const { t } = useTranslation();

  const [items, loading, error, /* headers */, update] = useAPI('GET', `/items/uninventoried/${location.code}`, {
    params: {
      tresholdDate,
      filters
    }
  }, [updateCount]);

  useEffect(() => {
    if (!loading && !error && items) {
      setCurrentUninventoried(items.length);

      if (items.length === 0) {
        setForceClose(true);
        onClose();
      }
    }
  }, [error, items, loading, setCurrentUninventoried, onClose, setForceClose]);

  const onEdit = (item, changes) => {
    if (changes.itemStatus) {
      if (item.fixedFieldItemStatus === 'm') {
        incrementStatistic('missingItemsFound');
      }

      incrementStatistic('statusChanges');
    }

    if (changes.location) {
      incrementStatistic('locationChanges');
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch items');
  }

  return (
    <QuickItemBrowser
      items={items}
      update={update}
      onEdit={onEdit}
      showMissingButton
    />
  );
};

export default UninventoriedItemsViewer;
