import axios from 'axios';

import { getURL } from '.';

import type { AxiosRequestConfig, AxiosResponse } from 'axios';

interface APIResponse<T> {
  data: T
}

const apiGet = async <T>(
  path: string,
  options?: AxiosRequestConfig
): Promise<T> =>
  (await axios.get<APIResponse<T>>(getURL(path), options))
    .data
    .data;

const apiPost = async <T>(
  path: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  body?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> =>
  await axios.post(getURL(path), body, options);

const apiDelete = async <T>(
  path: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> =>
  await axios.delete(getURL(path), options);

const apiPut = async <T>(
  path: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  body?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> =>
  await axios.put(getURL(path), options);

export {
  apiGet as get,
  apiPost as post,
  apiPut as put,
  apiDelete as delete
};
