import axios from 'axios';

import config from '../config';

const apiGet = async (path, options) => {
  const { data } = await axios.get(config.api.url + path, options);

  return data;
};

const apiGetRes = async (path, options) => {
  const res = await axios.get(config.api.url + path, options);

  return res;
};

const apiPost = async (path, body, options) => {
  const { data } = await axios.post(config.api.url + path, body, options);

  return data;
};

const apiDelete = async (path, options) => {
  const { data } = await axios.delete(config.api.url + path, options);

  return data;
};

const apiPut = async (path, body, options) => {
  const { data } = await axios.put(config.api.url + path, body, options);

  return data;
};

const api = {
  get: apiGet,
  getRes: apiGetRes,
  post: apiPost,
  delete: apiDelete,
  put: apiPut
};

export default api;
