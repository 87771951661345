import { useTranslation } from 'react-i18next';

import { Button, Tabs, Tab } from 'react-bootstrap';
import { FileEarmarkCode } from 'react-bootstrap-icons';

import OnClickModal from '../layout/OnClickModal';

const JSONContent = ({ data }) => (
  <pre style={{ marginBottom: 0 }}>
    {JSON.stringify(data, null, 2) ?? `${data}`}
  </pre>
);

const TabbedJSON = ({ arr }) => (
  <Tabs style={{ marginBottom: '10px' }}>
    {arr.map(({ name, data }) => (
      <Tab key={name} title={name} eventKey={name}>
        <JSONContent data={data} />
      </Tab>
    ))}
  </Tabs>
);

const JSONModal = ({ data, style }) => {
  const { t } = useTranslation();

  const button = (
    <Button variant="dark" size="sm" style={style}>
      <FileEarmarkCode
        size={16}
        style={{
          verticalAlign: 'middle',
          marginTop: '-4px'
        }}
      />

      &nbsp;

      {t('JSON')}
    </Button>
  );

  return (
    <OnClickModal
      size="lg"
      title={t('JSON')}
      button={button}
      closeButton
    >
      {(Array.isArray(data) && data[0]?.name && data[0]?.data)
        ? <TabbedJSON arr={data} />
        : <JSONContent data={data} />}
    </OnClickModal>
  );
};

export default JSONModal;
