import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../../config';
import { hasProblem, formatItemStatus, getSignumText } from '../../../services/item';

import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Authorized from '../../auth/Authorized';
import OnClickModal from '../../layout/OnClickModal';
import ItemEditor from '../ItemEditor';
import TopTooltip from '../../layout/TopTooltip';
import EditButton from '../../api/EditButton';
import OpenButton from '../../api/OpenButton';

const ItemShelfViewListElement = ({ item, update, highlight }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const style = {
    border: '1px solid',
    backgroundColor: (
      hasProblem(item)
        ? config.shelfViewColors.problem
        : highlight
          ? config.shelfViewColors.highlighted
          : config.shelfViewColors.normal
    )
  };

  return (
    <Row className="shelf-view-list-item" style={style} noGutters>
      <Col style={{ marginLeft: '4px' }}>
        <strong>
          {(item.fixedFieldItemStatus === '-' && !item.checkedOut) || formatItemStatus(item, t, true)}
          {' ' + getSignumText(item)}
        </strong>

        {` (${item.Bib.marcTitle}` + (item.Bib.marcAuthor ? ` / ${item.Bib.marcAuthor}` : '') + ')'}
      </Col>

      <Authorized role="EDIT-ITEMS">
        <Col xs="auto" style={{ marginTop: '2px', marginBottom: '2px' }}>
          <OnClickModal
            title={t('Edit Item')}
            button={<EditButton />}
            forceClose={forceClose}
            onOpen={() => setForceClose(false)}
            closeButton
          >
            <ItemEditor
              item={item}
              barcode
              itemStatus
              callNumber
              location
              onSave={() => {
                setForceClose(true);
                update();
              }}
            />
          </OnClickModal>
        </Col>
      </Authorized>

      <Col xs="auto" style={{ marginRight: '4px', marginTop: '2px', marginBottom: '2px' }}>
        <TopTooltip content={t('Open item in new tab')}>
          <OpenButton
            as={Link}
            to={`/items/view/id/${item.sierraItemRecordId}`}
            target="_blank"
            rel="noopener noreferrer"
          />
        </TopTooltip>
      </Col>
    </Row>
  );
};

export default ItemShelfViewListElement;
