import { useT } from '../common/i18n';

export const espooLocations = [
  'e01', 'e02', 'e10', 'e14', 'e15', 'e17', 'e23', 'e25', 'e30',
  'e32', 'e33', 'e36', 'e71', 'e73', 'e76', 'e78', 'e81', 'e97',
  'ekp'
];

export const kauniainenLocations = [
  'k01'
];

export const vantaaLocations = [
  'v20', 'v28', 'v30', 'v32', 'v33', 'v34', 'v37', 'v40', 'v45',
  'v51', 'v60', 'v62', 'v68', 'v70'
];

export const helsinkiLocations = [
  'h00', 'h01', 'h02', 'h13', 'h18', 'h19', 'h20', 'h25', 'h30',
  'h32', 'h33', 'h37', 'h40', 'h41', 'h42', 'h53', 'h55', 'h56',
  'h61', 'h63', 'h64', 'h67', 'h70', 'h71', 'h72', 'h73', 'h74',
  'h75', 'h76', 'h77', 'h80', 'h82', 'h84', 'h89', 'h90', 'h92',
  'h94', 'h98'
];

// Locations where the app is in use
export const appLocations = [
  ...espooLocations
];

// All locations used in Sierra
export const locations = [
  ...espooLocations,
  ...kauniainenLocations,
  ...vantaaLocations,
  ...helsinkiLocations
];

export type Location3 = typeof locations[number];

// eslint-disable-next-line max-lines-per-function
export const useGetLocationName = (): ((location: Location3) => string) => {
  const t = useT();

  // eslint-disable-next-line max-lines-per-function
  return (location: Location3): string => {
    const name = {
      // Espoo
      e01: t('Sello'),
      e02: t('Kirjastoauto Espoo'),
      e10: t('Tapiola'),
      e14: t('Laajalahti'),
      e15: t('Otaniemi'),
      e17: t('Haukilahti'),
      e23: t('Iso Omena'),
      e25: t('Suurpelto'),
      e30: t('Nöykkiö'),
      e32: t('Pikkulaiva'),
      e33: t('Saunalahti'),
      e36: t('Soukka'),
      e71: t('Viherlaakso'),
      e73: t('Laaksolahti'),
      e76: t('Entresse'),
      e78: t('Kauklahti'),
      e81: t('Karhusuo'),
      e97: t('Kalajärvi'),
      ekp: t('Kotipalvelu Espoo'),

      // Kauniainen
      k01: t('Kauniainen'),

      // Vantaa
      v20: t('Hakunila'),
      v28: t('Länsimäki'),
      v30: t('Tikkurila'),
      v32: t('Kirjastoauto Vantaa'),
      v33: t('Aineistohotelli Vantaa'),
      v34: t('Tikkurila musiikkivarasto'),
      v37: t('Hiekkaharju'),
      v40: t('Koivukylä'),
      v45: t('Lumon kirjasto'),
      v51: t('Pointin kirjasto'),
      v60: t('Myyrmäki'),
      v62: t('Martinlaakso'),
      v68: t('Pähkinärinne'),
      v70: t('Kivistö'),

      // Helsinki
      h00: t('Oodi'),
      h01: t('Pasila'),
      h02: t('Kirjastoauto Helsinki'),
      h13: t('Rikhardinkatu'),
      h18: t('Jätkäsaari'),
      h19: t('Suomenlinna'),
      h20: t('Lauttasaari'),
      h25: t('Töölö'),
      h30: t('Pikku Huopalahti'),
      h32: t('Etelä-Haaga'),
      h33: t('Munkkiniemi'),
      h37: t('Pitäjänmäki'),
      h40: t('Pohjois-Haaga'),
      h41: t('Malminkartano'),
      h42: t('Kannelmäki'),
      h53: t('Kallio'),
      h55: t('Vallila'),
      h56: t('Arabianranta'),
      h61: t('Käpylä'),
      h63: t('Maunula'),
      h64: t('Oulunkylä'),
      h67: t('Paloheinä'),
      h70: t('Malmi'),
      h71: t('Viikki'),
      h72: t('Pukinmäki'),
      h73: t('Tapanila'),
      h74: t('Suutarila'),
      h75: t('Tapulikaupunki'),
      h76: t('Puistola'),
      h77: t('Jakomäki'),
      h80: t('Herttoniemi'),
      h82: t('Roihuvuori'),
      h84: t('Laajasalo'),
      h89: t('Sakarinmäki'),
      h90: t('Itäkeskus'),
      h92: t('Myllypuro'),
      h94: t('Kontula'),
      h98: t('Vuosaari')
    }[location] ?? t('Unknown');

    return `${name} (${location})`;
  };
};
