import { useState } from 'react';

import Map from './Map';
import LibrariesLayer from './layers/LibrariesLayer';
import TransitVolumeLayer from './layers/TransitVolumeLayer';

const TransitVolumeMap = ({ height }) => {
  const [libraries, setLibraries] = useState(null);
  const [selectedLibrary, setSelectedLibrary] = useState(null);

  return (
    <Map height={height}>
      <LibrariesLayer
        setLibraries={setLibraries}
        setSelectedLibrary={setSelectedLibrary}
      />

      <TransitVolumeLayer
        libraries={libraries}
        selectedLibrary={selectedLibrary}
      />
    </Map>
  );
};

export default TransitVolumeMap;
