const ClickSelect = ({ pre, code, style, children }) => {
  const onClick = event => {
    const range = document.createRange();
    range.selectNodeContents(event.target);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  if (pre) {
    return (
      <pre onClick={onClick} style={{ marginBottom: 0, ...style }}>
        {children}
      </pre>
    );
  } else if (code) {
    return (
      <code onClick={onClick} style={{ marginBottom: 0, ...style }}>
        {children}
      </code>
    );
  }

  return (
    <span onClick={onClick} style={style}>
      {children}
    </span>
  );
};

export default ClickSelect;
