import type { FormDataValue } from '.';

const getElementValue = (
  element: HTMLFormElement | HTMLInputElement,
  isArray?: boolean
): FormDataValue => {
  switch (element.type) {
    case 'text':
    case 'textarea':
    case 'select-one':
    case 'password':
      return element.value.trim().length > 0
        ? isArray
          ? element.value.split(',').map((s: string) => s.trim()).filter((s: string) => s.length > 0)
          : element.value
        : null;

    case 'number':
      return (element.value.trim().length > 0 ? Number(element.value) : null);

    case 'checkbox':
    case 'radio':
      return (element.id.startsWith('multiselect') && element.checked)
        ? element.value
        : element.checked;

    default:
      throw new Error(`Invalid form element type ${element.type}`);
  }
};

export default getElementValue;
