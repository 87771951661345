import { useT } from '../common/i18n';
import { expandRow } from '../common/layout';

import { APITable, useTableFormatters } from '../api';
import { ItemAllocationView } from '.';

import type { APITableColumn } from '../api';

// eslint-disable-next-line max-lines-per-function
const useColumns = (): APITableColumn[] => {
  const t = useT();
  const { location5, dateTimeRelative, bool } = useTableFormatters();

  return [
    {
      field: 'id',
      label: t('ID')
    },
    {
      field: 'sierraItemRecordId',
      label: t('Sierra Item ID')
    },
    {
      field: 'allocationType',
      label: t('Allocation Type ID')
    },
    {
      field: 'locationSource',
      label: t('Source'),
      formatter: location5
    },
    {
      field: 'locationDestination',
      label: t('Destination'),
      formatter: location5
    },
    {
      field: 'priority',
      label: t('Priority')
    },
    {
      field: 'active',
      label: t('Active'),
      formatter: bool
    },
    {
      field: 'isItemSpecific',
      label: t('Is Item Specific'),
      formatter: bool
    },
    {
      field: 'expiresAt',
      label: t('Expires At'),
      formatter: dateTimeRelative
    },
    {
      field: 'satisfiedAt',
      label: t('Satisfied At'),
      formatter: dateTimeRelative
    },
    {
      field: 'createdAt',
      label: t('Created At'),
      formatter: dateTimeRelative
    },
    {
      field: 'updatedAt',
      label: t('Updated At'),
      formatter: dateTimeRelative,
      disableSorting: true
    }
  ];
};

const ItemAllocationTable: React.FC<{
  enableQueryParams: boolean
}> = ({ enableQueryParams }) => (
  <APITable
    resource="item-allocations/active"
    keyField="id"
    columns={useColumns()}
    enableQueryParams={enableQueryParams}
    expandRow={expandRow(row => <ItemAllocationView itemAllocation={row} />)}
  />
);

export default ItemAllocationTable;
