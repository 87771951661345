import { PageTitle } from '../common/layout';
import { useTranslation } from '../common/i18n';

// TODO
import OldLoginForm from '../legacy/components/auth/LoginForm';

const LoginPage: React.FC = () => (
  <>
    <PageTitle
      title={useTranslation('Authentication required')}
      hidden
    />

    <OldLoginForm />
  </>
);

export default LoginPage;
