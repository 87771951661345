import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';

import CreateSharedAccountButton from './CreateSharedAccountButton';
import SharedAccountsTable from './SharedAccountsTable';

const SharedAccountsManager = () => {
  const { t } = useTranslation();

  const [sharedAccounts, loading, error, /* headers */, update] = useAPI('GET', '/accounts/shared');

  return (
    <>
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <CreateSharedAccountButton update={update} />
      </div>

      {
        error
          ? t('Could not fetch shared accounts')
          : <SharedAccountsTable accounts={sharedAccounts} loading={loading} update={update} />
      }
    </>
  );
};

export default SharedAccountsManager;
