import { useTranslation } from '../i18n';

import { useSelector } from '../../state/store';
import { selectEnabled as selectDarkModeEnabled } from '../../state/slices/darkMode';

import bannerImageDarkMode from '../../resources/brand/banner-dark.png';
import bannerImageLightMode from '../../resources/brand/banner.png';

const useAltText = (): string => useTranslation('Logo');

const bannerImageStyle: React.CSSProperties = {
  width: '400px',
  maxWidth: '100%',
  height: 'auto'
};

interface BannerImageProps {
  hidden: boolean;
}

const DarkModeBannerImage: React.FC<BannerImageProps> = ({ hidden }) => (
  <img
    src={bannerImageDarkMode}
    alt={useAltText()}
    style={bannerImageStyle}
    hidden={hidden}
  />
);

const LightModeBannerImage: React.FC<BannerImageProps> = ({ hidden }) => (
  <img
    src={bannerImageLightMode}
    alt={useAltText()}
    style={bannerImageStyle}
    hidden={hidden}
  />
);

const BannerImage: React.FC = () => {
  const darkMode = useSelector(selectDarkModeEnabled);

  return (
    <>
      <DarkModeBannerImage hidden={!darkMode} />
      <LightModeBannerImage hidden={darkMode} />
    </>
  );
};

export default BannerImage;
