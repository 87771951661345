import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import expandRow from '../../services/expandRow';
import { hasRole } from '../../services/auth';
import { getBibColumns } from '../../services/tableColumns';
import useQueryParamState from '../../hooks/useQueryParamState';
import { getUserLocation } from '../../services/user';

import APITable from '../api/APITable';
import BibView from './BibView';
import BackToQueryButton from '../api/BackToQueryButton';
import BibSearchFilters from './BibSearchFilters';
import SearchTitle from '../api/SearchTitle';

import lorpImg from '../../../resources/brand/lorp.png';

const Lorp = () => (
  <div style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }}>
    <img id="lorp" src={lorpImg} alt="" />
  </div>
);

// TODO
const isStandardNumber = query => {
  let normalized = query.replace(/-/g, '').replace(/ /g, '');

  while (normalized.startsWith('0')) normalized = normalized.slice(1);

  if (normalized.length >= 8 && /^[0-9]+[xX]?$/.test(normalized)) return normalized;
  else return null;
};

const BibTable = ({ query, setQuery, simple, isSearchResults, enableQueryParams, enableQuickFilters, enableFullFilters }) => {
  const { t } = useTranslation();

  const [currentQuery, setCurrentQuery] = useState(query);

  const emptyFilters = {
    bibBindings: [],
    bibLanguages: [],
    bibMaterialTypes: [],
    bibIncludeAcquisitions: true,
    bibHideHiddenByBCode3: true,
    itemLocations: [],
    itemStatuses: [],
    itemCheckedOut: null,
    itemFiction: null
  };

  const [filters, setFilters] = useQueryParamState('filters', { ...emptyFilters });

  const [onlyOnShelfHereChecked, setOnlyOnShelfHereChecked] = useState(
    filters.itemLocations.length === 1 &&
    filters.itemLocations[0] === getUserLocation() &&
    filters.itemStatuses.length === 1 &&
    filters.itemStatuses[0] === '-' &&
    filters.itemCheckedOut === false
  );

  useEffect(() => (
    typeof setQuery === 'function' && setQuery(currentQuery)
  ), [currentQuery, setQuery]);

  useEffect(() => {
    if (hasRole('DEVELOPER')) {
      // eslint-disable-next-line no-console
      console.log('Filters:', filters);
    }
  }, [filters]);

  // TODO user selectable columns
  return (
    <>
      {isSearchResults && (
        <>
          {(Math.random() < 0.001) && <Lorp />}

          <SearchTitle query={currentQuery} setQuery={setCurrentQuery} />
        </>
      )}

      {(currentQuery && !simple) && <BackToQueryButton resource="bibs" query={currentQuery} />}

      {(simple && !isStandardNumber(query) && (enableQuickFilters || enableFullFilters)) && (
        <BibSearchFilters
          query={query}
          filters={filters}
          setFilters={setFilters}
          enableQuickFilters={enableQuickFilters}
          enableFullFilters={enableFullFilters}
          onlyOnShelfHereChecked={onlyOnShelfHereChecked}
          setOnlyOnShelfHereChecked={setOnlyOnShelfHereChecked}
          emptyFilters={emptyFilters}
        />
      )}

      <APITable
        resource="bibs"
        keyField="sierraBibRecordId"
        columns={getBibColumns(t, simple)}
        enableQueryParams={enableQueryParams}
        query={currentQuery}
        simple={simple}
        filters={filters}
        defaultSortOrder="desc"

        expandRow={expandRow(row => (
          <BibView
            bibId={row.sierraBibRecordId}
            currentLocationFilterEnabled={onlyOnShelfHereChecked}
            hideCoverImage
          />
        ))}

        sizeIndicator={(count, more, headers) => simple && (
          `${count}${more ? '+' : ''} ${t('results')}` + (
            hasRole('DEVELOPER')
              ? (
                  (headers['x-query-time-ms']
                    ? `, ${t('query took')} ` + headers['x-query-time-ms'] + (
                    (headers['x-request-time-ms']
                      ? ('+' + (headers['x-request-time-ms'] - headers['x-query-time-ms']))
                      : '')
                  ) + ' ms'
                    : '')
                )
              : ''
          )
        )}

        errorIndicator={headers => headers['x-invalid-subject-headings'] && (
          <div style={{ color: 'var(--danger)', marginBottom: '6px' }}>
            <strong>
              {
                headers['x-invalid-subject-headings'].split(',').length === 1
                  ? t('Invalid subject heading')
                  : t('Invalid subject headings')
              }:
            </strong>

            &nbsp;

            {headers['x-invalid-subject-headings'].split(',').join(', ')}
          </div>
        )}
      />
    </>
  );
};

export default BibTable;
