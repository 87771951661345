import { useTranslation } from 'react-i18next';

import { formatDateTimeRelative } from '../../services/date';

import Signum from '../items/Signum';
import BibTitleAndAuthor from '../bibs/BibTitleAndAuthor';
import ClickSelect from '../api/ClickSelect';
import InfoBadge from '../api/InfoBadge';
import { Alert } from 'react-bootstrap';

const getThreeMonthsAgo = () => {
  const date = new Date();

  date.setMonth(date.getMonth() - 3);

  return date;
};

const ItemDescription = ({ allocation }) => {
  const { t } = useTranslation();

  const callNumberItemTypes = [103];
  const callNumbered = callNumberItemTypes.includes(allocation?.Item?.fixedFieldItemType);
  const productCodeStyle = {
    fontSize: '20px'
  };

  return (
    <>
      {!callNumbered &&
        <h3>
          <Signum
            itemId={allocation.sierraItemRecordId}
            title={allocation.Item.callNumber}
            height="2rem"
            noPrinting
            noError
          />
          {allocation.Item?.genre && `(${allocation.Item.genre})`}
        </h3>
      }
      <BibTitleAndAuthor bib={allocation.Item.Bib} />

      {callNumbered && (
        <div>
          <strong>
            {allocation.Item.callNumber}
          </strong>
        </div>
      )}

      <div style={{ marginBottom: '6px' }} />

      {allocation?.Item?.fixedFieldLocation[4] !== 'l' && (
        <div>
          {allocation.Item.location && allocation.Item.location.name}
        </div>
      )}

      {allocation?.Item?.itemType && (
        <div>
          {allocation.Item.itemType.description.replace(/ [0-9]+$/, '')}
          {allocation.Item?.Bib?.fixedFieldLang !== 'fin' && `, ${allocation.Item.Bib.fixedFieldLang}`}
          {allocation.Item?.Bib?.marcBindingInfo && `, ${allocation.Item.Bib.marcBindingInfo}`}
          {allocation.Item?.genre && `, ${allocation.Item.genre}`}
        </div>
      )}

      {allocation?.Item?.Bib?.marcPhysicalDescription && (
        <div>
          {allocation.Item.Bib.marcPhysicalDescription}
        </div>
      )}

      <div style={{ marginBottom: '6px' }} />

      {allocation?.Item?.Bib?.marcSeriesInfo && (
        <div>
          <strong>{t('Series')}:</strong> {allocation.Item.Bib.marcSeriesInfo}
        </div>
      )}
      {allocation?.Item?.Bib?.marcSeriesInfo2 && (
        <div>
          <strong>{t('Series')}:</strong> {allocation.Item.Bib.marcSeriesInfo2}
        </div>
      )}

      {allocation?.pagedTitles.length > 0 && allocation.pagedTitles.map(pagedTitle => (
        <div key={pagedTitle.id}>
          <strong>{t('Hold Created')}:</strong> {formatDateTimeRelative(pagedTitle.sierraHoldCreatedAt, t)}
        </div>
      ))}

      <div>
        <strong>{t('Checked in')}:</strong> {allocation?.Item?.fixedFieldLastCheckinDate
          ? formatDateTimeRelative(allocation.Item.fixedFieldLastCheckinDate, t)
          : t('never')}
      </div>

      {(allocation?.Item?.sierraCreatedAt && new Date(allocation.Item.sierraCreatedAt) > getThreeMonthsAgo()) && (
        <div>
          <strong>{t('Item created at')}:</strong> {formatDateTimeRelative(allocation.Item.sierraCreatedAt, t)}
        </div>
      )}

      <pre style={{
        marginBottom: 0,
        marginTop: '6px',
        fontSize: '20px',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word'
      }}>
        <ClickSelect>
          {allocation.Item.barcode}
        </ClickSelect>
      </pre>

      {!allocation.isItemSpecific && (
        allocation?.Item?.Bib?.marcISBN
          ? <><ClickSelect style={productCodeStyle} code>{allocation.Item.Bib.marcISBN}</ClickSelect> (ISBN)</>
          : allocation?.Item?.Bib?.marcISSN
            ? <><ClickSelect style={productCodeStyle} code>{allocation.Item.Bib.marcISSN}</ClickSelect> (ISSN)</>
            : allocation?.Item?.Bib?.marcEAN13
              ? <><ClickSelect style={productCodeStyle} code>{allocation.Item.Bib.marcEAN13}</ClickSelect> (EAN13)</>
              : null
      )}

      <div>
        <h4>
          <InfoBadge
            text={
              {
                1: t('Title Paging'),
                2: t('Item Paging'),
                3: t('Item Deletion'),
                4: t('Task'),
                5: t('Floating order'),
                6: t('Homing order')
              }[allocation.allocationType] ?? `${t('Invalid allocation type')} "${allocation.allocationType}"`
            }

            variant={
              {
                1: 'success',
                2: 'warning',
                3: 'danger',
                4: 'info',
                5: 'primary',
                6: 'primary'
              }[allocation.allocationType] ?? 'danger'
            }
            allocation
          />
        </h4>
      </div>
      <div>{allocation?.Comments[0]?.comment && <Alert variant={
        {
          1: 'success',
          2: 'warning',
          3: 'danger',
          4: 'info',
          5: 'primary',
          6: 'primary'
        }[allocation.allocationType] ?? 'danger'
      }>
        {allocation.allocationType === 4 && (<b>{'Kuittaa kokoelmanhoitotehtävä tehdyksi painamalla vahvista-nappia sitten, kun olet hoitanut tehtävän annetun ohjeistuksen mukaisesti.'}<br /></b>)}
        {allocation.Comments[0].comment} </Alert>}</div>
    </>
  );
};

export default ItemDescription;
