import type { DateLocale } from '.';

export const formatDate = (date: Date | number | string): string => {
  if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(date);
  }

  return date.toISOString().split('T')[0];
};

export const formatTime = (date: Date | number | string, locale: DateLocale = 'fi'): string => {
  if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(date);
  }

  return date.toLocaleTimeString(locale);
};

export const formatDateTime = (date: Date | number | string, locale: DateLocale = 'fi'): string => {
  if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(date);
  }

  return date.toLocaleString(locale);
};
