import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from '../../state/store';

import {
  selectOpen as selectOpenDialogs,
  closeFirst as closeFirstDialog
} from '../../state/slices/dialogs';

import { Modal, Markdown } from '../../common/layout';
import { TODO } from '../../common/placeholders';
import { APIFormModal, APIPostModal, APIDeleteModal } from '../../api';

import type { Method } from '../../api';
import type { ContentType } from '..';
import type { Dialog } from '.';

type APIFormDialogType =
  | 'api-form-post'
  | 'api-form-put'
  | 'api-form-patch';

const getFormMethod = (dialogType: APIFormDialogType): Method => ({
  'api-form-post': 'POST',
  'api-form-put': 'PUT',
  'api-form-patch': 'PATCH'
})[dialogType] as Method;

const useOnHide = (dialog?: Dialog): (() => void) => {
  const dispatch = useDispatch();

  return (): void => {
    if (dialog) {
      dispatch(closeFirstDialog());
    }
  };
};

const InnerDialogModal: React.FC<{
  show: boolean,
  dialog: Dialog
}> = ({ show, dialog, children }) => (
  <Modal
    show={show}
    title={dialog.title}
    closeButton={dialog.showCloseButton}
    onHide={useOnHide(dialog)}
  >
    {children}
  </Modal>
);

interface DialogModalProps {
  show: boolean;
  dialog: Dialog;
}

const Content: React.FC<{
  content: string,
  contentType?: ContentType
}> = ({ content, contentType }) =>
  (contentType === 'markdown')
    ? <Markdown>{content}</Markdown>
    : <>{content}</>;

// eslint-disable-next-line max-lines-per-function
const DialogModal: React.FC<DialogModalProps> = ({ show, dialog }) => {
  const onHide = useOnHide(dialog);

  switch (dialog.type) {
    case 'info':
      return (
        <InnerDialogModal show={show} dialog={dialog}>
          <Content contentType={dialog.contentType} content={dialog.content} />
        </InnerDialogModal>
      );

    case 'api-post':
      return (
        <APIPostModal
          show={show}
          onHide={onHide}
          title={dialog.title}
          showCloseButton={dialog.showCloseButton}
          path={dialog.path}
          params={dialog.params}
          data={dialog.data}
        >
          <Content contentType={dialog.contentType} content={dialog.content} />
        </APIPostModal>
      );

    case 'api-put':
    case 'api-patch':
      return (
        <InnerDialogModal show={show} dialog={dialog}>
          <TODO dialog={dialog} />
        </InnerDialogModal>
      );

    case 'api-delete':
      return (
        <APIDeleteModal
          show={show}
          onHide={onHide}
          title={dialog.title}
          showCloseButton={dialog.showCloseButton}
          path={dialog.path}
          params={dialog.params}
          recordId={dialog.recordId}
        >
          <Content contentType={dialog.contentType} content={dialog.content} />
        </APIDeleteModal>
      );

    case 'api-form-post':
      return (
        <APIFormModal
          show={show}
          onHide={onHide}
          title={dialog.title}
          method={getFormMethod(dialog.type)}
          path={dialog.path}
          form={dialog.form}
        />
      );

    case 'api-form-put':
    case 'api-form-patch':
      return (
        <APIFormModal
          show={show}
          onHide={onHide}
          title={dialog.title}
          method={getFormMethod(dialog.type)}
          path={[dialog.path, dialog.recordId].join('/')}
          form={dialog.form}
        />
      );
  }
};

const DialogView: React.FC = () => {
  const openDialogs = useSelector(selectOpenDialogs);

  const [lastDialog, setLastDialog] = useState<Dialog | null>(null);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (openDialogs.length > 0) {
      setLastDialog(openDialogs[0]);
      setShow(true);
    } else {
      setShow(false);
    }
  }, [openDialogs]);

  return lastDialog
    ? <DialogModal key={lastDialog.id} show={show} dialog={lastDialog} />
    : null;
};

export default DialogView;
