import type { APITableColumn } from '.';

const useColumns = (columns: APITableColumn[]): object[] =>
  columns.map(column => ({
    dataField: column.field,
    text: column.label ?? `[${column.field}]`,
    formatter: column.formatter,
    sort: !(column.disableSorting || column.isDummyField),
    isDummyField: column.isDummyField
  }));

export default useColumns;
