import api from './api';

export const createBibList = async bibList => {
  if (!bibList) throw new Error('No name given!');
  if (typeof bibList.isPublic !== 'boolean') throw new Error('isPublic must be a boolean!');
  if (!bibList.primaryColor) bibList.primaryColor = null;
  if (!bibList.secundaryColor) bibList.secundaryColor = null;

  await api.post('/bib-lists', bibList);
};

export const updateBibList = async bibList => {
  if (!bibList.id) throw new Error('No id given!');

  await api.put(`/bib-lists/${bibList.id}`, bibList);
};

export const deleteBibList = async bibListId => {
  if (typeof bibListId !== 'number') throw new Error('bibListId must be a number!');

  await api.delete(`/bib-lists/${bibListId}`);
};
