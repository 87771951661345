// Prompt the user to download a file
// Character # in filename is expanded into current date
export const downloadFile = (content, filename = null, type = 'text/plain') => {
  if (typeof content === 'object') content = JSON.stringify(content);
  if (typeof content !== 'string') throw new Error(`Invalid file content! (type=${typeof content})`);

  const date = new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  const dateString = date.toISOString().replace(/-/g, '').replace(/:/g, '').replace('T', '-').split('.')[0];

  const element = document.createElement('a');

  element.href = URL.createObjectURL(new Blob([content], { type }));

  element.download = (
    typeof filename === 'string'
      ? filename.replace('#', dateString)
      : `lirp-${dateString}.txt`
  );

  // Required for this to work in Firefox
  document.body.appendChild(element);

  element.click();
};
