import BootstrapButton, { ButtonProps as BootstrapButtonProps } from 'react-bootstrap/Button';

import type { ButtonType, ButtonVariant } from '.';

const linkStyle: React.CSSProperties = {
  margin: 0,
  padding: 0,
  border: 0,
  verticalAlign: 'baseline'
};

const useStyle = (style: React.CSSProperties, variant?: ButtonVariant): React.CSSProperties =>
  variant === 'link'
    ? { ...linkStyle, ...style }
    : style;

export interface Props {
  type?: ButtonType;
  variant?: ButtonVariant;
  style?: React.CSSProperties;
  onClick?: () => void;
  large?: boolean;
  disabled?: boolean;
}

export const useProps = ({
  type,
  variant,
  large,
  style,
  ...rest
}: Props): BootstrapButtonProps => ({
  type: type ?? 'button',
  variant: variant ?? 'link',
  size: large ? undefined : 'sm',
  style: useStyle(style ?? {}, variant ?? 'link'),
  ...rest
});

const Button: React.FC<Props> = ({ ...props }) =>
  <BootstrapButton {...useProps(props)} />;

export default Button;
