import QRCode from 'qrcode.react';
import { Form } from 'react-bootstrap';

const SharePage = () => (
  <center>
    <QRCode value={window.location.href} size={256} />

    <hr />

    <Form style={{ marginTop: '10px' }}>
      <Form.Control
        type="text"
        value={window.location.href}
        onClick={event => event.target.select()}
        readOnly
      />
    </Form>
  </center>
);

export default SharePage;
