import { useT } from '../../../common/i18n';

import type { FormItem } from '../../../layout/forms';

const useMassUpdateItemForm = (): FormItem[] => {
  const t = useT();

  const placeholder = `<${t('no change')}>`;

  return [
    {
      type: 'text',
      name: 'classification',
      label: t('Classification'),
      placeholder
    },
    {
      type: 'text',
      name: 'genre',
      label: t('Genre'),
      placeholder
    }
  ];
};

export default useMassUpdateItemForm;
