import useCreateLinkGroupDialog from './useCreateLinkGroupDialog';
import useEditLinkGroupDialog from './useEditLinkGroupDialog';
import useDeleteLinkGroupDialog from './useDeleteLinkGroupDialog';

import useCreateLinkDialog from './useCreateLinkDialog';
import useEditLinkDialog from './useEditLinkDialog';
import useDeleteLinkDialog from './useDeleteLinkDialog';

export {
  useCreateLinkGroupDialog,
  useEditLinkGroupDialog,
  useDeleteLinkGroupDialog,

  useCreateLinkDialog,
  useEditLinkDialog,
  useDeleteLinkDialog
};
