import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import { hasRole } from '../../services/auth';

import BibDetails from './BibDetails';
import BibItemTable from './BibItemTable';
import BibMARC from './BibMARC';
import ItemDetails from '../items/details/ItemDetails';
import DynamicView from '../api/DynamicView';
import Loading from '../api/Loading';

const BibView = ({ bibId, currentLocationFilterEnabled, hideCoverImage }) => {
  const { t } = useTranslation();

  const [bib, loading, error] = useAPI('GET', `/bibs/${bibId}`, {}, [bibId]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [bibItemTableUpdateCount, setBibItemTableUpdateCount] = useState(0);

  const firstPanel = (
    <BibDetails
      title={t('Bib Details')}
      key="bibDetails"
      bib={bib}
      hideCoverImage={hideCoverImage}
      update={() => setBibItemTableUpdateCount(c => c + 1)}
    />
  );

  const thirdPanel = (
    <BibItemTable
      title={t('Items')}
      key="bibItemTable"
      bibId={bibId}
      setItem={setSelectedItem}
      noHighlight={selectedItem === null}
      currentLocationFilterEnabled={currentLocationFilterEnabled}
      updateCount={bibItemTableUpdateCount}
      autoSelect
    />
  );

  const itemDetails = selectedItem && (
    <ItemDetails
      key="dynamicDetails"
      title={`${t('Item')} i${selectedItem.sierraItemRecordId}`}
      item={selectedItem}
      onClose={() => setSelectedItem(null)}
      onChange={() => setBibItemTableUpdateCount(c => c + 1)}
      closeButton
    />
  );

  const secondPanel = (
    selectedItem
      ? itemDetails
      : <BibMARC key="dynamicDetails" title={t('MARC')} bibId={bibId} style={{ maxWidth: '33%' }} />
  );

  if (loading) return <Loading />;

  if (error) return t('Could not fetch bib');

  if (bib === null) return `${t('No bib found for Sierra ID')} ${bibId}`;

  return (
    <DynamicView defaultTab="bibDetails">
      {firstPanel}
      {hasRole('VIEW-ITEMS') ? secondPanel : null}
      {thirdPanel}
    </DynamicView>
  );
};

export default BibView;
