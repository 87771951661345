import { CaretUpSquareFill, CaretDownSquare } from 'react-bootstrap-icons';

const expandRow = (renderer, expanded, onlyOneExpanding = true) => {
  const options = {
    renderer,
    onlyOneExpanding,
    showExpandColumn: true,
    expandByColumnOnly: true,
    hideExpandHeaderColumn: true,
    expandColumnRenderer: ({ expanded }) => (
      <div className="expand-row-caret">
        {expanded ? <CaretUpSquareFill /> : <CaretDownSquare />}
      </div>
    )
  };

  if (expanded) options.expanded = [expanded];

  return options;
};

export default expandRow;
