// @ts-ignore: missing typedefs
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import Form from 'react-bootstrap/Form';

import { useT } from '../../../common/i18n';

import { FormItemLabel } from '..';

import type { FormElementProps } from '.';
import type { SelectOption } from '..';

interface DropdownMultiselectOption {
  key: string;
  label: string;
}

const mapOptions = (options: SelectOption[]): DropdownMultiselectOption[] =>
  options.map(({ label, value: key }) => ({ key, label }));

const Multiselect: React.FC<FormElementProps> = ({ formItem, onChange }) => {
  const t = useT();

  if (formItem.type !== 'multiselect') {
    throw new Error(`Invalid multiselect form input type ${formItem.type}`);
  }

  return (
    <Form.Group>
      <FormItemLabel formItem={formItem} />

      <DropdownMultiselect
        name={formItem.name}
        buttonClass="btn-light btn-sm"
        options={mapOptions(formItem.options)}
        selected={formItem.defaultValue ?? undefined}
        placeholder={t('Nothing selected')}
        selectDeselectLabel={t('Select all')}
        handleOnChange={(selected: string[]): void => onChange &&
          onChange((Array.isArray(selected) && selected.length > 0) ? selected : null)}
      />
    </Form.Group>
  );
};

export default Multiselect;
