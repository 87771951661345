import { store } from '../state';
import api, { getResourceForPath } from '.';

import type { Params } from '.';

const deleteRecord = async (
  path: string,
  recordId: number,
  params?: Params
): Promise<void> => {
  if (path.includes('undefined')) {
    // eslint-disable-next-line no-console
    console.error('DELETE path contains undefined:', path);
  }

  await api.delete(`${path}/${recordId}`, { params });

  store.dispatch({
    type: 'api/resourceUpdated',
    payload: getResourceForPath(path)
  });
};

export default deleteRecord;
