import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createBibList } from '../../../services/bibList';

import { Button } from 'react-bootstrap';

import OnClickModal from '../../layout/OnClickModal';
import BibListForm from './BibListForm';

const CreateBibListButton = ({ title, parentListId, update }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const button = (
    <Button size="sm">
      {title || t('Create Bib List')}
    </Button>
  );

  const onSubmit = async bibList => {
    if (typeof parentListId === 'number' && !isNaN(parentListId)) {
      bibList.parentListId = parentListId;
    } else {
      bibList.parentListId = null;
    }

    await createBibList(bibList);

    update();

    setForceClose(true);
  };

  return (
    <OnClickModal
      title={title || t('Create Bib List')}
      button={button}
      forceClose={forceClose}
      onOpen={() => setForceClose(false)}
      closeButton
    >
      <BibListForm onSubmit={onSubmit} />
    </OnClickModal>
  );
};

export default CreateBibListButton;
