import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';

const CityItemsInCirculation = ({ items, city }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const countStatus = useCallback((status, isCheckedOut = false) =>
    items.filter(({ fixedFieldLocation, fixedFieldItemStatus, checkedOut }) => (
      fixedFieldLocation.startsWith(city) &&
      fixedFieldItemStatus === status &&
      checkedOut === isCheckedOut
    )).length, [items, city]);

  return (
    <>
      <Button
        variant="link"
        size="sm"
        style={{ padding: 0 }}
        onClick={() => setExpanded(e => !e)}
      >
        {expanded ? '(-)' : '(+)'}
      </Button>

      {expanded && (
        <ul style={{ marginBottom: 0 }}>
          <li>{t('Hyllyssä')}: <strong>{countStatus('-', false)}</strong></li>
          <li>{t('Lainassa')}: <strong>{countStatus('-', true)}</strong></li>
          <li>{t('Matkalla')}: <strong>{countStatus('t')}</strong></li>
          <li>{t('Odottaa noutoa')}: <strong>{countStatus('!')}</strong></li>
          {/* TODO: deleted are not in circulation <li>{t('Poistettu')}: <strong>{countStatus('p')}</strong></li> */}
        </ul>
      )}
    </>
  );
};

const ItemsInCirculation = ({ items }) => {
  const { t } = useTranslation();

  const cityIs = city =>
    ({ fixedFieldLocation }) => fixedFieldLocation.startsWith(city);

  const espooCount = items.filter(cityIs('e')).length;
  const helsinkiCount = items.filter(cityIs('h')).length;
  const kauniainenCount = items.filter(cityIs('k')).length;
  const vantaaCount = items.filter(cityIs('v')).length;

  return (
    <>
      <div>
        {t('Espoo')}: <strong>{espooCount}</strong> {espooCount > 0 && (
          <CityItemsInCirculation items={items} city="e" />
        )}
      </div>

      <div>
        {t('Helsinki')}: <strong>{helsinkiCount}</strong> {helsinkiCount > 0 && (
          <CityItemsInCirculation items={items} city="h" />
        )}
      </div>

      <div>
        {t('Kauniainen')}: <strong>{kauniainenCount}</strong> {kauniainenCount > 0 && (
          <CityItemsInCirculation items={items} city="k" />
        )}
      </div>

      <div>
        {t('Vantaa')}: <strong>{vantaaCount}</strong> {vantaaCount > 0 && (
          <CityItemsInCirculation items={items} city="v" />
        )}
      </div>
    </>
  );
};

export default ItemsInCirculation;
