import {
  useDateTimeRelativeFormatter,
  useCityFormatter,
  useLocation3Formatter,
  useLocation5Formatter,
  useEmailFormatter,
  useBooleanFormatter
} from './formatters';

import type { APITableFormatter } from '.';

const useTableFormatters = (): Record<string, APITableFormatter> => ({
  dateTimeRelative: useDateTimeRelativeFormatter(),
  city: useCityFormatter(),
  location3: useLocation3Formatter(),
  location5: useLocation5Formatter(),
  email: useEmailFormatter(),
  bool: useBooleanFormatter()
});

export default useTableFormatters;
