const onClick = (event: React.MouseEvent<HTMLElement>): void => {
  const range = document.createRange();
  range.selectNodeContents(event.target as Node);

  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);
};

const ClickSelect: React.FC<{
  style?: React.CSSProperties
}> = ({ style, children }) => (
  <span onClick={onClick} style={style}>
    {children}
  </span>
);

export default ClickSelect;
