import Form from 'react-bootstrap/Form';

import { FormItemLabel, getElementValue } from '..';

import type { FormElementProps } from '.';
import type { SelectOption } from '..';

const Options: React.FC<{
  options: SelectOption[]
}> = ({ options }) => (
  <>
    {options.map(({ label, value }, i) => (
      <option key={i} value={value}>
        {label}
      </option>
    ))}
  </>
);

const Select: React.FC<FormElementProps> = ({ formItem, onChange }) => {
  if (formItem.type !== 'select') {
    throw new Error(`Invalid select form input type ${formItem.type}`);
  }

  return (
    <Form.Group>
      <FormItemLabel formItem={formItem} />

      <Form.Control
        as="select"
        size="sm"
        name={formItem.name}
        defaultValue={formItem.defaultValue ?? undefined}
        onChange={(event): void => onChange &&
          onChange(getElementValue(event.target as unknown as HTMLInputElement))}
      >
        {formItem.type === 'select' && (
          <Options options={formItem.options} />
        )}
      </Form.Control>
    </Form.Group>
  );
};

export default Select;
