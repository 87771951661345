import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  updateAccount,
  deleteAccount
} from '../../services/account';

import { Button } from 'react-bootstrap';
import { PencilSquare, Trash } from 'react-bootstrap-icons';

import AccountConfirmDelete from './AccountConfirmDelete';
import AccountEditor from './AccountEditor';
import OnClickModal from '../layout/OnClickModal';

const EditButton = () => (
  <Button variant="link" size="sm" style={{ padding: 0, border: 0 }}>
    <PencilSquare size={20} style={{ color: 'var(--primary)' }} />
  </Button>
);

const DeleteButton = () => (
  <Button variant="link" size="sm" style={{ padding: 0, border: 0 }}>
    <Trash size={20} style={{ color: 'var(--danger)' }} />
  </Button>
);

const AccountOptionsColumn = ({ account, update }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const onSubmit = async ({ username, password, city, location, roles, email }) => {
    await updateAccount({
      id: account.id,
      username,
      password,
      city,
      location,
      email,
      roles
    });

    update();
    setForceClose(true);
  };

  const onConfirmDelete = async () => {
    await deleteAccount(account.id);
    update();
    setForceClose(true);
  };

  return (
    <>
      <OnClickModal
        title={t('Edit account')}
        button={<EditButton />}
        forceClose={forceClose}
        onOpen={() => setForceClose(false)}
        closeButton
      >
        <AccountEditor account={account} onSubmit={onSubmit} />
      </OnClickModal>

      <span style={{ marginRight: '8px' }} />

      <OnClickModal
        title={t('Confirm deletion')}
        button={<DeleteButton />}
        forceClose={forceClose}
        onOpen={() => setForceClose(false)}
        closeButton
      >
        <AccountConfirmDelete
          account={account}
          onConfirm={onConfirmDelete}
          onCancel={() => setForceClose(true)}
        />
      </OnClickModal>
    </>
  );
};

export default AccountOptionsColumn;
