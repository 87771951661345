import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useQueryParamState from '../../hooks/useQueryParamState';
import { getUserLocation } from '../../services/user';
import useAPI from '../../hooks/useAPI';
import sierraGlobals from '../../sierraGlobals';
import { downloadFile } from '../../services/file';
import api from '../../services/api';
import { hasRole } from '../../services/auth';

import { Button, Form, Row, Col, ProgressBar } from 'react-bootstrap';

import {
  ArrowRepeat,
  CaretDownSquare,
  CaretUpSquareFill,
  Download,
  Search
} from 'react-bootstrap-icons';

import LocationSelector from '../forms/LocationSelector';
import DateSelector from '../forms/DateSelector';
import Loading from '../api/Loading';
import OnClickModal from '../layout/OnClickModal';
import UninventoriedItemsViewer from './UninventoriedItemsViewer';
import TopTooltip from '../layout/TopTooltip';
import MaterialTypeSelector from '../forms/MaterialTypeSelector';
import Authorized from '../auth/Authorized';

const FiltersForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const defaultTresholdDate = `${new Date().getFullYear()}-01-01`;
  const defaultMaterialTypes = Object.keys(sierraGlobals.materialTypes);

  const [
    selectedLocation,
    setSelectedLocation
  ] = useQueryParamState('location', getUserLocation());

  const [
    tresholdDate,
    setTresholdDate
  ] = useQueryParamState('tresholdDate', defaultTresholdDate);

  const [
    materialTypes,
    setMaterialTypes
  ] = useQueryParamState('materialTypes', defaultMaterialTypes);

  const [languages, setLanguages] = useQueryParamState('languages', []);

  const onFormSubmit = event => {
    event.preventDefault();

    if (
      event.nativeEvent &&
      event.nativeEvent.submitter &&
      event.nativeEvent.submitter.id !== 'segment-browser-submit'
    ) {
      return;
    }

    if (typeof onSubmit === 'function') {
      onSubmit({ selectedLocation, tresholdDate, materialTypes, languages });
    }
  };

  return (
    <Form onSubmit={onFormSubmit}>
      <Form.Group as={Row} style={{ marginBottom: '10px' }}>
        <Form.Label xs="auto" column="sm">
          {t('Location')}
        </Form.Label>

        <Col xs="auto" style={{ paddingLeft: 0 }}>
          <LocationSelector
            defaultValue={selectedLocation}
            onChange={setSelectedLocation}
            showAllOption={hasRole('ADMIN')}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} style={{ marginBottom: '10px' }}>
        <Form.Label xs="auto" column="sm">
          {t('Treshold Date')}
        </Form.Label>

        <Col xs="auto" style={{ paddingLeft: 0 }}>
          <DateSelector
            defaultValue={tresholdDate}
            onChange={setTresholdDate}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} style={{ marginBottom: '10px' }}>
        <Form.Label xs="auto" column="sm">
          {t('Material Type')}
        </Form.Label>

        <Col xs="auto" style={{ paddingLeft: 0 }}>
          <MaterialTypeSelector
            defaultValue={materialTypes}
            onChange={setMaterialTypes}
            sm
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} style={{ marginBottom: '10px' }}>
        <Form.Label xs="auto" column="sm">
          {t('Language')}
        </Form.Label>

        <Col xs="auto" style={{ paddingLeft: 0 }}>
          <Form.Control
            size="sm"
            name="languages"
            defaultValue={languages.join(',')}
            onChange={event => setLanguages(event.target.value.split(',').filter(l => l.length === 3))}
            placeholder={`${t('Any')} (${t('default')})`}
          />
        </Col>
      </Form.Group>

      <Button
        size="sm"
        type="submit"
        id="segment-browser-submit"
      >
        {t('Make Search')}
      </Button>
    </Form>
  );
};

const SectionButtons = ({ uninventoried, update, title, canOpen, open, setOpen, location, tresholdDate, filters }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const [currentUninventoried, setCurrentUninventoried] = useState(null);

  const buttonStyle = {
    marginLeft: '6px',
    verticalAlign: 'baseline',
    padding: 0,
    border: 0
  };

  const downloadExportFile = async () => {
    const res = await api.getRes(`/items/uninventoried/${location.code}/sierra-export`, {
      params: {
        filters,
        tresholdDate
      }
    });

    const filename = res.headers['content-disposition'].split('=')[1];

    downloadFile(res.data, filename);
  };

  const searchButton = (
    <TopTooltip content={t('Show uninventoried items')}>
      <Button
        variant="link"
        style={buttonStyle}
      >
        <Search />
      </Button>
    </TopTooltip>
  );

  const modalTitle = (
    <>
      {t('Uninventoried items')} {title}{currentUninventoried && ` (${currentUninventoried}${currentUninventoried === 100 ? '+' : ''})`}

      <Button
        variant="link"
        size="sm"
        onClick={() => setUpdateCount(c => c + 1)}
        style={{
          padding: 0,
          border: 0,
          marginTop: '-4px',
          marginLeft: '10px'
        }}
      >
        <ArrowRepeat size={24} />
      </Button>

      <Button
        variant="link"
        size="sm"
        onClick={downloadExportFile}
        style={{
          padding: 0,
          border: 0,
          marginTop: '-4px',
          marginLeft: '10px'
        }}
      >
        <Download size={24} />
      </Button>
    </>
  );

  if (uninventoried === 0) {
    return null;
  }

  return (
    <>
      <TopTooltip content={t('Update') + ` ${title}`}>
        <Button
          variant="link"
          onClick={update}
          style={buttonStyle}
        >
          <ArrowRepeat size={18} />
        </Button>
      </TopTooltip>

      <OnClickModal
        title={modalTitle}
        button={searchButton}
        forceClose={forceClose}
        onOpen={() => setForceClose(false)}
        onClose={update}
        size="lg"
        closeButton
      >
        <UninventoriedItemsViewer
          location={location}
          tresholdDate={tresholdDate}
          filters={filters}
          updateCount={updateCount}
          setCurrentUninventoried={setCurrentUninventoried}
          setForceClose={setForceClose}
          onClose={update}
        />
      </OnClickModal>

      {canOpen && (
        <TopTooltip content={open ? (t('Close') + ` ${title}`) : (t('Open') + ` ${title}`)}>
          <Button
            variant="link"
            onClick={() => setOpen(o => !o)}
            style={buttonStyle}
          >
            {open ? <CaretUpSquareFill /> : <CaretDownSquare />}
          </Button>
        </TopTooltip>
      )}
    </>
  );
};

const SectionProgress = ({ title, location, tresholdDate, filters, children }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [count, loading, error, /* headers */, update] = useAPI('GET', `/items/uninventoried/${location.code}/count`, {
    params: {
      tresholdDate,
      filters
    }
  });

  if (loading) {
    return (
      <div>
        <strong>{title}</strong> <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <strong>{title}</strong>: {t('Could not fetch inventoried count')}
      </div>
    );
  }

  const total = count.itemsTotal;

  const inventoried = count.itemsInventoried;
  const uninventoried = total - inventoried;

  const inventoriedPercent = Math.floor(100 * (inventoried / total));
  const uninventoriedPercent = 100 - inventoriedPercent;

  const inventoriedSpan = (
    <span>
      <strong>{inventoriedPercent}%</strong> ({inventoried} {inventoried === 1 ? t('item') : t('items')})
    </span>
  );

  const uninventoriedSpan = (
    <span>
      <strong>{100 - inventoriedPercent}%</strong> ({uninventoried} {uninventoried === 1 ? t('item') : t('items')})
    </span>
  );

  if (total === 0) {
    return (
      <div>
        <strong>{title}</strong>: {t('No items')}
      </div>
    );
  }

  return (
    <div>
      <strong>{title}</strong>

      <Authorized role="DEVELOPER">
        &nbsp;({count.queryTimeMs} ms)
      </Authorized>

      <SectionButtons
        uninventoried={uninventoried}
        update={update}
        title={title}
        canOpen={!!children}
        open={open}
        setOpen={setOpen}
        location={location}
        tresholdDate={tresholdDate}
        filters={filters}
      />

      <ProgressBar>
        <ProgressBar
          variant="danger"
          now={uninventoriedPercent === 0 ? 0 : Math.max(10, uninventoriedPercent)}
          label={uninventoriedSpan}
        />

        <ProgressBar
          variant="success"
          now={inventoriedPercent === 100 ? 100 : Math.min(90, inventoriedPercent)}
          label={inventoriedSpan}
        />
      </ProgressBar>

      {(uninventoried > 0 && open && children) && (
        <div style={{
          marginLeft: '10px',
          paddingLeft: '10px',
          borderLeft: '2px solid #d3d3d3'
        }}>
          {children}
        </div>
      )}
    </div>
  );
};

// Only handles classes *.4 at the moment
const GenreSections = ({ location, tresholdDate, mainClass, filters }) => {
  const { t } = useTranslation();

  // TODO use config
  const genres = {
    a: {
      1: ['JÄNNITYS', 'VIIHDE', 'SCIFI', 'FANTASIA', 'KAUHU', 'VITSIT', 'SOTA', 'ISOTEKSTISET', 'SELKOKIRJAT', 'JOULU', 'ÄÄNIKIRJAT', 'OPI SUOMEA', 'BESTSELLER'],
      2: ['SPÄNNING', 'UNDERHÅLLNING', 'JULEN', 'SKRÄCK', 'SCIFI', 'FANTASY', 'STORSTIL', 'LL-BÖCKER', 'LJUDBÖCKER'],
      4: ['THRILLER', 'ROMANCE', 'SCIFI', 'FANTASY', 'HORROR', 'LARGE PRINT', 'EASY READERS', 'AUDIOBOOKS'],
      8: ['JÄNNITYS', 'VIIHDE', 'SCIFI', 'FANTASIA', 'ÄÄNIKIRJAT']
    },
    l: {
      1: ['HELPPOLUKUISET', 'SELKOKIRJAT', 'SUURAAKKOSET', 'TAVUTETUT', 'JÄNNITYS', 'FANTASIA', 'HEVOSET', 'SCIFI', 'KAUHU', 'VITSIT', 'JOULU', 'ÄÄNIKIRJAT'],
      2: ['BÖRJA LÄSA', 'LL-BÖCKER', 'SCIFI', 'SKRÄCK', 'SPÄNNING', 'FANTASY', 'HÄSTAR', 'JULEN', 'LJUDBÖCKER'],
      4: ['EASY READERS', 'FANTASY', 'HORROR', 'SCIFI', 'THRILLER', 'CHRISTMAS', 'AUDIOBOOKS'],
      8: ['HELPPOLUKUISET', 'FANTASIA']
    },
    n: {
      1: ['FANTASIA', 'KAUHU', 'JOJO', 'JÄNNITYS', 'SCIFI', 'ÄÄNIKIRJAT'],
      2: ['LJUDBÖCKER', 'SKRÄCK', 'SPÄNNING', 'FANTASY', 'JOJO', 'SCIFI'],
      4: ['FANTASY', 'HORROR', 'SCIFI', 'THRILLER', 'AUDIOBOOKS'],
      8: ['ÄÄNIKIRJAT', 'FANTASIA']
    }
  }[location.code[3]][mainClass];

  if (typeof genres !== 'object' || !genres.length) {
    throw new Error(`GenreSections got invalid main class ${mainClass} for location ${location}!`);
  }

  if (genres.length === 0) return null;

  const sectionProgresses = genres.map(genre => (
    <SectionProgress
      key={genre}
      title={`${mainClass}.4 ${genre}`}
      location={location}
      tresholdDate={tresholdDate}
      filters={{
        ...filters,
        fiction: true,
        classification: `${mainClass}.4`,
        genre
      }}
    />
  ));

  return (
    <>
      <SectionProgress
        title={`${mainClass}.4 ${t('No genre')}`}
        location={location}
        tresholdDate={tresholdDate}
        filters={{
          ...filters,
          fiction: true,
          classification: `${mainClass}.4`,
          genre: null
        }}
      />

      {sectionProgresses}
    </>
  );
};

const Sections = ({ updateCount, selectedLocation, tresholdDate, materialTypes, languages }) => {
  const { t } = useTranslation();

  const [sections, loading, error] = useAPI('GET', `/locations/${selectedLocation}`, {}, [updateCount]);

  const filters = {
    materialTypes,
    languages
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch locations');
  }

  return sections.map(section => section.code.length === 5 && (
    <div key={section.code} style={{ marginBottom: '10px' }}>
      <SectionProgress
        title={section.name}
        location={section}
        tresholdDate={tresholdDate}
        filters={filters}
      >
        <SectionProgress
          title={t('Fiction')}
          location={section}
          tresholdDate={tresholdDate}
          filters={{ ...filters, fiction: true }}
        >
          {[...Array(8).keys()].map(i => i + 1).map(mainClass => (
            <SectionProgress
              key={mainClass}
              title={`${mainClass}.*`}
              location={section}
              tresholdDate={tresholdDate}
              filters={{
                ...filters,
                fiction: true,
                classification: `${mainClass}.%`
              }}
            >
              <SectionProgress
                title={`${mainClass}`}
                location={section}
                tresholdDate={tresholdDate}
                filters={{
                  ...filters,
                  fiction: true,
                  classification: `${mainClass}`
                }}
              />

              {[1, 2, 3, 4, 7, 79, 791, '8%'].map(subClass => (
                /* TODO: .79 subclasses for children, .791 manga/non-manga subclass */
                <SectionProgress
                  key={subClass}
                  title={`${mainClass}.${typeof subClass === 'string' ? subClass.replace(/%/g, '*') : subClass}`}
                  location={section}
                  tresholdDate={tresholdDate}
                  filters={{
                    ...filters,
                    fiction: true,
                    classification: `${mainClass}.${subClass}`
                  }}
                >
                  {(
                    [1, 2, 4, 8].includes(mainClass) &&
                    subClass === 4 &&
                    ['a', 'l', 'n'].includes(section.code[3])
                  ) && (
                    <GenreSections
                      location={section}
                      tresholdDate={tresholdDate}
                      mainClass={mainClass}
                      filters={filters}
                    />
                  )}
                </SectionProgress>
              ))}
            </SectionProgress>
          ))}

          <SectionProgress
            title="9.*"
            location={section}
            tresholdDate={tresholdDate}
            filters={{
              ...filters,
              fiction: true,
              classification: '9.%'
            }}
          />
        </SectionProgress>

        <SectionProgress
          title={t('Nonfiction')}
          location={section}
          tresholdDate={tresholdDate}
          filters={{ ...filters, fiction: false }}
        >
          {[...Array(10).keys()].map(mainClass => (
            <SectionProgress
              key={mainClass}
              title={`${mainClass}0-${mainClass}9`}
              location={section}
              tresholdDate={tresholdDate}
              filters={{
                ...filters,
                fiction: false,
                classification: `${mainClass}%`
              }}
            >
              {[...Array(10).keys()].map(subClass => (
                <SectionProgress
                  key={subClass}
                  title={`${mainClass}${subClass}.*`}
                  location={section}
                  tresholdDate={tresholdDate}
                  filters={{
                    ...filters,
                    fiction: false,
                    classification: `${mainClass}${subClass}%`
                  }}
                />
              ))}
            </SectionProgress>
          ))}
        </SectionProgress>
      </SectionProgress>
    </div>
  ));
};

const UninventoriedSegments = () => {
  const [updateCount, setUpdateCount] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [tresholdDate, setTresholdDate] = useState(null);
  const [materialTypes, setMaterialTypes] = useState(null);
  const [languages, setLanguages] = useState(null);

  const onFormSubmit = ({
    selectedLocation,
    tresholdDate,
    materialTypes: newMaterialTypes,
    languages
  }) => {
    setSelectedLocation(selectedLocation);
    setTresholdDate(tresholdDate);
    setLanguages(languages.length === 0 ? null : languages);
    setUpdateCount(c => c + 1);

    setMaterialTypes(
      newMaterialTypes.length === 0 ||
      newMaterialTypes.length === Object.keys(sierraGlobals.materialTypes).length
        ? null
        : newMaterialTypes
    );
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <FiltersForm onSubmit={onFormSubmit} />
      </div>

      {(selectedLocation !== null && tresholdDate !== null) && (
        <div style={{ paddingBottom: '20px' }}>
          <Sections
            updateCount={updateCount}
            selectedLocation={selectedLocation}
            tresholdDate={tresholdDate}
            materialTypes={materialTypes}
            languages={languages}
          />
        </div>
      )}
    </>
  );
};

export default UninventoriedSegments;
