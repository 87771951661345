import { Heading, Alert, useMargins } from '../common/layout';
import { T, Tp } from '../common/i18n';

import { EmailChangeForm, PasswordChangeForm } from '.';

const Divider: React.FC = () =>
  <hr style={useMargins(0, 0, 25, 25)} />;

// TODO combine username and email into single form
const AccountSettings: React.FC = () => (
  <>
    <Heading style={useMargins(0, 0, 0, 10)}>
      <T>Change Password</T>
    </Heading>

    <PasswordChangeForm />

    <Divider />

    <Alert
      variant="warning"
      heading={<T>Notice</T>}
    >
      <Tp>You will be logged out after a successful email address change.</Tp>
      <Tp>You can then log back in using your username or new email address.</Tp>
    </Alert>

    {/*
    <Heading style={useMargins(0, 0, 0, 10)}>
      <T>Change Username</T>
    </Heading>

    <UsernameChangeForm />

    <Divider />
    */}

    <Heading style={useMargins(0, 0, 0, 10)}>
      <T>Change Email Address</T>
    </Heading>

    <EmailChangeForm />
  </>
);

export default AccountSettings;
