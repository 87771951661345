import { useState, useEffect, useRef, useMemo } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useTranslation } from 'react-i18next';

import config from '../config';

const debug = false;

const useMassreader = (readerName, onTagRead, onlyCallbackUniqueTags = false, tagsReadCallback = null) => {
  const { t } = useTranslation();

  const idSet = useRef(new Set());
  const mounted = useRef(true);

  const removeTag = tagId => idSet.current.delete(tagId);

  const {
    sendJsonMessage,
    lastJsonMessage,
    readyState
  } = useWebSocket(`${config.api.webSocketUrl}/massreader/listen`, {
    retryOnError: true,
    shouldReconnect: event => mounted,
    reconnectAttempts: 1000,
    reconnectInterval: 5000 // 5 seconds
  });

  const connectionStatuses = useMemo(() => ({
    [ReadyState.CONNECTING]: t('CONNECTING...'),
    [ReadyState.OPEN]: t('OPEN'),
    [ReadyState.CLOSING]: t('CLOSING...'),
    [ReadyState.CLOSED]: t('CLOSED'),
    [ReadyState.UNINSTANTIATED]: t('UNINSTANTIATED')
  }), [t]);

  const [connectionStatus, setConnectionStatus] = useState(connectionStatuses[readyState]);

  useEffect(() => {
    setConnectionStatus(connectionStatuses[readyState]);

    if (readyState !== ReadyState.OPEN) {
      return;
    }

    // eslint-disable-next-line no-console
    if (debug) console.log('useMassreader: SET_READER', readerName);

    sendJsonMessage({
      type: 'SET_READER',
      readerName: readerName ?? 'none'
    });
  }, [readerName, sendJsonMessage, readyState, connectionStatuses]);

  const onTagsRead = async tags => {
    // eslint-disable-next-line no-console
    if (debug) console.log('useMassreader:', tags.length, 'tags read');

    let tagIndex = 0;

    if (typeof tagsReadCallback === 'function') {
      await tagsReadCallback(tags);
    }

    for (const tag of tags) {
      if (!idSet.current.has(tag.tagId)) {
        idSet.current.add(tag.tagId);

        await onTagRead(tag, true, tagIndex++);
      } else if (!onlyCallbackUniqueTags) {
        await onTagRead(tag, false, tagIndex++);
      }
    }
  };

  useEffect(() => {
    if (!lastJsonMessage) return;

    if (lastJsonMessage.type === 'TAGS_READ') {
      onTagsRead(lastJsonMessage.tags);
    } else if (lastJsonMessage.type === 'PONG') {
      // eslint-disable-next-line no-console
      if (debug) console.log('useMassreader: (pong)');
    } else {
      // eslint-disable-next-line no-console
      if (debug) console.log('useMassreader received:', lastJsonMessage);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  // Unmounting for reader
  useEffect(() => () => { mounted.current = false; }, []);

  // Websocket pings
  useEffect(() => {
    const interval = setInterval(() => {
      // eslint-disable-next-line no-console
      if (debug) console.log('useMassreader: (ping)');

      sendJsonMessage({ type: 'PING' });
    }, 20 * 1000);

    return () => clearInterval(interval);
  }, [sendJsonMessage]);

  return [sendJsonMessage, removeTag, connectionStatus];
};

export default useMassreader;
