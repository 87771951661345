import useToast from './useToast';

import ToastView from './View';

import type { ContentType } from '..';

export {
  useToast,

  ToastView
};

interface CommonToast {
  id: string;
  title?: string;
  showCloseButton?: boolean;
  autoCloseSeconds?: number;
}

interface InfoToast {
  contentType?: ContentType;
  content: string;
}

type ToastContent =
  | { type: 'info' } & InfoToast;

export type Toast = CommonToast & ToastContent;
