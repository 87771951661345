import { useTranslation } from 'react-i18next';

import { Form, Row, Col } from 'react-bootstrap';

import MultiLibrarySelector from '../forms/MultiLibrarySelector';

const groupStyle = () => ({ marginBottom: '4px' });

const colStyle = (zeroPadding = false) => {
  if (!zeroPadding) return { paddingLeft: 0 };

  return {
    paddingLeft: 0,
    paddingRight: '4px'
  };
};

const FilteringTypeButtonCol = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <Col xs="auto" style={colStyle(true)}>
      <Form.Control
        size="sm"
        as="select"
        value={value}
        onChange={event => onChange(event.target.value)}
      >
        <option value="is">{t('is')}</option>
        <option value="not">{t('is not')}</option>
      </Form.Control>
    </Col>
  );
};

export const ItemStatusFilter = ({ rules, setRules }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} style={groupStyle()}>
      <Form.Label xs="auto" column="sm">
        {t('Item Status')}
      </Form.Label>

      <FilteringTypeButtonCol
        value={rules.itemStatus.type}
        onChange={type => setRules(r => ({ ...r, itemStatus: { ...r.itemStatus, type } }))}
      />

      <Col xs="auto" style={colStyle()}>
        <Form.Control
          size="sm"
          value={rules.itemStatus.values.join(',')}
          onChange={event => setRules(r => ({
            ...r,
            itemStatus: { ...r.itemStatus, values: event.target.value.split(',') }
          }))}
        />
      </Col>
    </Form.Group>
  );
};

export const CityFilter = ({ rules, setRules }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} style={groupStyle()}>
      <Form.Label xs="auto" column="sm">
        {t('City')}
      </Form.Label>

      <FilteringTypeButtonCol
        value={rules.city.type}
        onChange={type => setRules(r => ({ ...r, city: { ...r.city, type } }))}
      />

      <Col xs="auto" style={colStyle()}>
        <Form.Control
          size="sm"
          value={rules.city.values.join(',')}
          onChange={event => setRules(r => ({
            ...r,
            city: { ...r.city, values: event.target.value.split(',') }
          }))}
        />
      </Col>
    </Form.Group>
  );
};

export const LocationFilter = ({ rules, setRules }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} style={groupStyle()}>
      <Form.Label xs="auto" column="sm">
        {t('Location')}
      </Form.Label>

      <FilteringTypeButtonCol
        value={rules.location.type}
        onChange={type => setRules(r => ({ ...r, location: { ...r.location, type } }))}
      />

      <Col xs="auto" style={colStyle()}>
        <Form.Control
          size="sm"
          value={rules.location.values.join(',')}
          onChange={event => setRules(r => ({
            ...r,
            location: { ...r.location, values: event.target.value.split(',') }
          }))}
        />
      </Col>
    </Form.Group>
  );
};

export const CallNumberFilter = ({ rules, setRules }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} style={groupStyle()}>
      <Form.Label xs="auto" column="sm">
        {t('Call Number')}
      </Form.Label>

      <FilteringTypeButtonCol
        value={rules.callNumber.type}
        onChange={type => setRules(r => ({ ...r, callNumber: { ...r.callNumber, type } }))}
      />

      <Col xs="auto" style={colStyle()}>
        <Form.Control
          size="sm"
          value={rules.callNumber.values.join(',')}
          onChange={event => setRules(r => ({
            ...r,
            callNumber: { ...r.callNumber, values: event.target.value.split(',') }
          }))}
        />
      </Col>
    </Form.Group>
  );
};

export const GenreFilter = ({ rules, setRules }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} style={groupStyle()}>
      <Form.Label xs="auto" column="sm">
        {t('Genre')}
      </Form.Label>

      <FilteringTypeButtonCol
        value={rules.genre.type}
        onChange={type => setRules(r => ({ ...r, genre: { ...r.genre, type } }))}
      />

      <Col xs="auto" style={colStyle()}>
        <Form.Control
          size="sm"
          value={rules.genre.values.join(',')}
          onChange={event => setRules(r => ({
            ...r,
            genre: { ...r.genre, values: event.target.value.split(',') }
          }))}
        />
      </Col>
    </Form.Group>
  );
};

export const LanguageFilter = ({ rules, setRules }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} style={groupStyle()}>
      <Form.Label xs="auto" column="sm">
        {t('Language')}
      </Form.Label>

      <FilteringTypeButtonCol
        value={rules.language.type}
        onChange={type => setRules(r => ({ ...r, language: { ...r.language, type } }))}
      />

      <Col xs="auto" style={colStyle()}>
        <Form.Control
          size="sm"
          value={rules.language.values.join(',')}
          onChange={event => setRules(r => ({
            ...r,
            language: { ...r.language, values: event.target.value.split(',') }
          }))}
        />
      </Col>
    </Form.Group>
  );
};

export const ItemTypeFilter = ({ rules, setRules }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} style={groupStyle()}>
      <Form.Label xs="auto" column="sm">
        {t('Item Type')}
      </Form.Label>

      <FilteringTypeButtonCol
        value={rules.itemType.type}
        onChange={type => setRules(r => ({
          ...r,
          itemType: { ...r.itemType, type }
        }))}
      />

      <Col xs="auto" style={colStyle()}>
        <Form.Control
          size="sm"
          value={rules.itemType.values.join(',')}
          onChange={event => setRules(r => ({
            ...r,
            itemType: {
              ...r.itemType,
              values: event.target.value
                .replace(/,,+/g, ',')
                .split(',')
                .map(v => v.replace(/[^0-9]/g, '').slice(0, 3))
                .filter(v => v.length === 0 || !isNaN(Number(v)))
                .map(v => v.length === 0 ? '' : Number(v))
            }
          }))}
        />
      </Col>
    </Form.Group>
  );
};

export const NotScannedAfterFilter = ({ rules, setRules, notScannedAfter, setNotScannedAfter }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} style={groupStyle()}>
      <Form.Label xs="auto" column="sm">
        {t('Not Scanned After')}
      </Form.Label>

      <Col xs="auto" style={colStyle()}>
        <Form.Control
          size="sm"
          type="date"
          defaultValue={notScannedAfter}
          onChange={event => setNotScannedAfter(event.target.value)}
        />
      </Col>
    </Form.Group>
  );
};

const CirculationFilterOperators = ({ name, defaultValue }) => {
  const { t } = useTranslation();

  return (
    <Form.Control
      size="sm"
      as="select"
      name={name}
      defaultValue={defaultValue}
    >
      <option value=">=">
        {t('over')}
      </option>

      <option value="<=">
        {t('under')}
      </option>
    </Form.Control>
  );
};

export const CirculationFilter = ({ rules, setRules }) => {
  const { t } = useTranslation();

  const onChange = ({ target }) => {
    const circulation = { ...rules.circulation };

    if (target.name === 'groupOperator') circulation.group = target.value;
    else if (target.name === 'operatorThisYear') circulation.thisYear.operator = target.value;
    else if (target.name === 'operatorLastYear') circulation.lastYear.operator = target.value;
    else if (target.name === 'numberThisYear') circulation.thisYear.value = target.value;
    else if (target.name === 'numberLastYear') circulation.lastYear.value = target.value;
    else throw new Error(`Invalid target name ${target.name}`);

    setRules({ ...rules, circulation });
  };

  return (
    <Form.Group as={Row} style={groupStyle()} onChange={onChange}>
      <Form.Label xs="auto" column="sm">
        {t('Circulation')}
      </Form.Label>

      <Col xs="auto" style={colStyle(true)}>
        <CirculationFilterOperators
          name="operatorThisYear"
          defaultValue={rules.circulation.thisYear.operator}
        />
      </Col>

      <Col xs="auto" style={colStyle(true)}>
        <Form.Control
          size="sm"
          type="number"
          name="numberThisYear"
          defaultValue={rules.circulation.thisYear.value}
          style={{ maxWidth: '80px' }}
        />
      </Col>

      <Form.Label xs="auto" column="sm" style={colStyle()}>
        {t('this year')}
      </Form.Label>

      <Col xs="auto" style={colStyle()}>
        <Form.Control
          size="sm"
          as="select"
          name="groupOperator"
          defaultValue={rules.circulation.group}
        >
          <option value="AND">
            {t('and')}
          </option>

          <option value="OR">
            {t('or')}
          </option>
        </Form.Control>
      </Col>

      <Col xs="auto" style={colStyle(true)}>
        <CirculationFilterOperators
          name="operatorLastYear"
          defaultValue={rules.circulation.lastYear.operator}
        />
      </Col>

      <Col xs="auto" style={colStyle(true)}>
        <Form.Control
          size="sm"
          type="number"
          name="numberLastYear"
          defaultValue={rules.circulation.lastYear.value}
          style={{ maxWidth: '80px' }}
        />
      </Col>

      <Form.Label xs="auto" column="sm" style={colStyle()}>
        {t('last year')}
      </Form.Label>
    </Form.Group>
  );
};

export const FloatableFilter = ({ rules, setRules }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} style={groupStyle()}>
      <Form.Label xs="auto" column="sm">
        {t('Floatable')}
      </Form.Label>

      <Form.Label xs="auto" column="sm" style={colStyle(true)}>
        {t('Copies in own location greater than')}
      </Form.Label>

      <Col xs="auto" column="sm" style={colStyle(true)}>
        <Form.Control
          size="sm"
          type="number"
          min="0"
          defaultValue={rules.floatable.minCopiesInOwnLocation}
          style={{ maxWidth: '80px' }}
          onChange={event => setRules(rules => ({
            ...rules,
            floatable: {
              ...rules.floatable,
              minCopiesInOwnLocation: Number(event.target.value)
            }
          }))}
        />
      </Col>

      <Form.Label xs="auto" column="sm" style={colStyle(true)}>
        , {t('in target location less than')}
      </Form.Label>

      <Col xs="auto" column="sm" style={colStyle(true)}>
        <Form.Control
          size="sm"
          type="number"
          min="0"
          defaultValue={rules.floatable.maxCopiesInTargetLocation}
          style={{ maxWidth: '80px' }}
          onChange={event => setRules(rules => ({
            ...rules,
            floatable: {
              ...rules.floatable,
              maxCopiesInTargetLocation: Number(event.target.value)
            }
          }))}
        />
      </Col>

      <Form.Label xs="auto" column="sm" style={colStyle(true)}>
        , {t('target locations')}:
      </Form.Label>

      <MultiLibrarySelector
        defaultValue={rules.floatable.targetLocations}
        onChange={selected => setRules(rules => ({
          ...rules,
          floatable: {
            ...rules.floatable,
            targetLocations: selected
          }
        }))}
      />
    </Form.Group>
  );
};

export const ZeroesFilter = ({ rules, setRules }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} style={groupStyle()}>
      <Form.Label xs="auto" column="sm">
        {t('Last checkout before')}
      </Form.Label>

      <Col xs="auto" style={colStyle()}>
        <Form.Control
          size="sm"
          type="date"
          defaultValue={rules.zeroesDate}
          onChange={event => setRules(rules => ({
            ...rules,
            zeroesDate: new Date(event.target.value)
          }))}
        />
      </Col>
    </Form.Group>
  );
};
