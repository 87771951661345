import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getSession } from '../../services/reception';
import useAPI from '../../hooks/useAPI';
import useQueryParamState from '../../hooks/useQueryParamState';
import api from '../../services/api';

import { Button, Form } from 'react-bootstrap';

import ReceptionSessionView from '../orders/reception/ReceptionSessionView';
import OnClickModal from '../layout/OnClickModal';
import MassreaderSelector from '../api/MassreaderSelector';
import Loading from '../api/Loading';
import EditButton from '../api/EditButton';

const SessionSelectorForm = ({ sessions, sessionId, setSessionId, setSession, children }) => {
  const { t } = useTranslation();

  const onChange = event => {
    const id = Number(event.target.value);

    for (const session of sessions) {
      if (session.id === id) {
        setSessionId(id);

        return setSession(session);
      }
    }
  };

  const options = sessions.slice().reverse().map(session => (
    <option key={session.id} value={session.id}>
      {session.name} {session.saved || `(${t('new')})`}
    </option>
  ));

  return (
    <Form inline>
      <Form.Label>
        {t('Session')}
      </Form.Label>

      <Form.Control
        as="select"
        size="sm"
        onChange={onChange}
        defaultValue={sessionId}
        style={{ marginLeft: '6px' }}
      >
        {options}
      </Form.Control>

      {children}
    </Form>
  );
};

const RenameSessionButton = ({ session, setSession, saveSession }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = event => {
    event.preventDefault();

    const sessionName = event.target.sessionName.value;

    if (sessionName.length === 0) return setError(t('Session name cannot be empty!'));
    else if (sessionName.length > 64) return setError(t('Session name too long!'));

    const newSession = {
      ...session,
      name: sessionName
    };

    setSession(newSession);
    saveSession(newSession);

    setForceClose(true);
  };

  return (
    <OnClickModal
      title={t('Rename Session')}
      button={<EditButton size={20} style={{ marginLeft: '8px' }} />}
      onOpen={() => setForceClose(false)}
      forceClose={forceClose}
      closeButton
    >
      <Form onSubmit={onSubmit}>
        <Form.Control
          size="sm"
          name="sessionName"
          defaultValue={session.name}
        />

        <Button
          type="submit"
          variant="primary"
          size="sm"
          style={{ marginTop: '6px' }}
        >
          {t('Save')}
        </Button>

        <div style={{ color: 'red', marginTop: '6px' }}>
          {error}
        </div>
      </Form>
    </OnClickModal>
  );
};

const ReceptionPage = () => {
  const { t } = useTranslation();

  const [sessionId, setSessionId] = useQueryParamState('session', null, 'number');
  const [reader, setReader] = useQueryParamState('reader', null);

  const [session, setSession] = useState(null);
  const [sessions, setSessions] = useState(null);

  const [apiSessions, loading, error] = useAPI('GET', '/reception/sessions');

  useEffect(() => {
    if (apiSessions === null) return;

    getSession().then(session => {
      const _sessions = [
        ...apiSessions.slice().reverse().map(apiSession => ({ ...apiSession, saved: true })),
        { ...session, saved: false }
      ];

      setSessions(_sessions);

      if (sessionId === null) {
        setSessionId(session.id);
        setSession(session);
      } else {
        for (const s of _sessions) {
          if (s.id === sessionId) {
            return setSession(s);
          }
        }

        const newSession = _sessions[_sessions.length - 1];

        setSessionId(newSession.id);
        setSession(newSession);
      }
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSessions]);

  const saveSession = async newSession => {
    if (!newSession) newSession = session;

    // Save session to backend
    await api.post('/reception/sessions', {
      id: newSession.id,
      name: newSession.name
    });

    setSessions(s => s.map(_s => {
      if (newSession.id === _s.id) return { ..._s, name: newSession.name, saved: true };
      else return _s;
    }));

    if (session.id === newSession.id) {
      setSession(s => ({ ...s, saved: true }));
    }
  };

  if (loading || sessionId === null || session === null || sessions === null) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch reception sessions');
  }

  return (
    <>
      <div style={{ marginTop: '10px', marginBottom: '4px' }}>
        <SessionSelectorForm
          sessions={sessions}
          sessionId={sessionId}
          setSessionId={setSessionId}
          setSession={setSession}
        >
          <RenameSessionButton
            session={session}
            setSession={setSession}
            saveSession={saveSession}
          />
        </SessionSelectorForm>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <MassreaderSelector
          reader={reader}
          setReader={setReader}
        />
      </div>

      {session && <ReceptionSessionView session={session} saveSession={saveSession} reader={reader} />}
    </>
  );
};

export default ReceptionPage;
