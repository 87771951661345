import { Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Routes from './Routes';

import { useDarkMode } from '../../../application';

const AppContainer = ({ navbarHeight, darkMode }) => {
  useDarkMode();

  const containerMarginTop = 10;
  const containerHeight = window.innerHeight - navbarHeight - containerMarginTop * 2;

  return (
    <Container style={{ marginTop: `${containerMarginTop}px` }} fluid>
      <Switch>
        <Routes containerHeight={containerHeight} />
      </Switch>
    </Container>
  );
};

export default AppContainer;
