import Checkable from './Checkable';
import Checkbox from './Checkbox';
import Text from './Text';
import TextArray from './TextArray';
import Number from './Number';
import Textarea from './Textarea';
import Radio from './Radio';
import Select from './Select';
import Multiselect from './Multiselect';
import Password from './Password';

import type { FormItem, OnChangeElement } from '..';

export {
  Checkable,
  Checkbox,
  Text,
  TextArray,
  Number,
  Textarea,
  Radio,
  Select,
  Multiselect,
  Password
};

export interface FormElementProps {
  formItem: FormItem;
  onChange?: OnChangeElement;
}
