import { useT } from '../../common/i18n';

import { useCommons } from '../../layout/forms';
import { useOrderPriorityFormItem } from '.';

import type { LinkGroup } from '../../api/models';
import type { FormItem } from '../../layout/forms';

const useLinkGroupForm = (group?: LinkGroup): FormItem[] => {
  const t = useT();

  const commons = useCommons();

  const visibility: FormItem = {
    type: 'select',
    name: 'visibility',
    label: t('Visibility'),
    options: [
      /*
      // TODO
      {
        value: 'private',
        label: t('Private')
      },
      */
      {
        value: 'selectedLocations',
        label: t('Selected locations')
      },
      {
        value: 'global',
        label: t('All locations')
      }
    ],
    defaultValue: group?.private
      ? 'private'
      : group?.global
        ? 'global'
        : 'selected-locations'
  };

  return [
    commons.nameRequired(group?.name),
    commons.description(group?.description),
    commons.keywords(group?.keywords),
    commons.appLocations(group?.locations),
    visibility,
    useOrderPriorityFormItem(group?.orderPriority)
  ];
};

export default useLinkGroupForm;
