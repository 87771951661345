import { Spinner } from 'react-bootstrap';

import { T } from '../i18n';

const Loading: React.FC<{
  text?: string
}> = ({ text }) => (
  <>
    <Spinner size="sm" animation="border" />

    &nbsp;

    {text ?? <T>Loading...</T>}
  </>
);

export default Loading;
