import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { hasRole } from '../../services/auth';
import { markAsMissing, setItemStatus } from '../../services/item';
import sierraGlobals from '../../sierraGlobals';

import { Button, Row, Col } from 'react-bootstrap';
import { QuestionDiamond, Tools } from 'react-bootstrap-icons';

import ClickSelect from '../api/ClickSelect';
import BibTitleAndAuthor from '../bibs/BibTitleAndAuthor';
import LastItemScanDetails from '../items/LastItemScanDetails';
import ItemEditor from '../items/ItemEditor';
import OnClickModal from '../layout/OnClickModal';
import Signum from './Signum';
import TopTooltip from '../layout/TopTooltip';
import EditButton from '../api/EditButton';

const ItemDescription = ({ item, update, onEdit, noEditButton, showMissingButton }) => {
  const { t } = useTranslation();

  const [forceCloseEditor, setForceCloseEditor] = useState(false);

  const editButton = (
    <EditButton
      verticalAlign="baseline"
      size={24}
      noMargin
    />
  );

  const itemEditor = hasRole('EDIT-ITEMS') && (
    <OnClickModal
      button={editButton}
      title={t('Edit Item')}
      forceClose={forceCloseEditor}
      onOpen={() => setForceCloseEditor(false)}
      closeButton
    >
      <ItemEditor
        item={item}

        barcode
        itemStatus
        callNumber
        location

        onSave={changes => {
          setForceCloseEditor(true);

          if (typeof update === 'function') update();
          if (typeof onEdit === 'function') onEdit(item, changes);
        }}
      />
    </OnClickModal>
  );

  const markAsMissingButton = (
    <TopTooltip content={t('Mark as missing')}>
      <Button
        variant="link"
        size="sm"

        style={{
          verticalAlign: 'baseline',
          padding: 0,
          border: 0
        }}

        onClick={async () => {
          await markAsMissing(item.sierraItemRecordId);
          onEdit(item, { itemStatus: true });
          update();
        }}
      >
        <QuestionDiamond size={24} />
      </Button>
    </TopTooltip>
  );

  const markRepairButton = (
    <TopTooltip content={`${t('Set item status to')} ${t('KORJATTAVANA')}`}>
      <Button
        variant="link"
        size="sm"

        style={{
          verticalAlign: 'baseline',
          padding: 0,
          border: 0
        }}

        onClick={async () => {
          await setItemStatus(item.sierraItemRecordId, 'k');
          onEdit(item, { itemStatus: true });
          update();
        }}
      >
        <Tools size={24} />
      </Button>
    </TopTooltip>
  );

  const colStyle = { paddingRight: 0 };

  if (!item.Bib) return t('Invalid bib');

  return (
    <>
      <div style={{ marginBottom: '6px' }}>
        <Row>
          {noEditButton || (
            <Col xs="auto" style={colStyle}>{itemEditor}</Col>
          )}

          {showMissingButton && (
            <Col xs="auto" style={colStyle}>{markAsMissingButton}</Col>
          )}

          {showMissingButton && (
            <Col xs="auto" style={colStyle}>{markRepairButton}</Col>
          )}

          <Col xs="auto" style={{ ...colStyle, paddingLeft: '4px' }}>
            <Signum
              itemId={item.sierraItemRecordId}
              height="2rem"
              loading={item.callNumber}
              noPrinting
            />
          </Col>
        </Row>
      </div>

      <BibTitleAndAuthor bib={item.Bib} />

      <div style={{ marginTop: '10px' }}>
        {item.location && item.location.name}
      </div>

      <div>
        {item.itemType && item.itemType.description.replace(/ [0-9]+$/, '')}
        {(item.fixedFieldItemStatus !== undefined && item.fixedFieldItemStatus !== '-') && `, ${sierraGlobals.itemStatuses[item.fixedFieldItemStatus]}`}
        {item.Bib.marcBindingInfo && `, ${item.Bib.marcBindingInfo}`}
        {item.genre ? `, ${item.genre}` : `, ${t('no genre')}`}
      </div>

      {item.Bib.marcSeriesInfo && (
        <div>
          <strong>{t('Series')}:</strong> {item.Bib.marcSeriesInfo}
        </div>
      )}
      {item.Bib.marcSeriesInfo2 && (
        <div>
          <strong>{t('Series')}:</strong> {item.Bib.marcSeriesInfo2}
        </div>
      )}

      {item.holdCount > 0 && (
        <div style={{ marginTop: '10px', color: 'var(--danger)' }}>
          <strong>
            {t('Item has a hold')}
          </strong>
        </div>
      )}

      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        {
          item.LastItemScan
            ? <LastItemScanDetails lastItemScan={item.LastItemScan} />
            : <span style={{ color: 'var(--danger)' }}>{t('This item has never been scanned.')}</span>
        }
      </div>

      <ClickSelect pre>
        {item.barcode}
      </ClickSelect>
    </>
  );
};

export default ItemDescription;
