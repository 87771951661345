import { T } from '../common/i18n';

import type { PagedTitle, PagedTitleEvent } from '../api/models';

const parseEvents = (events: PagedTitleEvent[]): object =>
  events.map((event: PagedTitleEvent) => ({
    ...event,
    dataJson: JSON.parse(event.dataJson ?? '')
  }));

const EventsJSON: React.FC<{
  pagedTitle: PagedTitle
}> = ({ pagedTitle }) => (
  <>
    <strong>
      <T>Events</T>
    </strong>

    <pre>
      {JSON.stringify(pagedTitle?.PagedTitleEvents ? parseEvents(pagedTitle.PagedTitleEvents) : null, null, 2)}
    </pre>
  </>
);

export default EventsJSON;
