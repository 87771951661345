import { useTranslation } from 'react-i18next';

import useAPI from '../../hooks/useAPI';
import sierraGlobals from '../../sierraGlobals';

import { Chart } from 'react-charts';

import Loading from '../api/Loading';

const HoldShelfChart = ({ pageHeight }) => {
  const { t } = useTranslation();

  const dateString = new Date().toISOString().split('T')[0];
  const showHoldShelfTotal = false;

  const [statistics, loading, error] = useAPI('GET', '/holdshelf/statistics', {
    params: {
      date: new Date(dateString).getTime()
    }
  }, [dateString]);

  if (loading) return <Loading />;
  else if (error) return t('Could not fetch statistics');
  else {
    const data = [];

    if (showHoldShelfTotal) {
      data.push({
        label: t('Total amount of items in hold shelf'),
        data: sierraGlobals.ownLocations.map(location => {
          if (statistics[location]) return [location, statistics[location].totalHoldShelfItems];
          else return [location, 0];
        })
      });
    }

    data.push({
      label: t('New items'),
      data: sierraGlobals.ownLocations.map(location => {
        if (statistics[location]) return [location, statistics[location].clearNewItemsTotal];
        else return [location, 0];
      })
    });

    data.push({
      label: t('Items carried over from previous days'),
      data: sierraGlobals.ownLocations.map(location => {
        if (statistics[location]) return [location, statistics[location].clearItemsCarriedOver];
        else return [location, 0];
      })
    });

    data.push({
      label: t('Cleared items'),
      data: sierraGlobals.ownLocations.map(location => {
        if (statistics[location]) {
          return [location, statistics[location].clearItemsScanned + statistics[location].clearItemsCheckedOut];
        } else {
          return [location, 0];
        }
      })
    });

    data.push({
      label: t('Marked as missing'),
      data: sierraGlobals.ownLocations.map(location => {
        if (statistics[location]) {
          return [location, statistics[location].clearItemsMarkedAsMissing];
        } else {
          return [location, 0];
        }
      })
    });

    const chartHeight = pageHeight - 100; // Extra padding for vertical scroll bar

    return (
      <div style={{ width: '100%', height: `${chartHeight}px` }}>
        <Chart
          data={data}
          tooltip

          series={{
            type: 'bar'
          }}

          axes={[
            { primary: true, type: 'ordinal', position: 'bottom' },
            { type: 'linear', position: 'left' }
          ]}
        />
      </div>
    );
  }
};

export default HoldShelfChart;
