import { useTranslation } from 'react-i18next';

import useQueryParamState from '../../hooks/useQueryParamState';
import { isSearchTermAllowed } from '../../services/search';

import BibTable from '../bibs/BibTable';

const InvalidSearchTerm = ({ query }) => {
  const { t } = useTranslation();

  return (
    <>
      <strong>{t('Invalid search term')}:</strong> {query}
    </>
  );
};

const BibSearchPage = () => {
  const [query, setQuery] = useQueryParamState('query', null, 'string');

  if (!isSearchTermAllowed(query)) {
    return <InvalidSearchTerm query={query} />;
  }

  return (
    <BibTable
      query={query}
      setQuery={setQuery}
      simple
      enableQueryParams
      enableQuickFilters
      enableFullFilters
      isSearchResults
    />
  );
};

export default BibSearchPage;
