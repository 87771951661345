import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';

const AccountConfirmDelete = ({ account, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <>
      <strong>
        {t('Are you sure you want to permanently delete this account?')}
      </strong>

      <div style={{ marginTop: '10px' }}>
        <div>
          <strong>{t('Username')}:</strong> {account.username}
        </div>

        <div>
          <strong>{t('Location')}:</strong> {account.location}
        </div>
      </div>

      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        {t('Anyone currently signed in with this account will be able to continue using the site until their login expires.')}
      </div>

      <Button variant="primary" size="sm" style={{ marginRight: '4px' }} onClick={onConfirm}>
        {t('Delete')}
      </Button>

      <Button variant="secondary" size="sm" onClick={onCancel}>
        {t('Cancel')}
      </Button>
    </>
  );
};

export default AccountConfirmDelete;
