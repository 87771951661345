import { T } from '../../common/i18n';

import { useGet, APIWrap } from '../../api';
import { MassUpdateOperationsList } from '.';

import type { MassUpdateOperation } from '../../api/models';

const NoActiveOperations: React.FC = () =>
  <T>No active operations currently in progress.</T>;

// TODO
const MassUpdateView: React.FC = () => {
  const { data: operations, loading, error } = useGet<MassUpdateOperation[]>('/sierra/mass-updates');

  return (
    <APIWrap loading={loading} error={error}>
      {(operations && operations.length > 0)
        ? <MassUpdateOperationsList operations={operations} />
        : <NoActiveOperations />}
    </APIWrap>
  );
};

export default MassUpdateView;
