import { useQueryParams } from '../../common/navigation';
import { asNumber } from '../../common/helpers';

import type { SortOrder } from '.';

interface ReturnType {
  queryPage: number | null;
  queryPageSize: number | null;
  querySortField: string | null;
  querySortOrder: SortOrder | null;
}

const useTableQueryParams = (enabled: boolean): ReturnType => {
  const queryParams = useQueryParams();

  let queryPage = null;
  let queryPageSize = null;
  let querySortField = null;
  let querySortOrder = null;

  if (enabled) {
    queryPage = asNumber(queryParams.get('page') ?? '');
    queryPageSize = asNumber(queryParams.get('pageSize') ?? '');

    querySortField = queryParams.get('sortField');
    querySortOrder = queryParams.get('sortOrder');

    if (typeof querySortOrder === 'string' && querySortOrder !== 'asc' && querySortOrder !== 'desc') {
      querySortOrder = null;
    }
  }

  return {
    queryPage,
    queryPageSize,
    querySortField,
    querySortOrder
  };
};

export default useTableQueryParams;
