import { useT } from '../common/i18n';

import { MassUpdatePage, ItemAllocationsPage } from '../pages';
import { BibTable } from '../bibs';
import { UserTable, AccountSettings } from '../users';
import { Sandbox } from '../developer';
import { Dashboard } from '.';

import type { Route } from '.';

const useBibRoutes = (): Route[] => {
  const t = useT();

  return [
    {
      path: '/bibs/browse',
      content: <BibTable enableQueryParams />,
      role: 'DEVELOPER',
      title: t('Browse Bibs')
    }
  ];
};

const useRoutes = (): Route[] => {
  const t = useT();

  return [
    ...useBibRoutes(),
    {
      path: '/account',
      content: <AccountSettings />,
      role: 'USER',
      roleNot: 'SHARED',
      title: t('My Account'),
      titleHidden: true,
      container: true
    },
    {
      path: '/users',
      content: <UserTable enableQueryParams />,
      role: 'DEVELOPER',
      title: t('Users')
    },
    {
      path: '/mass-updates',
      content: <MassUpdatePage />,
      role: 'DEVELOPER',
      title: t('Mass Updates'),
      container: true
    },
    {
      path: '/developer/sandbox',
      content: <Sandbox />,
      role: 'DEVELOPER',
      title: t('Sandbox')
    },
    {
      path: '/',
      content: <Dashboard />,
      role: 'USER',
      exact: true,
      title: t('Front Page'),
      titleHidden: true
    },
    {
      path: '/developer/item-allocations',
      content: <ItemAllocationsPage />,
      role: 'DEVELOPER',
      title: t('Item Allocations')
    }
  ];
};

export default useRoutes;
