import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import paginationFactory from '@ounai/react-bootstrap-table2-paginator';

import { hasRole } from '../../services/auth';
import expandRow from '../../services/expandRow';
import useQueryParamState from '../../hooks/useQueryParamState';

import { Form, Row, Col } from 'react-bootstrap';
import BootstrapTable from '@ounai/react-bootstrap-table2';

import ItemView from '../items/ItemView';
import JSONModal from '../api/JSONModal';
import Loading from '../api/Loading';
import ItemDescription from './ItemDescription';
import ItemDropdown from './ItemDropdown';

import { CoverImage } from '../../../bibs';

const ItemSearchBootstrapTable = ({ allocationCount, allocations, updateTable, enablePagination }) => {
  const { t } = useTranslation();

  const columnStyle = (cell, row, rowIndex, colIndex) => {
    if (row.satisfiedAt !== null) {
      return { backgroundColor: '#81c784' };
    }
  };

  const columns = [
    {
      dataField: 'coverImage',
      text: t('Cover'),
      isDummyField: true,
      style: columnStyle,
      headerStyle: () => ({ width: '100px' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <CoverImage isbn={row.Item?.Bib?.marcISBN} materialType={row.Item?.Bib?.fixedFieldMaterialType} />
      )
    },
    {
      dataField: 'itemDescription',
      text: t('Item'),
      isDummyField: true,
      style: columnStyle,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <ItemDescription
          allocation={row}
        />
      )
    },
    {
      dataField: 'json',
      text: '',
      isDummyField: true,
      style: columnStyle,
      hidden: !hasRole('DEVELOPER'),
      headerStyle: () => ({ width: '100px' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <JSONModal data={row} />
      )
    },
    {
      dataField: 'dropdown',
      text: t('Found'),
      isDummyField: true,
      style: columnStyle,
      headerStyle: () => ({ width: '100px' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <ItemDropdown
          allocation={row}
          updateTable={updateTable}
        />
      )
    }
  ];

  const paginationOptions = {
    sizePerPageList: [
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: t('All'), value: allocations.length }
    ],
    sizePerPage: 25,
    hidePageListOnlyOnePage: false,
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
      <span style={{ marginLeft: '6px' }} className="react-bootstrap-table-pagination-total">
        {t('Showing item searches')} {from} - {to} / {size}.
      </span>
    )
  };

  return (
    <>
      <strong>
        {allocations.length} {(allocations.length === allocationCount) ? '' : `/ ${allocationCount}`} {t('items on list')}, {allocations.filter(item => item.satisfiedAt !== null).length} {t('marked as found')}
      </strong>

      <BootstrapTable
        keyField="id"
        columns={columns}
        data={allocations}
        bordered={false}
        pagination={enablePagination ? paginationFactory(paginationOptions) : null}
        bootstrap4

        expandRow={
          hasRole('VIEW-ITEMS')
            ? expandRow(row => <ItemView itemId={row.sierraItemRecordId} />)
            : undefined
        }
      />
    </>
  );
};

const ItemSearchTable = ({
  allocations,
  update,
  location,
  loading,
  enablePagination,

  sections,
  enabledSections,
  setEnabledSections,

  materialTypes,
  enabledMaterialType,
  setEnabledMaterialType,

  allocationTypes,
  enabledAllocationTypes,
  setEnabledAllocationTypes
}) => {
  const { t } = useTranslation();

  const toggleSection = section => setEnabledSections(enabledSections => (
    enabledSections.includes(section)
      ? enabledSections.filter(enabledSection => enabledSection !== section)
      : section === 'all'
        ? ['all']
        : [...enabledSections.filter(enabledSection => enabledSection !== 'all'), section]
  ));

  const toggleAllocationType = allocationType => setEnabledAllocationTypes(enabledAllocationTypes => (
    enabledAllocationTypes.includes(allocationType)
      ? enabledAllocationTypes.filter(enabledAllocationType => enabledAllocationType !== allocationType)
      : allocationType === 'all'
        ? ['all']
        : [...enabledAllocationTypes.filter(enabledAllocationType => enabledAllocationType !== 'all'), allocationType]
  ));

  const [filters, setFilters] = useQueryParamState('filters', {
    area: 'all',
    noSatisfiedItems: true,
    old: false,
    veryOld: false
  });

  const setArea = area => setFilters(f => ({ ...f, area }));

  const filteredAllocations = useMemo(() => allocations
    .filter(allocation => allocation?.Item?.Bib)
    .filter(allocation => {
      switch (filters.area) {
        case 'fiction':
          if (!allocation.Item.fiction) {
            return false;
          }

          break;

        case 'nonfiction-fin-swe-eng':
          if (allocation.Item.fiction) {
            return false;
          }

          if (!['fin', 'swe', 'eng'].includes(allocation.Item.Bib.fixedFieldLang)) {
            return false;
          }

          break;

        case 'nonfiction-other':
          if (allocation.Item.fiction) {
            return false;
          }

          if (['fin', 'swe', 'eng'].includes(allocation.Item.Bib.fixedFieldLang)) {
            return false;
          }

          break;

        default: break;
      }

      if (filters.noSatisfiedItems && allocation.satisfiedAt !== null) {
        return false;
      }

      if (filters.old) {
        if (!Array.isArray(allocation.pagedTitles) || allocation.pagedTitles.length === 0) {
          return false;
        }

        const lastMidnight = new Date();
        lastMidnight.setHours(0, 0, 0, 0);

        if (new Date(allocation.pagedTitles[0].sierraHoldCreatedAt) > lastMidnight) {
          return false;
        }
      }

      if (filters.veryOld) {
        if (!Array.isArray(allocation.pagedTitles) || allocation.pagedTitles.length === 0) {
          return false;
        }

        const twoDaysMidnight = new Date();
        twoDaysMidnight.setHours(0, 0, 0, 0);
        twoDaysMidnight.setDate(new Date().getDate() - 1);
        if (new Date(allocation.pagedTitles[0].sierraHoldCreatedAt) > twoDaysMidnight) {
          return false;
        }
      }
      return true;
    }), [allocations, filters]);

  return (
    <>
      <strong>{t('Sections')}</strong>

      <Row style={{ marginBottom: '10px' }}>
        {['all', ...Object.keys(sections).sort()].map(section => (
          <Col key={section} xs="auto" style={{ paddingRight: 0 }}>
            <span onClick={() => toggleSection(section)}>
              <Form.Check
                label={
                  ({
                    all: t('All'),
                    a: t('Adult'),
                    l: t('Children'),
                    m: t('Music'),
                    n: t('Youth'),
                    v: t('Storage'),
                    e: t('Espoo Collection'),
                    k: t('Kauniainen Collection')
                  }[section] ?? `${t('Unknown section')} "${section}"`) +
                  (section === 'all'
                    ? ` (${Object.values(sections).reduce((acc, cur) => acc + cur, 0)})`
                    : ` (${sections[section]})`)
                }

                checked={enabledSections.includes(section)}
                readOnly
              />
            </span>
          </Col>
        ))}
      </Row>

      <strong>{t('Material Types')}</strong>

      <Row style={{ marginBottom: '10px' }}>
        {['all', ...Object.keys(materialTypes).sort()].map(materialType => (
          <Col key={materialType} xs="auto" style={{ paddingRight: 0 }}>
            <span onClick={() => setEnabledMaterialType(materialType)}>
              <Form.Check
                label={
                  ({
                    all: t('All'),
                    book: t('Books'),
                    disc: t('Discs'),
                    magazine: t('Magazines'),
                    other: t('Other'),
                    cd: t('CDs'),
                    vinyl: t('Vinyl Records'),
                    audioCassette: t('Audio Cassettes'),
                    sheetMusic: t('Sheet Music'),
                    video: t('DVD, BD, VHS'),
                    romDisc: t('ROM Discs')
                  }[materialType] ?? `${t('Unknown material type class')} "${materialType}"`) +
                  (materialType === 'all'
                    ? ` (${Object.values(materialTypes).reduce((acc, cur) => acc + cur, 0)})`
                    : ` (${materialTypes[materialType]})`)
                }

                type="radio"
                checked={enabledMaterialType === materialType}
                readOnly
              />
            </span>
          </Col>
        ))}
      </Row>

      <strong>{t('Search Types')}</strong>

      <Row style={{ marginBottom: '10px' }}>
        {[...Object.keys(allocationTypes).sort()].map(allocationType => (
          <Col key={allocationType} xs="auto" style={{ paddingRight: 0 }}>
            <span onClick={() => toggleAllocationType(allocationType)}>
              <Form.Check
                label={
                  ({
                    all: t('All'),
                    1: t('Title Paging'),
                    2: t('Item Paging'),
                    3: t('Item Deletion'),
                    4: t('Collection Task'),
                    5: t('Floating Task'),
                    6: t('Float Homing Task')
                  }[allocationType] ?? `${t('Unknown allocation type class')} "${allocationType}"`) +
                  (allocationType === 'all'
                    ? ` (${Object.values(allocationTypes).reduce((acc, cur) => acc + cur, 0)})`
                    : ` (${allocationTypes[allocationType]})`)
                }

                checked={enabledAllocationTypes.includes(allocationType)}
                readOnly
              />
            </span>
          </Col>
        ))}
      </Row>

      <div style={{ marginBottom: '10px' }}>
        <strong>{t('Filter')}</strong>

        <Row>
          <Col xs="auto" style={{ paddingRight: 0 }}>
            <span onClick={() => setArea('all')}>
              <Form.Check
                type="radio"
                label={t('All')}
                checked={filters.area === 'all'}
                readOnly
              />
            </span>
          </Col>

          <Col xs="auto" style={{ paddingRight: 0 }}>
            <span onClick={() => setArea('fiction')}>
              <Form.Check
                type="radio"
                label={t('Fiction')}
                checked={filters.area === 'fiction'}
                readOnly
              />
            </span>
          </Col>

          <Col xs="auto" style={{ paddingRight: 0 }}>
            <span onClick={() => setArea('nonfiction-fin-swe-eng')}>
              <Form.Check
                type="radio"
                label={`${t('Nonfiction')} (${t('Finnish')}, ${t('Swedish')}, ${t('English')})`}
                checked={filters.area === 'nonfiction-fin-swe-eng'}
                readOnly
              />
            </span>
          </Col>

          <Col xs="auto" style={{ paddingRight: 0 }}>
            <span onClick={() => setArea('nonfiction-other')}>
              <Form.Check
                type="radio"
                label={`${t('Nonfiction')} (${t('other')})`}
                checked={filters.area === 'nonfiction-other'}
                readOnly
              />
            </span>
          </Col>
        </Row>

        <Row>
          <Col xs="auto" style={{ paddingRight: 0 }}>
            <span onClick={() => setFilters(f => ({ ...f, old: !f.old }))}>
              <Form.Check
                label={t('Only holds older than a day')}
                checked={filters.old}
                readOnly
              />
            </span>
          </Col>

          <Col xs="auto" style={{ paddingRight: 0 }}>
            <span onClick={() => setFilters(f => ({ ...f, veryOld: !f.veryOld }))}>
              <Form.Check
                label={t('Only holds older than two days')}
                checked={filters.veryOld}
                readOnly
              />
            </span>
          </Col>
          <Col xs="auto" style={{ paddingRight: 0 }}>
            <span onClick={() => setFilters(f => ({ ...f, noSatisfiedItems: !f.noSatisfiedItems }))}>
              <Form.Check
                label={t('No found items')}
                checked={filters.noSatisfiedItems}
                readOnly
              />
            </span>
          </Col>
        </Row>
      </div>

      {loading
        ? <Loading />
        : <ItemSearchBootstrapTable
          allocationCount={allocations.length}
          allocations={filteredAllocations}
          updateTable={update}
          enablePagination={enablePagination}
        />}
    </>
  );
};

export default ItemSearchTable;
