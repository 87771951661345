import getElementValue from './getElementValue';

import useCommons from './useCommons';
import useOnSubmit from './useOnSubmit';

import FormContainer from './Container';
import FormContent from './Content';
import FormItemLabel from './FormItemLabel';

import type { IInfo } from '../../common/layout';
import type { FormItemValidation } from './validation';

export * from './validation';
export * from './options';

export {
  getElementValue,

  useCommons,
  useOnSubmit,

  FormContainer,
  FormContent,
  FormItemLabel
};

export type PureOnSubmit = (event: React.FormEvent) => void;

export type FormDataValue = string | number | boolean | null | string[];
export type FormData = Record<string, FormDataValue>;

export type OnSubmit = (data: FormData) => void;
export type OnChangeElement = (newValue: FormDataValue) => void;
export type OnChangeForm = (elementName: string, value: FormDataValue) => void;

interface Element {
  name: string;
  label?: string;
  validation?: FormItemValidation | FormItemValidation[];
  onChange?: OnChangeElement;
  info?: IInfo;
}

interface Required {
  required?: boolean;
}

interface NumberProps {
  min?: number;
  max?: number;
}

export interface SelectOption {
  label: string;
  value: string;
}

interface SelectProps {
  options: SelectOption[];
}

interface DefaultValue<T> {
  defaultValue?: T | null;
}

interface Placeholder {
  placeholder?: string;
}

// TODO disallow empty text inputs
// TODO inverted checkboxes
export type FormItem =
  | { type: 'checkbox' } & Element & Required & DefaultValue<boolean>
  | { type: 'text' } & Element & Required & DefaultValue<string> & Placeholder
  | { type: 'text-array' } & Element & Required & DefaultValue<string[]> & Placeholder
  | { type: 'number' } & Element & Required & NumberProps & DefaultValue<number> & Placeholder
  | { type: 'textarea' } & Element & Required & DefaultValue<string> & Placeholder
  | { type: 'radio' } & Element & Required & DefaultValue<boolean>
  | { type: 'select' } & Element & SelectProps & DefaultValue<string>
  | { type: 'multiselect' } & Element & SelectProps & DefaultValue<string[]>
  | { type: 'password' } & Element & Required & DefaultValue<string> & Placeholder;
