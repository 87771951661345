import Form from 'react-bootstrap/Form';

import { FormItemLabel, getElementValue } from '..';

import type { FormElementProps } from '.';

const Password: React.FC<FormElementProps> = ({ formItem, onChange }) => {
  if (formItem.type !== 'password') {
    throw new Error(`Invalid password form item type ${formItem.type}`);
  }

  return (
    <Form.Group>
      <FormItemLabel formItem={formItem} />

      <Form.Control
        type="password"
        size="sm"
        name={formItem.name}
        defaultValue={formItem.defaultValue ?? ''}
        required={formItem.required}
        placeholder={formItem.placeholder}
        onChange={(event): void => onChange &&
          onChange(getElementValue(event.target as unknown as HTMLInputElement))}
      />
    </Form.Group>
  );
};

export default Password;
