export * from './formatters';

// TODO move
export const hoursFromNow = (hourCount: number): Date => {
  const date = new Date();

  date.setHours(hourCount);

  return date;
};

// TODO daysFromNow etc.

export const oneHourAgo = (): Date => hoursFromNow(-1);

// TODO oneDayAgo etc.

// TODO more
export type DateLocale =
  | 'fi';
