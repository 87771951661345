import useDarkMode from './useDarkMode';
import useRoutes from './useRoutes';

import App from './App';
import Version from './Version';
import Dashboard from './Dashboard';
import Broadcasts from './Broadcasts';
import Routes from './Routes';

import type { Role } from '../users';

export {
  useDarkMode,
  useRoutes,

  App,
  Version,
  Broadcasts,
  Dashboard,
  Routes
};

export interface Route {
  path: string;
  content: React.ReactNode;
  role: Role | Role[];
  roleNot?: Role | Role[];
  exact?: boolean;
  title?: string;
  titleHidden?: boolean;
  container?: boolean;
}
