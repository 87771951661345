import { Table } from 'react-bootstrap';

const DetailRow = ({ label, value }) => {
  if (value === null || value === undefined || typeof value === 'boolean') {
    return null;
  }

  return (
    <tr>
      <td><strong>{label}</strong></td>
      <td style={{ width: '100%' }}>{value}</td>
    </tr>
  );
};

const DetailsTable = ({ details }) => {
  const rows = details.map(detail => (
    <DetailRow key={detail.label} label={detail.label} value={detail.value} />
  ));

  return (
    <Table className="w-auto" size="sm" borderless striped>
      <tbody>
        {rows}
      </tbody>
    </Table>
  );
};

export default DetailsTable;
