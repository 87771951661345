import { useEffect, useMemo } from 'react';

import { Form } from 'react-bootstrap';

const DateSelector = ({ defaultValue, onChange }) => {
  const dateString = useMemo(() => {
    const monthAgo = new Date();
    monthAgo.setDate(monthAgo.getDate() - 30);

    return monthAgo.toISOString().split('T')[0];
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(defaultValue || dateString), []);

  return (
    <Form.Control
      type="date"
      size="sm"
      defaultValue={defaultValue || dateString}
      onChange={event => onChange(event.target.value)}
    />
  );
};

export default DateSelector;
