import { InfoHoverTooltip, Stack, Space } from '../common/layout';
import { T } from '../common/i18n';

import { hasRole } from '../users';

import type { Location3 } from '../sierra';

const JoinedArray: React.FC<{ array: string[] }> = ({ array, children }) => (
  <>
    <strong>{children}</strong>:

    <Space />

    <em>{array.join(', ')}</em>
  </>
);

const DescriptionInfoTooltip: React.FC<{
  id: string,
  description?: string | null,
  keywords?: string[],
  locations?: Location3[],
  orderPriority?: number
}> = ({ id, orderPriority, description, keywords, locations }) => {
  const orderPriorityExists = (hasRole('EDIT-LINKS') && typeof orderPriority === 'number');
  const descriptionExists = (typeof description === 'string');
  const keywordsExist = (Array.isArray(keywords) && keywords.length > 0);
  const locationsExist = (Array.isArray(locations) && locations.length > 0);

  if (!descriptionExists && !keywordsExist && !locationsExist && !orderPriorityExists) {
    return null;
  }

  return (
    <InfoHoverTooltip id={id}>
      <Stack>
        {descriptionExists && (
          <span>
            {description}
          </span>
        )}

        {keywordsExist && (
          <JoinedArray array={keywords}>
            <T>Keywords</T>
          </JoinedArray>
        )}

        {locationsExist && (
          <JoinedArray array={locations}>
            <T>Locations</T>
          </JoinedArray>
        )}

        {orderPriorityExists && (
          <>
            <T>Order Priority</T>: {orderPriority}
          </>
        )}
      </Stack>
    </InfoHoverTooltip>
  );
};

export default DescriptionInfoTooltip;
