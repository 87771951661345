import { useT } from '../common/i18n';
import { expandRow } from '../common/layout';

import { APITable, useTableFormatters } from '../api';
import { PagedTitleView } from '.';

import type { APITableColumn } from '../api';
import type { PagedTitle } from '../api/models';

// eslint-disable-next-line max-lines-per-function
const useColumns = (): APITableColumn[] => {
  const t = useT();
  const { dateTimeRelative, location5 } = useTableFormatters();

  // TODO is allocated
  // TODO does reservable item exist
  return [
    {
      field: 'id',
      label: t('ID')
    },
    {
      field: 'sierraHoldCreatedAt',
      label: t('Hold Created At'),
      formatter: dateTimeRelative
    },
    {
      field: 'sierraPickupLocation',
      label: t('Pickup Location'),
      formatter: location5
    },
    {
      field: 'sierraRecordType',
      label: t('Record Type')
    },
    {
      field: 'sierraRecordId',
      label: t('Record ID')
    },
    {
      field: 'createdAt',
      label: t('Created At'),
      formatter: dateTimeRelative
    },
    {
      field: 'updatedAt',
      label: t('Updated At'),
      formatter: dateTimeRelative,
      disableSorting: true
    },
    {
      field: 'eventCount',
      label: t('Event Count'),
      isDummyField: true,
      formatter: (cell, row): number | string =>
        (row as PagedTitle).PagedTitleEvents?.length ?? '?'
    },
    {
      field: 'allocationCount',
      label: t('Allocation Count'),
      isDummyField: true,
      formatter: (cell, row): number | string =>
        (row as PagedTitle).allocationHistory?.length ?? '?'
    },
    {
      field: 'hasActiveAllocation',
      label: t('Has Active Allocation'),
      isDummyField: true,
      formatter: (cell, row): string =>
        (row as PagedTitle).allocationHistory?.some(({ active }) => active)
          ? t('yes')
          : t('no')
    }
  ];
};

const PagedTitleTable: React.FC<{
  enableQueryParams: boolean
}> = ({ enableQueryParams }) => (
  <APITable
    resource="paged-titles/active"
    keyField="id"
    columns={useColumns()}
    enableQueryParams={enableQueryParams}
    expandRow={expandRow(row => <PagedTitleView pagedTitle={row} />)}
    sortOrder="desc"
  />
);

export default PagedTitleTable;
