import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ReactToPrint from 'react-to-print';
import { Button } from 'react-bootstrap';
import { Printer } from 'react-bootstrap-icons';

const ReceiptButton = ({ button, children }) => {
  const { t } = useTranslation();
  const divRef = useRef(null);

  if (!button) {
    button = (
      <Button size="sm">
        <Printer style={{ marginTop: '-2px' }} />

        &nbsp;{t('Print receipt')}
      </Button>
    );
  }

  return (
    <>
      <ReactToPrint
        trigger={() => button}
        content={() => divRef.current}
        copyStyles={false}
      />

      <div style={{ display: 'none' }}>
        <div ref={divRef} style={{
          marginTop: '10px',
          marginLeft: '8px',
          marginRight: '8px',
          color: 'black',
          fontFamily: 'Georgia'
        }}>
          {children}
        </div>
      </div>
    </>
  );
};

export default ReceiptButton;
