import { FormContent } from '../../layout/forms';
import { useMassUpdateBibForm } from '.';

import type { FormDataValue } from '../../layout/forms';
import type { Operation } from '.';

const MassUpdateBibForm: React.FC<{
  setOperation: React.Dispatch<Operation>
}> = ({ setOperation }) => (
  <FormContent
    form={useMassUpdateBibForm()}

    onChange={(name: string, value: FormDataValue): void => {
      // eslint-disable-next-line no-console
      console.log('TODO MassUpdateBibForm onChange', name, value);
    }}
  />
);

export default MassUpdateBibForm;
