const Price = ({ value }) => {
  if (value === undefined || value === null) return null;

  if (typeof value !== 'number') {
    // eslint-disable-next-line no-console
    console.error('Non-numeric price encountered:', value);

    return value;
  }

  return (
    <>
      {(value / 100).toFixed(2)}
      &nbsp;
      &euro;
    </>
  );
};

export default Price;
