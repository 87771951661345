import ClickSelect from '../api/ClickSelect';

const StandardNumbersDisplay = ({ bib }) => (
  <>
    {bib.marcISBN && <div><ClickSelect>{bib.marcISBN}</ClickSelect></div>}
    {bib.marcISSN && <div><ClickSelect>{bib.marcISSN}</ClickSelect></div>}
    {bib.marcEAN13 && <div><ClickSelect>{bib.marcEAN13}</ClickSelect></div>}

    {bib.marcBindingInfo && (
      <div>
        <strong>
          {bib.marcBindingInfo}
        </strong>
      </div>
    )}
  </>
);

export default StandardNumbersDisplay;
