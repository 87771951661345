import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../../hooks/useAPI';

import { LayersControl, LayerGroup, Polyline } from 'react-leaflet';

const TransitVolumeLayer = ({ libraries, selectedLibrary }) => {
  const { t } = useTranslation();

  const minVolume = 10;

  const [polylines, setPolylines] = useState([]);

  const [transitVolumeData] = useAPI('GET', '/transit-info/volume-data/today');

  useEffect(() => {
    setPolylines([]);

    if (libraries === null || selectedLibrary === null || transitVolumeData === null) return;

    let key = 0;

    const fromCoords = [
      libraries[selectedLibrary].latitude,
      libraries[selectedLibrary].longitude
    ];

    for (const to in transitVolumeData[selectedLibrary]) {
      if (!libraries[to]) continue;

      const volume = transitVolumeData[selectedLibrary][to];
      if (volume < minVolume) continue;

      const toCoords = [
        libraries[to].latitude,
        libraries[to].longitude
      ];

      let blue = Math.min(1, Math.log2(volume) / 6);
      blue = Math.floor(255 * blue).toString(16);
      if (blue.length === 1) blue = '0' + blue;

      const opts = {
        weight: (Math.ceil(Math.log2(volume / 5))),
        color: `#0000${blue}`
      };

      const polyline = <Polyline key={key++} positions={[fromCoords, toCoords]} pathOptions={opts} />;
      setPolylines(p => [...p, polyline]);
    }
  }, [libraries, minVolume, transitVolumeData, selectedLibrary]);

  return (
    <LayersControl.Overlay checked name={t('Transit Volume')}>
      <LayerGroup>
        {polylines}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

export default TransitVolumeLayer;
