export const materialTypes = {
  1: 'Kirja',
  2: 'Kartta',
  3: 'CD-levy',
  4: 'Kasetti',
  6: 'LP-levy',
  7: 'Nuotti',
  9: 'Lehti',
  a: 'Kuva',
  b: 'Diakuva',
  c: 'Työpiirrustus',
  d: 'CD-ROM',
  e: 'DVD-ROM',
  f: 'Videokasetti',
  g: 'DVD-levy',
  h: 'BD-levy',
  j: 'Mikrofilmi',
  k: 'Käsikirjoitus',
  n: 'Pistekirjoitus',
  q: 'Esine',
  r: 'Lautapeli',
  s: 'Konsolipeli',
  t: 'E-audio',
  v: 'E-video',
  x: 'Verkkoaineisto',
  y: 'E-lehti',
  z: 'E-kirja'
};

export type MaterialTypeCode = keyof typeof materialTypes;
export type MaterialTypeName = typeof materialTypes[MaterialTypeCode];
