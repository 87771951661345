import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAPI from '../../../hooks/useAPI';
import useWindowSize from '../../../hooks/useWindowSize';

import { Button, Form } from 'react-bootstrap';

import ItemShelfViewCanvas from './ItemShelfViewCanvas';
import ItemShelfViewList from './ItemShelfViewList';
import Loading from '../../api/Loading';

const HighlighterSelector = ({ highlighter, setHighlighter, style }) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={event => event.preventDefault()} style={style} inline>
      <Form.Label style={{ marginRight: '6px' }}>
        {t('Highlighter')}
      </Form.Label>

      <Form.Control
        as="select"
        size="sm"
        defaultValue={highlighter}
        onChange={event => setHighlighter(event.target.value)}
      >
        <option value="none">{t('None')}</option>
        <option value="lastSeen">{t('Last seen')}</option>
      </Form.Control>
    </Form>
  );
};

const ItemShelfView = ({ itemId, barcode, itemsPerSide }) => {
  const { t } = useTranslation();

  const defaultItemsPerSide = 4;

  if (!itemsPerSide) itemsPerSide = defaultItemsPerSide;

  const [currentItemId, setCurrentItemId] = useState(itemId);
  const [highlighter, setHighlighter] = useState('none');
  const [itemsBefore, setItemsBefore] = useState(itemsPerSide);
  const [itemsAfter, setItemsAfter] = useState(itemsPerSide);

  const windowSize = useWindowSize();

  const params = {
    itemsBefore,
    itemsAfter
  };

  if (currentItemId) params.sierraItemRecordId = currentItemId;
  else params.barcode = barcode;

  const [shelfData, loading, error, /* headers */, update] = useAPI('GET', '/items/shelf', {
    params
  }, [currentItemId, barcode, itemsBefore, itemsAfter, windowSize.width]);

  const moveShelfLeft = () => {
    setCurrentItemId(
      shelfData.previous.length !== 0
        ? shelfData.previous[0].sierraItemRecordId
        : shelfData.current.sierraItemRecordId
    );

    setItemsBefore(itemsPerSide * 2);
    setItemsAfter(0);
  };

  const moveShelfRight = () => {
    setCurrentItemId(
      shelfData.next.length !== 0
        ? shelfData.next[shelfData.next.length - 1].sierraItemRecordId
        : shelfData.current.sierraItemRecordId
    );

    setItemsBefore(0);
    setItemsAfter(itemsPerSide * 2);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return t('Could not fetch shelf data');
  }

  return (
    <>
      <HighlighterSelector
        highlighter={highlighter}
        setHighlighter={setHighlighter}
        style={{ marginBottom: '10px' }}
      />

      <ItemShelfViewCanvas
        shelfData={shelfData}
        highlighter={highlighter}
      />

      <div style={{ marginBottom: '10px' }}>
        <Button variant="outline-dark" onClick={moveShelfLeft} size="sm">
          &lt;&lt;
        </Button>

        <Button variant="outline-dark" onClick={moveShelfRight} size="sm" className="float-right">
          &gt;&gt;
        </Button>
      </div>

      <hr />

      <ItemShelfViewList
        shelfData={shelfData}
        update={update}
      />
    </>
  );
};

export default ItemShelfView;
