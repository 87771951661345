import { useEffect } from 'react';

import config from '../../config';

const PageTitle = ({ hidden, children }) => {
  useEffect(() => {
    const dev = (process.env.REACT_APP_DEV_MODE ? ' [dev]' : '');

    if (typeof children === 'string') {
      document.title = `${children} - ${config.appName}` + dev;
    } else {
      document.title = `${children.join('')} - ${config.appName}` + dev;
    }

    return () => { document.title = config.appName; };
  }, [children]);

  if (hidden) return null;

  return (
    <h3 style={{ marginBottom: '10px' }}>
      {children}
    </h3>
  );
};

export default PageTitle;
