import { useState, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useWindowSize from '../../hooks/useWindowSize';
import { getUsername, logout } from '../../services/auth';
import config from '../../config';
import { onBarcodeScanned, onRFIDScanned } from '../../services/search';
import { getUserLocation, getUserLocationName } from '../../services/user';

import { useSelector, useDispatch } from '../../../state';
import { selectReader } from '../../../state/slices/rfid';
import { fetchAll as fetchAllBroadcasts } from '../../../state/slices/broadcasts';

import {
  toggle as toggleDarkMode,
  selectEnabled as selectDarkModeEnabled
} from '../../../state/slices/darkMode';

import { Link } from 'react-router-dom';
import { NavDropdown, Nav, Navbar, Row, Col } from 'react-bootstrap';
import { UpcScan, Wifi, Share, GearFill, SunFill, MoonFill } from 'react-bootstrap-icons';

import {
  Authorized,
  AuthorizedNavDropdown,
  AuthorizedNavDropdownItem,
  AuthorizedNavLink
} from '../auth/Authorized';

import OnClickModal from '../layout/OnClickModal';
import BarcodeScannerModal from './BarcodeScannerModal';
import RFIDScannerModal from './RFIDScannerModal';
import SharePage from './SharePage';
import SearchBar from './SearchBar';
import LibrarySelect from './LibrarySelect';
import AlertBadge from '../api/AlertBadge';
import CreateBroadcastForm from '../developer/CreateBroadcastForm';
import BottomTooltip from '../layout/BottomTooltip';
import Preferences from './Preferences';
import CountBadge from '../api/CountBadge';
import AnnouncementsModal from './AnnouncementsModal';

import { LinksPopover } from '../../../links';

import logoImage from '../../../resources/brand/logo.png';

const CreateBroadcastLink = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [forceClose, setForceClose] = useState(false);

  const button = (
    <NavDropdown.Item>
      {t('Create Broadcast')}
    </NavDropdown.Item>
  );

  const onCreate = () => {
    setForceClose(true);
    dispatch(fetchAllBroadcasts());
  };

  return (
    <OnClickModal
      title={t('Create Broadcast')}
      button={button}
      forceClose={forceClose}
      onOpen={() => setForceClose(false)}
      closeButton
    >
      <CreateBroadcastForm onCreate={onCreate} />
    </OnClickModal>
  );
};

const LeftAlignedNavigationItems = ({ notificationBubbles }) => {
  const { t } = useTranslation();

  return (
    <>
      <AuthorizedNavDropdown role="QUERY-BIBS" title={t('Bibs')}>
        <AuthorizedNavDropdownItem role="QUERY-BIBS" as={Link} to="/bibs">{t('Browse')}</AuthorizedNavDropdownItem>
        <AuthorizedNavDropdownItem role="QUERY-BIBS" as={Link} to="/bibs/query">{t('Query')}</AuthorizedNavDropdownItem>
        <Authorized role="DEVELOPER"><NavDropdown.Divider /></Authorized>
        <AuthorizedNavDropdownItem role="DEVELOPER" as={Link} to="/bibs/lists">{t('Lists')}</AuthorizedNavDropdownItem>
      </AuthorizedNavDropdown>

      <AuthorizedNavDropdown role={['QUERY-ITEMS', 'INVENTORY', 'CREATE-COLLECTION-TASKS']} title={t('Items')}>
        <AuthorizedNavDropdownItem role="QUERY-ITEMS" as={Link} to="/items/query">{t('Query')}</AuthorizedNavDropdownItem>
        <AuthorizedNavDropdownItem role="CREATE-COLLECTION-TASKS" as={Link} to="/items/collection-tasks">{t('Collection Tasks')}</AuthorizedNavDropdownItem>
        <Authorized role="DEVELOPER"><NavDropdown.Divider /></Authorized>
        <AuthorizedNavDropdownItem role="DEVELOPER" as={Link} to="/items/most-traveled">{t('Most Traveled')}</AuthorizedNavDropdownItem>
        <AuthorizedNavDropdownItem role="DEVELOPER" as={Link} to="/items/duplicate-barcodes">{t('Duplicate Barcodes')}</AuthorizedNavDropdownItem>
        <Authorized role="INVENTORY"><Authorized role="QUERY-ITEMS"><NavDropdown.Divider /></Authorized></Authorized>
        <AuthorizedNavDropdownItem role="INVENTORY" as={Link} to="/items/inventory">{t('Inventory')}</AuthorizedNavDropdownItem>
        <AuthorizedNavDropdownItem role="DEVELOPER" as={Link} to="/items/shelf-inventory">{t('Shelf Inventory')}</AuthorizedNavDropdownItem>
        <AuthorizedNavDropdownItem role="INVENTORY" as={Link} to="/items/inventory/segments">{t('Segment Browser')}</AuthorizedNavDropdownItem>
      </AuthorizedNavDropdown>

      <AuthorizedNavDropdown role={['VIEW-ORDERS', 'RECEPTION', 'PROCESS-SHIPMENTS']} title={t('Orders')}>
        <AuthorizedNavDropdownItem role="VIEW-ORDERS" as={Link} to="/orders">{t('Browse')}</AuthorizedNavDropdownItem>
        <AuthorizedNavDropdownItem role="QUERY-ORDERS" as={Link} to="/orders/query">{t('Query')}</AuthorizedNavDropdownItem>
        <Authorized role={['RECEPTIONS', 'PROCESS-SHIPMENTS']}><Authorized role="VIEW-ORDERS"><NavDropdown.Divider /></Authorized></Authorized>
        <AuthorizedNavDropdownItem role="RECEPTION" as={Link} to="/orders/reception">{t('Reception')}</AuthorizedNavDropdownItem>
        <AuthorizedNavDropdownItem role="PROCESS-SHIPMENTS" as={Link} to="/orders/shipments">{t('Shipments')}</AuthorizedNavDropdownItem>
      </AuthorizedNavDropdown>

      <AuthorizedNavDropdown role="HANDLE-HOLDS" title={
        <>
          {t('Holds')}
          {notificationBubbles.clearEmailReceivedToday === false && <AlertBadge style={{ marginLeft: '4px' }} />}
          <CountBadge count={notificationBubbles.expiredHoldShelfItems} />
        </>
      }>
        <NavDropdown.Item as={Link} to="/holdshelf/clear">
          {t('Clear Hold Shelf')}
          {notificationBubbles.clearEmailReceivedToday === false && <AlertBadge style={{ marginLeft: '4px' }} />}
          <CountBadge count={notificationBubbles.expiredHoldShelfItems} />
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/holdshelf">{t('Mikro-Väylä Reservations')}</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/holds/missing">{t('Missing Holds')}</NavDropdown.Item>
      </AuthorizedNavDropdown>

      <AuthorizedNavLink as={Link} role="ITEM-SEARCH" to="/item-searches">
        {t('Tasks')}
        <CountBadge count={notificationBubbles.itemSearches} />
        <CountBadge count={notificationBubbles.collectionTasks} variant='info' />
      </AuthorizedNavLink>

      <AuthorizedNavDropdown role="DEVELOPER" title={t('Charts')}>
        <NavDropdown.Item as={Link} to="/charts/item-scans">{t('Item Scan Charts')}</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/charts/holdshelf">{t('Hold Shelf Chart')}</NavDropdown.Item>
      </AuthorizedNavDropdown>

      <AuthorizedNavDropdown role={['MANAGE-SHARED-ACCOUNTS', 'ADMIN']} title={t('Admin')}>
        <AuthorizedNavDropdownItem role="ADMIN" as={Link} to="/admin/accounts">{t('Accounts')}</AuthorizedNavDropdownItem>
        <AuthorizedNavDropdownItem role="MANAGE-SHARED-ACCOUNTS" as={Link} to="/admin/shared-accounts">{t('Shared Accounts')}</AuthorizedNavDropdownItem>
        <AuthorizedNavDropdownItem role="ADMIN" as={Link} to="/admin/rfid-readers">{t('RFID Readers')}</AuthorizedNavDropdownItem>
      </AuthorizedNavDropdown>

      <AuthorizedNavDropdown role="DEVELOPER" title={t('Developer')}>
        <CreateBroadcastLink />

        <NavDropdown.Divider />

        <NavDropdown.Item as={Link} to="/developer/sandbox">{t('Sandbox')}</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/developer/logs">{t('Logs')}</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/developer/item-allocations">{t('Item Allocations')}</NavDropdown.Item>

        <NavDropdown.Divider />

        <NavDropdown.Item as={Link} to="/bibs/random">{t('Random Bib')}</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/items/random">{t('Random Item')}</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/orders/random">{t('Random Order')}</NavDropdown.Item>

        <NavDropdown.Divider />

        <NavDropdown.Item as={Link} to="/maps/transit">{t('Transit Volume Map')}</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/users">{t('Users')}</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/mass-updates">{t('Mass Updates')}</NavDropdown.Item>
      </AuthorizedNavDropdown>

      {getUserLocation() === 'e01' && (
        <AuthorizedNavDropdown role="USER" title={t('Sello')}>
          <AnnouncementsModal location="e01" locationName={getUserLocationName()} />
        </AuthorizedNavDropdown>
      )}

      {getUserLocation()[0] === 'e' && (
        <Authorized role="USER">
          <LinksPopover />
        </Authorized>
      )}
    </>
  );
};

const SettingsDropdown = () => {
  const { t } = useTranslation();

  const labelPrinter = null; // TODO
  const rfidReader = useSelector(selectReader);

  const selectLabelPrinterButton = (
    <NavDropdown.Item>
      {t('Select label printer')} ({labelPrinter === null ? t('none') : labelPrinter})
    </NavDropdown.Item>
  );

  const selectRfidReaderButton = (
    <NavDropdown.Item>
      {t('Select RFID Reader')} ({rfidReader === null ? t('none') : rfidReader.name})
    </NavDropdown.Item>
  );

  const preferencesButton = (
    <NavDropdown.Item>
      {t('Preferences')}
    </NavDropdown.Item>
  );

  return (
    <NavDropdown
      title={<GearFill size={24} />}
      alignRight
    >
      <Authorized role="DEVELOPER">
        <OnClickModal
          title={t('Select RFID Reader')}
          button={selectRfidReaderButton}
        >
          TODO:
          List all possible readers here, also from Octopus Reader
        </OnClickModal>

        <OnClickModal
          title={t('Select label printer')}
          button={selectLabelPrinterButton}
        >
          TODO
        </OnClickModal>

        <NavDropdown.Divider />
      </Authorized>

      <OnClickModal
        title={t('Preferences')}
        button={preferencesButton}
        closeButton
      >
        <Preferences />
      </OnClickModal>

      <NavDropdown.Item as={Link} to="/account">
        {t('Account Settings')}
      </NavDropdown.Item>

      <NavDropdown.Divider />

      <NavDropdown.Item onClick={logout}>
        {t('Logout')} ({getUsername()})
      </NavDropdown.Item>
    </NavDropdown>
  );
};

const NavScanButton = () => (
  <Nav.Link style={{ paddingRight: 0 }}>
    <UpcScan size={24} />
  </Nav.Link>
);

const NavRFIDScanButton = () => (
  <Nav.Link style={{ paddingRight: 0 }}>
    <Wifi size={24} />
  </Nav.Link>
);

const NavShareButton = () => (
  <Nav.Link style={{ paddingLeft: 0, paddingRight: 0 }}>
    <Share size={24} />
  </Nav.Link>
);

const IconsRow = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Authorized role="USER">
        <Col xs="auto" style={{ paddingRight: 0 }}>
          <BarcodeScannerModal
            scanButton={<NavScanButton />}
            onScan={onBarcodeScanned}
          />
        </Col>
      </Authorized>

      <Authorized role="DEVELOPER">
        <Col xs="auto" style={{ paddingRight: 0 }}>
          <RFIDScannerModal
            scanButton={<NavRFIDScanButton />}
            onScan={onRFIDScanned}
          />
        </Col>
      </Authorized>

      <Col xs="auto" style={{ paddingRight: 0 }}>
        <OnClickModal
          title={t('Share this page')}
          button={<NavShareButton />}
        >
          <SharePage />
        </OnClickModal>
      </Col>

      <Authorized role="DEVELOPER">
        <Col xs="auto">
          <SettingsDropdown />
        </Col>
      </Authorized>
    </Row>
  );
};

const Sun = () => <SunFill size={20} style={{ color: 'gold', marginTop: '-2px' }} />;
const Moon = () => <MoonFill size={20} style={{ color: 'white', marginTop: '-2px' }} />;

const RightAlignedNavigationItems = () => {
  const { t } = useTranslation();

  const darkModeEnabled = useSelector(selectDarkModeEnabled);
  const dispatch = useDispatch();

  return (
    <>
      <BottomTooltip content={darkModeEnabled ? t('Switch to light mode') : t('Switch to dark mode')}>
        <Nav.Link onClick={() => dispatch(toggleDarkMode())} style={{
          marginRight: '8px',
          marginTop: 'auto',
          marginBottom: 'auto'
        }}>
          {darkModeEnabled ? <Moon /> : <Sun />}
        </Nav.Link>
      </BottomTooltip>

      <Authorized role="USER">
        <SearchBar style={{ marginRight: '8px' }} />
      </Authorized>

      <IconsRow />

      <Authorized role="USER" roleNot="DEVELOPER">
        <Nav.Link onClick={logout} style={{ paddingRight: 0, marginLeft: '20px' }}>
          {t('Logout')}&nbsp;({getUsername()})
        </Nav.Link>
      </Authorized>

      <Authorized role="CHANGE-LOCATION">
        <LibrarySelect />
      </Authorized>
    </>
  );
};

const NavigationItems = ({ notificationBubbles }) => (
  <>
    <Nav className="mr-auto">
      <LeftAlignedNavigationItems notificationBubbles={notificationBubbles} />
    </Nav>

    <Nav className="ml-auto">
      <RightAlignedNavigationItems />
    </Nav>
  </>
);

const Brand = () => (
  <Navbar.Brand
    as={Link}
    to="/"
    style={{
      marginLeft: '-6px',
      marginRight: '8px',
      paddingTop: 0,
      paddingBottom: 0
    }}
  >
    <img
      src={logoImage}
      alt={config.appName}
      style={{ height: '40px', width: 'auto' }}
    />

    {process.env.REACT_APP_DEV_MODE && (
      <sup style={{ fontSize: '12px', marginLeft: '-6px' }}>
        dev
      </sup>
    )}
  </Navbar.Brand>
);

const Navigation = ({ setNavbarHeight, notificationBubbles }) => {
  const windowSize = useWindowSize();
  const navbarRef = useRef(null);

  useLayoutEffect(() => {
    if (setNavbarHeight && navbarRef.current) {
      setNavbarHeight(navbarRef.current.clientHeight);
    }
  }, [navbarRef, windowSize, setNavbarHeight]);

  return (
    <Navbar ref={navbarRef} variant="light" expand="xl" style={{ backgroundSize: 0, backgroundColor: '#08D9D6' }} >
      <Brand />

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <NavigationItems notificationBubbles={notificationBubbles} />
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
