import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserLocation, getUserId } from '../../services/user';
import { incrementStatistic } from '../../services/inventory';

import { XSquare, Bell, BellFill, Search, Broadcast } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

import OnClickModal from '../layout/OnClickModal';
import ItemEditor from './ItemEditor';
import RfidItemScanBrowser from './RfidItemScanBrowser';
import EditButton from '../api/EditButton';
import InventoryRadar from './InventoryRadar';
import Authorized from '../auth/Authorized';
import JSONModal from '../api/JSONModal';

const BellButton = ({ item, notifys, setNotifys }) => {
  const onBellClick = tagId => setNotifys({ ...notifys, [tagId]: !notifys[tagId] });

  return (
    <Button
      variant="link"
      size="sm"
      onClick={event => onBellClick(item.tagId)}
      style={{ padding: 0, border: 0, verticalAlign: 'baseline' }}
    >
      {notifys[item.tagId] ? <BellFill size={20} /> : <Bell size={20} />}
    </Button>
  );
};

const RadarButton = ({ item, radarRssi, setRadarItem, rssiMax, lastSeen }) => {
  const { t } = useTranslation();

  const button = (
    <Button
      variant="link"
      size="sm"
      style={{ padding: 0, border: 0, verticalAlign: 'baseline' }}
    >
      <Broadcast size={20} />
    </Button>
  );

  return (
    <OnClickModal
      title={t('Radar Mode')}
      button={button}
      onOpen={() => setRadarItem(item)}
      onClose={() => setRadarItem(null)}
      closeButton
    >
      <InventoryRadar
        item={item}
        rssi={radarRssi}
        rssiMax={rssiMax}
        lastSeen={lastSeen}
      />
    </OnClickModal>
  );
};

const EditItemButton = ({ item, removeTag }) => {
  const { t } = useTranslation();

  const [forceClose, setForceClose] = useState(false);

  const button = <EditButton size={20} noMargin />;

  const onSave = changes => {
    setForceClose(true);

    if (changes.itemStatus) {
      if (item.item.fixedFieldItemStatus === 'm') {
        incrementStatistic('missingItemsFound');
      }

      incrementStatistic('statusChanges');
    }

    if (changes.city) incrementStatistic('cityChanges');
    if (changes.location) incrementStatistic('locationChanges');
  };

  return (
    <OnClickModal
      button={button}
      title={t('Edit Item')}
      forceClose={forceClose}
      onOpen={() => setForceClose(false)}
      closeButton
    >
      <ItemEditor
        item={item.item}
        onSave={onSave}

        secondaryButton={{
          text: t('Save and remove from list'),
          onClick: () => removeTag(item.tagId)
        }}

        barcode
        itemStatus
        callNumber
        location
        city
      />
    </OnClickModal>
  );
};

const ItemScansButton = ({ item }) => {
  const { t } = useTranslation();

  const button = (
    <Button
      variant="link"
      style={{
        padding: 0,
        verticalAlign: 'baseline'
      }}
    >
      <Search />
    </Button>
  );

  return (
    <OnClickModal
      button={button}
      title={t('RFID Item Scans')}
      closeButton
    >
      <RfidItemScanBrowser
        itemScanId={item.rfidItemScanId}
        location={getUserLocation()}
        userId={getUserId()}
      />
    </OnClickModal>
  );
};

const RemoveFromListButton = ({ item, removeTag }) => {
  return (
    <Button
      variant="link"
      size="sm"
      onClick={event => removeTag(item.tagId)}
      style={{ padding: 0, border: 0, verticalAlign: 'baseline' }}
    >
      <XSquare size={20} />
    </Button>
  );
};

const InventoryTableItemTools = ({ item, notifys, setNotifys, removeTag, radarRssi, setRadarItem, rssiMax, radarLastSeen }) => (
  <>
    <BellButton
      item={item}
      notifys={notifys}
      setNotifys={setNotifys}
    />

    <span style={{ marginLeft: '8px' }} />

    <RadarButton
      item={item}
      radarRssi={radarRssi}
      setRadarItem={setRadarItem}
      rssiMax={rssiMax}
      lastSeen={radarLastSeen}
    />

    <span style={{ marginLeft: '8px' }} />

    {item?.item && (
      <>
        <EditItemButton
          item={item}
          removeTag={removeTag}
        />

        <span style={{ marginLeft: '8px' }} />
      </>
    )}

    <ItemScansButton item={item} />

    <span style={{ marginLeft: '8px' }} />

    <RemoveFromListButton
      item={item}
      removeTag={removeTag}
    />

    <Authorized role="DEVELOPER">
      <JSONModal data={item} style={{ marginTop: '6px' }} />
    </Authorized>
  </>
);

export default InventoryTableItemTools;
