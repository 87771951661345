import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Builder } from '@ounai/react-query-builder';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/*
function Select({ disabled, emptyValue, onChange, selectedValue, values }) {
  // TODO
  console.log('value:', values);

  return (
    <Form.Control
      as="select"
      onChange={onChange}
      className="w-auto"
      disabled={disabled}
      value={selectedValue}
      placeholder={emptyValue}
    >
      {values.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Form.Control>
  );
}

function SelectMulti(props) {
  // TODO
  console.log('SelectMulti:', props);

  return '(multiselect)';
}

function Switch(props) {
  // TODO
  console.log('Switch:', props);

  return '(switch)';
}

function Input(props) {
  // TODO
  console.log('Input:', props);

  return '(input)';
}
*/

const Remove = ({ label, onClick, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="danger"
      size="sm"
      onClick={onClick}
      className="w-auto"
      {...props}
    >
      {label === 'Delete' ? t('Delete') : label}
    </Button>
  );
};

const Add = ({ label, onClick, ...props }) => (
  <Button
    size="sm"
    onClick={onClick}
    className="w-auto"
    {...props}
  >
    {label}
  </Button>
);

/*
function Component(props) {
  // TODO

  console.log('Component:', props);

  return '(component)';
}

function GroupHeaderOption(props) {
  // TODO

  console.log('GroupHeaderOption:', props);

  return '(group header option)';
}
*/

const QueryBuilder = ({ resource, fields, query, onChange }) => {
  const { t } = useTranslation();

  const [currentQuery, setCurrentQuery] = useState(
    typeof query === 'string'
      ? JSON.parse(query)
      : (query ?? [])
  );

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(currentQuery);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuery]);

  const strings = {
    group: {
      not: t('Not'),
      or: t('Or'),
      and: t('And'),
      addRule: t('Add Rule'),
      addGroup: t('Add Group'),
      delete: t('Delete')
    },
    components: {
      delete: t('Delete')
    },
    form: {
      selectYourValue: t('Select your value')
    },
    operators: {
      LARGER: t('Larger'),
      SMALLER: t('Smaller'),
      LARGER_EQUAL: t('Larger or equal'),
      SMALLER_EQUAL: t('Smaller or equal'),
      EQUAL: t('Equal'),
      NOT_EQUAL: t('Not equal'),
      ALL_IN: t('All in'),
      ANY_IN: t('Any in'),
      NOT_IN: t('Not in'),
      BETWEEN: t('Between'),
      NOT_BETWEEN: t('Not between'),
      LIKE: `${t('Like')} (% = ${t('wild card')})`,
      NOT_LIKE: `${t('Not like')} (% = ${t('wild card')})`
    }
  };

  // TODO
  const components = {
    form: {
      // Select,
      // SelectMulti,
      // Switch,
      // Input
    },
    Remove,
    Add
    // Component,
    // GroupHeaderOption
  };

  return (
    <>
      <span className="query-builder-container">
        <Builder
          fields={fields}
          onChange={setCurrentQuery}
          data={currentQuery}
          strings={strings}
          components={components}
        />
      </span>

      <Button
        size="sm"
        as={Link}
        to={`/${resource}?query=${JSON.stringify(currentQuery)}`}
        style={{ marginTop: '10px' }}
      >
        {t('Execute Query')}
      </Button>
    </>
  );
};

export default QueryBuilder;
